import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { activitiesData } from "../constans";

type LearningActivities = {
  id: number;
  type: string;
  date: string;
  course: string;
  icon: JSX.Element;
};

type LearningActiviriesStore = {
  learningActivitiesData: LearningActivities[];
};

export const useLearningActivityStore = create<LearningActiviriesStore>()(
  persist(
    (set) => ({
      learningActivitiesData: activitiesData,
    }),
    {
      name: "learningActivity-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
