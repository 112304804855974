import { AddCircle, Link2 } from "iconsax-react";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Breadcrumb from "../../../../components/materials/Breadcrump";
import { useNavigate } from "react-router-dom";

export default function CreateSubjectProfessor() {
  const [lectureName, setLectureName] = useState("");
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [certificate, setCertificate] = useState<File | null>(null);
  const [enrollmentPeriod, setEnrollmentPeriod] = useState("");
  const [lecturePeriod, setLecturePeriod] = useState("");
  const [capacity, setCapacity] = useState("");
  const [category, setCategory] = useState("");
  const [requirements, setRequirements] = useState("");
  const [instructor, setInstructor] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  const handleNext = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    navigate("/dashboard-professor/create-lecture-form2");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const handleFileChange =
    (setter: React.Dispatch<React.SetStateAction<File | null>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0] || null;
      setter(file);
    };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate("/dashboard-professor/create-lecture-form2");
    window.scrollTo(0, 0);
  };

  return (
    <div className="min-h-screen bg-white p-6 rounded-lg shadow">
      <form
        onSubmit={handleSubmit}
        className="w-full px-4 py-6 bg-white rounded-2xl flex flex-col gap-7"
      >
        <div className="flex items-center">
          <Breadcrumb
            items={[
              { title: "강의", path: "/dashboard-professor/subject" },
              {
                title: "강의 등록",
                path: "/dashboard-professor/create-lecture-form",
              },
            ]}
          />
        </div>
        <div className="text-ledt gap-1 flex text-[#242424] text-xl font-bold font-['Pretendard']">
          강의 등록 <span className="text-primary">1/2</span>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-[#242424] text-base font-bold font-['Pretendard']">
            기본정보
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                강좌명
              </span>
              <input
                type="text"
                value={lectureName}
                onChange={(e) => setLectureName(e.target.value)}
                placeholder="강좌명을 입력해 주세요"
                className="w-full h-[54px] px-4 py-[15px] bg-white rounded-xl border border-[#e0e0e0] text-[#a5a5a5] text-base font-medium font-['Pretendard']"
              />
            </div>
            <div className="flex flex-wrap gap-5">
              <div className="flex-1 flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                    강의 썸네일
                  </span>
                  <span className="text-[#a5a5a5] text-xs font-medium font-['Pretendard']">
                    *png, jpg 형태의 파일만 가능합니다.
                  </span>
                </div>
                <label
                  htmlFor="thumbnail-upload"
                  className="cursor-pointer w-full h-14 px-7 py-4 rounded-xl border border-[#e0e0e0] flex items-center justify-center text-[#a5a5a5] border-dashed text-base font-medium font-['Pretendard']"
                >
                  {thumbnail ? thumbnail.name : <AddCircle size={18} />}
                </label>
                <input
                  id="thumbnail-upload"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange(setThumbnail)}
                  className="hidden"
                />
              </div>

              <div className="flex-1 flex flex-col gap-2">
                <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                  강의 수료증
                </span>
                <label
                  htmlFor="certificate-upload"
                  className="cursor-pointer w-full h-14 px-7 py-4 rounded-xl border border-[#e0e0e0] flex items-center justify-between text-[#a5a5a5] text-base font-medium font-['Pretendard']"
                >
                  파일을 선택해 주세요
                  <Link2 />
                </label>
                <input
                  id="certificate-upload"
                  type="file"
                  onChange={handleFileChange(setCertificate)}
                  className="hidden"
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-5">
              <div className="flex-1 flex flex-col gap-2">
                <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                  수강신청 기간
                </span>
                <input
                  type="date"
                  value={enrollmentPeriod}
                  onChange={(e) => setEnrollmentPeriod(e.target.value)}
                  className="w-full h-[54px] px-4 py-[15px] bg-white rounded-xl border border-[#e0e0e0] text-[#a5a5a5] text-base font-medium font-['Pretendard']"
                />
              </div>
              <div className="flex-1 flex flex-col gap-2">
                <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                  강의 기간
                </span>
                <input
                  type="date"
                  value={lecturePeriod}
                  onChange={(e) => setLecturePeriod(e.target.value)}
                  className="w-full h-[54px] px-4 py-[15px] bg-white rounded-xl border border-[#e0e0e0] text-[#a5a5a5] text-base font-medium font-['Pretendard']"
                />
              </div>
            </div>

            <div className="flex flex-wrap gap-5">
              <div className="flex-1 flex flex-col gap-2">
                <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                  모집인원
                </span>
                <input
                  type="number"
                  value={capacity}
                  onChange={(e) => setCapacity(e.target.value)}
                  placeholder="모집인원을 입력해주세요"
                  className="w-full h-[54px] px-4 py-[15px] bg-white rounded-xl border border-[#e0e0e0] text-[#a5a5a5] text-base font-medium font-['Pretendard']"
                />
              </div>
              <div className="flex-1 flex flex-col gap-2">
                <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                  분류
                </span>
                <input
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  placeholder="분류를 선택해주세요"
                  className="w-full h-[54px] px-4 py-[15px] bg-white rounded-xl border border-[#e0e0e0] text-[#a5a5a5] text-base font-medium font-['Pretendard']"
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-5">
              <div className="flex-1 flex flex-col gap-2">
                <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                  수강 조건
                </span>
                <input
                  type="text"
                  value={requirements}
                  onChange={(e) => setRequirements(e.target.value)}
                  placeholder="수강조건을 입력해주세요"
                  className="w-full h-[54px] px-4 py-[15px] bg-white rounded-xl border border-[#e0e0e0] text-[#a5a5a5] text-base font-medium font-['Pretendard']"
                />
              </div>
              <div className="flex-1 flex flex-col gap-2">
                <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                  담당 강사
                </span>
                <input
                  type="text"
                  value={instructor}
                  onChange={(e) => setInstructor(e.target.value)}
                  placeholder="강사를 선택해 주세요"
                  className="w-full h-[54px] px-4 py-[15px] bg-white rounded-xl border border-[#e0e0e0] text-[#a5a5a5] text-base font-medium font-['Pretendard']"
                />
              </div>
            </div>
            <div className="flex items-start gap-4">
              <span className="text-[#242424] text-base font-bold font-['Pretendard']">
                공개여부
              </span>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    checked={isPublic}
                    onChange={() => setIsPublic(true)}
                    className="w-5 h-5 border border-[#c3c3c3]"
                  />
                  <span className="text-[#242424] text-base font-medium font-['Pretendard']">
                    공개
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    checked={!isPublic}
                    onChange={() => setIsPublic(false)}
                    className="w-5 h-5 border border-[#c3c3c3]"
                  />
                  <span className="text-[#242424] text-base font-medium font-['Pretendard']">
                    비공개
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={modules}
            placeholder="내용을 입력해주세요"
          />
        </>
        <div className="flex flex-col gap-2">
          <span className="text-[#242424] text-base font-bold font-['Pretendard']">
            강좌명
          </span>
          <input
            type="text"
            value={lectureName}
            onChange={(e) => setLectureName(e.target.value)}
            placeholder="강좌명을 입력해 주세요"
            className="w-full h-[54px] px-4 py-[15px] bg-white rounded-xl border border-[#e0e0e0] text-[#a5a5a5] text-base font-medium font-['Pretendard']"
          />
        </div>
        <div className="flex justify-end ">
          <div className="h-10 justify-start items-center gap-3 inline-flex">
            <button
              className="w-[120px] h-10 px-9 py-2.5 bg-white rounded-[10px] border border-[#2860ef] justify-center items-center gap-1 flex"
              onClick={handleNext}
            >
              <div className="justify-center items-center gap-1 flex">
                <div className="text-center text-[#2860ef] text-sm font-bold font-['Pretendard'] leading-tight">
                  등록
                </div>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
