import React from "react";
import { ArrowRight2 } from "iconsax-react";

function QASection() {
  const questions = [
    {
      status: "답변 완료",
      course: "JavaScript 기초",
      date: "23.02.04 23:59",
      icon: <ArrowRight2 />,
    },
    {
      status: "미확인",
      course: "JavaScript 기초",
      date: "23.02.04 23:59",
      icon: <ArrowRight2 />,
    },
  ];

  return (
    <div className="bg-white ">
      <div className="flex justify-between items-center mb-4 p-2 border-b">
        <h3 className="text-lg font-bold">Q&A</h3>
      </div>
      <ul>
        {questions.map((question, index) => (
          <li key={index} className="flex items-center justify-between py-2">
            <div className="flex items-center gap-4">
              <span
                className={`flex items-center justify-center w-20 h-8 text-xs rounded ${
                  question.status === "답변 완료"
                    ? "bg-blue-100  border-blue-500 text-blue-500 text-blue-500"
                    : "bg-gray-100 border text-gray-500"
                }`}
              >
                {question.status}
              </span>
              <div>
                <p className="text-gray-900">{question.course}</p>
                <p className="text-gray-500 text-xs">{question.date}</p>
              </div>
            </div>
            <div className="text-blue-500 text-2xl">{question.icon}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default QASection;
