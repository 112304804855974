import { type ComponentPropsWithoutRef, forwardRef } from "react";

type TextAreaProps = {
  label?: string;
  isRequired?: boolean;
  id: string;
  errorMessage?: string;
  rows? : number;
} & ComponentPropsWithoutRef<"textarea">;

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function (
  { label, id, errorMessage, isRequired, rows = 10 , ...props },
  ref
) {
  return (
    <div className="grid">
      {
        label &&
        <label htmlFor={id} className="block relative mb-2">
          <span className={`after:ml-0.5 after:absolute after:bottom-1 after:text-red-500 block text-grey-7 text-base font-bold ${isRequired && "after:content-['*']" } `  }>
            {label}
          </span>
        </label>
      }
      <div className="border text-grey-6 font-medium py-3 px-4 rounded-xl bg-white border-gray">
        <textarea
          ref={ref}
          className="focus:outline-none w-full"
          id={id}
          rows={rows}
          {...props}
        />
      </div>
    </div>
  );
});

export default TextArea;
