import Button from "../../components/materials/Button"
import Check from "../../assets/check.jpg"
import { useNavigate } from 'react-router-dom'
const InformationExist = () => {
  const navigate = useNavigate()
  const handleClick = function () {
    navigate('/login')
  }
  return (
    <div className="bg-blue-1 h-full flex items-center justify-center">
      <div className="grid gap-6 py-12 px-28 rounded-[20px] bg-white border border-grey-1">
        <div className="flex justify-center">
         <img src={Check} alt="" className="w-[158px]" />
        </div>
        <div>
          <div className="text-grey-5 text-center text-[20px] font-medium">홍길동님의 아이디는</div>
          <div className="mt-2 text-primary text-2xl font-bold">abcd038@naver.com <span className="text-grey-9">입니다.</span></div>
        </div>
        <Button onClick={handleClick} variant="primary">로그인</Button>
      </div>
    </div>
    
  )
}

export default InformationExist