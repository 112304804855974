import { ButtonProps, colorVariants , sizeVariants } from "./buttonModel"

const Button = ({
  children,
  variant = 'primary' ,
  size = 'md',
  ...props
} : ButtonProps) => {

  return (
    <button 
      className={`
        ${colorVariants[variant as keyof typeof colorVariants]} 
        ${sizeVariants[size as keyof typeof sizeVariants]}
      `} 
      {...props} >
      {children}
    </button>
  )
}

export default Button