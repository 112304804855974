const IconQuateLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M2.92537 10.9967C1.01493 10.9967 0 9.74744 0 7.5294C0 5.10817 1.44198 3.55145 4.07427 2.27829C4.41854 2.11179 4.83004 2.23782 5.03575 2.56019C5.29155 2.96105 5.12614 3.49127 4.7121 3.72512C3.28975 4.52847 2.59701 5.53937 2.59701 6.99402L2.80597 9.33953L1.19403 7.50391C1.70149 7.17248 2.26866 6.91753 2.92537 6.91753C4.20895 6.91753 5.28358 7.65688 5.28358 8.85513C5.28358 10.1809 4.29851 10.9967 2.92537 10.9967Z"
        fill="#E0E0E0"
      />
      <path
        d="M9.40389 10.9967C7.49344 10.9967 6.47852 9.74744 6.47852 7.5294C6.47852 5.10817 7.92049 3.55145 10.5528 2.27829C10.8971 2.11179 11.3086 2.23782 11.5143 2.56019C11.7701 2.96105 11.6047 3.49127 11.1906 3.72512C9.76827 4.52847 9.07553 5.53937 9.07553 6.99402L9.28449 9.33953L7.67255 7.50391C8.18001 7.17248 8.74717 6.91753 9.40389 6.91753C10.6875 6.91753 11.7621 7.65688 11.7621 8.85513C11.7621 10.1809 10.777 10.9967 9.40389 10.9967Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};

const IconQuateRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M9.07463 1.00332C10.9851 1.00332 12 2.25256 12 4.4706C12 6.89183 10.558 8.44855 7.92573 9.72171C7.58146 9.88821 7.16996 9.76218 6.96425 9.43981C6.70845 9.03895 6.87386 8.50873 7.2879 8.27488C8.71025 7.47153 9.40299 6.46063 9.40299 5.00598L9.19403 2.66047L10.806 4.49609C10.2985 4.82752 9.73134 5.08247 9.07463 5.08247C7.79105 5.08247 6.71642 4.34312 6.71642 3.14487C6.71642 1.81915 7.70149 1.00332 9.07463 1.00332Z"
        fill="#E0E0E0"
      />
      <path
        d="M2.59611 1.00332C4.50656 1.00332 5.52148 2.25256 5.52148 4.4706C5.52148 6.89183 4.07951 8.44855 1.44721 9.72171C1.10295 9.88821 0.691446 9.76218 0.485731 9.43981C0.229933 9.03895 0.395347 8.50873 0.809386 8.27488C2.23173 7.47153 2.92447 6.46063 2.92447 5.00598L2.71551 2.66047L4.32745 4.49609C3.81999 4.82752 3.25283 5.08247 2.59611 5.08247C1.31253 5.08247 0.237904 4.34312 0.237904 3.14487C0.237904 1.81915 1.22298 1.00332 2.59611 1.00332Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};

export {
  IconQuateLeft,
  IconQuateRight
}
