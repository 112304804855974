import React, { useState } from "react";
import { Table } from "../../../components/tables";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination";
import Modal from "../../../components/modal";
import Button from "../../../components/materials/Button";
import CourseImage from "../../../assets/course-image.png";

interface CheckHistoryProps {
  id: number;
  no: number;
  date: string;
  course: string;
  payment: string;
  progress: string;
}

interface CheckHistory2Props {
  id: number;
  no: number;
  date: string;
  course: string;
  confirmation: string;
  status: string;
}

interface ExtendedCheckHistoryProps extends CheckHistoryProps {
  isChecked: boolean;
  toggleCheckbox: () => void;
}

const dataCheckHistory: CheckHistoryProps[] = [
  {
    id: 1,
    no: 1,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 2,
    no: 2,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 3,
    no: 3,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 4,
    no: 4,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 5,
    no: 5,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 6,
    no: 6,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 7,
    no: 7,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 8,
    no: 8,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 9,
    no: 9,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 10,
    no: 10,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
];

const dataCheckHistory2: CheckHistory2Props[] = [
  {
    id: 1,
    no: 1,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 2,
    no: 2,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 3,
    no: 3,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 4,
    no: 4,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 5,
    no: 5,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 6,
    no: 6,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 7,
    no: 7,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 8,
    no: 8,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 9,
    no: 9,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
  {
    id: 10,
    no: 10,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    confirmation: "신청확인",
    status: "환불 대기중",
  },
];

const columns: ColumnDef<ExtendedCheckHistoryProps>[] = [
  {
    accessorKey: "checkbox",
    header: "",
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={row.original.isChecked}
        onChange={row.original.toggleCheckbox}
      />
    ),
  },
  {
    accessorKey: "no",
    header: "NO.",
  },
  {
    accessorKey: "date",
    header: "신청일",
  },
  {
    accessorKey: "course",
    header: "강의명",
  },
  {
    accessorKey: "payment",
    header: "결제수단",
  },
  {
    accessorKey: "progress",
    header: "진행사항",
  },
  {
    accessorKey: "refund",
    header: "",
    cell: () => null,
  },
];

const columns2: ColumnDef<CheckHistory2Props>[] = [
  {
    accessorKey: "no",
    header: "NO.",
  },
  {
    accessorKey: "date",
    header: "신청일",
  },
  {
    accessorKey: "course",
    header: "강의명",
  },
  {
    accessorKey: "confirmation",
    header: "신청확인",
  },
  {
    accessorKey: "status",
    header: "상태",
  },
  {
    accessorKey: "detail",
    header: "",
    cell: () => null,
  },
];

export default function CheckHistory() {
  const [activeTab, setActiveTab] = useState("payment-history");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const dataPerPage = 10;
  const navigate = useNavigate();
  const [showModalViewDetailRefund, setShowModalViewDetailRefund] =
    useState(false);
  const [showModalViewDetailRefundPolicy, setShowModalViewDetailRefundPolicy] =
    useState(false);

  const openModalViewDetailRefund = () => setShowModalViewDetailRefund(true);
  const closeModalViewDetailRefund = () => setShowModalViewDetailRefund(false);

  const openModalViewDetailRefundPolicy = () =>
    setShowModalViewDetailRefundPolicy(true);
  const closeModalViewDetailRefundPolicy = () =>
    setShowModalViewDetailRefundPolicy(false);

  const tabs = [
    { name: "결제내역", id: "payment-history" },
    { name: "환불시청", id: "refund-request" },
  ];

  const filteredDataCheckHistory = dataCheckHistory.filter(
    (notice) => activeTab === "payment-history"
  );
  const filteredDataCheckHistory2 = dataCheckHistory2.filter(
    (notice) => activeTab === "refund-request"
  );

  const totalPages = Math.ceil(filteredDataCheckHistory.length / dataPerPage);
  const totalPages2 = Math.ceil(filteredDataCheckHistory2.length / dataPerPage);

  const handleCheckboxChange = (id: number) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(id)) {
        newSelectedRows.delete(id);
      } else {
        newSelectedRows.add(id);
      }
      return newSelectedRows;
    });
  };

  const isButtonEnabled = selectedRows.size > 0;

  const extendedData: ExtendedCheckHistoryProps[] = filteredDataCheckHistory
    .slice((currentPage - 1) * dataPerPage, currentPage * dataPerPage)
    .map((row) => ({
      ...row,
      isChecked: selectedRows.has(row.id),
      toggleCheckbox: () => handleCheckboxChange(row.id),
    }));

  const handleRefundRequestClick = (row: CheckHistoryProps) => {
    navigate("/dashboard/check-history/" + row.id);
  };

  const handleRefundRequestClick2 = (row: CheckHistory2Props) => {
    openModalViewDetailRefund();
  };

  return (
    <div className="bg-white min-h-screen p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold border-b pb-2">내역확인</h2>
      <div className="flex justify-between items-center">
        <div className="flex">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`py-2 px-6 ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-600 text-blue-600"
                  : "text-gray-400"
              } focus:outline-none`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="flex justify-end">
          <div className="flex justify-end m-4">
            {activeTab === "payment-history" ? (
              <button
                className={`flex justify-center gap-1 items-center px-4 py-2 rounded-lg ${
                  isButtonEnabled
                    ? "border border-primary text-primary rounded-md text-sm cursor-pointer"
                    : "border border-gray-500 text-gray-500 rounded-md text-sm opacity-50 cursor-not-allowed"
                }`}
                onClick={() => {
                  if (isButtonEnabled) {
                    alert("영수증 발급이 완료되었습니다.");
                  }
                }}
              >
                영수증 발급
              </button>
            ) : (
              <button
                className={`flex justify-center gap-1 items-center px-4 py-2 rounded-lg border border-primary text-primary rounded-md text-sm cursor-pointer`}
                onClick={() => {
                  openModalViewDetailRefundPolicy();
                }}
              >
                환불 정책확인
              </button>
            )}
          </div>
        </div>
      </div>
      {activeTab === "payment-history" ? (
        <>
          <Table
            columns={columns}
            data={extendedData}
            onRefundRequestClick={handleRefundRequestClick}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </>
      ) : (
        <>
          <Table
            columns={columns2}
            data={filteredDataCheckHistory2}
            onRefundRequestClick={handleRefundRequestClick2}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages2}
            onPageChange={setCurrentPage}
          />
        </>
      )}

      {showModalViewDetailRefund && (
        <Modal
          title="환불 내역"
          onClose={() => {
            closeModalViewDetailRefund();
          }}
          size="lg"
        >
          <div className="space-y-4">
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">신청 정보</h3>
              <div className="border bg-gray-50 p-4 rounded-lg justify-between items-center">
                <div className="grid grid-cols-[40%_60%] gap-4 items-center">
                  <img
                    src={CourseImage}
                    alt="Course"
                    className="w-full h-36 rounded-lg"
                  />
                  <div className="space-y-2">
                    <p className="text-xl font-bold border-b pb-2">
                      기초 css 배우기
                    </p>
                    <p className="text-gray-500">최종 결제 금액</p>
                    <p className="text-2xl text-red-500 font-bold">25,200원</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="border bg-gray-50 p-4 rounded-lg">
              <div className="space-y-2">
                <div className="flex justify-between border-b border-gray-100 pb-1">
                  <span className=" font-semibold">예금주 명</span>
                  <span className="text-gray-500">김채피</span>
                </div>
                <div className="flex justify-between border-b border-gray-100 pb-1">
                  <span className="font-semibold">은행 명</span>
                  <span className="text-gray-500">신한</span>
                </div>
                <div className="flex justify-between border-b border-gray-100 pb-1">
                  <span className="font-semibold">계좌 번호</span>
                  <span className="text-gray-500">1234-4444-4444-77</span>
                </div>
                <div className="flex justify-between border-b border-gray-100 pb-1">
                  <span className="font-semibold">상태</span>
                  <span className="text-gray-500">환불 대기중</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                closeModalViewDetailRefund();
              }}
            >
              확인
            </Button>
          </div>
        </Modal>
      )}

      {showModalViewDetailRefundPolicy && (
        <Modal
          title="환불 정책"
          onClose={() => {
            closeModalViewDetailRefundPolicy();
          }}
          size="lg"
        >
          <div className="space-y-4">
            <div className="relative grid grid-cols-4 gap-4 items-end pb-12">
              <div className="flex flex-col items-center">
                <div className="bg-black text-white py-1.5 px-4 rounded-md font-semibold">
                  전액 환불
                </div>
                <div className="w-full h-0.5 bg-black my-2"></div>
                <div className="text-blue-500 text-sm">결제 후 7일</div>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-black text-white py-1.5 px-4 rounded-md font-semibold">
                  결제 금액 2/3
                </div>
                <div className="w-full h-0.5 bg-black my-2"></div>
                <div className="text-blue-500 text-sm">수업 1/3</div>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-black text-white py-1.5 px-4 rounded-md font-semibold">
                  결제 금액 1/2
                </div>
                <div className="w-full h-0.5 bg-black my-2"></div>
                <div className="text-blue-500 text-sm">수업 2/3</div>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-black text-white py-1.5 px-4 rounded-md font-semibold">
                  환불 불가
                </div>
                <div className="w-full h-0.5 bg-black my-2"></div>
                <div className="text-blue-500 text-sm">수업 1/2 이후</div>
              </div>

              <div className="absolute bottom-0 left-0 right-0 flex">
                <div className="w-1/4 bg-blue-500 text-white text-center py-2 rounded-l-lg">
                  수업 시작 전
                </div>
                <div className="w-1/4 bg-blue-300 text-white text-center py-2">
                  수업 1/3
                </div>
                <div className="w-1/4 bg-blue-200 text-white text-center py-2">
                  수업 2/3
                </div>
                <div className="w-1/4 bg-blue-100 text-blue-500 text-center py-2 rounded-r-lg">
                  수업 1/2 이후
                </div>
              </div>
            </div>

            <div className="border bg-gray-50 p-4 rounded-lg">
              <div className="space-y-2">
                <div className="flex justify-between border-b border-gray-100 pb-1">
                  <span className="font-semibold">전액환불</span>
                  <span className="text-gray-500">
                    결제 후 7일 이내 및 수업 시작 전
                  </span>
                </div>
                <div className="flex justify-between border-b border-gray-100 pb-1">
                  <span className="font-semibold">결제 금액 2/3</span>
                  <span className="text-gray-500">
                    수업 커리큘럼 1/3 경과 전
                  </span>
                </div>
                <div className="flex justify-between border-b border-gray-100 pb-1">
                  <span className="font-semibold">결제 금액 1/2</span>
                  <span className="text-gray-500">
                    수업 커리큘럼 2/3 경과 전
                  </span>
                </div>
                <div className="flex justify-between border-b border-gray-100 pb-1">
                  <span className="font-semibold">환불 불가</span>
                  <span className="text-gray-500">
                    수업 커리큘럼 1/2 경과 후
                  </span>
                </div>
              </div>
            </div>
            <p className="text-gray-500 text-sm mt-4">
              * 위약금 및 반환 수수료 - 10% <br />
              * 대금 환불 - 전화 후 3일 영업일 이내 <br />* 10원 단위는
              절사됩니다
            </p>
            <p className="text-gray-500 text-sm mt-4">
              환불을 원할시,{" "}
              <button
                className="text-blue-500"
                onClick={() => {
                  /* handle button click */
                }}
              >
                고객센터
              </button>{" "}
              에 문의해 주세요
            </p>
          </div>
          <div className="mt-4">
            <button
              className="w-full bg-blue-500 text-white py-2 rounded-lg"
              onClick={() => {
                closeModalViewDetailRefundPolicy();
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
