import Footer from "../../components/containers/footer";
import Input from "../../components/materials/Input";
import { ArrowDown2, SearchNormal1 } from "iconsax-react";
import Pagination from "../Announcement/pagination";
import ListLectures from "./list";
const Lectures = () => {
  return (
    <div>
      <div
        className=" 2xl:px-72 px-40 
        py-14 bg-white"
      >
        <div className="flex gap-2 justify-end items-center">
          {/* field */}
          <div className="inline-block">
            <div className="flex items-center gap-2 text-grey-3 border border-grey-1 rounded-[4px] px-3 py-[6px]">
              <div className=" text-sm font-medium">분야</div>
              <ArrowDown2 size={15} />
            </div>
          </div>
          {/* difficulty */}
          <div className="inline-block">
            <div className="flex items-center gap-2 text-grey-3 border border-grey-1 rounded-[4px] px-3 py-[6px]">
              <div className=" text-sm font-medium">난이도</div>
              <ArrowDown2 size={15} />
            </div>
          </div>
          {/* Language */}
          <div className="inline-block">
            <div className="flex items-center gap-2 text-grey-3 border border-grey-1 rounded-[4px] px-3 py-[6px]">
              <div className=" text-sm font-medium">언어</div>
              <ArrowDown2 size={15} />
            </div>
          </div>
          <div className="w-[335px]">
          <Input icons={<SearchNormal1 size={16} />}   placeholder="가장쉬운 코딩 학습, 채피" sizeVariant="sm" id="keyword" /> 
          </div>
        </div>
        <ListLectures/>
        <div className="mt-4"></div>
        <Pagination/>
      </div>
      <Footer variant="blue" />
    </div>
  );
};

export default Lectures;
