import React from "react";
import { useParams, Link } from "react-router-dom";
import ExampleProfle from "../../../assets/profile-img.png";
import { Edit2, Trash } from "iconsax-react";
import Breadcrumb from "../../../components/materials/Breadcrump";

interface BulletinLearningDetailProps {
  id: number;
  no: number;
  course: string;
  title: string;
  author: string;
  date: string;
  content: string;
}

const dummyData: BulletinLearningDetailProps[] = [
  {
    id: 1,
    no: 1,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
    content: `안녕하세요 학습자여러분, 김강사 입니다.
날씨가 점점 추워지는데 옷 따뜻히 입으시길 바랍니다.

수업 관련 공지가 있어 안내 드립니다.

학습시간을 9시에서 11시로 변경하도록 하겠습니다.

감사합니다.`,
  },
  {
    id: 2,
    no: 2,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
    content: `안녕하세요 학습자여러분, 김강사 입니다.
날씨가 점점 추워지는데 옷 따뜻히 입으시길 바랍니다.

수업 관련 공지가 있어 안내 드립니다.

학습시간을 9시에서 11시로 변경하도록 하겠습니다.

감사합니다.`,
  },
];

const DetailBulletinLearning: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const notice = dummyData.find((item) => item.id === parseInt(id || "", 10));

  if (!notice) {
    return (
      <div className="bg-white p-6 rounded-lg text-center">
        Notice not found
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg">
      <Breadcrumb
        items={[
          { title: "게시판", path: "/dashboard/bulletin" },
          { title: notice.title, path: `/dashboard/bulletin/${notice.id}` },
        ]}
      />
      <div className="mb-4 space-y-4">
        <h2 className="text-2xl font-bold">{notice.title}</h2>
        <div className="flex justify-between items-center text-gray-600 text-sm mb-4">
          <div className="space-x-6 text-md">
            <span className="text-md">작성자: {notice.author}</span>
            <span className="text-md">작성일: {notice.date}</span>
            <span className="text-md">강의명: {notice.course}</span>
          </div>
          <div className="flex space-x-6">
            <button className="flex gap-1 justify-center items-center text-gray-500">
              <Edit2 size="18" />
              수정
            </button>
            <button className="flex gap-1 justify-center items-center text-gray-500">
              <Trash size="18" />
              삭제
            </button>
          </div>
        </div>
        <div className="border-y py-4">{notice.content}</div>
      </div>
      <div className="flex justify-end">
        <Link
          to="/dashboard/bulletin"
          className="text-blue-600 text-sm bg-primary p-2 text-white px-3 rounded-lg"
        >
          목록으로 이동
        </Link>
      </div>
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4">
          댓글
          <span className="text-primary ml-2">3</span>
        </h3>
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="border-b p-4 rounded-lg mb-4">
            <div className="flex items-center">
              <img
                src={ExampleProfle}
                alt="avatar"
                className="w-10 h-10 rounded-full mr-4"
              />
              <div className="flex space-x-2 justify-center items-center">
                <p className="font-bold">김채피</p>
                <p className="text-xs text-gray-600">2023.3.13</p>
              </div>
            </div>
            <p className="ml-14 mb-1">덕 좋은 정보 좋아요!</p>
            <button className="ml-14 text-blue-600 text-sm">댓글 작성</button>
          </div>
          <div className="border-b p-4 rounded-lg mb-4">
            <div className="flex items-center">
              <img
                src={ExampleProfle}
                alt="avatar"
                className="w-10 h-10 rounded-full mr-4"
              />
              <div className="flex space-x-2 justify-center items-center">
                <p className="font-bold">김채피</p>
                <p className="text-xs text-gray-600">2023.3.13</p>
              </div>
            </div>
            <p className="ml-14 mb-1">덕 좋은 정보 좋아요!</p>
            <button className="ml-14 text-blue-600 text-sm">댓글 작성</button>
          </div>
          <div className="border-b p-4 rounded-lg mb-4">
            <div className="flex items-center">
              <img
                src={ExampleProfle}
                alt="avatar"
                className="w-10 h-10 rounded-full mr-4"
              />
              <div className="flex space-x-2 justify-center items-center">
                <p className="font-bold">김채피</p>
                <p className="text-xs text-gray-600">2023.3.13</p>
              </div>
            </div>
            <p className="ml-14 mb-1">덕 좋은 정보 좋아요!</p>
            <button className="ml-14 text-blue-600 text-sm">댓글 작성</button>
          </div>
        </div>
        <div className="flex items-center mt-6 border-t pt-6">
          <img
            src={ExampleProfle}
            alt="avatar"
            className="w-10 h-10 rounded-full mr-4"
          />
          <input
            type="text"
            placeholder="댓글 작성하기"
            className="flex-1 border rounded-md px-4 py-2"
          />
          <button className="ml-4 text-blue-600">등록</button>
        </div>
      </div>
    </div>
  );
};

export default DetailBulletinLearning;
