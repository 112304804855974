import { useState } from "react";
import QASection from "../components/QASection";
import ReactApexChart from "react-apexcharts";
import Card from "../../../components/containers/card";
import IconAssignment from "../../../assets/IconAssignment.png";
import IconDate from "../../../assets/IconDate.png";
import IconTest from "../../../assets/IconTest.png";
import InstructorImage from "../../../assets/Instruktur.png";
import CourseImage from "../../../assets/course-image.png";
import NoticeSection from "../components/NoticeSection";
import QuestionSection from "../components/QuestionSection";
import useClickOutside from "../../../hook/useClickOutside";

const courses = [
  {
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    status: "시작이 좋아요",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    status: "시작이 좋아요",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    status: "시작이 좋아요",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    status: "시작이 좋아요",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
];
function Home() {
  const [isMenu, setIsMenu] = useState<{ [key: string]: boolean }>({
    data1: false,
    data2: false,
    data3: false,
  });

  const openMenu = function (data: string) {
    restartDrop();
    setTimeout(() => {
      setIsMenu({ ...isMenu, [data]: !isMenu[data] });
    }, 100);
  };

  const restartDrop = () => {
    setIsMenu({ data1: false, data2: false, data3: false });
  };

  // const handleClickOutside = function () {
  //   setIsMenu({ ...isMenu, data1: false, data2: false, data3: false });
  // };

  // const ref = useClickOutside(handleClickOutside);

  const heatmapData = {
    series: [
      {
        name: "출결",
        data: [52, 60, 70, 80, 90],
      },
      {
        name: "과제",
        data: [84, 75, 65, 55, 45],
      },
      {
        name: "시험",
        data: [48, 55, 65, 75, 85],
      },
    ],

    options: {
      chart: {
        type: "line" as const,
        height: 350,
        toolbar: {
          offsetX: 0,
          offsetY: -4,
          tools: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        curve: "smooth" as const,
      },
      xaxis: {
        categories: [
          "2019년 1분기",
          "2019년 2분기",
          "2019년 3분기",
          "2019년 4분기",
          "2019년 5분기",
        ],
      },
      colors: ["#2861EF", "#9758FF", "#FF537C"],
      markers: {
        size: [4, 4, 4],
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        x: {
          format: "yyyy년 M분기",
        },
      },
    },
  };
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col p-10 mb-6 space-y-6 bg-white rounded-2xl">
        <h1 className="text-xl font-bold border-b border-slate-100 ">
          전체 회원관리
        </h1>
        <div className="flex items-center justify-end gap-10 pb-5 border-b border-slate-100">
          <div
            onClick={() => openMenu("data1")}
            className="relative flex gap-2 cursor-pointer"
          >
            <div className="text-sm font-bold text-[#555555]">강의 </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className=" stroke-[#555555]"
              >
                <path
                  d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {isMenu.data1 && (
              <div className="shadow-menu rounded-xl top-11 absolute bg-white  w-[80px] drop-shadow-lg">
                <div className="flex items-center gap-2 px-4 py-2">
                  <div className="text-sm font-medium text-grey-6">Test1</div>
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => openMenu("data2")}
            className="relative flex gap-2 cursor-pointer"
          >
            <div className="text-sm font-bold text-[#555555]">강의 </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className=" stroke-[#555555]"
              >
                <path
                  d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {isMenu.data2 && (
              <div className="shadow-menu rounded-xl top-11 absolute bg-white  w-[80px] drop-shadow-lg">
                <div className="flex items-center gap-2 px-4 py-2">
                  <div className="text-sm font-medium text-grey-6">Test2</div>
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => openMenu("data3")}
            className="relative flex gap-2 cursor-pointer"
          >
            <div className="text-sm font-bold text-[#555555]">강의 </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className=" stroke-[#555555]"
              >
                <path
                  d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {isMenu.data3 && (
              <div className="shadow-menu rounded-xl top-11 absolute bg-white  w-[80px] drop-shadow-lg">
                <div className="flex items-center gap-2 px-4 py-2">
                  <div className="text-sm font-medium text-grey-6">Test3</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Card>
          <div className="px-2 pt-4">
            <ReactApexChart
              options={heatmapData.options}
              series={heatmapData.series}
              type="line"
              height={350}
            />
          </div>
        </Card>
      </div>
      <div className="grid flex-1 min-h-0 grid-cols-2 gap-6">
        <div className="flex flex-col col-span-1 p-5 bg-white rounded-lg shadow">
          <NoticeSection />
        </div>
        <div className="flex flex-col col-span-1 p-5 bg-white rounded-lg shadow">
          <QuestionSection />
        </div>
      </div>
    </div>
  );
}

export default Home;
