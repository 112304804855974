import InstructorImage from "../../../assets/instructorDummy.png";
const Instructors = () => {
  return (
    <div>
      <div className="text-grey-8 mb-7 text-xl font-bold">강사소개</div>
      <div className="grid grid-cols-[max-content_1fr] gap-12">
        <div>
          <div className="flex mb-4 justify-center w-[67px] h-[67px] bg-[#AAB6CF] rounded-full overflow-hidden">
            <img src={InstructorImage} alt="" />
          </div>
          <div className="flex items-center gap-2">
            <div className="text-grey-6 text-sm font-medium">강사</div>
            <div className="text-grey-1 text-sm  font-medium">|</div>
            <div className="text-base text-grey-7 font-bold">김코딩</div>
          </div>
        </div>
        <div className="p-5 border border-grey-1 rounded-xl grid gap-2">
          <div  className="text-sm flex gap-2 items-center">
            <div className="text-grey-6  font-medium">현</div>
            <div className="text-grey-1 font-medium">|</div>
            <div className="text-grey-7 font-bold">채피 대표 강사</div>
          </div>
          <div  className="text-sm flex gap-2 items-center font-medium">
            <div className="text-grey-6  ">전</div>
            <div className="text-grey-1 ">|</div>
            <div className="text-grey-6 ">코딩 경시대회 2등</div>
          </div>
          <div  className="text-sm flex gap-2 items-center font-medium">
            <div className="text-grey-6  ">전</div>
            <div className="text-grey-1 ">|</div>
            <div className="text-grey-6 ">코딩 컨설팅 4회 진행</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructors;
