import Solution from "../../assets/solution.png";
import Toa from "../../assets/toa.png"
import Chat from "../../assets/chat.png"
const WhyCappie = () => {
  return (
    <div className="py-[140px] px-[200px]">
        <div className="text-primary text-center text-xl font-bold mb-7">
          왜 채피일까요?
        </div>
        <div className="m-auto text-4xl font-bold w-[362px] ">
          철저한 학습 시스템으로 이해할 때까지 함께해요
        </div>
        <div className="mt-16 grid grid-cols-3">
          <div className="w-80 border border-[#F2F4F6] rounded-xl overflow-hidden">
            <div className="bg-blue-1 py-12 flex justify-center">
              <img src={Solution} alt="" />
            </div>
            <div className="py-6">
              <div className="text-center text-grey-8 font-bold text-xl mb-2">
                자동 평가 솔루션
              </div>
              <div className="text-center w-[174px] m-auto text-grey-6 text-sm font-bold">
                체점 알고리즘을 통한  사용자 성취도 및 스킬 분석 가능
              </div>
            </div>
          </div>
          <div className="w-80 border border-[#F2F4F6] rounded-xl overflow-hidden">
            <div className="bg-blue-1 py-12 flex justify-center">
              <img src={Toa} alt="" />
            </div>
            <div className="py-6">
              <div className="text-center text-grey-8 font-bold text-xl mb-2">
                개인 맞춤형 가이드
              </div>
              <div className="text-center w-[174px] m-auto text-grey-6 text-sm font-bold">
                오답 코드에 대한 빠른 실시간 피드백을 제공가능
              </div>
            </div>
          </div>
          <div className="w-80 border border-[#F2F4F6] rounded-xl overflow-hidden">
            <div className="bg-blue-1 py-12 flex justify-center">
              <img src={Chat} alt="" />
            </div>
            <div className="py-6">
              <div className="text-center text-grey-8 font-bold text-xl mb-2">
                친화적 코드 설명
              </div>
              <div className="text-center w-[174px] m-auto text-grey-6 text-sm font-bold">
                다양한 방식의 설명 및 해석으로 사용자 이해도 향상
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default WhyCappie