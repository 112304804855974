import BgIntro from "../../assets/bg_introduction.jpg";
import BgIntro2 from "../../assets/bgintro2.png"
import Story from "./story"
import Checkpoint from "./checkpoint";
import Solution from "./solution";
import Footer from "../../components/containers/footer";
const Introduction = () => {
  return (
    <div>
      <div>
        <img src={BgIntro} alt="" />
      </div>
      <Story/>
      <Checkpoint/>
      <Solution/>
      <div>
        <img src={BgIntro2} alt="" />
      </div>
      <Footer/>
    </div>
  );
};

export default Introduction;
