
import List from "./list";
import Pagination from "../../components/materials/Pagination";
import FormInquire from "./formInquire";
import Footer from "../../components/containers/footer";
const Inquiry = () => {
  return (
    <div>
      <div className="py-12 grid gap-12">
        <div className="text-center text-grey-9 font-bold text-2xl">
          자주 묻는 질문
        </div>
        <div className="flex justify-center">
          <div className="h-[56px] bg-bg_2 p-2 grid gap-2 grid-cols-4 w-[420px] rounded-lg">
            <div className="text-primary text-base font-bold bg-white rounded-lg flex justify-center items-center">
              전체
            </div>
            <div className="text-grey-3 font-medium flex justify-center items-center">
              도입문의
            </div>
            <div className="text-grey-3 font-medium flex justify-center items-center">
              학습문의
            </div>
          </div>
        </div>

        <List/>
        <Pagination/>
        <FormInquire/>
        <Footer/>
      </div>
    </div>
  );
};

export default Inquiry;
