import { ArrowDown2 } from "iconsax-react";
import { useState } from "react";
const List = () => {
  const [value, setValue] = useState(null);
  const items = [0, 1, 2];
  const handleClick = function (e: any, idx: any) {
    if (idx === value) {
      setValue(null);
      e.target.checked = false;
    } else {
      setValue(idx);
    }
  };
  return (
    <ul className="grid gap-4">
      {items.map((item) => {
        return (
          <li key={item} className=" items-center relative">
            <input
              id={`${item}`}
              type="radio"
              name="radio"
              onClick={(e) => handleClick(e, item)}
              className="peer absolute top-0 opacity-0"
            />
            <label htmlFor={`${item}`}>
              <div className="flex items-center gap-2">
                <div className="text-grey-7 dark:text-white text-base font-medium">
                  테스트케이스 - ${item + 1}
                </div>
                <div className="w-3 h-3 rounded-full bg-error"></div>
              </div>
            </label>
            <div
              className={`
                transition-transform duration-500
                peer-checked:rotate-180  rotate-0
                absolute top-0 bottom-0 right-3 
                dark:text-white
                h-4
                `}
            >
              <ArrowDown2 size="20" />
            </div>
            <div
              className="w-full overflow-hidden transition-all duration-500
                    max-h-0 mt-2 peer-checked:max-h-36  gap-4 items-start
                    peer-checked:py-4 peer-checked:px-5 bg-grey-1 peer-checked:rounded-[4px]
                "
            >
              <div className="text-grey-5 gap-2 text-xs fond-medium grid rounded-[4px] grid-cols-3 bg-grey-2 px-5 py-[6px]">
                <div>Input</div>
                <div>Output</div>
                <div>출력값</div>
              </div>
              <div className=" text-xs fond-medium gap-2  grid grid-cols-3 bg-grey-1 px-5 py-[6px]">
                <div className="text-grey-7">Solution(0)</div>
                <div className="text-grey-7">0</div>
                <div className="text-error">4</div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default List;
