import React from "react";

interface ModalProps {
  show?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const ModalMembership: React.FC<ModalProps> = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-start justify-center bg-black bg-opacity-50 z-[9999] py-10 overflow-y-scroll ">
      <div className="w-full max-w-lg p-6 bg-white rounded shadow-2xl">
        <button onClick={onClose} className="float-right text-gray-700">
          &times;
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ModalMembership;
