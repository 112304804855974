const IconDownload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M25.001 28H7C6.73478 28 6.48043 27.8946 6.29289 27.7071C6.10536 27.5196 6 27.2652 6 27V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H19.001L26.001 11V27C26.001 27.1313 25.9751 27.2614 25.9249 27.3827C25.8746 27.504 25.8009 27.6143 25.7081 27.7071C25.6152 27.8 25.505 27.8736 25.3837 27.9239C25.2623 27.9741 25.1323 28 25.001 28Z"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 4V11H26.001"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 19.5L16 23L19.5 19.5"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 15V23"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDownload;
