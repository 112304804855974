const IconCopy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M8 7.95141V4.66675C8 3.56218 8.8954 2.66675 10 2.66675H27.3333C28.4379 2.66675 29.3333 3.56218 29.3333 4.66675V22.0001C29.3333 23.1047 28.4379 24.0001 27.3333 24.0001H24.0116"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9993 8H4.66602C3.56145 8 2.66602 8.89543 2.66602 10V27.3333C2.66602 28.4379 3.56145 29.3333 4.66602 29.3333H21.9993C23.1039 29.3333 23.9993 28.4379 23.9993 27.3333V10C23.9993 8.89543 23.1039 8 21.9993 8Z"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCopy;
