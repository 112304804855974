export default function IconDataAnalys() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
      <path d="M4.76244 15.0846H2.53799C2.24096 15.0846 2.00195 14.8456 2.00195 14.5486V9.1415C2.00195 8.84448 2.2409 8.60547 2.53799 8.60547H4.76244C5.05947 8.60547 5.29848 8.84441 5.29848 9.1415V14.5508C5.29848 14.8456 5.05724 15.0846 4.76244 15.0846ZM3.07402 14.0125H4.22648V9.67747H3.07402V14.0125Z" fill="#A5A5A5" />
      <path d="M9.00854 15.0842H6.78408C6.48706 15.0842 6.24805 14.8453 6.24805 14.5482V10.7938C6.24805 10.4968 6.48699 10.2578 6.78408 10.2578H9.00854C9.30556 10.2578 9.54457 10.4968 9.54457 10.7938V14.5482C9.54457 14.8452 9.3034 15.0842 9.00854 15.0842ZM7.32011 14.0121H8.47257V11.3298H7.32011V14.0121Z" fill="#A5A5A5" />
      <path d="M13.2527 15.0857H11.0282C10.7312 15.0857 10.4922 14.8467 10.4922 14.5496V7.31338C10.4922 7.01636 10.7311 6.77734 11.0282 6.77734H13.2527C13.5497 6.77734 13.7887 7.01629 13.7887 7.31338V14.5496C13.7887 14.8466 13.5475 15.0857 13.2527 15.0857ZM11.5642 14.0136H12.7166V7.84941H11.5642V14.0136Z" fill="#A5A5A5" />
      <path d="M17.4988 15.0847H15.2743C14.9773 15.0847 14.7383 14.8458 14.7383 14.5487V10.3856C14.7383 10.0886 14.9772 9.84961 15.2743 9.84961H17.4988C17.7958 9.84961 18.0348 10.0886 18.0348 10.3856V14.5509C18.0348 14.8457 17.7936 15.0847 17.4988 15.0847ZM15.8103 14.0127H16.9628V10.9216H15.8103V14.0127Z" fill="#A5A5A5" />
      <path d="M21.7449 15.0848H19.5204C19.2234 15.0848 18.9844 14.8459 18.9844 14.5488V6.32314C18.9844 6.02612 19.2233 5.78711 19.5204 5.78711H21.7449C22.0419 5.78711 22.2809 6.02605 22.2809 6.32314V14.5488C22.2809 14.8458 22.042 15.0848 21.7449 15.0848ZM20.0564 14.0127H21.2089V6.85918H20.0564V14.0127Z" fill="#A5A5A5" />
      <path d="M8.20065 7.19648C8.05549 7.19648 7.91032 7.12492 7.80532 6.9818C7.60431 6.71213 7.61992 6.29384 7.83879 6.04891L10.9164 2.57053C11.1352 2.32286 11.4747 2.3421 11.6735 2.61177C11.8745 2.88144 11.8589 3.29973 11.64 3.54466L8.56245 7.02304C8.45974 7.13875 8.3302 7.19648 8.20065 7.19648Z" fill="#A5A5A5" />
      <path d="M6.32553 6.85937C6.4689 6.83666 6.6032 6.75659 6.68873 6.62543C6.85303 6.37782 6.7845 6.04496 6.53724 5.88286L3.05591 3.57603C2.8083 3.41173 2.47544 3.48026 2.31334 3.72752C2.14904 3.97514 2.21758 4.308 2.46484 4.47009L5.94616 6.77693C6.06229 6.85362 6.19758 6.87963 6.32553 6.85937Z" fill="#A5A5A5" />
      <path d="M17.1027 5.97432C16.9709 5.97432 16.8391 5.9274 16.7364 5.82916C16.5198 5.62591 16.5086 5.28866 16.7119 5.07203L19.8833 1.68173C20.0866 1.4651 20.4238 1.45394 20.6404 1.65718C20.8571 1.86043 20.8682 2.19768 20.665 2.41431L17.4935 5.80462C17.3886 5.91624 17.2456 5.97432 17.1027 5.97432Z" fill="#A5A5A5" />
      <path d="M15.8256 6.0611C15.7072 6.0611 15.5889 6.0231 15.4883 5.94271L12.4822 3.51055C12.2522 3.32515 12.2164 2.9879 12.4018 2.75565C12.5872 2.52563 12.9245 2.48987 13.1567 2.67526L16.1651 5.10742C16.3951 5.29282 16.4309 5.63007 16.2455 5.86232C16.1382 5.9941 15.9819 6.0611 15.8256 6.0611Z" fill="#A5A5A5" />
      <path d="M2.00105 4.66616C1.22384 4.66616 0.591797 4.03412 0.591797 3.25691C0.591797 2.4797 1.22384 1.84766 2.00105 1.84766C2.77826 1.84766 3.4103 2.4797 3.4103 3.25691C3.4103 4.03412 2.77826 4.66616 2.00105 4.66616ZM2.00105 2.91966C1.81565 2.91966 1.6638 3.07151 1.6638 3.25691C1.6638 3.4423 1.81565 3.59416 2.00105 3.59416C2.18644 3.59416 2.3383 3.4423 2.3383 3.25691C2.3383 3.07151 2.18637 2.91966 2.00105 2.91966Z" fill="#A5A5A5" />
      <path d="M7.22761 7.99624C6.4504 7.99624 5.81836 7.36419 5.81836 6.58698C5.81836 5.80978 6.4504 5.17773 7.22761 5.17773C8.00482 5.17773 8.63686 5.80978 8.63686 6.58698C8.63686 7.36413 8.00259 7.99624 7.22761 7.99624ZM7.22761 6.24967C7.04222 6.24967 6.89036 6.40152 6.89036 6.58692C6.89036 6.77231 7.04222 6.92417 7.22761 6.92417C7.413 6.92417 7.56486 6.77231 7.56486 6.58692C7.56486 6.40152 7.41294 6.24967 7.22761 6.24967Z" fill="#A5A5A5" />
      <path d="M12.1397 3.95327C11.3625 3.95327 10.7305 3.32122 10.7305 2.54402C10.7305 1.76681 11.3625 1.13477 12.1397 1.13477C12.9169 1.13477 13.549 1.76681 13.549 2.54402C13.549 3.32122 12.9169 3.95327 12.1397 3.95327ZM12.1397 2.20676C11.9543 2.20676 11.8025 2.35862 11.8025 2.54402C11.8025 2.72941 11.9543 2.88127 12.1397 2.88127C12.3251 2.88127 12.477 2.72941 12.477 2.54402C12.477 2.35862 12.325 2.20676 12.1397 2.20676Z" fill="#A5A5A5" />
      <path d="M16.5069 7.48452C15.7297 7.48452 15.0977 6.85247 15.0977 6.07527C15.0977 5.29806 15.7297 4.66602 16.5069 4.66602C17.2841 4.66602 17.9162 5.29806 17.9162 6.07527C17.9162 6.85247 17.2841 7.48452 16.5069 7.48452ZM16.5069 5.73802C16.3215 5.73802 16.1697 5.88987 16.1697 6.07527C16.1697 6.26066 16.3215 6.41252 16.5069 6.41252C16.6923 6.41252 16.8442 6.26066 16.8442 6.07527C16.8442 5.88987 16.6923 5.73802 16.5069 5.73802Z" fill="#A5A5A5" />
      <path d="M20.8702 2.8185C20.093 2.8185 19.4609 2.18646 19.4609 1.40925C19.4609 0.632044 20.093 0 20.8702 0C21.6474 0 22.2794 0.632044 22.2794 1.40925C22.2794 2.18646 21.6474 2.8185 20.8702 2.8185ZM20.8702 1.072C20.6848 1.072 20.5329 1.22386 20.5329 1.40925C20.5329 1.59465 20.6848 1.7465 20.8702 1.7465C21.0556 1.7465 21.2074 1.59465 21.2074 1.40925C21.2074 1.22386 21.0556 1.072 20.8702 1.072Z" fill="#A5A5A5" />
    </svg>
  )
}