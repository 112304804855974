type IconProps = {
  color? : string
}

const IconLamp : React.FC<IconProps> = (
  {
    color = '#2861EF'
  }
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_738_14076)">
        <path
          d="M12 2.81177C11.6119 2.81177 11.2969 3.12678 11.2969 3.51492C11.2969 3.90306 11.6119 4.21807 12 4.21807C14.3264 4.21807 16.2192 6.11049 16.2192 8.43699C16.2192 8.82513 16.5342 9.14014 16.9223 9.14014C17.3105 9.14014 17.6255 8.82513 17.6255 8.43699C17.6255 5.33515 15.1017 2.81177 12 2.81177Z"
          fill={color}
        />
        <path
          d="M10.1872 0.188437C7.01045 0.852213 4.43467 3.40325 3.75686 6.60025C3.18451 9.29942 3.88998 12.0094 5.69186 14.0354C6.57264 15.0254 7.07795 16.3328 7.07795 17.6252V19.0315C7.07795 19.9672 7.69061 20.7622 8.53529 21.0374C8.81326 22.679 10.2383 24.0005 11.9998 24.0005C13.7614 24.0005 15.1864 22.6799 15.4644 21.0374C16.3095 20.7622 16.9217 19.9672 16.9217 19.0315V17.6252C16.9217 16.3305 17.4284 15.039 18.3486 13.989C19.6953 12.4524 20.4373 10.4807 20.4373 8.43735C20.4373 3.11355 15.5647 -0.934731 10.1876 0.188906L10.1872 0.188437ZM11.9994 22.5937C11.0956 22.5937 10.3029 21.9716 10.0104 21.1358H13.9883C13.6958 21.9716 12.9031 22.5937 11.9994 22.5937ZM15.515 19.031C15.515 19.4187 15.1995 19.7342 14.8119 19.7342H9.18686C8.7992 19.7342 8.48373 19.4187 8.48373 19.031V18.3279H15.515V19.031ZM17.2906 13.0618C16.31 14.1807 15.7067 15.5298 15.5534 16.9216H8.44482C8.29201 15.5289 7.69014 14.1666 6.74186 13.1002C5.23998 11.4117 4.65311 9.14894 5.1317 6.89135C5.69045 4.25641 7.83733 2.11554 10.474 1.56474C14.975 0.623923 19.0301 4.00468 19.0301 8.43641C19.0301 10.1385 18.4123 11.7811 17.2901 13.0613L17.2906 13.0618Z"
          fill={color}
        />
        <path
          d="M2.10938 8.43701H0.703125C0.315 8.43701 0 8.75202 0 9.14016C0 9.5283 0.315 9.84332 0.703125 9.84332H2.10938C2.4975 9.84332 2.8125 9.5283 2.8125 9.14016C2.8125 8.75202 2.4975 8.43701 2.10938 8.43701Z"
          fill={color}
        />
        <path
          d="M2.39953 4.92123L1.40531 3.92697C1.13063 3.65227 0.685781 3.65227 0.411094 3.92697C0.136406 4.20167 0.136406 4.647 0.411094 4.92123L1.40531 5.91549C1.68 6.19018 2.12484 6.19018 2.39953 5.91549C2.67422 5.64079 2.67422 5.19546 2.39953 4.92123Z"
          fill={color}
        />
        <path
          d="M2.39953 12.3647C2.12484 12.09 1.67953 12.09 1.40531 12.3647L0.411094 13.359C0.136406 13.6337 0.136406 14.079 0.411094 14.3532C0.685781 14.6279 1.13063 14.6279 1.40531 14.3532L2.39953 13.359C2.67422 13.0843 2.67422 12.6394 2.39953 12.3647Z"
          fill={color}
        />
        <path
          d="M23.2969 8.43701H21.8906C21.5025 8.43701 21.1875 8.75202 21.1875 9.14016C21.1875 9.5283 21.5025 9.84332 21.8906 9.84332H23.2969C23.685 9.84332 24 9.5283 24 9.14016C24 8.75202 23.685 8.43701 23.2969 8.43701Z"
          fill={color}
        />
        <path
          d="M23.587 3.92697C23.3123 3.65227 22.867 3.65227 22.5928 3.92697L21.5986 4.92123C21.3239 5.19593 21.3239 5.64126 21.5986 5.91549C21.8733 6.19018 22.3186 6.19018 22.5928 5.91549L23.587 4.92123C23.8617 4.64653 23.8617 4.20167 23.587 3.92697Z"
          fill={color}
        />
        <path
          d="M23.5888 13.359L22.5946 12.3647C22.3199 12.09 21.8746 12.09 21.6004 12.3647C21.3262 12.6394 21.3257 13.0847 21.6004 13.359L22.5946 14.3532C22.8693 14.6279 23.3146 14.6279 23.5888 14.3532C23.863 14.0785 23.8635 13.6332 23.5888 13.359Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_738_14076">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconLamp;
