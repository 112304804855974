import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer: React.FC = () => {
  return (
    <div className="relative w-full h-full overflow-hidden">
      <ReactPlayer
        className="w-full h-full"
        url="https://www.youtube.com/watch?v=G_WaFtXdtt0"
        width="100%"
        height="100%"
        controls
      />
    </div>
  );
};

export default VideoPlayer;
