import React from "react";
import LogoChappy from "../../../assets/pencil-learning.png";
import IconAssignment from "../../../assets/IconAssignment.png";
import IconDate from "../../../assets/IconDate.png";
import IconTest from "../../../assets/IconTest.png";
import IconCoin from "../../../assets/coin.png";
import Book from "../../../assets/book.png";

function StatsCardsLearning() {
  const mainStats = [
    {
      label: "출결",
      value: "23",
      valueMax: "56회",
      icon: <img src={IconDate} alt="출결" width="55px" height="55px" />,
    },
    {
      label: "과제",
      value: "23",
      valueMax: "56회",
      icon: <img src={IconAssignment} alt="과제" width="55px" height="55px" />,
    },
    {
      label: "시험",
      value: "23",
      valueMax: "56회",
      icon: <img src={IconTest} alt="시험" width="55px" height="55px" />,
    },
  ];

  return (
    <div className="flex space-x-6">
      <div className="flex-1 grid grid-cols-2 gap-4 p-4 bg-primary rounded-lg shadow h-34 overflow-hidden relative">
        <div className="flex justify-start items-start relative">
          <img
            src={Book}
            alt="coin"
            className="w-48 h-48 absolute left-5 top-10 transform -translate-x-1/2 -translate-y-1/2"
          />
        </div>
        <div className="justify-end flex mt-2">
          <div className="w-[139px] h-[20px] justify-end items-center gap-2 inline-flex">
            <div className="text-white text-sm font-medium font-['Pretendard'] leading-tight">
              최종 성적
            </div>
            <div className="text-white text-2xl font-bold font-['Pretendard'] leading-[33.60px]">
              67.6%
            </div>
          </div>
          <div className="w-[496px] h-12 absolute left-[200px] top-[50px] bg-white rounded-lg flex justify-center items-center shadow">
            <div className="flex-grow flex-shrink basis-0 px-4 py-3 flex flex-col justify-center items-center text-xs">
              <div className="text-[#555555]">출석</div>
              <div className="text-[#2860ef]">20점</div>
            </div>
            <div className="flex-grow flex-shrink basis-0 px-4 py-3 flex flex-col justify-center items-center text-xs">
              <div className="text-[#555555]">과제</div>
              <div className="text-[#2860ef]">10점</div>
            </div>
            <div className="flex-grow flex-shrink basis-0 px-4 py-3 flex flex-col justify-center items-center text-xs">
              <div className="text-[#555555]">중간고사</div>
              <div className="text-[#2860ef]">30점</div>
            </div>
            <div className="flex-grow flex-shrink basis-0 px-4 py-3 flex flex-col justify-center items-center text-xs">
              <div className="text-[#555555]">기말고사</div>
              <div className="text-[#2860ef]">40점</div>
            </div>
            <div className="flex-grow flex-shrink basis-0 px-4 py-3 flex flex-col justify-center items-center text-xs">
              <div className="text-[#555555]">기타</div>
              <div className="text-[#2860ef]">10점</div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-1/4">
        <div className="bg-blue-100 rounded-lg px-6 py-4 h-32 rounded shadow flex justify-between items-center space-x-3">
          <img src={LogoChappy} alt="Profile" className="w-16 h-16" />
          <div className="flex flex-col">
            <div className="text-sm text-right text-gray-600">평균</div>
            <div className="text-3xl font-bold text-primary">67%</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsCardsLearning;
