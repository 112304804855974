import EvaluationsImage from "../../assets/evaluations.png";
import PersonalizedImage from "../../assets/personalized.png";
import FriendlyImage from "../../assets/friendly.png";

const Solution = () => {
  return (
    <div className="2xl:px-72 px-40 bg-white py-[140px]">
      <div className="text-center text-blue-4 text-xl font-bold mb-7">
        solution
      </div>
      <div className="text-black text-center mb-16  text-[40px] font-bold">
        채피가 제공하는 솔루션
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div className="p-7 bg-red rounded-xl border border-grey-1">
          <div className="bg-bg_2 rounded-lg h-[215px] flex justify-center items-center">
            <img src={EvaluationsImage} alt="" />
          </div>
          <div className="mt-7 grid gap-3">
            <div
              className="
              text-primary text-sm 
              font-medium border 
              border-blue-3 rounded-[60px]
              py-1.5 px-10
              w-max
              inline-block
            "
            >
              자동평가
            </div>
            <div className="text-grey-8 font-bold text-xl">
              <div>채점 알고리즘을 통한</div>
              <div>사용자 성취도 및 스킬 가능</div>
            </div>
            <div className="grid gap-1">
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •프로그램의 기능, 효율, 가독성 채점 알고리즘 개발
              </div>
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •코드 분석을 통한 사용자의 코딩 스킬 분석 알고리즘 개발
              </div>
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •사용자의 채점 결과 및 스킬 분석표를 통한 커리큘럼 제공
              </div>
            </div>
          </div>
        </div>
        <div className="p-7 bg-red rounded-xl border border-grey-1">
          <div className="bg-bg_2 rounded-lg h-[215px] flex justify-center items-center">
            <img src={PersonalizedImage} alt="" />
          </div>
          <div className="mt-7 grid gap-3">
            <div
              className="
              text-primary text-sm 
              font-medium border 
              border-blue-3 rounded-[60px]
              py-1.5 px-10
              w-max
              inline-block
            "
            >
              자동평가
            </div>
            <div className="text-grey-8 font-bold text-xl">
              <div>채점 알고리즘을 통한</div>
              <div>사용자 성취도 및 스킬 가능</div>
            </div>
            <div className="grid gap-1">
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •프로그램의 기능, 효율, 가독성 채점 알고리즘 개발
              </div>
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •코드 분석을 통한 사용자의 코딩 스킬 분석 알고리즘 개발
              </div>
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •사용자의 채점 결과 및 스킬 분석표를 통한 커리큘럼 제공
              </div>
            </div>
          </div>
        </div>
        <div className="p-7 bg-red rounded-xl border border-grey-1">
          <div className="bg-bg_2 rounded-lg h-[215px] flex justify-center items-center">
            <img src={FriendlyImage} alt="" />
          </div>
          <div className="mt-7 grid gap-3">
            <div
              className="
              text-primary text-sm 
              font-medium border 
              border-blue-3 rounded-[60px]
              py-1.5 px-10
              w-max
              inline-block
            "
            >
              자동평가
            </div>
            <div className="text-grey-8 font-bold text-xl">
              <div>채점 알고리즘을 통한</div>
              <div>사용자 성취도 및 스킬 가능</div>
            </div>
            <div className="grid gap-1">
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •프로그램의 기능, 효율, 가독성 채점 알고리즘 개발
              </div>
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •코드 분석을 통한 사용자의 코딩 스킬 분석 알고리즘 개발
              </div>
              <div className="text-grey-6 2xl:text-sm text-xs font-medium">
                •사용자의 채점 결과 및 스킬 분석표를 통한 커리큘럼 제공
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solution;
