import { ArrowRight2 } from "iconsax-react"
import RobotImage from "../../assets/robot.png"
const Intro = () => {
  return (
    <div className="bg-bg_3 2xl:px-72 px-40  py-36  flex justify-between ">
      <div>
        <div className="w-[345px]">
          <div className="text-white text-3xl font-bold mb-8">코딩공부, 외롭지 않게 채피와 행복하게 코딩하세요</div>
          <button className="py-4 px-16 rounded-xl text-blue-4 bg-white flex items-center">
            <div className="text-base font-bold">채피 도입 문의하기</div>
            <ArrowRight2 size="15"  />
          </button>
        </div>
      </div>
      <div>
        <img className="w-[271px]" src={RobotImage} alt="" />
      </div>
    </div>
  )
}

export default Intro