import Input from "../../../components/materials/Input";
import DummyImage from "../../../assets/dummyimage.png";
import { SearchNormal1 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Announcement/pagination";
import Button from "../../../components/materials/Button";
import IconPencil from "./iconPencil";
const NoticeManagement = () => {
  const navigation = useNavigate()
  const arrayDummy = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ,10 ,11];
  const handlerClick = function () {
    navigation('/dashboard-admin/notice-management/asd')
  }
  const openCreate = function () {
    navigation('/dashboard-admin/notice-management/create')
  }
  return (
    <div className="py-8 px-10 bg-white border border-grey-1 rounded-2xl">
      <div className="mb-4 text-xl text-grey-8 font-bold">공지관리</div>

      <div className="flex justify-between  items-center">
        <div className="h-14 flex text-base  bg-bg_2 rounded-[8px] p-2 gap-4">
          <div className="font-bold text-blue-4 flex px-7 items-center justify-center rounded-[8px] bg-white">전체</div>
          <div className="font-medium text-grey-3 flex px-7 items-center justify-center">공지</div>
          <div className="font-medium text-grey-3 flex px-7 items-center justify-center">뉴스</div>
          <div className="font-medium text-grey-3 flex px-7 items-center justify-center">소식</div>
        </div>
        <div className="">
          <Input id="search" placeholder="검색어를 입력하세요." icons={<SearchNormal1 size={15}/>} />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-7 my-5">
        {arrayDummy.map((i) => {
          return (
            <div onClick={handlerClick} key={i} className="relative cursor-pointer ">
              <div className="
              text-blue-4 text-sm font-medium
                rounded-[4px] border border-blue-3 bg-blue-1
                px-3 py-1.5 absolute top-4 left-4
              ">
                뉴스
              </div>
              <img src={DummyImage} alt="" />
              <div className="text-grey-9 text-base font-bold truncate">CHAT GPT 4.0과 숨막히는 코딩대결! 인공지능끼리 진검승부</div>
              <div className="text-grey-3 text-sm font-medium">2023. 12. 27</div>
            </div>
          );
        })}
      </div>
      
      <div className="flex justify-between items-center">
        <div></div>
        <Pagination/>
        <div className="w-[120px]">
          <Button onClick={openCreate} size="sm" variant="outline_primary" >
            <div className="flex items-center gap-2 justify-center">
              <IconPencil/>
              글쓰기
            </div>
              </Button>
        </div>
      </div>
    </div>
  );
};

export default NoticeManagement;
