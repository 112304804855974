import React from "react";
import ReactApexChart from "react-apexcharts";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function generateData(count: number, yrange: { min: number; max: number }) {
  const series = [];
  for (let i = 0; i < count; i++) {
    const x = months[i % months.length];
    const y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    series.push({ x, y });
  }
  return series;
}

const StatusCode: React.FC = () => {
  const heatmapData = {
    series: [
      {
        name: "Mon",
        data: generateData(12, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Tue",
        data: generateData(12, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Wed",
        data: generateData(12, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Thu",
        data: generateData(12, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Fri",
        data: generateData(12, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Sat",
        data: generateData(12, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Sun",
        data: generateData(12, {
          min: 0,
          max: 90,
        }),
      },
    ],
    options: {
      chart: {
        type: "heatmap" as const,
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          radius: 0,
          useFillColorAsStroke: false,
          distributed: false,
          enableShades: true,
          colorScale: {
            ranges: [
              { from: 0, to: 19, color: "#ffffff" },
              { from: 20, to: 39, color: "#C6D6FF" },
              { from: 40, to: 59, color: "#8AABFF" },
              { from: 60, to: 79, color: "#5E8CFF" },
              { from: 80, to: 100, color: "#2861EF" },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#2861EF"],
      xaxis: {
        categories: months,
        position: "bottom",
        tickPlacement: "between",
      },
      yaxis: {
        reversed: true,
      },
    },
  };

  return (
    <div className="bg-white min-h-screen	 p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">코딩현황</h2>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <div className="grid grid-cols-3 gap-4 min-h-min">
            <div className="flex flex-col justify-between bg-gray-50 border border-gray-100 p-6 rounded-lg">
              <div>
                <h3 className="text-lg font-semibold">최다 실행</h3>
                <p className="text-grey-3">가장 많이 실행된 횟수</p>
              </div>
              <div className="flex justify-end items-end">
                <p className="text-3xl font-bold text-blue-500">83</p>
                <span className="text-xl text-grey-3">회</span>
              </div>
            </div>

            <div className="flex flex-col justify-between bg-gray-50 border border-gray-100 p-6 rounded-lg">
              <div>
                <h3 className="text-lg font-semibold">오늘 실행</h3>
                <p className="text-grey-3">오늘 실행된 횟수</p>
              </div>
              <div className="flex justify-end items-end">
                <p className="text-3xl font-bold text-blue-500">83</p>
                <span className="text-xl text-grey-3">회</span>
              </div>
            </div>

            <div className="flex flex-col justify-between bg-gray-50 border border-gray-100 p-6 rounded-lg">
              <div>
                <h3 className="text-lg font-semibold">최근 실행</h3>
                <p className="text-grey-3">이번 주 내 실행 횟수</p>
              </div>
              <div className="flex justify-end items-end">
                <p className="text-3xl font-bold text-blue-500">83</p>
                <span className="text-xl text-grey-3">회</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <div className="pt-4 px-2 bg-gray-50 rounded-lg border border-gray-100">
            <ReactApexChart
              options={heatmapData.options}
              series={heatmapData.series}
              type="heatmap"
              height={500}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusCode;
