export default function IconAlgoritm() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
      <path d="M10.6953 4.88401H15.3047C15.5981 4.88401 15.8754 4.77058 16.082 4.56224C16.2879 4.35461 16.4032 4.07781 16.4032 3.78399V1.3965C16.4032 0.789679 15.9099 0.296484 15.3031 0.296484H10.6953C10.0885 0.296484 9.59531 0.789732 9.59531 1.3965V3.78399C9.59531 4.39081 10.0886 4.88401 10.6953 4.88401ZM10.5453 1.3965C10.5453 1.31422 10.6131 1.24651 10.6953 1.24651L15.3032 1.24646C15.3855 1.24646 15.4532 1.31418 15.4532 1.39645V3.78394C15.4532 3.82412 15.4375 3.86132 15.409 3.88981C15.3805 3.9183 15.3433 3.93398 15.3031 3.93398H10.6953C10.6131 3.93398 10.5453 3.86627 10.5453 3.78399V1.3965Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M7.70785 14.6024V14.6024V12.2149C7.70785 11.608 7.2146 11.1148 6.60783 11.1148H2.00002C1.39319 11.1148 0.9 11.6081 0.9 12.2149V14.6024C0.9 15.2091 1.39319 15.7024 2.00002 15.7024H6.60783C7.21461 15.7024 7.70779 15.2091 7.70785 14.6024ZM1.85003 12.2149C1.85003 12.1326 1.91774 12.0649 2.00002 12.0649H6.60783C6.69011 12.0649 6.75782 12.1326 6.75782 12.2149V14.6024C6.75782 14.6846 6.69011 14.7523 6.60783 14.7523H2.00002C1.91774 14.7523 1.85003 14.6846 1.85003 14.6024V12.2149Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M10.6953 15.7024H15.3047C15.5981 15.7024 15.8754 15.5889 16.082 15.3806C16.2879 15.173 16.4032 14.8961 16.4032 14.6024V12.2149C16.4032 11.608 15.9099 11.1148 15.3031 11.1148H10.6953C10.0885 11.1148 9.59531 11.6081 9.59531 12.2149V14.6024C9.59531 15.2092 10.0886 15.7024 10.6953 15.7024ZM10.5453 12.2149C10.5453 12.1326 10.6131 12.0649 10.6953 12.0649L15.3032 12.0648C15.3855 12.0648 15.4532 12.1325 15.4532 12.2148V14.6023C15.4532 14.6425 15.4375 14.6797 15.409 14.7082C15.3805 14.7367 15.3433 14.7523 15.3031 14.7523H10.6953C10.613 14.7523 10.5453 14.6846 10.5453 14.6024V12.2149Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M25.1004 14.6024V14.6024V12.2149C25.1004 11.608 24.6072 11.1148 24.0004 11.1148H19.3926C18.7858 11.1148 18.2926 11.6081 18.2926 12.2149V14.6024C18.2926 15.2092 18.7858 15.7024 19.3926 15.7024H24.0004C24.6072 15.7024 25.1004 15.2091 25.1004 14.6024ZM19.2426 12.2149C19.2426 12.1326 19.3103 12.0649 19.3926 12.0649H24.0004C24.0827 12.0649 24.1504 12.1326 24.1504 12.2149V14.6024C24.1504 14.6846 24.0827 14.7523 24.0004 14.7523H19.3926C19.3103 14.7523 19.2426 14.6846 19.2426 14.6024V12.2149Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M12.525 11.5895C12.525 11.8525 12.737 12.0645 13 12.0645C13.2628 12.0645 13.475 11.8528 13.475 11.591V4.40822C13.475 4.14514 13.2631 3.9332 13 3.9332C12.7369 3.9332 12.525 4.1452 12.525 4.40822V11.5895Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M22.1719 11.5914V11.5906V8.00001C22.1719 7.73693 21.9599 7.525 21.6969 7.525H4.3047C4.04162 7.525 3.82969 7.73699 3.82969 8.00001V11.5906C3.82969 11.8537 4.04168 12.0656 4.3047 12.0656C4.56778 12.0656 4.77971 11.8537 4.77971 11.5906V8.47503H21.2203V11.5906C21.2203 11.8537 21.4323 12.0656 21.6953 12.0656C21.9586 12.0656 22.1699 11.8537 22.1719 11.5914Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
    </svg>
  )
}