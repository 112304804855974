import {  useEditorContext } from "../../context/editorContext";
import Navigation from "../Editor/navigation"
import Content from "./content";
const DiffEditorPage = () => {
  const { isDark } = useEditorContext()
  return (
    <div className={`${isDark && "dark"}`}>
      <Navigation />
      <Content/>
    </div>
  );
};

export default DiffEditorPage;
