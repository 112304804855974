import React from "react";

export default function Wallet() {
  return (
    <div className="bg-white min-h-screen p-6 rounded-lg">
      <h2 className="text-xl font-bold mb-4">Wallet Overview</h2>
      <p>Here you can see your Wallet.</p>
    </div>
  );
}
