import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Card from "../../../components/containers/card";
import MainStats from "../components/MainStats";
import IconAssignment from "../../../assets/IconAssignment.png";
import IconDate from "../../../assets/IconDate.png";
import IconTest from "../../../assets/IconTest.png";
import InstructorImage from "../../../assets/Instruktur.png";
import CourseImage from "../../../assets/course-image.png";
import CourseCard from "../components/CourseCard";
import Pagination from "../../../components/pagination";

const mainStat = [
  {
    label: "출결",
    value: "23",
    valueMax: "56회",
    icon: <img src={IconDate} alt="출결" width="55px" height="55px" />,
  },
  {
    label: "과제",
    value: "23",
    valueMax: "56회",
    icon: <img src={IconAssignment} alt="과제" width="55px" height="55px" />,
  },
  {
    label: "시험",
    value: "23",
    valueMax: "56회",
    icon: <img src={IconTest} alt="시험" width="55px" height="55px" />,
  },
];

const courses = [
  {
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    status: "시작이 좋아요",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    status: "시작이 좋아요",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    status: "시작이 좋아요",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    status: "시작이 좋아요",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
];

function Grades() {
  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 4;
  const totalPages = Math.ceil(courses.length / coursesPerPage);

  const heatmapData = {
    series: [
      {
        name: "출결",
        data: [52, 60, 70, 80, 90],
      },
      {
        name: "과제",
        data: [84, 75, 65, 55, 45],
      },
      {
        name: "시험",
        data: [48, 55, 65, 75, 85],
      },
    ],

    options: {
      chart: {
        type: "line" as const,
        height: 350,
        toolbar: {
          offsetX: 0,
          offsetY: -4,
          tools: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        curve: "smooth" as const,
      },
      xaxis: {
        categories: [
          "2019년 1분기",
          "2019년 2분기",
          "2019년 3분기",
          "2019년 4분기",
          "2019년 5분기",
        ],
      },
      colors: ["#2861EF", "#9758FF", "#FF537C"],
      markers: {
        size: [4, 4, 4],
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        x: {
          format: "yyyy년 M분기",
        },
      },
    },
  };

  return (
    <div className="flex flex-col justify-between min-h-screen p-6 bg-white rounded-lg">
      <div>
        <h2 className="pb-2 mb-4 text-xl font-bold border-b">전체 성적</h2>
        <div className="grid flex-1 h-32 grid-cols-3 gap-4 p-4 my-6 border border-gray-100 rounded-lg bg-gray-50">
          {mainStat.map((stat, index) => (
            <MainStats
              key={index}
              label={stat.label}
              value={stat.value}
              valueMax={stat.valueMax}
              icon={stat.icon}
            />
          ))}
        </div>
        <Card>
          <div className="px-2 pt-4">
            <ReactApexChart
              options={heatmapData.options}
              series={heatmapData.series}
              type="line"
              height={350}
            />
          </div>
        </Card>
        <h3 className="pb-2 mt-6 mb-4 text-lg font-bold border-b">
          강의별 성적
        </h3>
        <div>
          <div className="grid grid-cols-4 gap-4">
            {courses.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Grades;
