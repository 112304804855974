import React from "react";
import InfoLearning from "../components/InfoLearning";
import ProgressBarLearning from "../components/ProgressBarLearning";
import DetailProgressLearning from "../components/DetailProgressLearning";

function Learning() {
  return (
    <div className="bg-white p-6 rounded-lg shadow min-h-screen flex flex-col">
      <div className="space-y-6 flex flex-col mb-6">
        <InfoLearning />
        <ProgressBarLearning />
        <DetailProgressLearning />
      </div>
    </div>
  );
}

export default Learning;
