import { ArrowDown2, TickSquare } from "iconsax-react";
import { useState } from "react";
const Item = () => {
  const [isCheck, setCheck] = useState("check");
  return (
    <div>
      <div className="relative w-full overflow-hidden">
        <input
          type="checkbox"
          name=""
          id="checkbox"
          value={isCheck}
          className="
          peer
          absolute
          w-full h-12
          z-10
          cursor-pointer
          opacity-0
        "
        />
        <div
          className="
          bg-white
          h-12 w-full py-3 px-4
          flex items-center
        "
        >
          <div className="text-lg pl-6 font-semibold text-grey-7">
            이용약관 정보제공 동의 (필수)
          </div>
        </div>
        <div
          className="
          rotate-0
          absolute top-3
          left-3
          text-grey-1 peer-checked:text-blue-3"
        >
          <TickSquare size="25" variant="Bold" className="" />
        </div>
        <div
          className="
          absolute top-3
          right-3 text-grey-7
          transition-transform duration-500
          rotate-0
          peer-checked:rotate-180
        "
        >
          <ArrowDown2
            size="25"
            className="
        
        "
          />
        </div>
        {/* content */}
        <div
          className="
<<<<<<< HEAD
          overflow-hidden
            transition-all duration-500
            max-h-0
            peer-checked:max-h-40
=======
         overflow-hidden
        transition-all duration-500
        max-h-0
        peer-checked:max-h-40
<<<<<<< HEAD
>>>>>>> development
=======
>>>>>>> a75ea8d (add term condition)
>>>>>>> development
      "
        >
          <div className="p-4 text-grey-5 bg-[#F9F9F9] rounded-lg">
            <p>
              서비스 이용약관 더미 텍스트 입니다 서비스 이용약관은 필수
              입니다1.서비스 이용약관에 동의하세요! 2.동의하세요서비스 이용약관
              더미 텍스트 입니다 서비스 이용약관은 필수 입니다 2.동의하세요
              1.서비스 이용약관에 동의하세요! 2.동의하세요서비스 이용약관 더미
              텍스트 입니다 서비스 이용약관은 필수 입니다1.서비스 이용약관에
              동의하세요! 2.동의하세요서비스 이용약관 더미 텍스트 입니다
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item
