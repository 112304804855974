const IconPlay = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M5.5 7.99996V3.9585L9 5.97923L12.5 7.99996L9 10.0207L5.5 12.0414V7.99996Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPlay;
