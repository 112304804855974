const IconProgramming = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
      <path d="M7.92647 11.543L10.5927 9.50532C10.5927 9.5053 10.5927 9.50528 10.5927 9.50526L10.5928 9.50524L10.532 9.42579L7.92647 11.543ZM7.92647 11.543L10.5927 13.5791L10.5927 13.5792L10.532 13.6586C10.6961 13.7837 10.7289 14.0195 10.6023 14.1836L7.92647 11.543ZM10.3039 14.432C10.4462 14.432 10.5885 14.367 10.6815 14.2447L6.9164 11.8414L6.85571 11.9209L6.85577 11.9209L10.0165 14.3348C10.0165 14.3348 10.0166 14.3349 10.0166 14.3349C10.102 14.4004 10.2032 14.432 10.3039 14.432Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M18.0754 11.5449L15.4092 13.5811C15.201 13.7397 15.1617 14.0384 15.3199 14.2461C15.4135 14.3695 15.5564 14.4325 15.6979 14.4325C15.7985 14.4325 15.8993 14.4009 15.9843 14.3376L15.9843 14.3376L15.9852 14.3369L19.1461 11.9228L19.1462 11.9229L19.1469 11.9222C19.2629 11.8319 19.3323 11.6936 19.3323 11.5449C19.3323 11.3969 19.2633 11.2564 19.1461 11.167L19.1461 11.167L18.0754 11.5449ZM18.0754 11.5449L15.4092 9.50728L18.0754 11.5449ZM15.3202 8.84189C15.1595 9.05037 15.2016 9.34903 15.4091 9.50723L15.4091 9.50719L15.3202 8.84189Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M11.8392 15.6946L11.84 15.6948C11.8824 15.7064 11.9256 15.7124 11.9678 15.7124C12.175 15.7124 12.3674 15.5763 12.4252 15.364C12.4252 15.3639 12.4253 15.3637 12.4253 15.3636L14.4908 7.97371L14.4909 7.97318C14.56 7.72049 14.4138 7.45922 14.1606 7.388L14.1606 7.388L14.1599 7.38781C13.9072 7.31868 13.646 7.46487 13.5747 7.71812L13.5747 7.71827L11.5091 15.1089L11.5089 15.1095C11.4398 15.3621 11.586 15.6234 11.8392 15.6946Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M2.3125 19.1481H23.6875C24.4663 19.1481 25.1 18.5141 25.1 17.734V2.26367C25.1 1.485 24.4662 0.851172 23.6875 0.851172H2.3125C1.53383 0.851172 0.9 1.485 0.9 2.26367V17.7355C0.9 18.5142 1.53382 19.1481 2.3125 19.1481ZM1.84998 2.26367C1.84998 2.00794 2.0568 1.80115 2.3125 1.80115H23.6875C23.9432 1.80115 24.15 2.00797 24.15 2.26367V17.734C24.15 17.9883 23.943 18.1965 23.6875 18.1965H2.3125C2.05677 18.1965 1.84998 17.9897 1.84998 17.734V2.26367Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M1.37501 4.88518H24.625C24.8881 4.88518 25.1 4.67324 25.1 4.41019C25.1 4.14709 24.8881 3.93516 24.625 3.93516H1.37501C1.11193 3.93516 0.9 4.14715 0.9 4.41017C0.9 4.67325 1.11199 4.88518 1.37501 4.88518Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M13.1856 3.34417H22.5137C22.7748 3.34417 22.9887 3.13252 22.9887 2.86915C22.9887 2.60607 22.7767 2.39414 22.5137 2.39414H13.1856C12.9225 2.39414 12.7105 2.60608 12.7105 2.86915C12.7105 3.13224 12.9225 3.34417 13.1856 3.34417Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M3.94729 3.43012C4.2571 3.43012 4.50825 3.17897 4.50825 2.86916C4.50825 2.55935 4.2571 2.3082 3.94729 2.3082C3.63748 2.3082 3.38633 2.55935 3.38633 2.86916C3.38633 3.17897 3.63748 3.43012 3.94729 3.43012Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M5.87698 3.43012C6.18679 3.43012 6.43794 3.17897 6.43794 2.86916C6.43794 2.55935 6.18679 2.3082 5.87698 2.3082C5.56717 2.3082 5.31602 2.55935 5.31602 2.86916C5.31602 3.17897 5.56717 3.43012 5.87698 3.43012Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
      <path d="M7.80666 3.43012C8.11647 3.43012 8.36762 3.17897 8.36762 2.86916C8.36762 2.55935 8.11647 2.3082 7.80666 2.3082C7.49685 2.3082 7.2457 2.55935 7.2457 2.86916C7.2457 3.17897 7.49685 3.43012 7.80666 3.43012Z" fill="#A5A5A5" stroke="#A5A5A5" strokeWidth="0.2" />
    </svg>
  )
}

export default IconProgramming