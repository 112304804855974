import {
  Setting2,
  Diagram,
  DirectboxNotif,
  Home as HomeIcon,
  Messages1,
  Wallet,
} from "iconsax-react";

import {
  IconComunication,
  IconLetter,
  IconWallet,
} from "../../../components/Icons";

interface SubItem {
  path: string;
  label: string;
}

interface SidebarItem {
  path: string;
  icon: JSX.Element;
  label: string;
  subItems?: SubItem[];
}

interface SidebarSection {
  label: string;
  items: SidebarItem[];
}

export const sidebarItems: SidebarSection[] = [
  {
    label: "관리현황",
    items: [
      {
        path: "/dashboard-admin",
        icon: <HomeIcon size="18" />,
        label: "홈",
      },
      {
        path: "/dashboard-admin/membership-management",
        icon: <Setting2 size="18" />,
        label: "회원관리",
      },
      {
        path: "/dashboard-admin/lectures-management",
        icon: <Setting2 size="18" />,
        label: "강의관리",
        subItems: [
          {
            path: "/dashboard-admin/students-management",
            label: "수강생",
          },
        ],
      },
      {
        path: "/dashboard-admin/grades",
        icon: <IconLetter />,
        label: "공지관리",
      },
      {
        path: "/dashboard-admin/notices",
        icon: <IconComunication />,
        label: "문의관리",
      },
      {
        path: "/dashboard-admin/bulletin",
        icon: <IconWallet />,
        label: "결제관리",
      },
      {
        path : '/dashboard-admin/notice-management',
        icon :<IconLetter />,
        label : '공지관리'
      },
      {
        path : '/dashboard-admin/inquiry-management',
        icon :<IconComunication />,
        label : '문의관리'
      }
    ],
  },
  {
    label: "사이트현황",
    items: [
      {
        path: "/dashboard-admin/wallet",
        icon: <IconWallet />,
        label: "사이트관리",
      },
    ],
  },
];
