import { useState } from "react";
import { TickSquare } from "iconsax-react";
import Item from "./item";
import Button from "../../components/materials/Button";
const TermCondition = () => {
  const [isCheck, setCheck] = useState("check");
  return (
    <div className="bg-blue-1 h-[calc(100vh-4rem)] relative">
      <div className="p-12 w-[440px] absolute top-20 left-0 right-0 m-auto rounded-[20px] bg-white border border-grey-1">
        <div className="text-center text-grey-9 text-2xl font-bold mb-12">
          약관동의
        </div>
        <div>
          <div className="relative w-full overflow-hidden">
            <input
              type="checkbox"
              name=""
              id="checkbox"
              value={isCheck}
              className="
                  peer
                  absolute
                  w-full h-12
                  z-10
                  cursor-pointer
                  opacity-0
                "
            />
            <div
              className="
              bg-[#F4F4F4]
              h-12 w-full py-3 px-4
              rounded-[10px]
              flex items-center
              text-grey-3 peer-checked:text-grey-8
            "
            >
              <div className="text-base pl-6 font-bold ">
                아래 약관에 모두 동의합니다.
              </div>
            </div>
            <div
              className="
          rotate-0
          absolute top-3
          left-3
          text-grey-1 peer-checked:text-blue-3"
            >
              <TickSquare size="25" variant="Bold" className="" />
            </div>
          </div>
          <div className="mt-4 mb-4">
            <Item />
            <Item />
            <Item />
            <Item />
          </div>
          <Button variant="primary">회원가입</Button>
        </div>
      </div>
    </div>
  );
};

export default TermCondition;
