import React, { ReactNode } from "react"

type cardModel = {
  children : ReactNode
}

const Card : React.FC<cardModel> = ({
  children
}) => {
  return (
    <div className="rounded-[20px] bg-white border border-grey-1">
      {children}
    </div>
  )
}

export default Card