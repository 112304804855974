import React, { useState } from "react";
import Search from "../../../components/materials/Search";
import { Table } from "../../../components/tables";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination";
import PeopleProgress from "../components/PeopleProgress";
import Input from "../../../components/materials/Input";
import DatePickers from "../../../components/materials/DatePickers";

import Button from "../../../components/materials/Button";
import Custom from "../../../components/tables/custom";
import IconUpload from "../../../assets/upload.png";
import ModalMembership from "../components/ModalMembership";
import Avatar from "../../../assets/avatar.png";
import ImgUpload from "../../../assets/img-icon.png";

interface Notice {
  id: number;
  no: number;
  member: string;
  name: string;
  email: string;
  mobilePhone: string;
  organization: string;
  student: string;
  intructor: string;
  personCharge: string;
  state: string;
  date: string;
  crystal: string;
}

interface MembershipManagementInterface {
  accessorKey: string;
  header: string;
  width: string;
}

const noticesData: Notice[] = [
  {
    id: 1,
    no: 1,
    member: "2311012",
    name: "김수강",
    email: "student@chappy.com",
    mobilePhone: "010-0000-0000",
    organization: "null",
    student: "false",
    intructor: "true",
    personCharge: "false",
    state: "활성",
    date: "2023.01.02 00:00:00",
    crystal: "icon",
  },
  {
    id: 2,
    no: 2,
    member: "2311012",
    name: "김수강",
    email: "student@chappy.com",
    mobilePhone: "010-0000-0000",
    organization: "null",
    student: "false",
    intructor: "true",
    personCharge: "false",
    state: "활성",
    date: "2023.01.02 00:00:00",
    crystal: "icon",
  },
  {
    id: 3,
    no: 3,
    member: "2311012",
    name: "김수강",
    email: "student@chappy.com",
    mobilePhone: "010-0000-0000",
    organization: "null",
    student: "false",
    intructor: "true",
    personCharge: "false",
    state: "활성",
    date: "2023.01.02 00:00:00",
    crystal: "icon",
  },
  {
    id: 4,
    no: 4,
    member: "2311012",
    name: "김수강",
    email: "student@chappy.com",
    mobilePhone: "010-0000-0000",
    organization: "null",
    student: "false",
    intructor: "true",
    personCharge: "false",
    state: "활성",
    date: "2023.01.02 00:00:00",
    crystal: "icon",
  },
  {
    id: 5,
    no: 5,
    member: "2311012",
    name: "김수강",
    email: "student@chappy.com",
    mobilePhone: "010-0000-0000",
    organization: "null",
    student: "false",
    intructor: "true",
    personCharge: "false",
    state: "활성",
    date: "2023.01.02 00:00:00",
    crystal: "icon",
  },
  {
    id: 6,
    no: 6,
    member: "2311012",
    name: "김수강",
    email: "student@chappy.com",
    mobilePhone: "010-0000-0000",
    organization: "null",
    student: "false",
    intructor: "true",
    personCharge: "false",
    state: "활성",
    date: "2023.01.02 00:00:00",
    crystal: "icon",
  },
  {
    id: 7,
    no: 7,
    member: "2311012",
    name: "김수강",
    email: "student@chappy.com",
    mobilePhone: "010-0000-0000",
    organization: "null",
    student: "false",
    intructor: "true",
    personCharge: "false",
    state: "활성",
    date: "2023.01.02 00:00:00",
    crystal: "icon",
  },
  {
    id: 8,
    no: 8,
    member: "2311012",
    name: "김수강",
    email: "student@chappy.com",
    mobilePhone: "010-0000-0000",
    organization: "null",
    student: "false",
    intructor: "true",
    personCharge: "false",
    state: "활성",
    date: "2023.01.02 00:00:00",
    crystal: "icon",
  },
];

const columns: MembershipManagementInterface[] = [
  {
    accessorKey: "no",
    header: "번호",
    width: "80px",
  },
  {
    accessorKey: "member",
    header: "회원ID",
    width: "80px",
  },
  {
    accessorKey: "name",
    header: "이름",
    width: "80px",
  },
  {
    accessorKey: "email",
    header: "이메일",
    width: "80px",
  },
  {
    accessorKey: "mobilePhone",
    header: "휴대폰 번호",
    width: "80px",
  },
  {
    accessorKey: "organization",
    header: "소속기관",
    width: "80px",
  },
  {
    accessorKey: "student",
    header: "학생",
    width: "80px",
  },
  {
    accessorKey: "intructor",
    header: "강사",
    width: "80px",
  },
  {
    accessorKey: "personCharge",
    header: "담당자",
    width: "80px",
  },
  {
    accessorKey: "state",
    header: "상태",
    width: "80px",
  },
  {
    accessorKey: "date",
    header: "등록일시",
    width: "80px",
  },
  {
    accessorKey: "crystal",
    header: "수정",
    width: "80px",
  },
];

export default function MembershipManagement() {
  const [openMenu, setOpenMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdditional, setIsAdditional] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [dataCurrent, setDataCurrent] = useState(false);

  const handleAdditional = (val: any) => {
    setTimeout(() => {
      if (dataCurrent) {
        setIsAdditional(!isAdditional);
        setIsConfirm(true);
      } else {
        setIsAdditional(!isAdditional);
        setIsCompleted(true);
      }
    }, 100);
  };

  const handleConfirm = (val: any) => {
    setTimeout(() => {
      if (dataCurrent) {
        setIsConfirm(false);
        setIsCompleted(true);
      }
    }, 100);
  };

  return (
    <>
      {isAdditional && (
        <ModalMembershipBulk
          show={true}
          onClose={() => setIsAdditional(false)}
          confirm={() => handleAdditional(true)}
          isExits={dataCurrent}
        />
      )}
      {isCompleted && (
        <ModalComplete show={true} onClose={() => setIsCompleted(false)} />
      )}
      {isConfirm && (
        <ModalIsConfirm
          show={true}
          onClose={() => setIsConfirm(false)}
          confirm={() => handleConfirm(true)}
        />
      )}
      <PeopleProgress />
      <div className="min-h-screen p-6 bg-white rounded-lg">
        <h2 className="pb-2 text-xl font-bold border-b">회원관리</h2>
        <div className="grid items-start grid-cols-4 gap-4 px-10 py-5">
          <label htmlFor="회원ID" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">회원ID</span>
            <Input id="회원ID" />
          </label>
          <label htmlFor="이름" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">이름</span>
            <Input id="이름" />
          </label>
          <label htmlFor="이메일" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">이메일</span>
            <Input id="이메일" />
          </label>
          <label htmlFor="휴대폰번호" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">휴대폰 번호</span>
            <Input id="휴대폰번호" />
          </label>
          <label htmlFor="소속기관" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">소속기관</span>
            <Input id="소속기관" />
          </label>
          <label htmlFor="search" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">등록 기간</span>
            <div className="flex items-center w-full gap-4 overflow-hidden">
              <DatePickers id={"res"} />
              <span>~</span>
              <DatePickers id={"pertahankan"} />
            </div>
          </label>
          <label htmlFor="search" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">등록 기간</span>
            <div className="flex items-center gap-4">
              <label htmlFor="" className="flex gap-2">
                <input type="checkbox" />
                <span className="text-sm font-medium ">학생</span>
              </label>
              <label htmlFor="" className="flex gap-2">
                <input type="checkbox" />
                <span className="text-sm font-medium ">강사</span>
              </label>
              <label htmlFor="" className="flex gap-2">
                <input type="checkbox" />
                <span className="text-sm font-medium ">담당자</span>
              </label>
            </div>
          </label>
          <label htmlFor="search" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">상태</span>
            <div className="flex items-center gap-4">
              <label htmlFor="" className="flex gap-2">
                <input type="radio" name="상태" />
                <span className="text-sm font-medium ">모두</span>
              </label>
              <label htmlFor="" className="flex gap-2">
                <input type="radio" name="상태" />
                <span className="text-sm font-medium ">활성</span>
              </label>
              <label htmlFor="" className="flex gap-2">
                <input type="radio" name="상태" />
                <span className="text-sm font-medium ">휴면</span>
              </label>
            </div>
          </label>
        </div>

        <div className="flex items-center justify-end gap-5 py-3 mb-10 border-b border-slate-200">
          <button className="min-w-[88px] w-[88px] h-[32px] rounded bg-[#A5A5A5] text-white text-sm font-bold">
            초기화
          </button>
          <button className="min-w-[88px] w-[88px] h-[32px] rounded bg-[#2861EF] text-white text-sm font-bold">
            검색
          </button>
        </div>
        <div className="flex flex-col items-start justify-start">
          <ul>
            <li className="border-b-2 cursor-pointer border-slate-700">
              <span className="px-3 text-base font-medium ">총 10건</span>
            </li>
          </ul>
          <div className="flex items-center self-end gap-5 py-2 ">
            <div
              onClick={() => setOpenMenu(!openMenu)}
              className="relative flex gap-2 cursor-pointer"
            >
              <div className="text-sm font-medium text-[#A5A5A5]">
                10개씩 보기{" "}
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className=" stroke-[#A5A5A5]"
                >
                  <path
                    d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {openMenu && (
                <div className="shadow-menu rounded-xl top-[100%] absolute bg-white  w-[80px] drop-shadow-lg">
                  <div className="flex items-center gap-2 px-4 py-2">
                    <div className="text-sm font-medium text-grey-6">Test1</div>
                  </div>
                </div>
              )}
            </div>
            <button className=" text-sm font-medium text-[#A5A5A5] flex items-center gap-1 border p-2 border-slate-300 rounded-lg">
              표 내보내기
              <img src={IconUpload} alt="" />
            </button>
          </div>
        </div>
        <Custom
          columns={columns}
          rows={noticesData}
          onChange={() => {
            setIsAdditional(true);
            setDataCurrent(true);
          }}
        />
        <div className="flex justify-end w-full my-2">
          <button
            className="py-2 px-6 text-[#2861EF] border border-[#2861EF] rounded-lg text-xs"
            onClick={() => {
              setIsAdditional(true);
              setDataCurrent(false);
            }}
          >
            + 추가
          </button>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={9}
          onPageChange={setCurrentPage}
        />
      </div>
    </>
  );
}

const ModalMembershipBulk = ({
  show = false,
  onClose = () => {},
  confirm = () => {},
  isExits = true,
}) => {
  return (
    <ModalMembership show={true} onClose={onClose}>
      <div className="w-[440px] min-h-fit flex flex-col items-center py-10">
        <h1 className="text-2xl font-bold ">회원가입</h1>
        <div className="relative flex mt-10 mb-8">
          <img
            src={Avatar}
            alt=""
            className="border rounded-full  border-slate-200 bg-[#F2F4F6]"
          />
          <button className="absolute bottom-0 right-0 p-2 rounded-full bg-[#A5A5A5] h-fit">
            <img src={ImgUpload} alt="" />
          </button>
        </div>
        <div className="flex flex-col w-full gap-4 px-5">
          <label htmlFor="이름" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              이름
            </span>
            <Input id="이름" placeholder="홍길동" />
          </label>
          <label htmlFor="이메일" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              이메일
            </span>
            <Input id="이메일" placeholder="abcd@naver.com" />
          </label>
          <label htmlFor="휴대폰번호" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">휴대폰 번호</span>
            <Input id="휴대폰번호" placeholder="010-0000-0000" />
          </label>
          <label htmlFor="비밀번호" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              비밀번호
            </span>
            <Input id="비밀번호" placeholder="***********" type="password" />
          </label>
          <label htmlFor="비밀번호재입력" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              비밀번호 재입력
            </span>
            <Input
              id="비밀번호재입력"
              placeholder="***********"
              type="password"
            />
          </label>
          <label htmlFor="소속기관" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              소속기관
            </span>
            <Input id="소속기관" placeholder="성균관대학교" />
          </label>
          <label htmlFor="" className="flex self-start gap-2">
            <input
              id="소속기관이없습니다."
              type="checkbox"
              className="w-6 h-6 custom-checkbox1-disabled"
            />
            <label
              htmlFor="소속기관이없습니다."
              className="text-base text-[#A5A5A5]"
            >
              소속기관이 없습니다.
            </label>
          </label>
          <label htmlFor="소속기관" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              권한
            </span>
            <div className="flex gap-4">
              <label htmlFor="" className="flex self-start gap-2">
                <input
                  id="학생"
                  type="checkbox"
                  className="w-6 h-6 min-w-6 custom-checkbox1"
                />
                <label htmlFor="학생" className="text-base text-sub-1">
                  학생
                </label>
              </label>
              <label htmlFor="" className="flex self-start gap-2">
                <input
                  id="강사"
                  type="checkbox"
                  className="w-6 h-6 min-w-6 custom-checkbox1"
                />
                <label htmlFor="강사" className="text-base text-sub-1">
                  강사
                </label>
              </label>
              <label htmlFor="" className="flex self-start gap-2">
                <input
                  id="담당자"
                  type="checkbox"
                  className="w-6 h-6 min-w-6 custom-checkbox1"
                />
                <label htmlFor="담당자" className="text-base text-sub-1">
                  담당자
                </label>
              </label>
            </div>
          </label>
        </div>
        <div className="w-full px-5 mt-10">
          {isExits ? (
            <>
              <button
                className="w-full rounded-lg mt-5 text-white bg-grey-3 h-[56px] flex justify-center items-center"
                onClick={confirm}
              >
                등록
              </button>
              <button
                className="w-full rounded-lg mt-2 text-white bg-red-3 h-[56px] flex justify-center items-center"
                onClick={onClose}
              >
                삭제
              </button>
            </>
          ) : (
            <button
              className="w-full rounded-lg mt-5 text-white bg-primary h-[56px] flex justify-center items-center"
              onClick={confirm}
            >
              등록
            </button>
          )}
        </div>
      </div>
    </ModalMembership>
  );
};

const ModalComplete = ({ show = false, onClose = () => {} }) => {
  return (
    <ModalMembership show={show}>
      <div className="flex flex-col items-center w-full min-h-[200px] justify-between">
        <h1 className="text-lg font-bold text-grey-8">추가완료</h1>
        <p className=" text-sm text-[#555555]">회원이 추가되었습니다.</p>
        <button
          className="w-full rounded-lg mt-5 text-white bg-primary h-[56px] flex justify-center items-center"
          onClick={onClose}
        >
          닫기
        </button>
      </div>
    </ModalMembership>
  );
};

const ModalIsConfirm = ({
  show = false,
  onClose = () => {},
  confirm = () => {},
}) => {
  return (
    <ModalMembership show={show}>
      <div className="flex flex-col items-center w-full min-h-[200px] justify-between">
        <h1 className="text-lg font-bold text-grey-8">
          정말 삭제하시겠습니까?
        </h1>
        <p className=" text-sm text-[#555555]">
          삭제하면 회원과 관련된 모든 정보가 삭제됩니다.
        </p>
        <div className="w-full">
          <button
            className="w-full rounded-lg mt-5 text-white bg-grey-3 h-[40px] flex justify-center items-center"
            onClick={confirm}
          >
            닫기
          </button>
          <button
            className="w-full rounded-lg mt-2 text-white bg-red-3 h-[40px] flex justify-center items-center"
            onClick={onClose}
          >
            삭제
          </button>
        </div>
      </div>
    </ModalMembership>
  );
};
