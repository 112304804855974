const IconBook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22.0977 5.71254H20.4872V4.87479C20.4872 4.41331 20.1091 4.03334 19.6278 4.01111C16.794 3.88184 14.0316 4.8855 12 6.78248C9.96844 4.88591 7.20598 3.88225 4.37219 4.01152C3.89094 4.03334 3.51281 4.41372 3.51281 4.8752V5.71295H1.90234C1.40391 5.71254 1 6.09951 1 6.57705V19.1355C1 19.613 1.40391 20 1.90234 20H22.0977C22.5961 20 23 19.613 23 19.1355V6.57705C23 6.09951 22.5961 5.71254 22.0977 5.71254ZM18.6825 5.74218V14.6314C16.6097 14.7207 14.6146 15.4128 12.9625 16.6148L12.8349 8.44604C14.3332 6.83805 16.4365 5.86527 18.6825 5.74177V5.74218ZM5.31707 5.74218C7.49301 5.86198 9.53746 6.77877 11.0276 8.30195L11.1591 16.7042C9.48418 15.4477 7.4423 14.7232 5.31707 14.6314V5.74218ZM2.80426 7.44156H3.51238V15.4947C3.51238 15.9722 3.91629 16.3592 4.41473 16.3592C4.42848 16.3592 4.44223 16.3592 4.45641 16.3584C6.58852 16.2703 8.67723 16.9528 10.3088 18.271H2.80469V7.44156H2.80426ZM21.1949 18.271H13.6908C15.3223 16.9528 17.4111 16.2703 19.5432 16.3584C20.0412 16.3802 20.4631 16.0113 20.4859 15.5346C20.4863 15.5214 20.4868 15.5083 20.4868 15.4947V7.44156H21.1949V18.271Z"
        fill="#A5A5A5"
      />
    </svg>
  );
};

export default IconBook;
