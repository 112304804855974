import React from "react";
import CourseStatus from "../components/courseStatusProfessor";
import LearningActivities from "../components/learningActivitiesProfessor";
import PerformanceCheck from "../components/performanceCheckProfessor";
import StatsCards from "../components/statCardsProfessor";
import QASection from "../components/QASectionProfessor";

function HomeProfessor() {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="space-y-6 flex flex-col mb-6">
        <StatsCards />
        <CourseStatus />
      </div>
      <div className="grid grid-cols-3 gap-6 flex-1 min-h-0">
        <div className="flex flex-col bg-white p-5 rounded-lg shadow col-span-1">
          <LearningActivities />
        </div>
        <div className="flex flex-col bg-white p-5 rounded-lg shadow col-span-1">
          <QASection />
        </div>
        <div className="flex flex-col bg-white p-5 rounded-lg shadow col-span-1">
          <PerformanceCheck />
        </div>
      </div>
    </div>
  );
}

export default HomeProfessor;
