import React, { useState } from "react";
import Search from "../../../components/materials/Search";
import { Table } from "../../../components/tables";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination";
import { MessageEdit } from "iconsax-react";

interface QNA {
  id: number;
  no: number;
  status: string;
  lectuter: string;
  title: string;
  author: string;
  date: string;
}

const dataQNA: QNA[] = [
  {
    id: 1,
    no: 1,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
  },
  {
    id: 2,
    no: 2,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
  },
  {
    id: 3,
    no: 3,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
  },
  {
    id: 4,
    no: 4,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "홍학생",
    date: "23.02.04 23:59",
  },
  {
    id: 5,
    no: 5,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "홍학생",
    date: "23.02.04 23:59",
  },
  {
    id: 6,
    no: 6,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "홍학생",
    date: "23.02.04 23:59",
  },
  {
    id: 7,
    no: 7,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "강학생",
    date: "23.02.04 23:59",
  },
  {
    id: 8,
    no: 8,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "정학생",
    date: "23.02.04 23:59",
  },
  {
    id: 9,
    no: 9,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "최학생",
    date: "23.02.04 23:59",
  },
  {
    id: 10,
    no: 10,
    status: "답변 완료",
    lectuter: "코린이의 첫 걸음마",
    title: "학습시간 변경 안내 공지",
    author: "권학생",
    date: "23.02.04 23:59",
  },
];

const Badge = ({ status }: { status: string }) => {
  let borderColor = "border-gray-500";
  let textColor = "text-gray-500";
  if (status === "답변 완료") {
    borderColor = "border-blue-500";
    textColor = "text-blue-500";
  } else if (status === "답변대기") {
    borderColor = "border-lightblue-500";
    textColor = "text-lightblue-500";
  } else {
    borderColor = "border-gray-500";
    textColor = "text-gray-500";
  }

  return (
    <span
      className={`px-2 py-1 border ${borderColor} ${textColor} rounded-md text-sm`}
    >
      {status}
    </span>
  );
};

const columns: ColumnDef<QNA>[] = [
  {
    accessorKey: "no",
    header: "NO.",
  },
  {
    accessorKey: "status",
    header: "답변여부",
    cell: (info) => <Badge status={info.getValue() as string} />,
  },
  {
    accessorKey: "lectuter",
    header: "강의명",
  },
  {
    accessorKey: "title",
    header: "제목",
  },
  {
    accessorKey: "author",
    header: "작성자",
  },
  {
    accessorKey: "date",
    header: "작성일",
  },
];

export default function Qna() {
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const navigate = useNavigate();

  const tabs = [
    { name: "전체", id: "all" },
    { name: "미확인", id: "kuliah-1" },
    { name: "답변대기", id: "kluiah-2" },
    { name: "답변 완료", id: "kuliah-3" },
  ];

  const filteredDataQNA = dataQNA.filter(
    (notice) => activeTab === "all" || notice.status
  );

  const totalPages = Math.ceil(filteredDataQNA.length / dataPerPage);

  return (
    <div className="bg-white min-h-screen p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold border-b pb-2">나의 Q&A</h2>
      <div className="flex justify-between items-center">
        <div className="flex">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`py-2 px-6 ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-600 text-blue-600"
                  : "text-gray-400"
              } focus:outline-none`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="flex justify-end">
          <div className="relative mt-4">
            <Search placeholder="검색..." />
          </div>
          <div className="flex justify-end m-4">
            <button
              className="flex justify-center gap-1 items-center bg-primary px-4 text-white rounded-lg"
              onClick={() => navigate("/dashboard/qna/create")}
            >
              글쓰기
              <MessageEdit size={18} />
            </button>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={dataQNA}
        onRowClick={(row) => {
          navigate(`/dashboard/qna/${row.id}`);
        }}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}
