
import Navigation from "./navigation";
import Content from "./content";
import {  useEditorContext } from "../../context/editorContext";
const EditorPage = () => {

  const { isDark } = useEditorContext()
  
  return (
    <div className={`${isDark && "dark"}`}>
      <Navigation />
      <Content/>
    </div>
  );
};

export default EditorPage;
