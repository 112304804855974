import React, { useState } from "react";
import {
  AddSquare,
  ArrowLeft2,
  ArrowRight2,
  Setting2,
  Setting4,
} from "iconsax-react";
import Modal from "../../../components/modal";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { useSubjectStore } from "../../../store/subject";
import { StrictModeDroppable } from "../../../components/strictModeDroppable";

function CourseStatus() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmCancellation, setIsOpenConfirmCancellation] =
    useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenConfirmAdd, setIsOpenConfirmAdd] = useState(false);
  const [mode, setMode] = useState("drag");
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const { subjectData, setSubjectData, subjectAllData, setSubjectAllData } =
    useSubjectStore((state) => state);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const openConfirmCancellationModal = () => setIsOpenConfirmCancellation(true);
  const closeConfirmCancellationModal = () =>
    setIsOpenConfirmCancellation(false);
  const openAddModal = () => setIsOpenAdd(true);
  const closeAddModal = () => setIsOpenAdd(false);
  const openConfirmAddModal = () => setIsOpenConfirmAdd(true);
  const closeConfirmAddModal = () => setIsOpenConfirmAdd(false);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const items = Array.from(subjectData);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    setSubjectData(items);
  };

  const handleModeChange = (newMode: string) => {
    setMode(newMode);
    setSelectedCourses([]);
  };

  const handleCheckBoxChange = (id: number, checked: boolean) => {
    if (checked) {
      setSelectedCourses((prev) => [...prev, id]);
    } else {
      setSelectedCourses((prev) => prev.filter((courseId) => courseId !== id));
    }
  };

  const handleAddCourses = () => {
    const updatedSubjectData = [...subjectData];
    const updatedSubjectAllData = subjectAllData.map((item) => {
      if (selectedCourses.includes(item.id)) {
        updatedSubjectData.push({ ...item, isView: true });
        return { ...item, isView: true };
      }
      return item;
    });

    setSubjectData(updatedSubjectData);
    setSubjectAllData(updatedSubjectAllData);
    setSelectedCourses([]);
  };

  const handleCancellationCourses = () => {
    const updatedSubjectData = subjectData.filter(
      (item) => !selectedCourses.includes(item.id)
    );
    const updatedSubjectAllData = subjectAllData.map((item) =>
      selectedCourses.includes(item.id) ? { ...item, isView: false } : item
    );

    setSubjectData(updatedSubjectData);
    setSubjectAllData(updatedSubjectAllData);
    setSelectedCourses([]);
  };

  return (
    <div className="bg-white px-6 py-4 rounded-lg border border-gray-100 shadow-sm">
      <div className="flex justify-between items-center text-center p-3 mb-2 border-b">
        <h3 className="text-lg font-bold">과목 현황</h3>
        <button
          className="flex gap-2 text-md text-gray-400 justify-center items-center cursor-pointer text-sm"
          aria-label="Edit course status"
          onClick={openModal}
        >
          수정
          <Setting2 size="16" color="gray" />
        </button>
      </div>
      <div className="grid grid-cols-4 gap-4 p-2">
        {subjectData.map((course, index) => (
          <div
            key={index}
            className="px-6 py-8 bg-gray-50 border border-gray-100 rounded-lg"
          >
            <div className="font-bold mb-2 border-b pb-2 text-center">
              {course.name}
            </div>
            <div className="text-xl text-primary mb-2 text-center">
              {course.progress}
            </div>
            <div className="text-gray-400 mb-4 text-center text-xs">
              {course.message}
            </div>
            <div className="h-2 bg-blue-100 rounded-full mb-4">
              <div
                className="h-full bg-primary rounded-full"
                style={{ width: course.progress }}
              ></div>
            </div>
            <div className="flex items-center justify-between text-gray-500 text-xs">
              <div className="bg-red-100 text-red-500 border-red-500 rounded px-2 py-1 text-xs">
                {course.notice}
              </div>
              <div className="flex items-center justify-between text-grey-5 gap-1">
                {course.week} | {course.type}
                <ArrowRight2 size={14} />
              </div>
            </div>
          </div>
        ))}
      </div>

      {isOpen && (
        <Modal
          title="과목현황 수정"
          onClose={() => {
            closeModal();
            setMode("drag");
          }}
          size="lg"
        >
          {mode === "drag" && (
            <>
              <div className="justify-end flex space-x-3 pb-3">
                <button
                  className="flex gap-1 justify-center items-center text-gray-400"
                  onClick={() => handleModeChange("cancellation")}
                >
                  해제
                </button>
                <span className="text-gray-200">|</span>
                <button
                  className="flex text-primary gap-1 justify-center items-center text-gray-500"
                  onClick={() => openAddModal()}
                >
                  추가
                  <AddSquare size="18" />
                </button>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="space-y-4"
                    >
                      {subjectData.map((course, index) => (
                        <Draggable
                          key={course.id}
                          draggableId={course.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="grid grid-cols-1 border border-gray-200 p-4 rounded shadow-sm bg-blue-1"
                            >
                              <div className="flex items-start gap-4">
                                <Setting4 size="20" color="gray" />
                                <div className="flex flex-col space-y-2 w-full">
                                  <p className="font-medium border-b pb-2">
                                    {course.name}
                                  </p>
                                  <p className="text-sm text-gray-600 my-2 space-x-4">
                                    수업일자: {course.startDate} ~{" "}
                                    {course.endDate}
                                  </p>
                                  <p className="text-sm text-gray-600">
                                    강사: {course.mentor}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </>
          )}
          {mode === "cancellation" && (
            <>
              <div className="justify-between flex space-x-3 pb-3">
                <button
                  className="flex gap-1 justify-center items-center text-gray-400"
                  onClick={() => {
                    handleModeChange("drag");
                    setSelectedCourses([]);
                  }}
                >
                  <ArrowLeft2 size="18" />
                  반품
                </button>
                <button
                  className={`flex gap-1 ${
                    selectedCourses?.length === 0 ? "opacity-50" : ""
                  } justify-center items-center px-6 py-1 bg-primary text-white rounded-md`}
                  onClick={openConfirmCancellationModal}
                  disabled={selectedCourses.length === 0}
                >
                  해제
                </button>
              </div>
              <div className="space-y-4">
                {subjectData
                  .filter((course) => course.isView)
                  .map((course) => (
                    <div
                      key={course.id}
                      className={`grid ${
                        course.isView
                          ? "cursor-pointer border hover:border-blue-300"
                          : ""
                      } grid-cols-1 border border-gray-200 p-4 rounded shadow-sm bg-blue-1`}
                      onClick={() => {
                        if (course.isView) {
                          handleCheckBoxChange(
                            course.id,
                            !selectedCourses.includes(course.id)
                          );
                        }
                      }}
                    >
                      <div className={`flex items-start gap-4`}>
                        <input
                          type="checkbox"
                          checked={selectedCourses.includes(course.id)}
                          className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          onChange={(e) =>
                            handleCheckBoxChange(course.id, e.target.checked)
                          }
                        />{" "}
                        <div className="flex flex-col space-y-2 w-full">
                          <p className="font-medium border-b pb-2">
                            {course.name}
                          </p>
                          <p className="text-sm text-gray-600 my-2 space-x-4">
                            수업일자: {course.startDate} ~ {course.endDate}
                          </p>
                          <p className="text-sm text-gray-600">
                            강사: {course.mentor}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
          {mode === "add" && (
            <>
              <div className="justify-between flex space-x-3 pb-3">
                <button
                  className="flex gap-1 justify-center items-center text-gray-400"
                  onClick={() => {
                    handleModeChange("drag");
                    setSelectedCourses([]);
                  }}
                >
                  <ArrowLeft2 size="18" />
                  반품
                </button>
                <button
                  className={`flex gap-1 ${
                    selectedCourses?.length === 0 ? "opacity-50" : ""
                  } justify-center items-center px-6 py-1 bg-primary text-white rounded-md`}
                  onClick={openConfirmAddModal}
                  disabled={selectedCourses.length === 0}
                >
                  추가
                </button>
              </div>
              <div className="space-y-4">
                {subjectAllData.map((course) => (
                  <div
                    key={course.id}
                    className={`grid ${
                      !course.isView
                        ? "cursor-pointer border hover:border-blue-300"
                        : ""
                    } grid-cols-1 border border-gray-200 p-4 rounded shadow-sm bg-blue-1`}
                    onClick={() => {
                      if (!course.isView) {
                        handleCheckBoxChange(
                          course.id,
                          !selectedCourses.includes(course.id)
                        );
                      }
                    }}
                  >
                    <div
                      className={`flex items-start gap-4 ${
                        course.isView ? " opacity-40" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={selectedCourses.includes(course.id)}
                        disabled={course.isView}
                        className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={(e) =>
                          handleCheckBoxChange(course.id, e.target.checked)
                        }
                      />{" "}
                      <div className="flex flex-col space-y-2 w-full">
                        <p className="font-medium border-b pb-2">
                          {course.name}
                        </p>
                        <p className="text-sm text-gray-600 my-2 space-x-4">
                          수업일자: {course.startDate} ~ {course.endDate}
                        </p>
                        <p className="text-sm text-gray-600">
                          강사: {course.mentor}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </Modal>
      )}

      {isOpenConfirmCancellation && (
        <Modal
          title="과목해제"
          onClose={() => {
            closeConfirmCancellationModal();
            setSelectedCourses([]);
          }}
          size="sm"
        >
          <div className="space-y-4">
            <div className="text-center">
              <p className="text-sm text-gray-500">
                선택한 과목을 해제하시겠습니까?
              </p>
            </div>
            <div className="flex justify-center space-x-4">
              <button
                className="flex gap-1 justify-center items-center px-6 py-1 bg-gray-200 text-gray-500 rounded-md"
                onClick={closeConfirmCancellationModal}
              >
                취소
              </button>
              <button
                className="flex gap-1 justify-center items-center px-6 py-1 bg-primary text-white rounded-md"
                onClick={() => {
                  handleCancellationCourses();
                  closeConfirmCancellationModal();
                  setMode("drag");
                }}
                disabled={selectedCourses.length === 0}
              >
                닫기
              </button>
            </div>
          </div>
        </Modal>
      )}

      {isOpenConfirmAdd && (
        <Modal
          title="과목추가"
          onClose={() => {
            closeConfirmAddModal();
            setSelectedCourses([]);
          }}
          size="sm"
        >
          <div className="space-y-4">
            <div className="text-center">
              <p className="text-lg text-gray-500">추가하시겠습니까?</p>
            </div>
            <div className="flex justify-center space-x-4">
              <button
                className="flex gap-1 justify-center items-center px-6 py-1 bg-gray-200 text-gray-500 rounded-md"
                onClick={closeConfirmAddModal}
              >
                취소
              </button>
              <button
                className="flex gap-1 justify-center items-center px-6 py-1 bg-primary text-white rounded-md"
                onClick={() => {
                  handleAddCourses();
                  closeConfirmAddModal();
                  setMode("drag");
                }}
                disabled={selectedCourses.length === 0}
              >
                추가
              </button>
            </div>
          </div>
        </Modal>
      )}

      {isOpenAdd && (
        <Modal
          title="과목추가"
          onClose={() => {
            closeAddModal();
            setSelectedCourses([]);
          }}
          size="sm"
        >
          <div className="space-y-4">
            <div className="text-center">
              <p className="text-sm text-gray-500">
                4개까지 등록이 가능합니다.
              </p>
            </div>
            <div className="flex justify-center space-x-4">
              <button
                className="flex gap-1 justify-center items-center px-6 py-1 bg-gray-200 text-gray-500 rounded-md"
                onClick={() => {
                  closeAddModal();
                }}
              >
                취소
              </button>
              <button
                className="flex gap-1 justify-center items-center px-6 py-1 bg-primary text-white rounded-md"
                onClick={() => {
                  closeAddModal();
                  setMode("add");
                }}
              >
                확인
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CourseStatus;
