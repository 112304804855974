const Checkpoint = () => {
  return (
    <div className="2xl:px-72 px-40 bg-bg_2 py-[140px] flex justify-between">
      <div>
        <div className="text-blue-4 text-xl font-bold" >checkpoint</div>
        <div className="text-grey-9 text-[40px] font-bold">올인원 프로그래밍</div>
        <div className="text-grey-9 text-[40px] font-bold">코딩교육 서비스</div>
      </div>
      <div className="flex gap-4">
        <div className="bg-white rounded-xl py-10 2xl:px-16 px-14">
          <div className="text-grey-6 text-sm font-bold">지원 가능한 언어수</div>
          <div className="text-primary text-[40px] font-bold" >+218</div>
        </div>
        <div className="bg-white rounded-xl py-10 px-16 2xl:px-16 px-14">
          <div className="text-grey-6 text-sm font-bold">지원 가능한 언어수</div>
          <div className="text-primary text-[40px] font-bold" >+218</div>
        </div>
        <div className="bg-white rounded-xl py-10 px-16 2xl:px-16 px-14">
          <div className="text-grey-6 text-sm font-bold">지원 가능한 언어수</div>
          <div className="text-primary text-[40px] font-bold" >+218</div>
        </div>
      </div>
    </div>
  )
}

export default Checkpoint