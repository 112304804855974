const IconSend = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M13.6406 2.24241L1.99513 5.52703C1.89716 5.55467 1.80996 5.61154 1.74518 5.69006C1.6804 5.76858 1.64112 5.865 1.63261 5.96644C1.6241 6.06788 1.64675 6.1695 1.69753 6.25771C1.74832 6.34593 1.82482 6.41655 1.91682 6.46013L7.26749 8.99466C7.37185 9.04409 7.45589 9.12812 7.50532 9.23248L10.0398 14.5832C10.0834 14.6752 10.154 14.7517 10.2423 14.8024C10.3305 14.8532 10.4321 14.8759 10.5335 14.8674C10.635 14.8589 10.7314 14.8196 10.8099 14.7548C10.8884 14.69 10.9453 14.6028 10.9729 14.5048L14.2576 2.85936C14.2817 2.77382 14.2826 2.68339 14.2601 2.59739C14.2377 2.51139 14.1927 2.43293 14.1299 2.37008C14.067 2.30723 13.9886 2.26227 13.9026 2.23983C13.8166 2.21739 13.7262 2.21828 13.6406 2.24241Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.42969 9.0711L10.2581 6.24268"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSend;
