const dummyFont = [
  {
    label : 'Roboto',
    value : 'roboto'
  },
  {
    label : 'San serif',
    value : 'roboto'
  }
]

const dummySize = [
  {
    label : '12',
    value : '12'
  },
  {
    label : '14',
    value : '14'
  }
]

export { 
  dummyFont,
  dummySize
}