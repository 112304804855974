import React from "react";
import StatsCardsLearning from "../../DashboardLearning/components/StatsCardLearning";
import CourseStatusLearning from "../../DashboardLearning/components/CourseStatusLearning";
import { Table } from "../../../components/tables";
import Pagination from "../../../components/pagination";
import { Export, SearchNormal } from "iconsax-react";

const columns = [
  {
    header: "No",
    accessorKey: "no",
  },
  {
    header: "수강생 ",
    accessorKey: "student",
  },
  {
    header: "학습 진도",
    accessorKey: "progress",
  },
  {
    header: "중간고사",
    accessorKey: "midterm",
  },
  {
    header: "기말고사",
    accessorKey: "final",
  },
  {
    header: "과제",
    accessorKey: "assignment",
  },
  {
    header: "출석",
    accessorKey: "attendance",
  },
  {
    header: "최저",
    accessorKey: "lowest",
  },
  {
    header: "기타",
    accessorKey: "etc",
  },
  {
    header: "총점",
    accessorKey: "total",
  },
];

const columns2 = [
  {
    header: "No",
    accessorKey: "no",
  },
  {
    header: "차시",
    accessorKey: "class",
  },
  {
    header: "유형",
    accessorKey: "type",
  },
  {
    header: "과제명",
    accessorKey: "task",
  },
  {
    header: "제출 사람",
    accessorKey: "submitter",
  },
  {
    accessorKey: "submitterr",
    header: "제출 사람",
    cell: () => null,
  },
];

const data = [
  {
    id: 1,
    no: 1,
    student: "김학생",
    progress: "100%",
    midterm: "100",
    final: "100",
    assignment: "100",
    attendance: "100",
    lowest: "100",
    etc: "100",
    total: "100",
    by: "student",
  },
  {
    id: 2,
    no: 2,
    student: "박학생",
    progress: "100%",
    midterm: "100",
    final: "100",
    assignment: "100",
    attendance: "100",
    lowest: "100",
    etc: "100",
    total: "100",
    by: "student",
  },
  {
    id: 3,
    no: 3,
    student: "이학생",
    progress: "100%",
    midterm: "100",
    final: "100",
    assignment: "100",
    attendance: "100",
    lowest: "100",
    etc: "100",
    total: "100",
    by: "student",
  },
  {
    id: 4,
    no: 4,
    student: "최학생",
    progress: "100%",
    midterm: "100",
    final: "100",
    assignment: "100",
    attendance: "100",
    lowest: "100",
    etc: "100",
    total: "100",
    by: "student",
  },
  {
    id: 5,
    no: 5,
    student: "배학생",
    progress: "100%",
    midterm: "100",
    final: "100",
    assignment: "100",
    attendance: "100",
    lowest: "100",
    etc: "100",
    total: "100",
    by: "student",
  },
];

const data2 = [
  {
    id: 1,
    no: 1,
    class: "1차시",
    type: "과제",
    task: "과제1",
    submitter: "김학생",
    by: "assignment",
  },
  {
    id: 2,
    no: 2,
    class: "2차시",
    type: "과제",
    task: "과제2",
    submitter: "박학생",
    by: "assignment",
  },
  {
    id: 3,
    no: 3,
    class: "3차시",
    type: "과제",
    task: "과제3",
    submitter: "이학생",
    by: "assignment",
  },
  {
    id: 4,
    no: 4,
    class: "4차시",
    type: "과제",
    task: "과제4",
    submitter: "최학생",
    by: "assignment",
  },
  {
    id: 5,
    no: 5,
    class: "5차시",
    type: "과제",
    task: "과제5",
    submitter: "배학생",
    by: "assignment",
  },
];

export default function GradesClassroom() {
  const [activeTab, setActiveTab] = React.useState("student");
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalPages = Math.ceil(data.length / 5);
  const totalPages2 = Math.ceil(data2.length / 5);

  const tabs = [
    { name: "수강생 별", id: "student" },
    { name: "과제 별", id: "assigment" },
  ];

  const filteredData = data.filter((notice) => activeTab === "student");
  const filteredData2 = data2.filter((notice) => activeTab === "assigment");

  const handleRowClick = (row: any) => {
    console.log("Row clicked:", row);
  };

  const handleRefundRequestClick = (row: any) => {
    console.log("Refund request clicked:", row);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow min-h-screen flex flex-col">
      <div className="space-y-6 flex flex-col mb-6">
        <StatsCardsLearning />
        <CourseStatusLearning />
      </div>
      <h2 className="text-xl font-bold border-b pb-4">수강생 학습 현황</h2>
      <div className="h-11 rounded-lg flex mt-6">
        <div className="flex-grow h-11 px-3 py-2.5 bg-white rounded-tl-lg rounded-bl-lg border border-[#e0e0e0] flex items-center">
          <input
            type="text"
            placeholder="학습자명"
            className="w-full text-[#a5a5a5] text-sm font-medium font-['Pretendard'] leading-tight outline-none"
          />
        </div>
        <div className="flex-grow h-11 px-3 py-2.5 bg-white border-r border-t border-b border-[#e0e0e0] flex items-center">
          <input
            type="text"
            placeholder="과제명"
            className="w-full text-[#a5a5a5] text-sm font-medium font-['Pretendard'] leading-tight outline-none"
          />
        </div>
        <div className="w-12 h-11 bg-[#e0e0e0] flex justify-center items-center">
          <button className="w-6 h-6">
            <SearchNormal color="white" />
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`py-2 px-6 ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-600 text-blue-600"
                  : "text-gray-400"
              } focus:outline-none`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="flex justify-end">
          <div className="flex justify-end m-4 space-x-4">
            <button
              className={`flex justify-center gap-1 items-center px-4 py-2 rounded-lg border border-gray-500 text-gray 500 rounded-md text-sm cursor-pointer`}
              onClick={() => {
                console.log("가나다 순");
              }}
            >
              표 내보내기 <Export size={16} />
            </button>
            <button
              className={`flex justify-center gap-1 items-center px-4 py-2 rounded-lg border border-primary text-primary rounded-md text-sm cursor-pointer`}
              onClick={() => {
                console.log("가나다 순");
              }}
            >
              가나다 순
            </button>
          </div>
        </div>
      </div>
      {activeTab === "student" ? (
        <>
          <Table
            columns={columns}
            data={filteredData}
            onRefundRequestClick={handleRowClick}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </>
      ) : (
        <>
          <Table
            columns={columns2}
            data={filteredData2}
            onRefundRequestClick={handleRefundRequestClick}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages2}
            onPageChange={setCurrentPage}
          />
        </>
      )}
    </div>
  );
}
