import React, { useState } from "react";
import Search from "../../../components/materials/Search";
import { Table } from "../../../components/tables";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { MessageEdit } from "iconsax-react";
import Pagination from "../../../components/pagination";
import { ArrowDown2 } from "iconsax-react";



interface Inquiry {
  id : number,
  no : number,
  title  : string,
  name : string,
  email : string,
  createAt : string
}

const InquiryDAta : Inquiry[] = [
  {
    id : 1,
    no : 1,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 2,
    no : 2,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 3,
    no : 3,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 4,
    no : 4,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 5,
    no : 5,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 6,
    no : 6,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 7,
    no : 7,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 8,
    no : 8,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 9,
    no : 9,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  },
  {
    id : 10,
    no : 10,
    title : '학습시간 변경 안내 공지',
    name : '김강사',
    email : 'tads@chappy.com',
    createAt : '23.02.04 23:59'
  }
]



const columnsInquiry : ColumnDef<Inquiry>[] =[
  {
    accessorKey :'no',
    header : 'No.'
  },
  {
    accessorKey : 'title',
    header : '제목'
  },
  {
    accessorKey : 'name',
    header : '이름'
  },
  {
    accessorKey : 'email',
    header : '이메일'
  },
  {
    accessorKey : 'createAt',
    header : '작성일'
  }
]



export default function InquiryManagement() {
  const [activeTab, setActiveTab] = useState("kuliah-1");
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const navigate = useNavigate();

  const tabs = [
    { name: "전체", id: "kuliah-1" },
    { name: "학습문의", id: "kluiah-2" },
    { name: "도입문의", id: "kuliah-3" },
  ];

  const filteredNotices = InquiryDAta.filter(
    (notice) => activeTab === "kuliah-1" 
  );

  const totalPages = Math.ceil(filteredNotices.length / dataPerPage);

  return (
    <div className="bg-white min-h-screen p-6 rounded-lg">
      <h2 className="text-xl font-bold border-b pb-2">문의관리</h2>
      <div className="flex justify-between items-center">
        <div className="flex">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`py-2 px-6 ${
                activeTab === tab.id
                  ? "border-b-2 border-grey-8 text-grey-8"
                  : "text-gray-400"
              } focus:outline-none`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="flex justify-end">
          <div className="relative mt-4">
            <Search placeholder="검색..." />
          </div>
        </div>
      </div>
      <div className="text-grey-4 text-right text-sm font-medium mb-4 flex justify-end items-center gap-2">10개씩 보기 <ArrowDown2 size={15} /> </div>
      <Table
        columns={columnsInquiry}
        data={InquiryDAta}
        onRowClick={(row) => {
          navigate(`/dashboard-admin/inquiry-management/${row.id}`);
        }}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}
