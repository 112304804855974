import { ArrowUp2, ArrowDown2 } from "iconsax-react";
import { useEditorContext } from "../../../../context/editorContext";
import { useState } from "react";
import IconProhibit from "../iconProhibit";
import List from "./list";
import Hint from "./hint";
const RightSide = () => {
  const { output } = useEditorContext();
  const [isExpand, setExpand] = useState(false);
  const expandTab = function () {
    setExpand(!isExpand);
  };
  return (
    <div className="bg-bg_4 dark:bg-grey-7 relative w-full">
      {/* execution results */}
      <div
        className={`
        
        bg-bg_4 dark:bg-grey-7
        absolute top-0 w-full cursor-pointer 
        z-10 overflow-hidden
        transition-all duration-500
        ${isExpand ? "h-full" : "h-14"}
        `}
      >
        <div
          onClick={expandTab}
          className="
          px-5 text-grey-6 text-xl font-bold bg-grey-1
          dark:bg-grey-8 dark:text-grey-4 h-14
          flex items-center justify-between
        "
        >
          <div>실행 결과</div>
          {
            isExpand ? <ArrowDown2 size="20" />
            : <ArrowUp2 size="20" />
          }
        </div>
        <div className="p-4 relative h-full">
          <div className="text-grey-3 font-bold  text-base">
            테스트 1 채점 결과
          </div>
          <div
            className="
            mt-4 flex gap-2 items-center border border-error
            p-2 rounded-lg bg-white dark:bg-grey-7
          "
          >
            <div>
              <IconProhibit />
            </div>
            <div className="text-error text-base font-medium">오답입니다.</div>
            <div className="bg-error text-white px-[10px] py-[6px] rounded-[4px]  ml-auto">
              60점
            </div>
          </div>
          <div className="text-grey-5 mt-2 mb-4 dark:text-grey-1 text-xs font-medium text-right">
            아쉬워요! 틀린 부분을 선택해 다시 시도해볼까요?
          </div>
          <List />
        </div>
      </div>
      {
        isExpand && <Hint/>
      }
      
      <div className="p-4 mt-14 text-base font-bold text-grey-7 dark:text-white">
        {output && output.map((line: any, i: any) => <p key={i}>{line}</p>)}
      </div>
    </div>
  );
};

export default RightSide;
