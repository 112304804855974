import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateNoticesClassroom: React.FC = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);

  const handleDropdownToggle = (menu: string) => {
    setDropdownOpen(dropdownOpen === menu ? null : menu);
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <div className="bg-white min-h-screen p-6 rounded-lg shadow">
      <div className="flex items-center justify-between border-b pb-4 mb-6">
        <h2 className="text-2xl font-bold text-gray-800">게시판</h2>
        <div className="flex space-x-4">
          <button className="text-gray-600 hover:text-black transition">
            돌음
          </button>
          <button className="text-gray-600 hover:text-black transition">
            분문2
          </button>
        </div>
      </div>
      <div className="mb-6 flex items-center space-x-4">
        <div className="relative">
          <button
            onClick={() => handleDropdownToggle("lecture")}
            className="flex items-center text-gray-600 hover:text-black transition focus:outline-none"
          >
            강의
            <svg
              className={`ml-2 w-4 h-4 transform ${
                dropdownOpen === "lecture" ? "rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          {dropdownOpen === "lecture" && (
            <ul className="absolute mt-2 py-2 bg-white border border-gray-300 rounded shadow-lg">
              <li>
                <button className="block w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none">
                  1
                </button>
              </li>
              <li>
                <button className="block w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none">
                  2
                </button>
              </li>
            </ul>
          )}
        </div>
        <div className="relative">
          <button
            onClick={() => handleDropdownToggle("comments")}
            className="flex items-center text-gray-600 hover:text-black transition focus:outline-none"
          >
            댓글 허용
            <svg
              className={`ml-2 w-4 h-4 transform ${
                dropdownOpen === "comments" ? "rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          {dropdownOpen === "comments" && (
            <ul className="absolute mt-2 py-2 bg-white border border-gray-300 rounded shadow-lg">
              <li>
                <button className="block w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none">
                  1
                </button>
              </li>
              <li>
                <button className="block w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none">
                  2
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="mb-6">
        <input
          type="text"
          className="w-full border border-gray-300 rounded-sm focus:outline-none focus:border-gray-500 text-xl py-2 px-3"
          placeholder="제목을 입력해주세요"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <ReactQuill
          value={content}
          onChange={setContent}
          modules={modules}
          placeholder="내용을 입력해주세요"
        />
      </div>
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md transition"
        >
          임시저장
        </button>
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition"
        >
          완료
        </button>
      </div>
    </div>
  );
};

export default CreateNoticesClassroom;
