import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../components/materials/Breadcrump";
import Modal from "../../../../components/modal/index";

type Item = {
  type: string;
  editable: boolean;
};

type Section = {
  id: number;
  title: string;
  items: Item[];
};

export default function LectureRegistrationNext() {
  const [sections, setSections] = useState<Section[]>([
    { id: 1, title: "CHAPTER 1", items: [] },
  ]);
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<number | null>(null);

  const handleOpenDeleteModal = (sectionId: number) => {
    setSelectedSection(sectionId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedSection !== null) {
      handleDeleteSection(selectedSection);
      setIsDeleteModalOpen(false);
    }
  };

  const handleAddSection = () => {
    const newSection: Section = {
      id: sections.length + 1,
      title: `CHAPTER ${sections.length + 1}`,
      items: [],
    };
    setSections([...sections, newSection]);
  };

  const handleOpenModal = (sectionId: number) => {
    setSelectedSectionId(sectionId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSectionId(null);
    setSelectedType("");
  };

  const handleConfirmModal = () => {
    if (selectedSectionId !== null && selectedType) {
      handleAddItem(selectedSectionId, selectedType);
      handleCloseModal();
    }
  };

  const handleAddItem = (sectionId: number, type: string) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          items: [...section.items, { type, editable: true }],
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const handleDeleteSection = (sectionId: number) => {
    const updatedSections = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSections);
  };

  const handleDeleteItem = (sectionId: number, itemIndex: number) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const newItems = section.items.filter(
          (_, index) => index !== itemIndex
        );
        return {
          ...section,
          items: newItems,
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const navigate = useNavigate();
  const handlePrevious = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    navigate("/dashboard-professor/create-lecture-form");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="min-h-screen bg-white p-6 rounded-lg">
      <div className="w-full max-w-screen-lg h-auto px-4 py-6 bg-white rounded-2xl flex flex-col gap-6">
        {/* Header Section */}
        <div className="flex items-center">
          <Breadcrumb
            items={[
              { title: "강의", path: "/dashboard-professor/subjects" },
              {
                title: "강의 등록",
                path: "/dashboard-professor/create-lecture-form2",
              },
            ]}
          />
        </div>

        {/* Title Section */}
        <div className="flex gap-1">
          <div className="text-center text-[#242424] text-xl font-bold font-['Pretendard']">
            강의 등록
          </div>
          <div className="text-left gap-1 flex text-[#242424] text-xl font-bold font-['Pretendard']">
            <span className="text-primary">2/2</span>
          </div>
        </div>

        {/* Form Section */}
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-5">
            <div className="text-center flex justify-start text-[#242424] text-base font-bold font-['Pretendard']">
              세부 커리큘럼 구성
            </div>
            <div className="flex flex-col gap-4">
              <div className="px-5 py-4 bg-[#f2f4f6] rounded-lg text-[#555555] text-sm font-medium font-['Pretendard']">
                <p>
                  차시를 추가하여 세부 커리큘럼을 구성하고, 드래그 하여 차시 및
                  세부 커리큘럼의 옮길 수 있습니다.
                </p>
              </div>
              <div className="flex gap-3 justify-end">
                <button className="w-full max-w-[120px] h-10 px-4 py-2 bg-white rounded-lg border border-[#c3c3c3] text-[#a5a5a5] text-sm font-bold font-['Pretendard']">
                  미리보기
                </button>
                <button
                  onClick={handleAddSection}
                  className="w-full max-w-[120px] h-10 px-4 py-2 bg-[#2860ef] rounded-lg text-white text-sm font-bold font-['Pretendard']"
                >
                  차시 추가
                </button>
              </div>
            </div>
          </div>

          {/* Sections Display */}
          {sections.map((section) => (
            <div key={section.id} className="flex flex-col gap-2">
              <div className="px-4 py-4 bg-[#759CFF] rounded-lg text-white text-sm font-bold font-['Pretendard'] flex justify-between items-center">
                <span>
                  {section.id}. {section.title}
                </span>
                <button
                  onClick={() => handleOpenDeleteModal(section.id)}
                  className="text-white"
                >
                  삭제
                </button>
              </div>
              {section.items.map((item, index) => (
                <div
                  key={index}
                  className="px-4 py-2 bg-[#edf2fa] rounded-lg flex justify-between items-center text-sm font-medium font-['Pretendard']"
                >
                  <span>{item.type}</span>
                  <div className="flex gap-3">
                    <button className="text-[#555555]">수정</button>
                    <button
                      onClick={() => handleDeleteItem(section.id, index)}
                      className="text-[#555555]"
                    >
                      삭제
                    </button>
                  </div>
                </div>
              ))}
              <button
                onClick={() => handleOpenModal(section.id)}
                className="px-4 py-2 bg-[#f2f4f6] rounded-lg text-[#a5a5a5] text-sm font-medium font-['Pretendard']"
              >
                +
              </button>
            </div>
          ))}

          {/* Final Actions */}
          <div className="flex justify-end gap-3">
            <button
              onClick={handlePrevious}
              className="w-[120px] h-10 px-9 py-2.5 bg-white rounded-[10px] border border-[#2860ef] justify-center items-center gap-1 flex"
            >
              <div className="justify-center items-center gap-1 flex">
                <div className="text-center text-[#2860ef] text-sm font-bold font-['Pretendard'] leading-tight">
                  이전
                </div>
              </div>
            </button>
            <button
              onClick={handlePrevious}
              className="w-[120px] h-10 px-9 py-2.5 bg-white rounded-[10px] border bg-[#2861EF] justify-center items-center gap-1 flex"
            >
              <div className="justify-center items-center gap-1 flex">
                <div className="text-center text-[#fff] text-sm font-bold font-['Pretendard'] leading-tight">
                  완성된
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <Modal title="구성 추가하기" onClose={handleCloseModal} size="sm">
          <div className="flex flex-col gap-4">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                value="동영상 강의"
                checked={selectedType === "동영상 강의"}
                onChange={() => setSelectedType("동영상 강의")}
              />
              동영상 강의
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                value="과제"
                checked={selectedType === "과제"}
                onChange={() => setSelectedType("과제")}
              />
              과제
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                value="실습 IDE"
                checked={selectedType === "실습 IDE"}
                onChange={() => setSelectedType("실습 IDE")}
              />
              실습 IDE
            </label>
          </div>
          <div className="flex justify-center gap-4 mt-6">
            <button
              onClick={handleConfirmModal}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg"
            >
              확인
            </button>
            <button
              onClick={handleCloseModal}
              className="px-6 py-2 bg-gray-300 text-black rounded-lg"
            >
              취소
            </button>
          </div>
        </Modal>
      )}

      {isDeleteModalOpen && (
        <Modal
          title="삭제 확인"
          onClose={() => setIsDeleteModalOpen(false)}
          size="sm"
        >
          <div className="text-center">
            <p>이 섹션을 삭제하시겠습니까?</p>
            <div className="flex flex-col w-full justify-end gap-3 mt-4">
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-lg"
              >
                삭제
              </button>
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="px-4 py-2 bg-gray-300 rounded-lg"
              >
                취소
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
