import { useState } from "react";
import { IconQuateLeft, IconQuateRight } from "../../../components/Icons/iconQuote";
import Instructors from "./instructors";
import Curriculum from "./curriculum";
import Review from "./review"
const TabsLectures = () => {
  const [tab, setTab] = useState(0);

  const handlerClick = function (pos: number) {
    setTab(pos);
  };

  return (
    <div className="grid gap-16 relative" id="intro">
      <div className="flex items-center sticky top-16 w-full bg-white z-10">
        <a
          href="#intro"
          onClick={() => handlerClick(0)}
          className={`px-3 py-[10px] cursor-pointer
            ${
              tab === 0
                ? " text-grey-8 text-base font-bold border-b border-b-grey-8"
                : "text-grey-3 font-medium text-base"
            }`}
        >
          강의소개
        </a>
        <a
          href="#curriculum"
          onClick={() => handlerClick(1)}
          className={`px-3 py-[10px] cursor-pointer
            ${
              tab === 1
                ? " text-grey-8 text-base font-bold border-b border-b-grey-8"
                : "text-grey-3 font-medium text-base"
            }`}
        >
          커리큘럼
        </a>
        <a
          onClick={() => handlerClick(2)}
          href="#instructor"
          className={`px-3 py-[10px] cursor-pointer
           ${
             tab === 2
               ? " text-grey-8 text-base font-bold border-b border-b-grey-8"
               : "text-grey-3 font-medium text-base"
           }`}
        >
          강사소개
        </a>
        <a
          onClick={() => handlerClick(3)}
          href="#review"
          className={`px-3 py-[10px] cursor-pointer
            ${
              tab === 3
                ? " text-grey-8 text-base font-bold border-b border-b-grey-8"
                : "text-grey-3 font-medium text-base"
            }`}
        >
          강의후기
        </a>
      </div>

      <div className=""  >
        <div className="mb-4 text-grey-7 font-bold text-xl">걸음마 부터 천천히 JavaScript 시작해요</div>
        <div className="text-grey-3 text-sm font-bold">
          글로벌 IT 기업 UX디자이너의 업무 프로세스와 더미 텍스트 입니다. 확실한 도움을 드리겠습니다. 저와 같은 상황인 분들을 진심으로 돕겠습니다. 저와 같은 상황인 분들을 진심으로 돕겠습니다.글로벌 IT 기업 UX디자이너의 업무 프로세스와 커리어 상담과 취업준비를 하시는데 확실한 도움을 드리겠습니다. 더미텍스트입니다. 더미텍스트저와 같은 상황인 분들을 진심으로 돕겠습니다.저와 같은 상황인 분들을 진심으로 돕겠습니다.
        </div>
        <div className="my-10 flex gap-4 justify-center items-start">
          <IconQuateLeft/>
          <div> 걸음마에서 마라톤까지 🏃</div>
          <IconQuateRight/>
        </div>
        <div id="curriculum" className="text-grey-3 text-sm font-bold">
          글로벌 IT 기업 UX디자이너의 업무 프로세스와 더미 텍스트 입니다. 확실한 도움을 드리겠습니다. 저와 같은 상황인 분들을 진심으로 돕겠습니다. 저와 같은 상황인 분들을 진심으로 돕겠습니다.글로벌 IT 기업 UX디자이너의 업무 프로세스와 커리어 상담과 취업준비를 하시는데 확실한 도움을 드리겠습니다. 더미텍스트입니다. 더미텍스트저와 같은 상황인 분들을 진심으로 돕겠습니다.저와 같은 상황인 분들을 진심으로 돕겠습니다.
        </div>
      </div>

      <div   className="w-full h-[1px] bg-body "></div>
      
      <Curriculum />

      <div id="instructor"   className="w-full h-[1px] bg-body "></div>

      <Instructors/>

      <div id="review"   className="w-full h-[1px] bg-body "></div>

      <Review/>
    </div>
  );
};

export default TabsLectures;
