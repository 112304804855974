import React from "react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";

interface TableProps<T extends { id: number }> {
  columns: ColumnDef<T, any>[];
  data: T[];
  onRowClick?: (row: T) => void;
  onRefundRequestClick?: (row: T) => void;
}

export const Table = <T extends { id: number }>({
  columns,
  data,
  onRowClick,
  onRefundRequestClick,
}: TableProps<T>) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <table className="min-w-full divide-y divide-gray-200 border border-gray-200 mb-4">
        <thead className="bg-grey-1">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => {
                if (onRowClick) {
                  onRowClick(row.original);
                }
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="px-8 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  {cell.column.id === "refund" ? (
                    <button
                      className="px-2 py-1 bg-gray-500 text-white rounded-md text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onRefundRequestClick) {
                          onRefundRequestClick(row.original);
                        }
                      }}
                    >
                      환불신청
                    </button>
                  ) : cell.column.id === "detail" ? (
                    <button
                      className="px-2 py-1 bg-gray-500 text-white rounded-md text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onRefundRequestClick) {
                          onRefundRequestClick(row.original);
                        }
                      }}
                    >
                      상세보기
                    </button>
                  ) : cell.column.id === "submitterr" ? (
                    <button
                      className="px-2 py-1 bg-gray-500 text-white rounded-md text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onRefundRequestClick) {
                          onRefundRequestClick(row.original);
                        }
                      }}
                    >
                      제출명단
                    </button>
                  ) : cell.column.id === "purchase" ? (
                    <button
                      className="px-2 py-1 border border-blue-500 text-blue-500 rounded-md text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onRefundRequestClick) {
                          onRefundRequestClick(row.original);
                        }
                      }}
                    >
                      구매명단
                    </button>
                  ) : cell.column.id === "refundList" ? (
                    <button
                      className="px-2 py-1 border border-gray-500 text-gray-500 rounded-md text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onRefundRequestClick) {
                          onRefundRequestClick(row.original);
                        }
                      }}
                    >
                      환불명단
                    </button>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
