import { ArrowUp2 } from "iconsax-react";
import { useState } from "react";
import IconList from "./iconList";
import { useEditorContext } from "../../../context/editorContext";
const Recommendation = () => {
  const [isCollapse, setCollpase] = useState(false);
  const { isDark } = useEditorContext();
  const [tab, setTab] = useState("learningRecommendation");
  const tabs = [
    {
      label: "학습추천",
      value: "learningRecommendation",
    },
    {
      label: "문제추천",
      value: "questionRecommendation",
    },
  ];
  const clickPosition = function (tab: any) {
    setTab(tab.value);
  };
  return (
    <div
      className={`*
        z-20 absolute w-full
        transition-all duration-500 overflow-hidden 
        bg-bg_4 dark:bg-grey-7 bottom-0
        ${isCollapse ? "h-[calc(100%-56px)]  " : "h-14  "}
      `}
    >
      {/* Recommendation */}
      <div
        onClick={() => setCollpase(!isCollapse)}
        className={`
        px-5 h-14 flex items-center justify-between
        text-grey-6 text-xl font-bold bg-grey-1
        dark:bg-grey-8 dark:text-grey-4 cursor-pointer
        ${isCollapse && "border-b border-b-grey-2 dark:border-b-grey-7 border-t border-t-grey-2 dark:border-t-grey-7 " }
        `}
      >
        <div>추천</div>
        <div
          className={`
            transition-transform duration-500 rotate-0
            ${isCollapse && "rotate-180"}
          `}
        >
          <ArrowUp2 size="20" />
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2">
          {tabs.map((data, idx) => {
            return (
              <button
                key={idx}
                onClick={() => clickPosition(data)}
                className={`
                   py-3  text-sm font-bold
                  ${
                    data.value === tab
                      ? "bg-bg_4 text-grey-7 dark:bg-grey-7 dark:text-white"
                      : "bg-grey-1 text-grey-3  dark:bg-grey-8 dark:text-grey-5"
                  }
                  `}
              >
                {data.label}
              </button>
            );
          })}
        </div>
        <div className="p-4 grid gap-3">
          <div className="text-grey-7 text-base font-bold dark:text-white" >영상 추천</div>
          <div className="px-4 py-3 rounded-lg dark:bg-grey-6 bg-grey-1 h-32">
            <ul className="list-disc pl-4 text-grey-7 text-sm font-medium dark:text-white">
              <li className=" ">
                <div className=" items-center justify-between flex">
                  <div>피보나치 알고리즘 마스터하기</div>
                  <IconList color={`${isDark ? 'white' : '#333333' } `} />
                </div>
              </li>
            </ul>
          </div>
          {/* recommended materials */}
          <div className="text-grey-7 text-base font-bold dark:text-white" >자료 추천</div>
          <div className="px-4 py-3 rounded-lg dark:bg-grey-6 bg-grey-1 h-32">
            <ul className="grid gap-2 list-disc pl-4 text-grey-7 text-sm font-medium dark:text-white">
              <li className=" ">
                <div className=" items-center justify-between flex">
                  <div>피보나치란?</div>
                  <IconList color={`${isDark ? 'white' : '#333333' } `}/>
                </div>
              </li>
              <li className=" ">
                <div className=" items-center justify-between flex">
                  <div>파이썬으로 피보나치 수 알고리즘 구현하기</div>
                  <IconList color={`${isDark ? 'white' : '#333333' } `}/>
                </div>
              </li>
              <li className="">
                <div className=" items-center justify-between flex">
                  <div>피보나치 수 - 위키백과</div>
                  <IconList color={`${isDark ? 'white' : '#333333' } `}/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recommendation;
