import {
  type ComponentPropsWithoutRef,
  forwardRef,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import IconDate from "../../Icons/iconDate";

type InputProps = {
  label?: string;
  isRequired?: boolean;
  id: string;
  errorMessage?: string;
  variant?: string;
  timer?: string | number;
  sizeVariant?: string;
  icons?: any;
} & ComponentPropsWithoutRef<"input">;

type InputVariant = {
  default: string;
  error: string;
  timer: string;
  disabled: string;
};

type SizeVariant = {
  md: string;
  sm: string;
};

const DatePickers = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    label,
    id,
    errorMessage,
    isRequired,
    variant = "default",
    sizeVariant = "md",
    timer,
    icons,
    value,
    ...props
  },
  ref
) {
  const [startDate, setStartDate] = useState(new Date());
  const [startOpen, setStartOpen] = useState(false);

  const dateRef = useRef<HTMLInputElement>(null);

  const handleOpen = () => {
    setStartOpen(!startOpen);
  };

  const handleSelect = (e: any) => {
    const formattedDate = new Date(new Intl.DateTimeFormat("en-US").format(e));
    const final = `${formattedDate.getFullYear()}/${
      (formattedDate.getMonth() + 1).toString().length === 2
        ? formattedDate.getMonth() + 1
        : `0${formattedDate.getMonth() + 1}`
    }/${formattedDate.getDate()}`;

    setStartDate(new Date(final));
    setStartOpen(false);
  };

  return (
    <div className="grid w-full h-[55px]">
      <label
        htmlFor={id}
        className="relative  justify-start items-center  w-full h-[55px] rounded-xl border cursor-pointer flex "
        onClick={handleOpen}
      >
        <span
          className={`after:ml-0.5 after:absolute after:bottom-1 after:text-red-500 block text-grey-7 text-base font-bold ${
            isRequired && "after:content-['*']"
          } `}
        ></span>
        <span className="pl-10 pr-4 text-[10px] font-medium">
          {startDate.getFullYear()}년{" "}
          {(startDate.getMonth() + 1).toString().length > 1
            ? startDate.getMonth() + 1
            : `0${startDate.getMonth() + 1}`}
          월 {startDate.getDate()}일
        </span>
        <div className="absolute left-2.5 top-0 bottom-0 self-center">
          <IconDate />
        </div>
      </label>
      <input
        ref={dateRef}
        type="date"
        className="hidden pl-5 break-words w-fit focus:outline-none disabled:bg-transparent date-input date-picker"
        id={id}
        {...props}
      />
      <DatePicker
        open={startOpen}
        selected={startDate}
        className="hidden w-full p-2 border"
        placeholderText="2022.01.01. 00:00"
        onChange={(e) => handleSelect(e)}
      />

      {errorMessage && (
        <div className="flex items-center gap-2 mt-2 text-sm font-medium text-danger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.00065 14.6673C4.31865 14.6673 1.33398 11.6827 1.33398 8.00065C1.33398 4.31865 4.31865 1.33398 8.00065 1.33398C11.6827 1.33398 14.6673 4.31865 14.6673 8.00065C14.6673 11.6827 11.6827 14.6673 8.00065 14.6673ZM7.33398 10.0007V11.334H8.66732V10.0007H7.33398ZM7.33398 4.66732V8.66732H8.66732V4.66732H7.33398Z"
              fill="#FF1D53"
            />
          </svg>
          {errorMessage}
        </div>
      )}
    </div>
  );
});

export default DatePickers;
