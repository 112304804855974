import Input from "../../components/materials/Input"
import Button from "../../components/materials/Button"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'


const resetPasswordSchema = z.object({
  newPassword: z.string().refine(value => /^(?=.*[a-z]){2,}(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value ?? ""), "error"),
  password: z.string()
}).refine((data) => data.newPassword === data.password, {
  message: 'password dont match',
  path: ['password']
})

type ResetPasswordType = z.infer<typeof resetPasswordSchema>
const ResetPassword = () => {
  const { formState, register ,handleSubmit } = useForm<ResetPasswordType>({
    resolver: zodResolver(resetPasswordSchema),
    mode: 'onChange'
  })
  const [open, setOpen] = useState(false)

  const handlerDialog = function () {
    setOpen(!open)
  } 
  const submitHandler = handleSubmit(() => {
    console.log('submit')
    setOpen(true)
  })
  return (
    <div className="bg-blue-1 h-full flex items-center justify-center">
      <div className="p-12 w-[440px] rounded-[20px] bg-white border border-grey-1">
        <div className="text-center text-grey-9 text-2xl font-bold mb-12">비밀번호  재설정</div>
        <form onSubmit={submitHandler} >
          <div className="grid gap-3.5 mb-16">
            <Input
              id="newPassword"
              isRequired
              label="새로운 비밀번호"
              placeholder="새로운 비밀번호"
              errorMessage={formState.errors.newPassword && formState.errors.newPassword.message}
              {...register('newPassword')}
            />
            <Input
              id="password"
              isRequired
              label="비밀번호 재입력"
              placeholder="비밀번호 재입력"
              errorMessage={formState.errors.password && formState.errors.password.message}
              {...register('password')}
            />
          </div>
          
          <Button disabled={!formState.isValid}  onClick={handlerDialog} type="submit" variant="primary">비밀번호 변경하기</Button>
        </form>
        <Dialog open={open} onClose={handlerDialog} className="relative z-10">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white py-7 px-10">
                    <div className="flex flex-col items-center gap-6 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="text-grey-8 text-lg font-bold">비밀번호 변경</div>
                      <div className="text-grey-6 text-sm font-medium">비밀번호가 변경되었습니다.</div>
                      <Button onClick={handlerDialog} variant="primary" >닫기</Button>
                    </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  )
}

export default ResetPassword