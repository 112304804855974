import React, { useState } from "react";
import { useCourseStore } from "../../../store/course";
import CourseCard from "../components/CourseCard";
import EmptyState from "../../../components/emptyState";
import Pagination from "../../../components/pagination";
import EmptyCourse from "../../../assets/course-empty.png";

const Course: React.FC = () => {
  const [activeTab, setActiveTab] = useState("in-progress");
  const { courseData } = useCourseStore();
  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 4;

  const tabs = [
    { name: "진행중", id: "in-progress" },
    { name: "종료", id: "termination" },
    { name: "이수", id: "completion" },
    { name: "수강 신청 중", id: "applying" },
  ];

  const filteredCourses = courseData.filter(
    (course) => activeTab === course.category
  );
  const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);
  const displayedCourses = filteredCourses.slice(
    (currentPage - 1) * coursesPerPage,
    currentPage * coursesPerPage
  );

  return (
    <div className="min-h-screen bg-white p-6 rounded-lg shadow">
      <div className="min-h-screen">
        <h2 className="text-xl font-bold mb-4 border-b pb-2">수강 과목</h2>
        <div className="flex mb-6">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`py-2 px-6 ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-600 text-blue-600"
                  : "text-gray-400"
              } focus:outline-none`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        {displayedCourses.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {displayedCourses.map((course, index) => (
                <CourseCard key={index} {...course} />
              ))}
            </div>
          </>
        ) : (
          <EmptyState
            imgSrc={EmptyCourse}
            title="강의 목록이 없어요"
            description="채피의 인기 강의를 둘러보세요"
            buttonText="강의 둘러보기"
            onButtonClick={() => console.log("Button clicked")}
          />
        )}
      </div>
      {displayedCourses && displayedCourses.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

export default Course;
