import React from "react";
import Avatar from "../../../assets/avatar.png";
import Plank from "../../../assets/plank.png";

interface ModalProps {
  show?: boolean;
  onClose?: () => void;
}

const ModalStudentProgress: React.FC<ModalProps> = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-start py-10 justify-center bg-black bg-opacity-50 z-[99999] overflow-y-scroll">
      <div className="w-full p-6 bg-white rounded shadow-lg max-w-[762px] min-h-[930px]">
        <button onClick={onClose} className="float-right text-gray-700">
          &times;
        </button>
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-center gap-10">
            <IconPrevious />
            <div className="mb-8 text-center">
              <img
                src={Avatar}
                alt="Profile"
                className="w-[70px] h-[70px] mx-auto rounded-full bg-[#AAB6CF]"
              />
              <h1 className="mt-4 text-base font-bold">관리자</h1>
              <p className="text-xs text-grey-5">admin@naasf.com</p>
            </div>
            <IconNext />
          </div>
          <div className="grid w-full grid-cols-4 gap-3">
            <div className="col-span-2  bg-blue-4 h-[105px] border to-blue-2 rounded-lg flex flex-col justify-center items-end px-5 relative">
              <h1 className="text-sm font-medium text-white">최종 성적</h1>
              <h3 className="text-[28px] font-semibold text-white">67.6%</h3>
              <img src={Plank} alt="" className="absolute -left-8" />
            </div>
            <div className=" bg-blue-1 h-[105px] border to-blue-2 rounded-lg flex justify-center items-center flex-col">
              <h1 className="text-sm font-medium text-blue-3">등수</h1>
              <h3 className="text-xl font-semibold text-blue-4">12</h3>
            </div>
            <div className=" bg-blue-1 h-[105px] border to-blue-2 rounded-lg flex justify-center items-center flex-col">
              <h1 className="text-sm font-medium text-blue-3">등수</h1>
              <h3 className="text-xl font-semibold text-blue-4">12</h3>
            </div>
          </div>
          <div className="flex flex-col w-full px-10 py-6 border rounded-lg bg-blue-1 border-blue-2">
            <h1 className="text-base font-bold ">진도율</h1>
            <div className="flex flex-row items-center gap-4">
              <div className="relative w-full h-3 rounded-full bg-blue-2">
                <span className="w-[53%] h-3 rounded-full absolute bg-blue-4"></span>
              </div>
              <h1 className="flex items-center font-bold text-blue-3">
                53 <span className="text-xs font-medium">%</span>
              </h1>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3">
            <CardValue title="중간고사" />
            <CardValue title="기말고사" />
          </div>
          <div className="grid grid-cols-3 gap-3">
            <CardValue title="출석" />
            <CardValue title="과제" />
            <CardValue title="기타" />
          </div>
          <div className="flex flex-col w-full gap-3">
            <button
              className="h-[56px] w-full text-center flex justify-center rounded-lg items-center text-base font-bold text-white bg-blue-4"
              onClick={onClose}
            >
              확인
            </button>
            <button
              className="h-[56px] w-full text-center flex justify-center rounded-lg items-center text-base font-bold text-white bg-grey-3"
              onClick={onClose}
            >
              수정
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalStudentProgress;

const IconPrevious = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className=" stroke-[#A5A5A5] rotate-90 h-6 w-6"
    >
      <path
        d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const IconNext = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className=" stroke-[#A5A5A5] -rotate-90 h-6 w-6"
    >
      <path
        d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

interface CardValueProps {
  title: string;
}
const CardValue: React.FC<CardValueProps> = ({ title }) => {
  return (
    <div className="flex flex-col w-full gap-4 px-6 py-4 border rounded-lg bg-blue-1 border-blue-2">
      <h1 className="text-base font-bold text-[#242424] text-center ">
        {title}
      </h1>
      <hr className="h-[1px] w-full bg-slate-400" />
      <div className="flex items-center justify-between w-full">
        <h2 className="text-sm font-medium text-blue-3">학생 점수</h2>
        <h1 className="text-base font-bold text-blue-4">68</h1>
      </div>
      <div className="flex items-center justify-between w-full">
        <h2 className="text-sm font-medium text-grey-5">평균 점수</h2>
        <h2 className="text-sm font-medium text-grey-5">48</h2>
      </div>
    </div>
  );
};
