import CommentImage from "../../../assets/comment.png";
import StarImage from "../../../assets/star.png";
import Avatar from "../../../assets/avatar.png";
import Ratings from "./rating";
const Review = () => {
  const comments = [0, 1, 2, 3, 4, 5, 6, 7];
  return (
    <div>
      <div className="text-grey-8 mb-7 text-xl font-bold">강의 평가</div>
      <div className="mb-6 grid grid-cols-2 gap-4 ">
        <div className="flex justify-between items-center py-3 px-7 border border-blue-2  bg-blue-1 rounded-xl ">
          <div className="flex items-center gap-6">
            <div className="text-grey-7 text-base font-bold">전체 리뷰 수</div>
            <div className="flex items-center gap-1">
              <div className="text-blue-5 text-xl font-bold">25 </div>
              <div className="text-blue-3 font-medium text-xs">개</div>
            </div>
          </div>
          <div>
            <img src={CommentImage} alt="" />
          </div>
        </div>
        <div className="flex justify-between items-center py-3 px-7 border border-blue-2  bg-blue-1 rounded-xl ">
          <div className="flex items-center gap-6">
            <div className="text-grey-7 text-base font-bold">평균 총점</div>
            <div className="flex items-center gap-1">
              <div className="text-blue-5 text-xl font-bold">4.5 </div>
              <div className="text-blue-3 font-medium text-xs">/ 5점</div>
            </div>
          </div>
          <div>
            <img src={StarImage} alt="" />
          </div>
        </div>
      </div>
      <ul className="grid gap-6">
        {comments.map((comment) => {
          return (
            <li key={comment} className="grid gap-3 border-b border-b-bg_2">
              <div className="flex gap-3 items-center">
                <div className="overflow-hidden w-11 h-11 rounded-full bg-bg_2">
                  <img src={Avatar} alt="" />
                </div>
                <div>
                  <div className="text-grey-7 text-xs font-bold">김학생</div>
                  <Ratings />
                </div>
              </div>
              <div className="text-grey-8 text-base font-bold">
                현실적인 도움이 되었습니다
              </div>
              <div className="text-grey-6 text-sm font-medium">
                신경써서 봐주신 덕분에 손쉽게 원하던 곳에 합격할 수있었습니다
                감사합니다 멘토님 저와 비슷한 경험이 있셔서 더욱 도움이
                되었습니다 감사합니다! 멘토님 저와 비슷한 경험이 있셔서 더욱
                도움이 되었습니다 감사합니다! 글로벌 IT 기업 UX디자이너의 업무
                프로세스와 더미텍스트.
              </div>
              <div className="text-grey-4 text-xs font-medium">22.08.14</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Review;
