import Robot from "../../assets/robot1.png";
import Button from "../../components/materials/Button";
import WhyCappie from "./whyChappie";
import TraningPlatform from "./trainingPlatform"
import Ide from "./ide";
import Review from "./reviews"
import Intro from "./intro";
import Footer from "../../components/containers/footer"
const Home = () => {
  return (
    <div>
      <div
        className="h-[694px] relative 2xl:px-72 px-40  py-36 "
        style={{
          background: "linear-gradient(0deg, #C8D6E7 0.2%, #FFF 92.52%), #FFF",
        }}
      >
        <img
          className="absolute  w-[800px] top-36 right-40 2xl:right-72"
          src={Robot}
          alt=""
        />
        <div className=" w-[404px] absolute">
          <div className="text-grey-6 text-xl font-medium mb-5">
            어렵고 지루한 코딩, 함께 해결해봐요
          </div>
          <div className="text-grey-9 font-bold text-5xl mb-14">
            가장 쉬운 코딩 공부법 채피에서 행복하게
          </div>
          <div className="w-56">
            <Button variant="primary">채피 체험해보기</Button>
          </div>
        </div>
      </div>
      <WhyCappie />
      <TraningPlatform/>
      <Ide/>
      <Review/>
      <Intro/>
      <Footer/>
    </div>
  );
};

export default Home;
