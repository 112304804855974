import { useState } from "react";
import { Languanges } from "../constants";
import { useEditorContext } from "../../../context/editorContext";
const LanguangeSelector = () => {
  const [open, setOpen] = useState(false);
  const { languange, setLanguangeEditor } = useEditorContext()
  const setUpMenu = function () {
    setOpen(!open);
  };
  const clickHandler = function (lang : any) {
    setOpen(false)
    setLanguangeEditor(lang.value)
  }
  return (
    <div className="relative w-[192px] inline-block text-left">
      <div>
        <button
          type="button"
          onClick={setUpMenu}
          aria-placeholder="test"
          className="
            inline-flex 
            w-full justify-between 
            gap-x-1.5 rounded-md bg-grey-1 px-3 py-2 text-base
            font-bold text-grey-5 border border-grey-2
            dark:bg-grey-6 dark:text-white dark:border-grey-4
            "
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
        >
          
          {languange ? languange : '과제' }
          <svg
            className="-mr-1 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {open && (
        <div
          className="overflow-hidden absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-bg_4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <ul role="none">
            {Languanges.map((lang, idx) => {
              return (
                <li
                  key={idx}
                  onClick={() => clickHandler(lang)}
                  className="
                    block px-4 py-2 border-b border-b-grey-2 text-sm text-grey-5 font-medium
                    dark:bg-grey-7 dark:border-grey-4 dark:text-white cursor-pointer
                    "
                  role="menuitem"
                  tabIndex={-1}
                  id="menu-item-0"
                >
                  1주차 과제 : {lang.label} 코드
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguangeSelector;
