import { ArrowUp2, ArrowDown2 } from "iconsax-react";
import { useState } from "react";
import IconLamp from "../../../../components/Icons/iconLamp";
import Button from "../../../../components/materials/Button";
import Select from "../../navigation/select";
import Editor from "@monaco-editor/react";
import { editor } from "monaco-editor";
import { useRef } from "react";
import { useEditorContext } from "../../../../context/editorContext";
import { CODE_SNIPPETS } from "../../constants";
import { IconThumbsDown, IconThumbsUp } from "../../../../components/Icons/iconThumbs";
const Hint = () => {
  const [isHint, setIshint] = useState(false);
  const { isDark } = useEditorContext();
  const [tab, setTab] = useState("order");
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  function handleEditorDidMount(editor: editor.IStandaloneCodeEditor) {
    editorRef.current = editor;
  }
  const options = [
    {
      label: "테스트케이스 - 1",
      value: "1",
    },
    {
      label: "테스트케이스 - 2",
      value: "2",
    },
  ];
  const [value, setValue] = useState<(typeof options)[0] | undefined>(
    options[0]
  );

  const tabs = [
    {
      label: "실행순서",
      english: "order of execution",
      value: "order",
    },
    {
      label: "틀린위치",
      english: "the wrong position",
      value: "position",
    },
    {
      label: "수정방법",
      english: "modification method",
      value: "method",
    },
    {
      label: "코드구조",
      english: "code structure",
      value: "structure",
    },
    {
      label: "코드설명",
      english: "code description",
      value: "description",
    },
  ];
  const expandHintTab = function () {
    setIshint(!isHint);
  };
  const clickPosition = function (tab: any) {
    setTab(tab.value);
  };
  return (
    <div
      className={`
        z-20 absolute w-full  h-14
        transition-all duration-500 overflow-hidden 
      bg-bg_4 dark:bg-grey-7 bottom-0
        ${isHint ? "h-[calc(100%-56px)]  " : "h-14  "}
      `}
    >
      <div>
        <div
          onClick={expandHintTab}
          className={`
            bg-grey-1 dark:bg-grey-8 cursor-pointer flex gap-2 items-center dark:text-white text-blue-4 h-14 p-5
            ${isHint && "border-b border-b-grey-2 dark:border-b-grey-7 border-t border-t-grey-2 dark:border-t-grey-7"}
            `}
        >
          <IconLamp color={`${isDark ? "#F7E379" : "#2861EF" }  `} />
          <div className="text-lg dark:text-[#F7E379] font-bold">힌트</div>
          <div className="ml-auto dark:text-[#F7E379] text-xs font-medium">
            어렵다면 힌트를 확인해보세요!
          </div>
          {isHint ? <ArrowDown2 size={20} /> : <ArrowUp2 size="20" />}
        </div>
        <div className=" h-full">
          <div className="grid grid-cols-5">
            {tabs.map((data, idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => clickPosition(data)}
                  className={`
                   py-3  text-sm font-bold
                  ${
                    data.value === tab
                      ? "bg-bg_4 text-grey-7 dark:bg-grey-7 dark:text-white"
                      : "bg-grey-1 text-grey-3  dark:bg-grey-8 dark:text-grey-5"
                  }
                  `}
                >
                  {data.label}
                </button>
              );
            })}
          </div>
          <div className="p-5">
            <div className="text-grey-9 mb-5 text-base font-bold dark:text-white">
              {"< Execution Trace 정보를 알려주는 힌트 >"}
            </div>
            <div className="flex items-center gap-2 w-full mb-4">
              <div className="flex-1">
                <Select
                  size="md"
                  options={options}
                  onChange={(o) => setValue(o)}
                  value={value}
                />
              </div>
              <div className="w-20">
                {/* view */}
                <Button size="sm" variant="primary">
                  보기
                </Button>
              </div>
            </div>
              <Editor
                theme={`${isDark ? "vs-dark" : "vs-light"}`}
                language="pyton"
                height="228px"
                value={CODE_SNIPPETS["python"]}
                onMount={(e) => handleEditorDidMount(e)}
                options={{
                  minimap: {
                    enabled: false,
                  },
                  tabSize: 1
                }}
              />
              <div className="mt-1 flex items-center justify-between">
                <div className="text-grey-4 text-sm font-medium">힌트가 도움이 되었나요?</div>
                <div className="flex gap-2 items-center">
                  <div className="flex items-center gap-2 rounded-[4px] bg-blue-3 text-white px-2 py-1 text-sm font-medium">
                    <IconThumbsUp/>
                    네
                  </div>
                  <div className="flex items-center gap-2 rounded-[4px] bg-grey-3 text-white px-2 py-1 text-sm font-medium">
                    <IconThumbsDown/>
                    아니요
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hint;
