import { ReactNode, type ComponentPropsWithoutRef } from "react"

export type ButtonProps = {
  children : ReactNode,
  variant : string,
  size? : string,
} & ComponentPropsWithoutRef<'button'>

export type ButtonVariants = {
  primary : string,
  outline_primary : string,
  red : string,
  grey : string
}

export type SizeVariants = {
  md : string,
  sm : string
}


export  const colorVariants : ButtonVariants = {
    primary: 'bg-primary  text-white disabled:bg-grey-1',
    red: 'bg-red-500 hover:bg-red-400 text-white',
    outline_primary : 'text-primary border bg-white border-primary disabled:border-grey-1 disabled:text-grey-3',
    grey : 'bg-grey-3 text-white '
  }

export const sizeVariants : SizeVariants = {
  md : 'h-14 rounded-xl w-full  text-base font-bold',
  sm : 'h-10 rounded-[10px] text-sm font-bold w-full '
}

