import React from "react";
import { useParams, Link } from "react-router-dom";
import Breadcrumb from "../../../components/materials/Breadcrump";

interface NoticeDetailProps {
  id: number;
  no: number;
  course: string;
  title: string;
  author: string;
  date: string;
  content: string;
}

const dummyData: NoticeDetailProps[] = [
  {
    id: 1,
    no: 1,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
    content: `안녕하세요 학습자여러분, 김강사 입니다.
날씨가 점점 추워지는데 옷 따뜻히 입으시길 바랍니다.

수업 관련 공지가 있어 안내 드립니다.

학습시간을 9시에서 11시로 변경하도록 하겠습니다.

감사합니다.`,
  },
  {
    id: 2,
    no: 2,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
    content: `안녕하세요 학습자여러분, 김강사 입니다.
날씨가 점점 추워지는데 옷 따뜻히 입으시길 바랍니다.

수업 관련 공지가 있어 안내 드립니다.

학습시간을 9시에서 11시로 변경하도록 하겠습니다.

감사합니다.`,
  },
];

const DetailNoticesProfessor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const notice = dummyData.find((item) => item.id === parseInt(id || "", 10));

  if (!notice) {
    return (
      <div className="bg-white p-6 rounded-lg text-center">
        Notice not found
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white p-6 rounded-lg shadow">
      <Breadcrumb
        items={[
          { title: "게시판", path: "/dashboard-professor/notices" },
          { title: notice.title, path: `/dashboard-professor/notices/${id}` },
        ]}
      />
      <div className="mb-4 space-y-4">
        <h2 className="text-2xl font-bold">{notice.title}</h2>
        <div className="flex justify-between items-center text-gray-600 text-sm mb-4">
          <div className="space-x-6 text-md">
            <span className="text-md">작성자: {notice.author}</span>
            <span className="text-md">작성일: {notice.date}</span>
            <span className="text-md">강의명: {notice.course}</span>
          </div>
        </div>
        <div className="border-y py-4">{notice.content}</div>
      </div>
      <div className="flex justify-end">
        <Link
          to="/dashboard/notices"
          className="text-blue-600 text-sm bg-primary p-2 text-white px-3 rounded-lg"
        >
          목록으로 이동
        </Link>
      </div>
    </div>
  );
};

export default DetailNoticesProfessor;
