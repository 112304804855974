import { ArrowDown2 } from "iconsax-react";
import { useState } from "react";
import Icon from "./icon";
const List = () => {
  const items = [0, 1, 2];
  const [value, setValue] = useState(null);

  const handleClick = function (e: any, idx: any) {
    if (idx === value) {
      setValue(null);
      e.target.checked = false;
    } else {
      setValue(idx);
    }
  };
  return (
    <div className="w-[55%]  m-auto">
      <ul>
        {items.map((item, idx) => {
          return (
            <li
              key={item}
              className="
                has-[:checked]:bg-blue-1
                has-[:checked]:rounded-xl 
                has-[:checked]:border 
                has-[:checked]:border-blue-4
                p-4 flex items-center
                flex-col relative
              "
            >
              <input
                id={`${item}`}
                type="radio"
                name="radio"
                onClick={(e) => handleClick(e, item)}
                className="peer absolute top-0 opacity-0"
              />
              <label htmlFor={`${item}`} className="h-full w-full">
                <div className="peer-checked:text-blue-3 text-grey-5  flex cursor-pointer justify-between items-center w-full">
                  <div className="flex gap-4 items-center">
                    <div className=" font-bold text-[28px]">Q</div>
                    <div className={
                      `px-3 py-2 rounded-[4px] 
                      ${value === item ? 'bg-primary text-white' : 'bg-grey-3'}
                      `
                    }>학습문의</div>
                    <div>채피 IDE가 무엇인가요?</div>
                  </div>
                </div>
              </label>
              <div
                className={`
                transition-transform duration-500
                peer-checked:rotate-180  rotate-0
                absolute top-0 bottom-0 right-3 flex items-center
                `}
              >
                <ArrowDown2 />
              </div>
              <div
                className="w-full overflow-hidden transition-all duration-500
                  max-h-0 mt-2 peer-checked:max-h-5 flex gap-4 items-start
                "
              >
                <Icon/>
                <div className="text-grey-6 font-medium text-base">
                  채피 IDE란 유연한 코딩 플랫폼으로 개발 환경을 구축한 채피만의
                  소프트웨어입니다.
                </div>
               
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default List;
