import React from "react";

export default function ProgressBarLearning() {
  const progress = 46; // Contoh nilai progress
  const total = 22;
  const completed = Math.round((progress / 100) * total);

  return (
    <div className="bg-white p-6 rounded-lg border border-gray-100 shadow-sm">
      <div className="font-bold text-lg mb-2">학습 진도율</div>
      <div className="p-6 bg-gray-50 rounded border border-gray-100">
        <div className="relative bg-gray-300 rounded-full h-3">
          <div
            className="bg-blue-500 h-3 rounded-full relative"
            style={{ width: `${progress}%` }}
          >
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
              <div className="relative">
                <div className="bg-black text-white text-xs rounded-lg px-2 py-1 absolute opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  벌써 {progress}% 달성했어요!
                </div>
                <div className="bg-black h-3 w-3 absolute transform rotate-45 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-gray-600 text-xs mt-2">
          <span>전체 학습 진행률</span>
          <span>
            {completed}/{total}
          </span>
        </div>
      </div>
    </div>
  );
}
