import { EditorContextProvider  } from "../../context/editorContext";
import DiffEditorPage from "./diffEditorPage";
const DiffEditor = () => {
  return ( 
    <EditorContextProvider>
      <DiffEditorPage/>
    </EditorContextProvider>
    
   );
}
 
export default DiffEditor;