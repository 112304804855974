import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useCourseStore } from "../../../store/course";
import Card from "../../../components/containers/card";
import MainStats from "../../Dashboard/components/MainStats";
import IconAssignment from "../../../assets/IconAssignment.png";
import IconDate from "../../../assets/IconDate.png";
import IconTest from "../../../assets/IconTest.png";
import CourseCard from "../components/courseCardProfessor";
import SelectDropdown from "../../../components/materials/SelectDropdown";

const mainStat = [
  {
    label: "출결",
    value: "23",
    valueMax: "56회",
    icon: <img src={IconDate} alt="출결" width="55px" height="55px" />,
  },
  {
    label: "과제",
    value: "23",
    valueMax: "56회",
    icon: <img src={IconAssignment} alt="과제" width="55px" height="55px" />,
  },
  {
    label: "시험",
    value: "23",
    valueMax: "56회",
    icon: <img src={IconTest} alt="시험" width="55px" height="55px" />,
  },
];

function GradesProfessor() {
  const [selectedQuarter, setSelectedQuarter] = useState("전체");
  const [selectedSeries, setSelectedSeries] = useState("전체");
  const [selectedGraph, setSelectedGraph] = useState("line");
  const { courseData } = useCourseStore();

  const initialHeatmapData = {
    series: [
      {
        name: "출결",
        data: [10, 50, 30, 90],
      },
      {
        name: "과제",
        data: [15, 40, 40, 85],
      },
      {
        name: "시험",
        data: [10, 70, 50, 95],
      },
    ],
    categories: [
      "2019년 1분기",
      "2019년 2분기",
      "2019년 3분기",
      "2019년 4분기",
    ],
  };

  const [heatmapData, setHeatmapData] = useState(initialHeatmapData);

  const heatmapChartOptions = {
    options: {
      ...heatmapData,
      chart: {
        type: selectedGraph === "line" ? ("line" as const) : ("bar" as const),
        height: 350,
        toolbar: {
          offsetX: 0,
          offsetY: -4,
          tools: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        curve: "straight" as const,
      },
      xaxis: {
        categories: heatmapData.categories,
      },
      colors: ["#2861EF", "#9758FF", "#FF537C"],
      markers: {
        size: [4, 4, 4],
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        x: {
          format: "yyyy년 M분기",
        },
      },
    },
  };

  const updateHeatmapData = (
    quarter: string,
    seriesFilter: string,
    graph?: string
  ) => {
    setSelectedQuarter(quarter);
    setSelectedSeries(seriesFilter);
    let filteredCategories = initialHeatmapData.categories;
    let filteredSeries = initialHeatmapData.series;

    if (quarter !== "전체") {
      const quarters = ["1분기", "2분기", "3분기", "4분기"];
      const quarterIndex = quarters.indexOf(quarter.split(" ")[1]);
      filteredCategories = initialHeatmapData.categories.slice(
        0,
        quarterIndex + 1
      );

      filteredSeries = filteredSeries.map((series) => ({
        ...series,
        data: series.data.slice(0, quarterIndex + 1),
      }));
    }

    if (seriesFilter !== "전체") {
      filteredSeries = filteredSeries.filter(
        (series) => series.name === seriesFilter
      );
    }

    if (graph === "bar") {
      filteredSeries = filteredSeries.map((series) => ({
        ...series,
        type: "bar",
      }));
    }

    if (graph === "line") {
      filteredSeries = filteredSeries.map((series) => ({
        ...series,
        type: "line",
      }));
    }

    setHeatmapData({
      series: filteredSeries,
      categories: filteredCategories,
    });
  };

  const handleQuarterChange = (value: string) => {
    setSelectedQuarter(value);
    updateHeatmapData(value, selectedSeries);
  };

  const handleSeriesChange = (value: string) => {
    setSelectedSeries(value);
    updateHeatmapData(selectedQuarter, value);
  };

  const handleGraphChange = (value: string) => {
    setSelectedGraph(value);
    updateHeatmapData(selectedQuarter, selectedSeries, value);
  };

  const getCourseDataFilter = courseData.filter(
    (course) => course.category === "completion"
  );

  return (
    <div className="flex flex-col bg-white min-h-screen p-6 rounded-lg justify-between shadow">
      <div>
        <h2 className="text-xl font-bold mb-4 border-b pb-2">성적확인 </h2>
        <div className="flex justify-end space-x-4">
          <SelectDropdown
            options={[
              { label: "모든 분기", value: "전체" },
              { label: "2019년 1분기", value: "2019년 1분기" },
              { label: "2019년 2분기", value: "2019년 2분기" },
              { label: "2019년 3분기", value: "2019년 3분기" },
              { label: "2019년 4분기", value: "2019년 4분기" },
            ]}
            value={selectedQuarter}
            onChange={handleQuarterChange}
          />
          <SelectDropdown
            options={[
              { label: "모든 과목", value: "전체" },
              { label: "출결", value: "출결" },
              { label: "과제", value: "과제" },
              { label: "시험", value: "시험" },
            ]}
            value={selectedSeries}
            onChange={handleSeriesChange}
          />
          <SelectDropdown
            options={[
              { label: "선 그래프", value: "line" },
              { label: "막대 그래프", value: "bar" },
            ]}
            value={selectedGraph}
            onChange={handleGraphChange}
          />
        </div>
        <div className="flex-1 grid grid-cols-3 gap-4 bg-gray-50 border border-gray-100 p-4 rounded-lg h-32 my-6">
          {mainStat.map((stat, index) => (
            <MainStats
              key={index}
              label={stat.label}
              value={stat.value}
              valueMax={stat.valueMax}
              icon={stat.icon}
            />
          ))}
        </div>
        <Card>
          <div className="pt-4 px-2">
            <ReactApexChart
              options={heatmapChartOptions.options}
              series={heatmapData.series}
              type="line"
              height={350}
            />
          </div>
        </Card>
        <h3 className="text-lg font-bold mb-4 border-b pb-2 mt-6">
          강의별 성적
        </h3>
        <div className="relative">
          <div className="grid grid-cols gap-4">
            <div className="flex overflow-x-auto gap-4 pb-4">
              <div className="flex  gap-6">
                {getCourseDataFilter.map((course, index) => (
                  <div key={index} className="flex-shrink-0">
                    <CourseCard {...course} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GradesProfessor;
