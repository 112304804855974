const IconChappieGrey = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="117"
      height="26"
      viewBox="0 0 117 26"
      fill="none"
    >
      <path
        d="M16.1255 5.56369C16.6698 5.03765 17.3132 4.60767 18.0543 4.27833C18.5712 4.05571 19.1186 3.91239 19.6995 3.84835C20.1096 3.80261 20.4115 3.44277 20.4115 3.03109V0.843072C20.4115 0.371925 20.0121 -0.0107861 19.544 0.0303821C18.9585 0.0822235 18.3821 0.184382 17.8134 0.341431C17.0098 0.564044 16.2292 0.894914 15.4698 1.33252C14.7288 1.77927 14.0594 2.29616 13.4617 2.88623C12.8549 3.50223 12.3456 4.16702 11.9355 4.88213C11.507 5.56979 11.1716 6.35503 10.9307 7.23939C10.6989 8.04293 10.583 8.91356 10.583 9.85128C10.583 10.789 10.6989 11.6459 10.9307 12.4495C11.1624 13.3064 11.4979 14.087 11.9355 14.793C12.3548 15.5157 12.864 16.1851 13.4617 16.8011C14.0777 17.3988 14.7471 17.908 15.4698 18.3274C16.1666 18.7558 16.9473 19.0958 17.8134 19.3459C18.3821 19.5014 18.9585 19.6051 19.544 19.6569C20.0136 19.6981 20.4115 19.3154 20.4115 18.8442V16.6654C20.4115 16.2491 20.1081 15.8847 19.6949 15.839C19.1124 15.7734 18.5651 15.6255 18.0558 15.3968C17.3239 15.0842 16.6805 14.6512 16.127 14.0977C15.5918 13.5625 15.1633 12.9236 14.8416 12.1826C14.5199 11.4599 14.3598 10.6823 14.3598 9.85281C14.3598 9.02334 14.5199 8.22285 14.8416 7.48182C15.1633 6.7408 15.5918 6.10193 16.127 5.56674L16.1255 5.56369Z"
        fill="#6E6E6E"
      />
      <path
        d="M24.9046 8.08314C24.7217 8.27069 24.5051 8.41859 24.2551 8.52532C24.005 8.63205 23.7412 8.68542 23.4653 8.68542C23.171 8.68542 22.9011 8.63205 22.6556 8.52532C22.4101 8.41859 22.1921 8.27069 22 8.08314C21.8079 7.8956 21.6584 7.67756 21.5517 7.4275C21.445 7.17744 21.3916 6.90451 21.3916 6.61024C21.3916 6.34188 21.445 6.0842 21.5517 5.83414C21.6584 5.58408 21.8079 5.36604 22 5.1785C22.1921 4.99096 22.4101 4.84153 22.6556 4.73022C22.9011 4.61892 23.171 4.5625 23.4653 4.5625C23.7428 4.5625 24.005 4.61892 24.2551 4.73022C24.5051 4.84153 24.7217 4.99096 24.9046 5.1785C25.0876 5.36604 25.2355 5.58408 25.3468 5.83414C25.4581 6.0842 25.5145 6.34341 25.5145 6.61024C25.5145 6.90451 25.4581 7.17744 25.3468 7.4275C25.2355 7.67756 25.0876 7.8956 24.9046 8.08314ZM24.9046 14.5176C24.7217 14.7005 24.5051 14.8484 24.2551 14.9598C24.005 15.0711 23.7412 15.1275 23.4653 15.1275C23.171 15.1275 22.9011 15.0711 22.6556 14.9598C22.4101 14.8484 22.1921 14.7005 22 14.5176C21.8079 14.3346 21.6584 14.1166 21.5517 13.8619C21.445 13.6073 21.3916 13.3374 21.3916 13.0523C21.3916 12.7672 21.445 12.5003 21.5517 12.2549C21.6584 12.0094 21.8079 11.7929 22 11.6053C22.1921 11.4178 22.4101 11.2699 22.6556 11.1631C22.9011 11.0564 23.171 11.003 23.4653 11.003C23.7428 11.003 24.005 11.0564 24.2551 11.1631C24.5051 11.2699 24.7217 11.4178 24.9046 11.6053C25.0876 11.7929 25.2355 12.0094 25.3468 12.2549C25.4581 12.5003 25.5145 12.7656 25.5145 13.0523C25.5145 13.3389 25.4581 13.6073 25.3468 13.8619C25.2355 14.1166 25.0876 14.3346 24.9046 14.5176Z"
        fill="#C3C3C3"
      />
      <path
        d="M27.5898 18.4602V1.03694C27.5898 0.805179 27.6676 0.613061 27.8247 0.460587C27.9802 0.308112 28.1753 0.233398 28.4071 0.233398H30.4152C30.647 0.233398 30.8391 0.309637 30.9915 0.460587C31.144 0.611537 31.2187 0.803655 31.2187 1.03694V5.68437C31.5222 5.40687 31.9064 5.18883 32.3699 5.02873C32.8334 4.86863 33.361 4.78782 33.9496 4.78782C34.8431 4.78782 35.668 4.95249 36.4273 5.28336C37.1683 5.59594 37.8377 6.05184 38.4354 6.64954C39.0072 7.23809 39.457 7.91355 39.7878 8.67135C40.1187 9.43983 40.2834 10.2693 40.2834 11.1628V18.4618C40.2834 18.6935 40.2071 18.8856 40.0562 19.0381C39.9052 19.1906 39.7085 19.2653 39.4676 19.2653H37.4458C37.2141 19.2653 37.0219 19.1891 36.8695 19.0381C36.717 18.8872 36.6423 18.695 36.6423 18.4618V11.9663L36.656 11.1628H36.6697C36.6697 10.7877 36.5981 10.4309 36.4547 10.0909C36.3023 9.76917 36.1071 9.48862 35.8662 9.24771C35.6161 8.9885 35.331 8.78723 35.0093 8.64543C34.6967 8.51125 34.3445 8.44417 33.9511 8.44417C33.5577 8.44417 33.2009 8.51125 32.8792 8.64543C32.5483 8.79791 32.2586 8.99765 32.0086 9.24771C31.7585 9.48862 31.5618 9.7707 31.42 10.0909C31.2858 10.4126 31.2187 10.7694 31.2187 11.1628V18.4618C31.2187 18.6935 31.1425 18.8856 30.9915 19.0381C30.8406 19.1906 30.6485 19.2653 30.4152 19.2653H28.4071C28.1753 19.2653 27.9802 19.1891 27.8247 19.0381C27.6691 18.8856 27.5898 18.695 27.5898 18.4618V18.4602Z"
        fill="#6E6E6E"
      />
      <path
        d="M44.4771 6.58237C45.1556 5.90386 45.9241 5.38544 46.781 5.02865C47.6913 4.65356 48.6382 4.47517 49.6201 4.49346C50.6112 4.51176 51.5489 4.69931 52.4317 5.0561C53.2978 5.41289 54.0663 5.92673 54.7356 6.59609C55.3791 7.23953 55.896 8.03393 56.2893 8.97927C56.6827 9.88955 56.8779 10.8944 56.8779 11.9922V18.7011C56.8779 18.9328 56.8017 19.1249 56.6507 19.2774C56.4998 19.4299 56.3076 19.5046 56.0743 19.5046H54.0251C53.8025 19.5046 53.6149 19.4284 53.4625 19.2774C53.31 19.1265 53.2261 18.791 53.2078 18.2726V11.9922C53.2078 11.4387 53.1148 10.9248 52.9273 10.4522C52.7397 9.98866 52.4851 9.58155 52.1634 9.2339C51.8417 8.89389 51.4544 8.62706 50.9985 8.43036C50.5517 8.24282 50.0928 8.14523 49.6186 8.13609C49.1368 8.12694 48.6671 8.22605 48.2128 8.43036C47.7752 8.6362 47.3955 8.90303 47.0738 9.2339C46.7338 9.59069 46.4715 9.9978 46.284 10.4522C46.0873 10.9081 45.9897 11.4204 45.9897 11.9922C45.9897 12.2865 46.0095 12.6859 46.0507 13.1906C46.0903 13.6953 46.1604 14.0658 46.258 14.3022C46.3556 14.5385 46.531 14.7825 46.781 15.0325C46.996 15.2551 47.2857 15.4472 47.6516 15.6089C48.0084 15.7613 48.3652 15.8361 48.7235 15.8361H50.6661C50.8978 15.8361 51.09 15.9123 51.2424 16.0632C51.3949 16.2142 51.4696 16.4063 51.4696 16.6396V18.6355C51.4696 18.8673 51.3934 19.0594 51.2424 19.2119C51.0915 19.3643 50.8994 19.439 50.6661 19.439H48.7235C47.8483 19.439 47.0311 19.2698 46.2733 18.9298C45.5597 18.6081 44.8812 18.1537 44.2377 17.5636C43.6568 17.0284 43.207 16.359 42.8853 15.5555C42.716 15.1362 42.5864 14.6742 42.4965 14.1695C42.4065 13.6648 42.3623 12.939 42.3623 11.9937C42.3623 10.8776 42.5544 9.86058 42.9387 8.93963C43.3229 8.01106 43.8352 7.22581 44.4787 6.58237H44.4771Z"
        fill="#6E6E6E"
      />
      <path
        d="M71.3 17.4154C70.6931 18.0588 69.9292 18.5849 69.0098 18.995C68.1529 19.3701 67.206 19.5576 66.1707 19.5576C65.8582 19.5576 65.5227 19.5271 65.1659 19.4646C64.8351 19.393 64.5011 19.2954 64.1611 19.1704C63.8485 19.0636 63.5497 18.902 63.2646 18.6885C63.2204 18.6611 63.1167 18.5986 62.9566 18.501L62.5678 18.2326L62.5403 18.2052V25.1962C62.5403 25.4279 62.4641 25.62 62.3131 25.7725C62.1622 25.925 61.9655 25.9997 61.7246 25.9997H59.7028C59.471 25.9997 59.2758 25.9235 59.1203 25.7725C58.9648 25.6216 58.8855 25.4294 58.8855 25.1962L58.9251 12.0315C58.9251 10.9245 59.1127 9.91971 59.4878 9.01858C59.8812 8.09001 60.4026 7.30019 61.0552 6.6476C61.7337 5.96909 62.5022 5.45067 63.3591 5.09388C64.2602 4.71879 65.198 4.53125 66.1707 4.53125C67.1435 4.53125 68.0904 4.71422 69.0098 5.08016C69.8576 5.43695 70.6215 5.95079 71.3 6.62015C71.9434 7.2636 72.4603 8.05342 72.8537 8.99114C73.2288 9.88464 73.4163 10.8971 73.4163 12.0315C73.4163 13.1659 73.2288 14.1524 72.8537 15.0444C72.4695 15.9455 71.9526 16.7369 71.3 17.4154ZM68.7155 9.28694C68.3847 8.9393 68.005 8.67094 67.5766 8.4834C67.1481 8.27755 66.68 8.1754 66.1707 8.1754C65.6615 8.1754 65.2071 8.27755 64.7787 8.4834C64.341 8.67094 63.9568 8.9393 63.6275 9.28694C63.3149 9.62696 63.0557 10.0325 62.8514 10.5052C62.6638 10.987 62.5708 11.4963 62.5708 12.0315C62.5708 12.5133 62.6638 12.9951 62.8514 13.4785C63.0481 13.9511 63.3073 14.3628 63.6275 14.7105C63.94 15.0505 64.3243 15.3402 64.7787 15.5811C65.198 15.7869 65.663 15.8891 66.1707 15.8891C66.6785 15.8891 67.139 15.7961 67.5766 15.6085C68.005 15.4027 68.3847 15.1313 68.7155 14.7913C69.0373 14.4528 69.2965 14.0457 69.4916 13.573C69.6883 13.1263 69.7859 12.6139 69.7859 12.033C69.7859 11.4521 69.6883 10.9703 69.4916 10.5067C69.3132 10.06 69.054 9.6544 68.7155 9.28846V9.28694Z"
        fill="#6E6E6E"
      />
      <path
        d="M87.8393 17.4154C87.2325 18.0588 86.4686 18.5849 85.5491 18.995C84.6922 19.3701 83.7454 19.5576 82.7101 19.5576C82.3975 19.5576 82.062 19.5271 81.7053 19.4646C81.3744 19.393 81.0404 19.2954 80.7004 19.1704C80.3879 19.0636 80.089 18.902 79.8039 18.6885C79.7597 18.6611 79.656 18.5986 79.4959 18.501L79.1071 18.2326L79.0796 18.2052V25.1962C79.0796 25.4279 79.0034 25.62 78.8524 25.7725C78.7015 25.925 78.5048 25.9997 78.2639 25.9997H76.2421C76.0103 25.9997 75.8151 25.9235 75.6596 25.7725C75.5041 25.62 75.4248 25.4294 75.4248 25.1962L75.4644 12.0315C75.4644 10.9245 75.652 9.91971 76.0271 9.01858C76.4205 8.09001 76.9419 7.30019 77.5945 6.6476C78.2471 5.99501 79.0415 5.45067 79.8984 5.09388C80.7995 4.71879 81.7373 4.53125 82.7101 4.53125C83.6828 4.53125 84.6297 4.71422 85.5491 5.08016C86.3969 5.43695 87.1608 5.95079 87.8393 6.62015C88.4828 7.2636 88.9996 8.05342 89.393 8.99114C89.7681 9.88464 89.9557 10.8971 89.9557 12.0315C89.9557 13.1659 89.7681 14.1524 89.393 15.0444C89.0088 15.9455 88.4919 16.7369 87.8393 17.4154ZM85.2548 9.28694C84.924 8.9393 84.5458 8.67094 84.1159 8.4834C83.6874 8.27755 83.2193 8.1754 82.7101 8.1754C82.2008 8.1754 81.7464 8.27755 81.318 8.4834C80.8804 8.67094 80.4961 8.9393 80.1668 9.28694C79.8542 9.62696 79.595 10.0325 79.3907 10.5052C79.2031 10.987 79.1101 11.4963 79.1101 12.0315C79.1101 12.5133 79.2031 12.9951 79.3907 13.4785C79.5874 13.9511 79.8466 14.3628 80.1668 14.7105C80.4793 15.0505 80.8636 15.3402 81.318 15.5811C81.7373 15.7869 82.2023 15.8891 82.7101 15.8891C83.2178 15.8891 83.6783 15.7961 84.1159 15.6085C84.5443 15.4027 84.924 15.1313 85.2548 14.7913C85.5766 14.4528 85.8358 14.0457 86.031 13.573C86.2277 13.1263 86.3252 12.6139 86.3252 12.033C86.3252 11.4521 86.2277 10.9703 86.031 10.5067C85.8526 10.06 85.5933 9.6544 85.2548 9.28846V9.28694Z"
        fill="#6E6E6E"
      />
      <path
        d="M92.7685 5.05566H94.7903C95.0221 5.05566 95.2142 5.1319 95.3667 5.28285C95.5192 5.4338 95.5939 5.62592 95.5939 5.85921V13.4387C95.5939 13.8397 95.661 14.1935 95.7951 14.4969C95.9385 14.8186 96.1351 15.1083 96.3837 15.3675C96.6246 15.6084 96.9097 15.8006 97.2406 15.9439C97.5715 16.0872 97.9145 16.1589 98.2713 16.1589H98.5397C98.8965 16.1589 99.2456 16.0872 99.5841 15.9439C99.915 15.8006 100.2 15.6084 100.441 15.3675C100.7 15.1083 100.897 14.8186 101.03 14.4969C101.136 14.2285 101.199 13.9709 101.217 13.7208V6.86401C101.217 6.79235 101.222 6.74356 101.231 6.71611V5.85921C101.231 5.62745 101.309 5.43533 101.466 5.28285C101.623 5.13038 101.812 5.05566 102.034 5.05566H104.043C104.274 5.05566 104.469 5.1319 104.625 5.28285C104.78 5.4338 104.86 5.62592 104.86 5.85921L104.832 18.4338C104.814 19.1474 104.726 19.7908 104.564 20.3626C104.404 20.9512 104.163 21.5321 103.841 22.1039C103.51 22.6391 103.128 23.1254 102.69 23.563C102.227 24.0266 101.739 24.3971 101.231 24.6746C100.696 24.9963 100.119 25.2418 99.5033 25.411C98.8965 25.5803 98.2576 25.6657 97.5882 25.6657H96.6109C96.3791 25.6657 96.1839 25.5894 96.0284 25.4385C95.8729 25.286 95.7936 25.0954 95.7936 24.8621V22.8403C95.7936 22.6086 95.8714 22.4134 96.0284 22.2579C96.1839 22.1023 96.3791 22.0231 96.6109 22.0231L97.8032 22.0368C98.3125 22.0368 98.7623 21.9438 99.1557 21.7562C99.5658 21.5595 99.9272 21.2927 100.24 20.9527C100.552 20.6218 100.789 20.2391 100.949 19.8015C101.074 19.4981 101.155 19.216 101.19 18.9583L100.975 19.0513C100.234 19.373 99.421 19.5331 98.5366 19.5331H98.2957C97.4114 19.5331 96.5956 19.373 95.8455 19.0513C95.0694 18.7113 94.3954 18.2569 93.8236 17.6851C93.261 17.1225 92.8051 16.444 92.4575 15.6496C92.1266 14.8552 91.9619 14.0288 91.9619 13.1719V5.85921C91.9619 5.62745 92.0397 5.43533 92.1967 5.28285C92.3538 5.13038 92.5428 5.05566 92.7655 5.05566H92.7685Z"
        fill="#6E6E6E"
      />
      <path
        d="M8.82315 0H6.46131C6.03743 0 5.64405 0.219564 5.42143 0.58093L0.241863 8.98991C-0.0798583 9.5129 -0.0813831 10.1731 0.241863 10.6961L5.41381 19.1051C5.63643 19.4665 6.02981 19.686 6.45369 19.686H8.814C9.32326 19.686 9.63584 19.128 9.36901 18.6934L4.19249 10.2692C4.03239 10.0069 4.03239 9.67757 4.19249 9.41684L9.37816 0.992611C9.64499 0.558058 9.33241 0 8.82315 0Z"
        fill="#C3C3C3"
      />
      <path
        d="M108.166 0H110.528C110.952 0 111.345 0.219564 111.568 0.58093L116.747 8.98991C117.069 9.5129 117.071 10.1731 116.747 10.6961L111.575 19.1051C111.353 19.4665 110.959 19.686 110.536 19.686H108.175C107.666 19.686 107.353 19.128 107.62 18.6934L112.797 10.2692C112.957 10.0069 112.957 9.67757 112.797 9.41684L107.611 0.992611C107.344 0.558058 107.657 0 108.166 0Z"
        fill="#C3C3C3"
      />
    </svg>
  );
};

export default IconChappieGrey
