import React from "react";
import EmptyState from "../../../components/emptyState";
import EmptyGrades from "../../../assets/empty-grades.png";
import { ArrowRight2 } from "iconsax-react";

interface PerformanceItem {
  status: string;
  course: string;
  weeks: number;
  type: string;
  icon?: React.ReactNode;
  date: string;
}

const performanceData: PerformanceItem[] = [
  {
    status: "답변 완료",
    course: "JavaScript 기초",
    weeks: 2,
    type: "과제",
    date: "23.02.04 23:59",
    icon: <ArrowRight2 />,
  },
  {
    status: "미확인",
    course: "JavaScript 기초",
    weeks: 2,
    type: "시험",
    date: "23.02.04 23:59",
    icon: <ArrowRight2 />,
  },
];

function PerformanceCheckProfessor() {
  return (
    <div className="bg-white rounded-lg flex flex-col p-2 ">
      <h3 className="text-lg font-bold mb-2 border-b pb-2">교강사 공지</h3>
      <ul>
        {performanceData.length === 0 ? (
          <EmptyState
            imgSrc={EmptyGrades}
            title="제출 기록이 없어요"
            description="강의를 복습하며 과제를 제출해 보아요"
            py="py-10"
          />
        ) : (
          <ul>
            {performanceData.map((question, index) => (
              <li
                key={index}
                className="flex items-center justify-between py-2"
              >
                <div className="flex items-center gap-4">
                  <div>
                    <p className="text-gray-900">{question.course}</p>
                    <p className="text-gray-500 text-xs">{question.date}</p>
                  </div>
                </div>
                <div className="text-blue-500 text-2xl">{question.icon}</div>
              </li>
            ))}
          </ul>
        )}
      </ul>
    </div>
  );
}

export default PerformanceCheckProfessor;
