import EditorPage from "./editorPage";
import { EditorContextProvider  } from "../../context/editorContext";
const EditorComponent = () => {

  return (
    <EditorContextProvider>
      <EditorPage/>
    </EditorContextProvider>
  );
};

export default EditorComponent;
