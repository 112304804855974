import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import ProfileLogo from "../../assets/profile-img.png";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { useCourseStore } from "../../store/course";
import {
  Book1,
  CodeCircle,
  Diagram,
  DirectboxNotif,
  Home as HomeIcon,
  MessageQuestion,
  Messages1,
  Wallet,
} from "iconsax-react";

function DashboardLayout() {
  const location = useLocation();
  const [openSubItems, setOpenSubItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [sidebarItems, setSidebarItems] = useState<any[]>([]);

  const isActive = (path: string): string => {
    return location.pathname === path
      ? "border border-primary text-primary rounded-lg"
      : "text-gray-500";
  };

  const getIconColor = (path: string) => {
    return location.pathname.startsWith(path) ? "blue" : "gray";
  };

  const handleToggleSubItems = (path: string) => {
    setOpenSubItems((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  const getSidebarItems = () => {
    const { courseData } = useCourseStore.getState();

    const subItems = courseData
      .filter((course) => course.category === "in-progress")
      .map((course) => ({
        path: `/learning/${course.id}`,
        label: course.title,
      }));

    return [
      {
        label: "내 학습",
        items: [
          {
            path: "/dashboard",
            icon: <HomeIcon size="18" />,
            label: "홈",
          },
          {
            path: "/dashboard/course",
            icon: <Book1 size="18" />,
            label: "수강 과목",
            subItems: subItems,
          },
          {
            path: "/dashboard/grades",
            icon: <Diagram size="18" />,
            label: "성적확인",
          },
          {
            path: "/dashboard/notices",
            icon: <DirectboxNotif size="18" />,
            label: "학습공지",
          },
          {
            path: "/dashboard/bulletin",
            icon: <Messages1 size="18" />,
            label: "게시판",
          },
          {
            path: "/dashboard/qna",
            icon: <MessageQuestion size="18" />,
            label: "나의 Q&A",
          },
          {
            path: "/dashboard/coding",
            icon: <CodeCircle size="18" />,
            label: "코딩 현황",
          },
        ],
      },
      {
        label: "결제",
        items: [
          {
            path: "/dashboard/check-history",
            icon: <Wallet size="18" />,
            label: "내역확인",
          },
        ],
      },
    ];
  };

  useEffect(() => {
    const items = getSidebarItems();
    setSidebarItems(items);
  }, []);

  return (
    <div className="relative px-16 py-8 flex min-h-screen bg-body">
      <div className="absolute top-0 left-0 w-full h-64 bg-decoration"></div>

      {/* Sidebar */}
      <aside className="relative w-64 bg-white p-6 shadow rounded-lg">
        <div className="text-center mb-8">
          <img
            src={ProfileLogo}
            alt="Profile"
            className="w-24 h-24 rounded-full mx-auto"
          />
          <h1 className="mt-4 font-bold text-xl">김채피</h1>
          <p className="text-grey-5">chappy@naasf.com</p>
        </div>
        <nav>
          {sidebarItems.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <div className="text-lg font-bold mb-4">{section.label}</div>
              <ul className="space-y-2">
                {section.items.map((item: any, itemIndex: number) => (
                  <li key={itemIndex}>
                    <Link
                      to={item.path}
                      className={`flex items-center py-2 px-4 mb-4 hover:border-primary hover:text-primary rounded ${
                        item.subItems ? "justify-between" : "gap-2"
                      } ${isActive(item.path)}`}
                      onClick={() => {
                        if (item.subItems) {
                          handleToggleSubItems(item.path);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        } else {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setOpenSubItems({});
                        }
                      }}
                    >
                      <span className="flex gap-2 items-center">
                        {React.cloneElement(item.icon, {
                          className: `transition-colors ${getIconColor(
                            item.path
                          )}`,
                        })}
                        {item.label}
                      </span>
                      {item.subItems && item.subItems.length > 0 && (
                        <>
                          {openSubItems[item.path] ? (
                            <ArrowUp2
                              size="18"
                              className="group-hover:text-primary"
                            />
                          ) : (
                            <ArrowDown2
                              size="18"
                              className="group-hover:text-primary"
                            />
                          )}
                        </>
                      )}
                    </Link>
                    {item.subItems && (
                      <ul
                        className={`pl-4 space-y-2 ${
                          openSubItems[item.path] ? "block" : "hidden"
                        }`}
                      >
                        {item.subItems.map(
                          (subItem: any, subItemIndex: number) => (
                            <li key={subItemIndex}>
                              <Link
                                to={subItem.path}
                                className={`block py-2 px-4 hover:border-blue-500 hover:text-blue-500 rounded ${isActive(
                                  subItem.path
                                )}`}
                                onClick={() =>
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  })
                                }
                              >
                                {subItem.label}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </nav>
      </aside>

      {/* Main content area */}
      <div className="relative flex-1 pl-6">
        <div className="relative bg-transparent">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
