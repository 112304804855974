import Navigation from "../../components/containers/navigation";
import { Outlet } from "react-router-dom";

function MainLayout() {
  return (
    <>

      <Navigation></Navigation>
      <div className=' h-full mt-16 '>
        <Outlet></Outlet> 
      </div>
    </>
  );
}

export default MainLayout;
