import Split from "react-split";
import "./content.css";
import LeftSide from "./leftSide";
import Editor from "./editor";
import RightSide from "./Right";
const Content = () => {
  return (
    <div className="h-[calc(100vh-88px)] ">
      <Split
        className="flex flex-row"
        gutterSize={14}
        sizes={[25, 50, 25]}
        style={{
          height: "100%",
        }}
      >
        <LeftSide />
        <Editor />
        {/* <div>asd</div> */}
        <RightSide />
      </Split>
    </div>
  );
};

export default Content;
