import React from "react";
import MdiEdit from "../../assets/mdi_edit.png";

type Headers = {
  accessorKey: string;
  header: string;
  width: string;
};

type DataTable = {
  id: number;
  member: string;
  courseName: string;
  RepLecture: string;
  studentID: number;
  studentName: string;
  CoursePeriode: string;
  CourseStatus: string;
  complStatus: string;
  cancelOfClass: string;
};
type ManagemenetStudentsTableProps = {
  columns: Headers[];
  rows: DataTable[];
  onChange?: (value: string) => void;
  checkStudentCard?: () => void;
};

const ManagemenetStudentsTable: React.FC<ManagemenetStudentsTableProps> = ({
  columns,
  rows,
  onChange,
  checkStudentCard,
}) => {
  return (
    <div className="flex overflow-hidden overflow-x-scroll rounded-2xl border border-[#E0E0E0]">
      <table className="w-full table-fixed ">
        <thead>
          <tr className="h-[48px] bg-[#E0E0E0] border-b border-[#E0E0E0]">
            {columns?.map((col: any, ky1: number) => (
              <th key={ky1} className={`w-[100%] text-center`}>
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((cols, ky2) => (
            <tr
              key={ky2}
              className={`${
                ky2 + 1 === rows.length ? "" : "border-b"
              }  h-[48px]`}
            >
              <td className="text-sm font-medium text-center break-words">
                {cols.member}
              </td>
              <td className="text-sm font-medium text-center break-words text-primary">
                {cols.courseName}
              </td>
              <td className="text-sm font-medium text-center break-words">
                {cols.RepLecture}
              </td>
              <td className="text-sm font-medium text-center break-words ">
                {cols.studentID}
              </td>
              <td className="text-sm font-medium text-center break-words text-primary">
                <button
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    checkStudentCard && checkStudentCard();
                  }}
                >
                  {cols.studentName}
                </button>
              </td>

              <td className="text-sm font-medium text-center break-words">
                {cols.CoursePeriode}
              </td>
              <td className="text-sm font-medium text-center break-words">
                {cols.CourseStatus}
              </td>
              <td className="text-sm font-medium text-center break-words">
                {cols.complStatus}
              </td>
              <td className="text-sm font-medium text-center break-words">
                {cols.cancelOfClass !== "" && (
                  <span className=" bg-red-3 px-6 text-[10px] font-bold text-white py-[2px] rounded-full">
                    {cols.cancelOfClass}
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManagemenetStudentsTable;
