import React, { useState } from "react";
import {
  ArrowDown2,
  ArrowUp2,
  ArrowLeft2,
  ArrowRight2,
  Calendar1,
  TickCircle,
  PlayCircle,
  DocumentDownload,
  PenAdd,
} from "iconsax-react";
import { Outlet } from "react-router-dom";
import Modal from "../../../components/modal";
import ReactQuill from "react-quill";

function LectureLayout() {
  const [openSubItems, setOpenSubItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [activeTab, setActiveTab] = useState("강의");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openNotes, setOpenNotes] = useState<{ [key: string]: boolean }>({});
  const [content, setContent] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const handleToggleSubItems = (section: string) => {
    setOpenSubItems((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleToggleNotes = (note: string) => {
    setOpenNotes((prev) => ({
      ...prev,
      [note]: !prev[note],
    }));
  };

  return (
    <div className="relative min-h-screen flex bg-body min-h-screen">
      {/* Main content area */}
      <div
        className={`flex-1 ${isSidebarOpen ? "mr-96" : "mr-0"} flex flex-col`}
      >
        <div className="flex-grow">
          <Outlet />
        </div>
      </div>

      {/* Sidebar */}
      <aside
        className={`fixed right-0 top-0 h-full bg-white shadow-lg transition-all duration-300 ${
          isSidebarOpen ? "w-96" : "w-0 overflow-hidden"
        }`}
      >
        <div className="relative h-full flex flex-col justify-between">
          {isSidebarOpen && (
            <div className="p-4">
              {/* Tabs with margin */}
              <div className="border-b mb-4 mt-16">
                <div className="flex">
                  {["강의", "강의노트", "Q&A"].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`flex-1 py-2 text-center ${
                        activeTab === tab
                          ? "font-bold border-b-2 border-primary"
                          : "text-gray-500"
                      }`}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>

              {/* Dropdown Content */}
              {activeTab === "강의" && (
                <div>
                  <div className="mb-2">
                    <button
                      onClick={() => handleToggleSubItems("CHAPTER 1")}
                      className="w-full flex justify-between items-center py-2 px-4 border-b border-gray-100 hover:bg-gray-100 rounded"
                    >
                      <span>CHAPTER 1 (1/6)</span>
                      {openSubItems["CHAPTER 1"] ? (
                        <ArrowUp2 size="18" className="text-gray-600" />
                      ) : (
                        <ArrowDown2 size="18" className="text-gray-600" />
                      )}
                    </button>
                    {openSubItems["CHAPTER 1"] && (
                      <div className="mt-2">
                        <div className="flex items-center justify-center gap-1 py-4 bg-decoration rounded mb-2">
                          <div className="flex items-center space-x-4">
                            <div className="flex justify-center items-center space-x-1">
                              <div className="p-1 rounded-full bg-white">
                                <Calendar1 size="18" className="text-primary" />
                              </div>
                              <span className="font-bold text-white text-xs">
                                강의 2
                              </span>
                            </div>
                            <div className="flex justify-center items-center space-x-1">
                              <div className="p-1 rounded-full bg-white">
                                <Calendar1 size="18" className="text-primary" />
                              </div>{" "}
                              <span className="font-bold text-white text-xs text-blue-500">
                                퀴즈 1
                              </span>
                            </div>
                            <div className="flex justify-center items-center space-x-1">
                              <div className="p-1 rounded-full bg-white">
                                <Calendar1 size="18" className="text-primary" />
                              </div>{" "}
                              <span className="font-bold text-white text-xs text-blue-500">
                                실습 1
                              </span>
                            </div>
                            <div className="flex justify-center items-center space-x-1">
                              <div className="p-1 rounded-full bg-white">
                                <Calendar1 size="18" className="text-primary" />
                              </div>{" "}
                              <span className="font-bold text-white text-xs text-blue-500">
                                과제 2
                              </span>
                            </div>
                          </div>
                        </div>

                        {[
                          {
                            id: "1-1",
                            text: "수업 계획에 대해 말씀드립니다",
                            icon: <Calendar1 size="18" />,
                            time: "32:23",
                            completed: true,
                          },
                          {
                            id: "1-2",
                            text: "수강 목표를 작성해 보세요",
                            icon: <Calendar1 size="18" />,
                            time: "32:23",
                            completed: true,
                          },
                          {
                            id: "1-3",
                            text: "반갑습니다 코린이 여러분",
                            icon: <Calendar1 size="18" />,
                            time: "32:23",
                            completed: false,
                          },
                          {
                            id: "1-4",
                            text: "반갑습니다 코린이 여러분",
                            icon: <Calendar1 size="18" />,
                            time: "32:23",
                            completed: true,
                          },
                          {
                            id: "1-5",
                            text: "반갑습니다 코린이 여러분",
                            icon: <Calendar1 size="18" />,
                            time: "32:23",
                            completed: true,
                          },
                          {
                            id: "1-6",
                            text: "반갑습니다 코린이 여러분",
                            icon: <Calendar1 size="18" />,
                            time: "32:23",
                            completed: true,
                          },
                        ].map((lesson) => (
                          <div
                            key={lesson.id}
                            className="flex justify-between border-b border-gray-100 items-center px-4 py-4  hover:bg-gray-100 rounded mb-2"
                          >
                            <div className="flex items-center space-x-2">
                              {lesson.icon}
                              <span>{lesson.text}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                              <span>{lesson.time}</span>
                              {lesson.completed ? (
                                <TickCircle
                                  size="18"
                                  className="text-blue-500"
                                />
                              ) : (
                                <PlayCircle
                                  size="18"
                                  className="text-blue-500"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {activeTab === "강의노트" && (
                <div>
                  <div className="mb-2">
                    <button
                      onClick={() => handleToggleSubItems("CHAPTER 1")}
                      className="w-full flex justify-between items-center py-2 px-4 border-b border-gray-100 hover:bg-gray-200 rounded"
                    >
                      <span>CHAPTER 1 (1/6)</span>
                      {openSubItems["CHAPTER 1"] ? (
                        <ArrowUp2 size="18" className="text-gray-600" />
                      ) : (
                        <ArrowDown2 size="18" className="text-gray-600" />
                      )}
                    </button>
                    {openSubItems["CHAPTER 1"] && (
                      <>
                        <div className="flex items-center justify-center gap-1 py-4 mt-2 bg-decoration rounded mb-2">
                          <div className="flex items-center space-x-4">
                            <div className="flex justify-center items-center space-x-1">
                              <div className="p-1 rounded-full bg-white">
                                <Calendar1 size="18" className="text-primary" />
                              </div>
                              <span className="font-bold text-white text-xs">
                                강의 2
                              </span>
                            </div>
                            <div className="flex justify-center items-center space-x-1">
                              <div className="p-1 rounded-full bg-white">
                                <Calendar1 size="18" className="text-primary" />
                              </div>{" "}
                              <span className="font-bold text-white text-xs text-blue-500">
                                퀴즈 1
                              </span>
                            </div>
                            <div className="flex justify-center items-center space-x-1">
                              <div className="p-1 rounded-full bg-white">
                                <Calendar1 size="18" className="text-primary" />
                              </div>{" "}
                              <span className="font-bold text-white text-xs text-blue-500">
                                실습 1
                              </span>
                            </div>
                            <div className="flex justify-center items-center space-x-1">
                              <div className="p-1 rounded-full bg-white">
                                <Calendar1 size="18" className="text-primary" />
                              </div>{" "}
                              <span className="font-bold text-white text-xs text-blue-500">
                                과제 2
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          {[
                            {
                              id: "1-1",
                              text: "수업 계획에 대해 말씀드립니다",
                              note: "Ini adalah catatan untuk subjek 1-1.",
                            },
                            {
                              id: "1-2",
                              text: "수강 목표를 작성해 보세요",
                              note: "Ini adalah catatan untuk subjek 1-2.",
                            },
                            {
                              id: "1-3",
                              text: "반갑습니다 코린이 여러분",
                              note: "Ini adalah catatan untuk subjek 1-3.",
                            },
                            {
                              id: "1-4",
                              text: "반갑습니다 코린이 여러분",
                              note: "Ini adalah catatan untuk subjek 1-4.",
                            },
                            {
                              id: "1-5",
                              text: "반갑습니다 코린이 여러분",
                              note: "Ini adalah catatan untuk subjek 1-5.",
                            },
                            {
                              id: "1-6",
                              text: "반갑습니다 코린이 여러분",
                              note: "Ini adalah catatan untuk subjek 1-6.",
                            },
                          ].map((note) => (
                            <div key={note.id}>
                              <div
                                onClick={() => handleToggleNotes(note.id)}
                                className={`flex justify-between items-center px-4 py-4 border-b border-gray-100 hover:bg-gray-100 rounded mb-2 cursor-pointer ${
                                  openNotes[note.id] ? "bg-gray-100" : ""
                                }`}
                              >
                                <div className="flex items-center space-x-2">
                                  <span>{note.id}</span>
                                  <Calendar1 size="18" />
                                  <span>{note.text}</span>
                                </div>
                                {openNotes[note.id] ? (
                                  <ArrowUp2
                                    size="18"
                                    className="text-gray-600"
                                  />
                                ) : (
                                  <ArrowDown2
                                    size="18"
                                    className="text-gray-600"
                                  />
                                )}
                              </div>
                              {openNotes[note.id] && (
                                <div className="px-4 py-4 border border-gray100 rounded mb-2 bg-white">
                                  <p className="text-center">{note.note}</p>
                                  <button className="mt-2 flex w-full bg-primary p-2 items-center rounded text-white justify-center items-center space-x-1 text-blue-500 hover:underline">
                                    <DocumentDownload size="18" />
                                    <span>Unduh</span>
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {activeTab === "Q&A" && (
                <div>
                  {/* Q&A Content */}
                  <div className="mb-2">
                    <div className="flex justify-end">
                      <button
                        onClick={onOpenModal}
                        className="flex items-center justify-center space-x-2 border border-primary text-xs text-primary px-4 py-2 rounded"
                      >
                        <span className="gap-2 flex">
                          질문하기
                          <PenAdd size={14} />
                        </span>
                      </button>
                    </div>
                    <button
                      onClick={() => handleToggleSubItems("CHAPTER 1")}
                      className="w-full flex justify-between items-center py-2 px-4 border-b border-gray-100 hover:bg-gray-100 rounded"
                    >
                      <span>CHAPTER 1 (1/6)</span>
                      {openSubItems["CHAPTER 1"] ? (
                        <ArrowUp2 size="18" className="text-gray-600" />
                      ) : (
                        <ArrowDown2 size="18" className="text-gray-600" />
                      )}
                    </button>
                    {openSubItems["CHAPTER 1"] && (
                      <div className="mt-2">
                        {[
                          {
                            id: "q1-1",
                            status: "답변 완료",
                            title: "JavaScript",
                            text: "첫 번째 질문입니다",
                            question: "what is JavaScript?",
                            answer: "첫 번째 질문에 대한 답변입니다.",
                            date: "2021-09-01",
                          },
                          {
                            id: "q1-2",
                            status: "미확인",
                            title: "CSS",
                            text: "두 번째 질문입니다",
                            question: "what is CSS?",
                            answer: null,
                            date: "2021-09-01",
                          },
                          {
                            id: "q1-3",
                            status: "답변 완료",
                            title: "HTML",
                            text: "세 번째 질문입니다",
                            question: "what is HTML?",
                            answer: "세 번째 질문에 대한 답변입니다.",
                            date: "2021-09-01",
                          },
                        ].map((qa) => (
                          <div key={qa.id} className="mb-4">
                            <button
                              onClick={() => handleToggleNotes(qa.id)}
                              className={`w-full flex justify-between items-center px-4 py-4 border-b border-gray-100 hover:bg-gray-100 rounded mb-2 cursor-pointer ${
                                openNotes[qa.id] ? "bg-gray-100" : ""
                              }`}
                            >
                              <div className="flex space-x-2">
                                <div className="flex flex-col items-left justify-left items-center space-x-2">
                                  {qa.status === "답변 완료" ? (
                                    <span className="border border-primary px-2 text-sm rounded text-primary">
                                      {qa.status}
                                    </span>
                                  ) : (
                                    <span className="border border-gray-500 px-2 text-sm rounded text-gray-500">
                                      {qa.status}
                                    </span>
                                  )}
                                </div>
                                <div className="flex flex-col items-left justify-left items-center space-x-2">
                                  <span>{qa.question}</span>
                                  <span>{qa.date}</span>
                                </div>
                              </div>
                              {openNotes[qa.id] ? (
                                <ArrowUp2 size="18" className="text-gray-600" />
                              ) : (
                                <ArrowDown2
                                  size="18"
                                  className="text-gray-600"
                                />
                              )}
                            </button>
                            {openNotes[qa.id] && (
                              <div className="px-4 py-4 border border-gray100 rounded mb-2 bg-white">
                                <p
                                  className={`${
                                    qa.answer
                                      ? " border-b border-gray-100 pb-2"
                                      : ""
                                  }`}
                                >
                                  {qa.text}..
                                </p>
                                {qa.answer && (
                                  <div className="mt-2">
                                    <p>Re: Lorem ipsum dolor sit amet</p>
                                    <p className="mt-2 text-gray-400">
                                      {qa.answer}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </aside>

      {/* Button to toggle sidebar */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className={`fixed bottom-20 transition-all duration-300 ${
          isSidebarOpen ? "right-96" : "right-0"
        } bg-gray-200 text-gray-500 py-2 z-50 w-6 h-10 flex items-center justify-center border border-gray-300`}
      >
        {isSidebarOpen ? <ArrowRight2 size="20" /> : <ArrowLeft2 size="20" />}
      </button>

      {openModal && (
        <Modal
          title="Q&A"
          onClose={() => {
            onCloseModal();
          }}
          size="lg"
        >
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={modules}
            placeholder="내용을 입력해주세요"
          />
          <div className="flex justify-end">
            <button className="bg-primary hover:bg-primary-slight text-white font-semibold py-2 px-6 mt-2 rounded-md transition">
              제출
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LectureLayout;
