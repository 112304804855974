import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { useState } from "react";
import IconProhibit from "./iconProhibit";
import Button from "../../../components/materials/Button";
const LeftSide = () => {
  const [isExpand, setExpand] = useState(false);
  const expandRestriction = function () {
    setExpand(!isExpand);
  };
  return (
    <div className="bg-bg_4 dark:bg-grey-7 relative">
      <div
        className="
        flex items-center justify-between h-14
        px-5 text-grey-6 text-xl font-bold bg-grey-1
        dark:bg-grey-8 dark:text-grey-4
      "
      >
        <div>문제&참조 / 제약사항</div>
        <ArrowDown2 size="20" />
      </div>
      <div className=" pl-5 pt-5 pr-2">
        {/* problem description */}
        <div className="border-b border-b-grey-1 pb-8">
          <div
            className="
          text-grey-9 text-base font-bold mb-5
          dark:text-white
        "
          >
            문제 설명
          </div>
          <div
            className="
          dark:text-grey-1 text-base font-medium text-grey-7
          h-52 overflow-hidden overflow-y-scroll
        "
          >
            A 나라가 B 나라를 침공하였습니다. B 나라의 대부분의 전략 자원은
            아이기스 군사 기지에 집중되어 있기 때문에 A 나라는 B 나라의 아이기스
            군사 기지에 융단폭격을 가했습니다.A 나라의 공격에 대항하여 아이기스
            군사 기지에서는 무수히 쏟아지는 폭격 미사일들을 요격하려고 합니다.
            이곳에는 백발백중을 자랑하는 요격 시스템이 있지만 운용 비용이
            상당하기 때문에 미사일을 최소로 사용해서 모든 폭격 미사일을 요격하려
            합니다.A 나라와 B 나라가 싸우고 있는 이 세계는 2 차원 공간으로
            이루어져 있습니다.
          </div>
        </div>
        {/* restrictions */}
        <div
          className="
          text-grey-9 text-base font-bold mb-5 mt-5
          dark:text-white
        "
        >
          제약 사항
        </div>
        <div>
          <ul className="dark:text-grey-1 text-base font-medium text-grey-7 list-disc pl-5">
            <li>1 ≤ targets의 길이 ≤ 500,000</li>
            <li>
              targets의 각 행은 [s,e] 형태입니다.
              <ul className="dark:text-grey-1 text-base font-medium text-grey-7 list-disc pl-5">
                <li>
                  이는 한 폭격 미사일의 x 좌표 범위를 나타내며, 개구간에서
                  요격해야 합니다.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      {/* h-14 */}
      <div
        onClick={expandRestriction}
        className={`
          ${isExpand ? "h-[436px] " : "h-14 "}
          absolute bottom-0 w-full
          bg-bg_4 dark:bg-grey-7
          cursor-pointer 
          transition-all duration-500
          overflow-hidden overflow-y-scroll
        `}
      >
        <div
          className="
          px-5 h-14 flex items-center justify-between
          text-grey-6 text-xl font-bold bg-grey-1
          dark:bg-grey-8 dark:text-grey-4 
        "
        >
          <div>테스트케이스</div>
          <div
            className={`transition-transform duration-500 rotate-0
              ${isExpand && "rotate-180"}
              `}
          >
            <ArrowUp2 size="20" />
          </div>
        </div>
        <div className="px-4 py-3">
          <div className="flex mb-3 items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="text-grey-7 text-base font-bold">
                테스트 케이스 1
              </div>
              <div
                className="flex items-center text-[#FF3E3E]
                bg-white border border-grey-2 rounded-[4px] px-2 py-1 gap-1
              "
              >
                <IconProhibit />
                FAIL
              </div>
            </div>
            <div className="w-16">
              <Button size="sm" variant="primary">
                검증
              </Button>
            </div>
          </div>
          <div className="h-[132px] grid gap-4 grid-cols-2">
            <div className="bg-grey-5 rounded-lg p-3">
              <div className="text-white text-base font-medium">input</div>
              <div className="text-blue-2 text-base font-medium">solution</div>
            </div>
            <div className="bg-grey-5 rounded-lg p-3">
              <div className="text-white text-base font-medium">output</div>
              <div className="text-red-2 text-base font-medium">0</div>
            </div>
          </div>
        </div>
     
        <div className="px-4 py-3">
          <div className="flex mb-3 items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="text-grey-7 text-base font-bold">
                테스트 케이스 1
              </div>
              <div
                className="flex items-center text-[#FF3E3E]
                bg-white border border-grey-2 rounded-[4px] px-2 py-1 gap-1
              "
              >
                <IconProhibit />
                FAIL
              </div>
            </div>
            <div className="w-16">
              <Button size="sm" variant="primary">
                검증
              </Button>
            </div>
          </div>
          <div className="h-[132px] grid gap-4 grid-cols-2">
            <div className="bg-grey-5 rounded-lg p-3">
              <div className="text-white text-base font-medium">input</div>
              <div className="text-blue-2 text-base font-medium">solution</div>
            </div>
            <div className="bg-grey-5 rounded-lg p-3">
              <div className="text-white text-base font-medium">output</div>
              <div className="text-red-2 text-base font-medium">0</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSide;
