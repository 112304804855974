import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { subjectData } from "../constans";

type SubjectData = {
  id: number;
  name: string;
  progress: string;
  message: string;
  week: string;
  type: string;
  notice: string;
  startDate: string;
  endDate: string;
  mentor: string;
  isView: boolean;
};

type SubjectStore = {
  subjectAllData: SubjectData[];
  subjectData: SubjectData[];
  setSubjectAllData: (data: SubjectData[]) => void;
  setSubjectData: (data: SubjectData[]) => void;
};

export const useSubjectStore = create<SubjectStore>()(
  persist(
    (set) => ({
      subjectAllData: subjectData,
      subjectData: subjectData?.filter((item) => item.isView),
      setSubjectAllData: (data: SubjectData[]) => set({ subjectAllData: data }),
      setSubjectData: (data: SubjectData[]) => set({ subjectData: data }),
    }),
    {
      name: "subject-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
