export default function IconWallet() {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.95C10.8678 7.95 9.95 8.86779 9.95 10C9.95 11.1322 10.8678 12.05 12 12.05H17C18.1322 12.05 19.05 11.1322 19.05 10C19.05 8.86779 18.1322 7.95 17 7.95H12ZM12 9.05H17C17.5247 9.05 17.95 9.47531 17.95 10C17.95 10.5247 17.5247 10.95 17 10.95H12C11.4753 10.95 11.05 10.5247 11.05 10C11.05 9.47531 11.4753 9.05 12 9.05Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
      <path
        d="M3.5 0.45C2.09168 0.45 0.95 1.59168 0.95 3V15C0.95 16.1322 1.86782 17.05 3 17.05H19C20.1322 17.05 21.05 16.1322 21.05 15V5C21.05 3.86782 20.1322 2.95 19 2.95H17.5495C17.5229 1.56475 16.3916 0.45 15 0.45H3.5ZM15 1.55C15.7841 1.55 16.4228 2.17234 16.4492 2.95H2.05085C2.0772 2.17234 2.71592 1.55 3.5 1.55H15ZM2.05 4.05H19C19.5247 4.05 19.95 4.47533 19.95 5V15C19.95 15.5247 19.5247 15.95 19 15.95H3C2.47533 15.95 2.05 15.5247 2.05 15V4.05Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
    </svg>
  );
}
