import React from "react";

interface MainStatProps {
  label: string;
  value: string;
  valueMax: string;
  icon: JSX.Element;
}

const MainStats: React.FC<MainStatProps> = ({
  label,
  value,
  valueMax,
  icon,
}) => {
  return (
    <div className="flex items-center space-x-3 text-center justify-center">
      <div className="text-5xl">{icon}</div>
      <div className="flex flex-col">
        <div className="text-lg font-bold">{label}</div>
        <div className="text-lg text-gray-400">
          <span className="font-bold text-primary">{value}</span> /{" "}
          <span className="text-sm">{valueMax}</span>
        </div>
      </div>
    </div>
  );
};

export default MainStats;
