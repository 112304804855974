import Input from "../../components/materials/Input";
import Button from "../../components/materials/Button";
import TextArea from "./textarea";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const inquiresSchema = z.object({
  name : z.string().min(1),
  email : z.string().email(),
  category : z.string().min(1),
  title : z.string().min(1),
  inquires : z.string().min(1)
})

type InquiresType = z.infer<typeof inquiresSchema>

const FormInquire = () => {
  const{
    register,
    handleSubmit,
    formState,

  } = useForm <InquiresType> ({
    resolver : zodResolver(inquiresSchema)
  })
  const submitHandler = handleSubmit(() => {
    
  })
  return (
    <div className="py-12 bg-bg_1">
      <div className="text-grey-9 font-bold text-2xl text-center">문의하기</div>
      <div className="mt-4 text-grey-6 text-base font-medium text-center">
        추가로 궁금한점은 문의하기를 통해 질문을 남겨주세요!
      </div>
      <div className="mb-14 text-grey-4 text-sm font-medium text-center">
        (문의 가능 시간 : 9:30 ~ 17:30 / 주말, 공휴일제외)
      </div>

      <div className="w-[50%] m-auto">
        <form onSubmit={submitHandler} className="grid gap-8">
          <div className="grid gap-3 grid-cols-2">
            <Input 
              isRequired 
              label="이름" 
              placeholder="이름" 
              id="name" 
              {...register('name')}
            />
            <Input 
              isRequired 
              label="이메일" 
              placeholder="이메일" 
              id="email" 
              {...register('email')}
            />
          </div>
          <Input
            isRequired
            label="문의 카테고리"
            placeholder="문의 카테고리"
            id="category"
            {...register('category')}
          />
          <Input
            id="title"
            label="제목"
            placeholder="제목"
            isRequired
            type="text"
            {...register('title')}
          />
          <TextArea
            id="inquires"
            label="문의 내용"
            placeholder="문의 내용을 입력해주세요."
            isRequired
            {...register('inquires')}
          />
          <div>
            <div className="w-32 ml-auto">
              <Button disabled={!formState.isValid} type="submit" variant="primary"> 문의하기</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormInquire;
