import React, { useState } from "react";
import InfoClassroom from "../components/infoClassroom";
import ProgressBarClassroom from "../components/progressBarClassroom";
import dummyImg from "../../../assets/dummyLectures.png";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { useParams } from "react-router-dom";

const chapters = [
  {
    id: 1,
    title: "CHAPTER 1",
    slug: "chapter-1",
    status: "incomplete",
    color: "bg-red-400",
    icon: "❌",
  },
  {
    id: 2,
    title: "CHAPTER 2",
    slug: "chapter-2",
    status: "complete",
    color: "bg-blue-400",
    icon: "✔️",
  },
  {
    id: 3,
    title: "CHAPTER 3",
    slug: "chapter-3",
    status: "in-progress",
    color: "bg-yellow-400",
    icon: "⚠️",
  },
];

function DetailChapterProfessor() {
  const { chapterSlug } = useParams<{ id: string; chapterSlug: string }>();
  const [openChapter, setOpenChapter] = useState<number | null>(null);

  const getChapter = chapters.filter((chapter) => chapter.slug === chapterSlug);
  console.log(getChapter);

  const handleToggle = (id: React.SetStateAction<number | null>) => {
    setOpenChapter(openChapter === id ? null : id);
  };
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="space-y-6 flex flex-col mb-6">
        <InfoClassroom />
        <ProgressBarClassroom />
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="font-bold text-lg mb-4">상세 진도율</div>
          {getChapter.map((chapter) => (
            <div
              key={chapter.id}
              className={`mb-2 ${chapter.color} rounded-lg`}
            >
              <div
                className="flex rounded-lg justify-between items-center p-4 cursor-pointer"
                onClick={() => handleToggle(chapter.id)}
              >
                <div className="flex items-center space-x-2">
                  <span>{chapter.icon}</span>
                  <span>{chapter.title}</span>
                </div>
                <span>
                  {openChapter === chapter.id ? (
                    <ArrowUp2 size="18" className="group-hover:text-primary" />
                  ) : (
                    <ArrowDown2
                      size="18"
                      className="group-hover:text-primary"
                    />
                  )}
                </span>
              </div>
              {openChapter === chapter.id && (
                <div className="py-2 bg-white">
                  <div className="mb-4 bg-gray-50 border border-gray-100 shadow-sm p-6 rounded-lg ">
                    <div className="text-lg font-semibold">
                      01. JavaScript 개념 강의
                      <span className="ml-2 text-red-500">수강 전</span>
                    </div>
                    <p className="text-sm text-gray-600">
                      강의에 대한 요약 설명이 들어갑니다. 더미 텍스트 입니다.
                      글로벌 IT 기업 UX디자이너의 업무 프로세스와 더미 텍스트
                      입니다. 확실한 도움을 드리겠습니다. 저와 같은 상황의
                      분들를 진심으로 돕겠습니다. 저와 같은 상황의 분들를
                      진심으로 돕겠습니다.글로벌 IT 기업 UX디자이너의 업무
                      프로세스와 거래의 성과를 위해준비를 하시는데 확실한 도움을
                      드리겠습니다.
                    </p>
                    <div className="mt-4">
                      <img
                        src={dummyImg}
                        alt="Content"
                        className="rounded-lg w-full shadow"
                      />
                    </div>
                  </div>
                  <div className="text-lg font-semibold bg-gray-50 border border-gray-100 shadow-sm p-6 rounded-lg">
                    02. 과제 실습
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DetailChapterProfessor;
