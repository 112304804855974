import React, { useState } from "react";
import { CloseCircle, Code1, Setting5 } from "iconsax-react";
import { useLearningActivityStore } from "../../../store/learningActivity";

function LearningActivitiesProfessor() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setFilter] = useState<{ [key: string]: boolean }>({
    과제: true,
    시험: true,
    강의: true,
  });
  const { learningActivitiesData } = useLearningActivityStore((state) => state);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleFilterChange = (type: string) => {
    setFilter((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleRemoveFilter = (type: string) => {
    setFilter((prev) => ({ ...prev, [type]: false }));
  };

  const allFiltersInactive = !Object.values(filter).some(Boolean);

  const filteredData = allFiltersInactive
    ? learningActivitiesData
    : learningActivitiesData.filter((activity) => filter[activity.type]);

  const activeFilters = Object.keys(filter).filter((key) => filter[key]);

  return (
    <div className="bg-white relative">
      <div className="flex justify-between items-center mb-4 p-2 border-b">
        <h3 className="text-lg font-bold">수강생 학습 현황</h3>
        <button
          className="text-xl cursor-pointer text-sm"
          onClick={handleDropdownToggle}
          aria-label="Edit course status"
        >
          <Setting5 size="16" color="gray" />
        </button>
      </div>

      <div className="flex flex-wrap">
        {activeFilters.map((filter) => (
          <div
            key={filter}
            className="bg-blue-1 text-blue-500 border border-blue-2 px-3 py-1 mb-2 mr-2 rounded-full flex items-center space-x-2"
          >
            <span>{filter}</span>
            <button
              className="text-primary"
              onClick={() => handleRemoveFilter(filter)}
            >
              <CloseCircle size="16" />
            </button>
          </div>
        ))}
      </div>

      <ul>
        {filteredData.map((activity: any, index: number) => (
          <li key={index} className="flex items-center justify-between py-2">
            <div className="flex items-center mr-4">
              <div className="text-blue-500 text-2xl mr-4">
                <Code1 size="24" color="gray" />
              </div>
              <div>
                <p className="text-gray-900">{activity.course}</p>
                <p className="text-gray-500 text-xs">{activity.date}</p>
              </div>
            </div>
            <span
              className={`px-2 py-1 text-xs rounded ${
                activity.type === "과제"
                  ? "bg-red-100 text-red-500"
                  : activity.type === "시험"
                  ? "bg-yellow-100 text-yellow-500"
                  : "bg-green-100 text-green-500"
              }`}
            >
              {activity.type}
            </span>
          </li>
        ))}
      </ul>

      {showDropdown && (
        <div className="absolute right-2 top-10 bg-white border rounded-lg shadow-lg p-4 w-36 z-50">
          <div className="mb-2">
            <div
              className=" mr-4 space-x-2 border-b my-2 cursor-pointer"
              onClick={() => handleFilterChange("과제")}
            >
              <input
                type="checkbox"
                checked={filter.과제}
                onChange={() => handleFilterChange("과제")}
              />
              <span>과제</span>
            </div>
            <div
              className="flex items-center space-x-2 border-b my-2 cursor-pointer"
              onClick={() => handleFilterChange("시험")}
            >
              <input
                type="checkbox"
                checked={filter.시험}
                onChange={() => handleFilterChange("시험")}
              />
              <span>시험</span>
            </div>
            <div
              className="flex items-center space-x-2 border-b my-2 cursor-pointer"
              onClick={() => handleFilterChange("강의")}
            >
              <input
                type="checkbox"
                checked={filter.강의}
                onChange={() => handleFilterChange("강의")}
              />
              <span>강의</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LearningActivitiesProfessor;
