import Footer from "../../../components/containers/footer";
import DetailImage from "../../../assets/detailLectures.png";
import { ArrowRight2 } from "iconsax-react";
import Avatar from "../../../assets/avatar.png";
import TabsLectures from "./tabs";
import RightSide from "./rightSide";
const DetailsLectures = () => {
  return (
    <div>
      <div
        className="
      2xl:px-72 px-40 
      py-14 bg-white
    "
      >
        <div className="w-full h-[522px] overflow-hidden rounded-2xl">
          <img src={DetailImage} alt="" />
        </div>
        <div className="mt-6 gap-7 grid grid-cols-[1fr_360px]">
          <div>
            <div className="border border-grey-1 rounded-2xl px-10 py-8 ">
              <div className="flex gap-2 items-center mb-4">
                <div className="text-grey-3 text-sm font-medium">공지</div>
                <ArrowRight2 color="#A5A5A5" size={16} />
                <div className="text-grey-6 text-sm font-bold">공지사항</div>
              </div>
              <div className="text-grey-8 text-[28px] font-bold mb-8">
                죽을때 까지 기억나는 JavaScript 기초부터 응용까지
              </div>
              <div className="w-full flex items-center justify-between">
                <div className="flex gap-3 items-center">
                  <div className="w-12 h-12 rounded-full bg-bg_2 overflow-hidden">
                    <img src={Avatar} alt="" />
                  </div>
                  <div className="text-grey-8 font-bold text-base">김강사</div>
                </div>
                <div className="flex gap-3">
                  <div className="text-red-3 flex justify-center border border-red-2 px-[10px] py-[6px] rounded-[4px]">
                    new
                  </div>
                  <div className="text-red-3 flex justify-center border border-red-2 px-[10px] py-[6px] rounded-[4px]">
                    JavaScript{" "}
                  </div>
                  <div className="text-red-3 flex justify-center border border-red-2 px-[10px] py-[6px] rounded-[4px]">
                    초급
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 border border-grey-1 rounded-2xl px-10 py-8 ">
              <TabsLectures/>
            </div>
          </div>

        <RightSide/>
        </div>
      </div>
      <Footer variant="blue" />
    </div>
  );
};

export default DetailsLectures;
