import React from "react";
import LogoChappy from "../../../assets/chappy.png";
import IconAssignment from "../../../assets/IconAssignment.png";
import IconDate from "../../../assets/IconDate.png";
import IconTest from "../../../assets/IconTest.png";
import MainStats from "./MainStats";

function StatsCards() {
  const mainStats = [
    {
      label: "출결",
      value: "23",
      valueMax: "56회",
      icon: <img src={IconDate} alt="출결" width="55px" height="55px" />,
    },
    {
      label: "과제",
      value: "23",
      valueMax: "56회",
      icon: <img src={IconAssignment} alt="과제" width="55px" height="55px" />,
    },
    {
      label: "시험",
      value: "23",
      valueMax: "56회",
      icon: <img src={IconTest} alt="시험" width="55px" height="55px" />,
    },
  ];

  const notificationStat = {
    label: "새로운 알림이 있어요 !",
    value: "8+",
  };

  return (
    <div className="flex space-x-6">
      <div className="flex-1 grid grid-cols-3 gap-4 bg-white p-4 rounded-lg shadow h-32">
        {mainStats.map((stat, index) => (
          <MainStats
            key={index}
            label={stat.label}
            value={stat.value}
            valueMax={stat.valueMax}
            icon={stat.icon}
          />
        ))}
      </div>
      <div className="w-1/4">
        <div className="bg-white rounded-lg px-6 py-4 h-32 rounded shadow flex justify-between items-center space-x-3">
          <div className="flex flex-col space-y-1">
            <div className="text-4xl font-bold text-primary">
              {notificationStat.value}
            </div>
            <div className="text-sm text-gray-500">
              {notificationStat.label}
            </div>
          </div>
          <img src={LogoChappy} alt="Profile" className="w-12 h-12" />
        </div>
      </div>
    </div>
  );
}

export default StatsCards;
