import Input from "../../components/materials/Input";
import Avatar from "../../assets/avatar.png";
import Button from "../../components/materials/Button";
import Dialog from "./dialog";
import IconSearch from "./iconSearch";
import { useState } from "react";
import TextArea from "../Inquiry/textarea";
const EditProfile = () => {
  const [isOpen, setOpen] = useState(false);
  const [dialogAffliation, setDialogAffliation] = useState(false);
  const setUpDialog = function () {
    setOpen(!isOpen);
  };

  const setUpDialogAffliation = function () {
    setDialogAffliation(!dialogAffliation);
  };
  return (
    <>
      <Dialog width="390" isDialog={isOpen} setUpDialog={setUpDialog}>
        <div className="grid gap-7">
          <div className="text-center text-grey-8 font-bold text-lg">
            인증번호 발송
          </div>
          <div className="text-center m-auto w-[160px] text-grey-6 text-sm font-medium">
            인증번호가 발송되었습니다
          </div>
          <div className="grid gap-2">
            <Button onClick={setUpDialog} size="sm" variant="primary">
              닫기
            </Button>
          </div>
        </div>
      </Dialog>
      <div className="text-grey-9 font-bold text-xl mb-8">내 프로필</div>
      <div className="grid gap-5">
        <div className="grid grid-cols-[100px_76px_1fr] gap-5">
          <div className="w-24 h-24 overflow-hidden rounded-full bg-bg_2 m-auto">
            <img src={Avatar} alt="" />
          </div>
          <div className="text-grey-3 font-medium flex items-center">
            회원ID
          </div>
          <div className="m-auto w-full">
            <Input disabled value={"123456"} id="membersId" />
          </div>
        </div>
        <div className="grid gap-4 grid-cols-[100px_1fr]">
          <div className="flex items-center text-base font-medium text-grey-3">
            이름
          </div>
          <Input value={"홍길동"} id="name" />
        </div>

        <div className="grid gap-4 grid-cols-[100px_1fr_120px]">
          <div className="flex items-center text-base font-medium text-grey-3">
            연락처
          </div>
          <Input value={"0123456"} id="contact" />
          <Button onClick={setUpDialog} variant="outline_primary">
            {" "}
            인증번호 전송
          </Button>
        </div>

        <div className="grid gap-4 grid-cols-[100px_1fr_120px]">
          <div className="flex items-center text-base font-medium text-grey-3">
          인증번호
          </div>
          <Input  value={"0123456"} id="authNumber" />
          <Button onClick={setUpDialog} variant="outline_primary">
            확인
          </Button>
        </div>

        <div className="grid gap-4 grid-cols-[100px_1fr]">
          <div
            className="
            flex items-center
            text-base font-medium text-grey-3
          "
          >
            이메일
          </div>
          <Input disabled value={"abcd@naver.com"} id="email" />

          <div
            className="
            flex items-center
            text-base font-medium text-grey-3
          "
          >
            소속
          </div>
          <div
            onClick={setUpDialogAffliation}
            className=" py-3.5 px-4 flex justify-between h-14 rounded-xl relative bg-white border border-grey-1"
          >
            <div
              className="
                text-xs text-blue-4 rounded-[4px] 
                border border-blue-4
                inline-block px-2 py-1
              "
            >
              소속없음
            </div>
            <IconSearch />
          </div>

          <div
            className="
            flex items-center
            text-base font-medium text-grey-3
          "
          >
            자기소개
          </div>
          <TextArea
            rows={2}
            value={` 안녕하세요. 코딩을 좋아하고 잘하고싶은 코딩 입문한지 얼마 안된 홍길동입니다!
          잘 부탁드립니다~~
        `}
            id="affiliation"
          />

          <Dialog
            width="390"
            isDialog={dialogAffliation}
            setUpDialog={setUpDialogAffliation}
          >
            <div className="grid gap-7">
              <div className="text-center text-grey-8 font-bold text-lg">
                소속기관 인증코드
              </div>
              <div className="text-center m-auto w-[160px] text-grey-6 text-sm font-medium">
                소속기관 인증코드를 입력해주세요.
              </div>
              <Input id="affliation" placeholder="소속기관 코드입력" />
              <label className="flex items-center gap-2">
                <input type="checkbox" />
                <div className="text-grey-3 font-medium text-sm">
                  소속기관이 없습니다.
                </div>
              </label>
              <div className="grid gap-2">
                <Button
                  disabled
                  onClick={setUpDialogAffliation}
                  size="sm"
                  variant="primary"
                >
                  닫기
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
