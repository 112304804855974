import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import React from "react";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex justify-center mt-6">
      <button
        className={`px-3 py-1 mx-1 ${
          currentPage === 1 ? "text-gray-400" : "text-blue-600"
        }`}
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <ArrowLeft2 size="18" />
      </button>
      {pageNumbers.map((number) => (
        <button
          key={number}
          className={`px-3 py-1 mx-1 rounded-full ${
            number === currentPage
              ? "bg-blue-200 text-primary"
              : "bg-gray-200 text-gray-600"
          }`}
          onClick={() => onPageChange(number)}
        >
          {number}
        </button>
      ))}
      <button
        className={`px-3 py-1 mx-1 ${
          currentPage === totalPages ? "text-gray-400" : "text-blue-600"
        }`}
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <ArrowRight2 size="18" />
      </button>
    </div>
  );
};

export default Pagination;
