import { SearchNormal1 } from "iconsax-react";

interface SearchProps {
  mb?: string;
  [key: string]: any;
}

export default function Search({ mb = "mb-4", ...props }: SearchProps) {
  return (
    <div className={` flex items-center ${mb} w-full md:w-auto`}>
      <input
        type="search"
        name="search"
        className="pr-10 pl-4 text-sm py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
        {...props}
      />
      <div className="absolute right-3 pointer-events-none text-sm">
        <SearchNormal1 size="20" className="text-gray-300" />
      </div>
    </div>
  );
}
