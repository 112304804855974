import Login from "../pages/login";
import FindMyId from "../pages/findMyID";
import FindPassword from "../pages/findPassword";
import InformationExist from "../pages/information/exists";
import InformationNotFound from "../pages/information/not-exist";
import ResetPassword from "../pages/resetPassword";
import JoinMemberShip from "../pages/joinMembership";
import TermCondition from "../pages/termCondition";
import HomePage from "../pages/Home";
import Introduction from "../pages/Introduction";
import Announcement from "../pages/Announcement";
import DetailAnnouncement from "../pages/Announcement/detail"
import Inquiry from "../pages/Inquiry";
import InformationProfileLayout from "../pages/InfomationProfile";
import InfoProfile from "../pages/InfomationProfile/infoProfile";
import EditProfile from "../pages/InfomationProfile/editProfile";
import ChangePassword from "../pages/InfomationProfile/changePassword";
import Lectures from "../pages/Lectures"
import DetailsLectures from "../pages/Lectures/details";
const route = [
  {
    index: true,
    path: "/",
    element: <HomePage />,
  },
  {
    path : '/lectures',
    element : <Lectures/>
  },
  {
    path : '/lectures/detail',
    element : <DetailsLectures/>
  },
  {
    path : '/info-profile',
    element : <InformationProfileLayout/>,
    children : [
      {
        index : true,
        path : '',
        element : <InfoProfile/>
      },
      {
        path : 'edit',
        element : <EditProfile/>
      },
      {
        path : 'change-password',
        element : <ChangePassword/>
      }
    ]
  },
  {
    path: "/inquiry",
    element: <Inquiry />,
  },
  {
    path: "/announcement",
    element: <Announcement />,
  },
  {
    path: "/announcement/detail",
    element: <DetailAnnouncement />,
  },
  {
    path: "/introduction",
    element: <Introduction />,
  },
  {
    path: "/term-condition",
    element: <TermCondition />,
  },

  {
    path: "/findMyId",
    element: <FindMyId></FindMyId>,
  },
  {
    path: "/login",
    element: <Login></Login>,
  },
  {
    path: "/information-exist",
    element: <InformationExist></InformationExist>,
  },
  {
    path: "/information-notfound",
    element: <InformationNotFound></InformationNotFound>,
  },
  {
    path: "/find-password",
    element: <FindPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/join-membership",
    element: <JoinMemberShip></JoinMemberShip>,
  },
]

export default route