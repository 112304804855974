import { JavaScript } from "iconsax-react";
import InstructorImage from "../assets/Instruktur.png";
import CourseImage from "../assets/course-image.png";

export const subjectData = [
  {
    id: 1,
    name: "JavaScript 하나",
    progress: "50%",
    message: "충분히 잘하고 있어요!",
    week: "5주차",
    type: "실력확인 테스트",
    notice: "공지",
    startDate: "2023.02.04",
    endDate: "2023.03.04",
    mentor: "김강사",
    isView: true,
  },
  {
    id: 2,
    name: "JavaScript 둘",
    progress: "83%",
    message: "충분히 잘하고 있어요!",
    week: "5주차",
    type: "실력확인 테스트",
    notice: "공지",
    startDate: "2023.03.04",
    endDate: "2023.04.04",
    mentor: "김강사",
    isView: true,
  },
  {
    id: 3,
    name: "JavaScript 삼",
    progress: "29%",
    message: "충분히 잘하고 있어요!",
    week: "5주차",
    type: "실력확인 테스트",
    notice: "공지",
    startDate: "2023.05.04",
    endDate: "2023.06.04",
    mentor: "김강사",
    isView: true,
  },
  {
    id: 4,
    name: "JavaScript 네",
    progress: "30%",
    message: "충분히 잘하고 있어요!",
    week: "5주차",
    type: "실력확인 테스트",
    notice: "공지",
    startDate: "2023.07.04",
    endDate: "2023.08.04",
    mentor: "김강사",
    isView: true,
  },
  {
    id: 5,
    name: "CSS 하나",
    progress: "10%",
    message: "충분히 잘하고 있어요!",
    week: "5주차",
    type: "실력확인 테스트",
    notice: "공지",
    startDate: "2023.08.04",
    endDate: "2023.09.04",
    mentor: "김강사",
    isView: false,
  },
  {
    id: 6,
    name: "CSS 둘",
    progress: "15%",
    message: "충분히 잘하고 있어요!",
    week: "5주차",
    type: "실력확인 테스트",
    notice: "공지",
    startDate: "2023.09.04",
    endDate: "2023.10.04",
    mentor: "김강사",
    isView: false,
  },
];

export const activitiesData = [
  {
    id: 1,
    type: "과제",
    date: "23.02.04 23:59",
    course: "JavaScript 기초",
    icon: <JavaScript />,
  },
  {
    id: 2,
    type: "시험",
    date: "23.02.04 23:59",
    course: "JavaScript 기초",
    icon: <JavaScript />,
  },
  {
    id: 3,
    type: "강의",
    date: "23.02.04 23:59",
    course: "JavaScript 기초",
    icon: <JavaScript />,
  },
  {
    id: 4,
    type: "강의",
    date: "23.02.04 23:59",
    course: "JavaScript 기초",
    icon: <JavaScript />,
  },
];

export const courseData = [
  {
    id: 1,
    title: "CSS Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    progress: 13,
    status: "시작이 좋아요",
    category: "in-progress",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
    chapter: [
      {
        id: 1,
        title: "Chapter 1",
        slug: "chapter-1",
        progress: 13,
        status: "시작이 좋아요",
        category: "in-progress",
        startDate: "22.12.01",
        endDate: "23.02.12",
        badge: "NEW",
        src: CourseImage,
      },
      {
        id: 2,
        title: "Chapter 2",
        slug: "chapter-2",
        progress: 13,
        status: "시작이 좋아요",
        category: "in-progress",
        startDate: "22.12.01",
        endDate: "23.02.12",
        badge: "NEW",
        src: CourseImage,
      },
      {
        id: 3,
        title: "Chapter 3",
        slug: "chapter-3",
        progress: 13,
        status: "시작이 좋아요",
        category: "in-progress",
        startDate: "22.12.01",
        endDate: "23.02.12",
        badge: "NEW",
        src: CourseImage,
      },
    ],
  },
  {
    id: 2,
    title: "HTML Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    progress: 13,
    status: "시작이 좋아요",
    category: "in-progress",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
    chapter: [
      {
        id: 1,
        title: "Chapter 1",
        slug: "chapter-1",
        progress: 13,
        status: "시작이 좋아요",
        category: "in-progress",
        startDate: "22.12.01",
        endDate: "23.02.12",
        badge: "NEW",
        src: CourseImage,
      },
      {
        id: 2,
        title: "Chapter 2",
        slug: "chapter-2",
        progress: 13,
        status: "시작이 좋아요",
        category: "in-progress",
        startDate: "22.12.01",
        endDate: "23.02.12",
        badge: "NEW",
        src: CourseImage,
      },
      {
        id: 3,
        title: "Chapter 3",
        slug: "chapter-3",
        progress: 13,
        status: "시작이 좋아요",
        category: "in-progress",
        startDate: "22.12.01",
        endDate: "23.02.12",
        badge: "NEW",
        src: CourseImage,
      },
    ],
  },
  {
    id: 3,
    title: "JavaScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    progress: 13,
    status: "시작이 좋아요",
    category: "termination",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    id: 4,
    title: "React Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    progress: 13,
    status: "시작이 좋아요",
    category: "termination",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    id: 5,
    title: "Vue Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    progress: 13,
    status: "시작이 좋아요",
    category: "completion",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    id: 6,
    title: "Angular Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    progress: 13,
    status: "시작이 좋아요",
    category: "completion",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    id: 7,
    title: "TypeScript Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    progress: 13,
    status: "시작이 좋아요",
    category: "applying",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
  {
    id: 8,
    title: "Sass Basics",
    instructor: "김강사",
    instructorImage: InstructorImage,
    progress: 13,
    status: "시작이 좋아요",
    category: "applying",
    startDate: "22.12.01",
    endDate: "23.02.12",
    badge: "NEW",
    src: CourseImage,
  },
];
