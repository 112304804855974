import React, { ReactNode, useEffect } from "react";

type ModalSize = "sm" | "md" | "lg" | "xl";

type ModalProps = {
  title: string;
  children: ReactNode;
  onClose: () => void;
  size?: ModalSize;
};

const sizeClasses = {
  sm: "w-1/4",
  md: "w-1/3",
  lg: "w-1/2",
  xl: "w-2/3",
};

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  onClose,
  size = "md",
}) => {
  useEffect(() => {}, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className={`bg-white rounded-lg ${sizeClasses[size]}`}>
        <div className="flex justify-between bg-gray-100 rounded-t-lg items-center py-4 px-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            ✕
          </button>
        </div>
        <div className="p-6 max-h-128 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
