export default function IconRouterSignal() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8635_39719)">
        <path
          d="M23.0799 16.7736C22.4794 16.0898 21.639 15.7132 20.7137 15.7132H20.5739C20.574 15.5542 20.548 15.3944 20.4936 15.2408C20.1622 14.3035 19.8723 12.9154 20.3739 11.6246C20.3739 11.6246 20.3739 11.6246 20.3739 11.6246C20.5422 11.1915 20.486 10.704 20.2236 10.3205C19.9612 9.93696 19.5271 9.70801 19.0624 9.70801H3.2862C1.35131 9.70801 0 11.2321 0 13.4143C0 14.49 0.321512 15.4154 0.929818 16.0906C1.52823 16.7549 2.36506 17.1207 3.28624 17.1207H3.53067C3.53072 17.2925 3.56206 17.4651 3.6261 17.6298C4.16173 19.0081 3.78101 20.5164 3.50637 21.2929C3.35366 21.7246 3.42009 22.2053 3.68404 22.5786C3.94709 22.9507 4.37628 23.1728 4.83216 23.1728H20.7138C21.635 23.1728 22.4718 22.807 23.0702 22.1427C23.6785 21.4675 24 20.5421 24 19.4664C24 18.3901 23.6818 17.4589 23.0799 16.7736ZM1.40746 13.4143C1.40746 12.0178 2.14493 11.1155 3.2862 11.1155L19.0628 11.1125C19.0628 11.1125 19.0626 11.1133 19.062 11.1148C18.404 12.8079 18.7558 14.5482 19.1667 15.7101C19.1667 15.7101 19.1663 15.7118 19.1661 15.7132H4.93753H3.2862C2.14493 15.7132 1.40746 14.8109 1.40746 13.4143ZM20.7138 21.7653L4.83384 21.7652C4.83361 21.7638 4.83328 21.7622 4.83328 21.7622C5.1729 20.802 5.63812 18.9223 4.93823 17.1207H19.1679H20.7138C21.8375 17.1207 22.5925 18.0634 22.5925 19.4664C22.5925 20.863 21.8551 21.7653 20.7138 21.7653Z"
          fill="#759CFF"
        />
        <path
          d="M16.1734 13.4137C16.1734 13.025 15.8583 12.71 15.4697 12.71H4.39514C4.00649 12.71 3.69141 13.025 3.69141 13.4137C3.69141 13.8023 4.00649 14.1174 4.39514 14.1174H15.4697C15.8583 14.1174 16.1734 13.8024 16.1734 13.4137Z"
          fill="#759CFF"
        />
        <path
          d="M19.626 18.7139H8.55139C8.16274 18.7139 7.84766 19.029 7.84766 19.4176C7.84766 19.8062 8.16274 20.1213 8.55139 20.1213H19.626C20.0146 20.1213 20.3297 19.8062 20.3297 19.4176C20.3297 19.029 20.0147 18.7139 19.626 18.7139Z"
          fill="#759CFF"
        />
        <path
          d="M7.50728 3.27505C7.61659 3.27505 7.72755 3.24949 7.83123 3.19563C7.87303 3.17386 12.0601 1.04718 16.1896 3.19549C16.5345 3.37503 16.9594 3.24085 17.1387 2.89598C17.3181 2.55115 17.184 2.12628 16.8392 1.94692C14.8606 0.917598 12.5654 0.589987 10.2021 0.999559C8.42579 1.30742 7.23198 1.92084 7.18206 1.94678C6.83718 2.12605 6.70296 2.55091 6.88218 2.89579C7.00758 3.13694 7.25304 3.27505 7.50728 3.27505Z"
          fill="#759CFF"
        />
        <path
          d="M9.87325 6.75575C9.51238 6.90006 9.33569 7.31006 9.48005 7.67098C9.59016 7.94623 9.85387 8.11391 10.133 8.11391C10.2198 8.11391 10.308 8.09768 10.3936 8.06347C10.41 8.05705 12.0467 7.43068 13.6265 8.06249C13.9873 8.20685 14.3969 8.03134 14.5412 7.67051C14.6856 7.30964 14.5101 6.90006 14.1493 6.75575C12.0355 5.91019 9.96061 6.72085 9.87325 6.75575Z"
          fill="#759CFF"
        />
        <path
          d="M15.0129 5.61251C15.2915 5.61251 15.5552 5.446 15.6658 5.17178C15.8112 4.81133 15.6369 4.40129 15.2764 4.25594C12.0476 2.95371 8.87807 4.20218 8.74474 4.25594C8.3849 4.4011 8.21112 4.80987 8.35548 5.17004C8.49984 5.53022 8.90922 5.70526 9.26977 5.56184C9.29731 5.55081 12.0597 4.47612 14.7499 5.56123C14.8362 5.59608 14.9253 5.61251 15.0129 5.61251Z"
          fill="#759CFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_8635_39719">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
