type IconProps = {
  color? : string
}
const IconList :React.FC<IconProps> = ({
  color = "#333333"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M13.25 9.91675V12.0001C13.25 12.4421 13.0744 12.866 12.7618 13.1786C12.4493 13.4912 12.0254 13.6667 11.5833 13.6667H2.41667C1.97464 13.6667 1.55072 13.4912 1.23816 13.1786C0.925595 12.866 0.75 12.4421 0.75 12.0001V2.83341C0.75 2.39139 0.925595 1.96746 1.23816 1.6549C1.55072 1.34234 1.97464 1.16675 2.41667 1.16675L4.91667 1.16675"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.58301 7.8335L12.833 1.5835"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 1.16675H13.25V6.16675"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconList;
