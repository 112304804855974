const IconDate = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_8635_37795"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99979 11.2498H7.49979V8.74977H4.99979V11.2498ZM4.99979 14.9998H7.49979V12.4998H4.99979V14.9998ZM8.74977 11.2498H11.2498V8.74977H8.74977V11.2498ZM8.74977 14.9998H11.2498V12.4998H8.74977V14.9998ZM12.4998 11.2498H14.9998V8.74977H12.4998V11.2498ZM12.4998 14.9998H14.9998V12.4998H12.4998V14.9998ZM17.5002 7.50016C17.5002 6.81016 16.9402 6.25015 16.2502 6.25015H3.75017C3.06017 6.25015 2.50017 6.81016 2.50017 7.50016V16.2502C2.50017 16.9402 3.06017 17.5002 3.75017 17.5002H16.2502C16.9402 17.5002 17.5002 16.9402 17.5002 16.2502V7.50016ZM17.5 20H2.5C1.12 20 0 18.8813 0 17.5V2.5C0 1.11875 1.12 0 2.5 0H5V1.25C5 1.94 5.56 2.5 6.25 2.5C6.94 2.5 7.5 1.94 7.5 1.25V0H12.5V1.25C12.5 1.94 13.06 2.5 13.75 2.5C14.44 2.5 15 1.94 15 1.25V0H17.5C18.8813 0 20 1.11875 20 2.5V17.5C20 18.8813 18.8813 20 17.5 20V20Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_8635_37795)">
        <rect width="19.8375" height="19.8375" fill="#E0E1E2" />
      </g>
    </svg>
  );
};

export default IconDate;
