import Button from "../../../components/materials/Button"
const DetailInquiry = () => {
  return (
    <div className="py-8 px-10 bg-white border border-grey-1 rounded-2xl">
      <div className="grid gap-4">
        <div className="flex items-center gap-2 text-sm">
          <div className="text-grey-3 font-medium">문의</div>
          <div className="text-grey-1 "> {">"} </div>
          <div className="text-grey-6 font-bold">문의 상세</div>
        </div>
        <div className=" text-xl text-grey-8 font-bold">
        학습시간 변경 안내 공지
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2 text-sm">
            <div className="text-grey-7 font-bold">작성자</div>
            <div className="text-xs text-grey-1 font-medium">|</div>
            <div className="text-grey-6 font-medium">작성자</div>
          </div>
          <div className="flex items-center gap-2 text-sm">
            <div className="text-grey-7 font-bold">문의종류</div>
            <div className="text-xs text-grey-1 font-medium">|</div>
            <div className="text-grey-4 font-medium">학습문의</div>
          </div>
          <div className="flex items-center gap-2 text-sm">
            <div className="text-grey-7 font-bold">작성일</div>
            <div className="text-xs text-grey-1 font-medium">|</div>
            <div className="text-grey-4 font-medium">23.02.04 23:59</div>
          </div>
          <div className="flex items-center gap-2 text-sm">
            <div className="text-grey-7 font-bold">이메일</div>
            <div className="text-xs text-grey-1 font-medium">|</div>
            <div className="text-grey-4 font-medium">tads@chappy.com</div>
          </div>
        </div>
        <div className="bg-grey-1 h-[1px]"></div>
        <div className="w-[301px] text-grey-6 text-sm font-medium">
          <div>
            안녕하세요 학생여러분, 김강사 입니다.날씨가 점점 추워지는데 몸
            관리에 유의하시길 바랍니다
          </div>
          <div>수업 관련 공지가 있어 안내 드립니다. </div>
          <div>
            안녕하세요 학생여러분, 김강사 입니다.날씨가 점점 추워지는데 몸
            관리에 유의하시길 바랍니다.수업 관련 공지가 있어 안내 드립니다.
            학습시간을 9시에서 11시로 변경하도록 하겠습니다. 감사합니다.
          </div>
          <div>감사합니다</div>
        </div>
        <div className="bg-grey-1 h-[1px]"></div>
        <div className="flex justify-end">
          <div className="w-[89px]">
            <Button size="sm" variant="grey">
            목록으로 이동 
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailInquiry;
