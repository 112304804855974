import { editor } from "monaco-editor";
import Editor from "@monaco-editor/react";
import { useRef, ChangeEvent } from "react";
import { useEditorContext } from "../../../context/editorContext";
import IconFolder from "../../../components/Icons/iconFolder";
import IconRefresh from "../../../components/Icons/iconRefresh";
import IconCopy from "../../../components/Icons/iconCopy";
import IconDownload from "../../../components/Icons/iconDownload";
import IconPlay from "../../../components/Icons/iconPlay";
import IconChecklist from "../../../components/Icons/iconChecklist";
import IconSend from "../../../components/Icons/iconSend";
import Button from "../../../components/materials/Button";
import IconPlus from "../../../components/Icons/iconPlus";
import { CODE_SNIPPETS } from "../constants";

const EditorComponent = () => {
  const {
    isDark,
    languange,
    copyCode,
    value,
    setValueEditor,
    runCode,
    uploadFile,
    acceptedFile,
    downloadCode,
  } = useEditorContext();
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  function handleEditorDidMount(editor: editor.IStandaloneCodeEditor) {
    editorRef.current = editor;
  }

  const openFile = function () {
    document.getElementById("upload")?.click();
  };

  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    const files = e.currentTarget.files;
    const file = files![0];
    if (file) {
      uploadFile(file);
    }
  };

  const onCopy = function () {
    copyCode();
  };

  return (
    <div className="relative overflow-hidden">
      <div className="flex items-center justify-between px-5 text-xl font-bold h-14 text-grey-6 bg-grey-1 dark:bg-grey-8 dark:text-grey-4">
        <input
          accept={acceptedFile}
          onChange={(e) => handleChange(e)}
          type="file"
          name="upload"
          id="upload"
          className="hidden"
        />
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-3">
            <div onClick={openFile} className="cursor-pointer">
              <IconFolder color={`${isDark ? "white" : "#555555"}`} />
            </div>
            <IconRefresh />
            <div className="cursor-pointer" onClick={onCopy}>
              <IconCopy />
            </div>
            <div onClick={downloadCode} className="cursor-pointer">
              <IconDownload />
            </div>
          </div>
          <div className="flex items-center gap-3">
            <div className="w-[94px]">
              {/* practice */}
              <Button onClick={runCode} size="sm" variant="grey">
                <div className="flex items-center justify-center gap-2">
                  <IconPlay />
                  실행
                </div>
              </Button>
            </div>
            <div className="w-[94px]">
              {/* grading , marking */}
              <Button size="sm" variant="grey">
                <div className="flex items-center justify-center gap-2">
                  <IconChecklist />
                  채점
                </div>
              </Button>
            </div>
            <div className="w-[94px]">
              {/* submission */}
              <Button onClick={runCode} size="sm" variant="primary">
                <div className="flex items-center justify-center gap-2">
                  <IconSend />
                  제출
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Editor
        theme={`${isDark ? "vs-dark" : "vs-light"}`}
        language={`${languange ? languange : "javascript"}`}
        defaultValue="// some comment"
        value={value}
        onChange={(value) => setValueEditor(value)}
        onMount={(e) => handleEditorDidMount(e)}
      />
      <div
        className="
        absolute bottom-0 w-full
        flex items-center gap-2 h-14
         text-grey-6 text-xl font-bold bg-white
        dark:bg-[#1e1e1e] dark:text-grey-4
      "
      >
        <div className="h-full">
          <div className="flex items-center h-full px-4 text-sm font-bold text-grey-7 dark:text-white bg-grey-3 dark:bg-grey-9">
            Page 1
          </div>
        </div>
        <IconPlus />
      </div>
    </div>
  );
};

export default EditorComponent;
