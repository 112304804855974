import IconPlay from "./iconPlay";
import DummyImage from "../../../assets/dummyLectures.png";
import { useState } from "react";
import { ArrowDown2 } from "iconsax-react";
const Curriculum = () => {
  const items = [0, 1, 2];
  const [value, setValue] = useState(null);

  const handleClick = function (e: any, idx: any) {
    if (idx === value) {
      setValue(null);
      e.target.checked = false;
    } else {
      setValue(idx);
    }
  };
  return (
    <div >
      <div  className="text-grey-8 font-bold text-xl">커리큘럼</div>
      <div className="text-grey-3 text-sm font-medium mt-2 mb-7">
        총 20강 | 23시간
      </div>
      <ul className="grid">
        {items.map((item) => {
          return (
            <li key={item} className="p-4 relative">
              <input
                id={`${item}`}
                type="radio"
                name="radio"
                onClick={(e) => handleClick(e, item)}
                className="peer absolute top-0 opacity-0"
              />
              <label
                htmlFor={`${item}`}
                className="
                  transition-all flex rounded-xl py-3 px-7 items-center gap-4 relative 
                  peer-checked:bg-blue-3 bg-[#F9F9F9] text-grey-6 peer-checked:text-white "
              >
                <IconPlay  
                  color={item === value ? '#759CFF' : '#A5A5A5'} 
                  fill={item === value ? '#759CFF' : ''}
                />
                <div className=" text-sm font-bold">CHAPTER 1</div>
              </label>
              <div
                className={`
                    transition-transform duration-500
                    peer-checked:rotate-180  rotate-0
                    absolute top-7 right-6 flex items-center
                    peer-checked:text-white
                  `}
              >
                <ArrowDown2 size={20} />
              </div>
              <div
                className="w-full overflow-hidden transition-all duration-500
                  max-h-0 mt-2 peer-checked:max-h-screen
                "
              >
                <div className="h-[245px] overflow-hidden rounded-xl">
                  <img src={DummyImage} alt="" />
                </div>
                <div className="mb-3 flex items-center gap-2">
                  <div className="text-grey-3 text-sm font-bold">01.</div>
                  <div className="text-gre-8 text-sm font-bold">
                    JavaScript 개념 강의
                  </div>
                  <div className="text-blue-3 text-xs font-medium border border-blue-2 rounded-[4px] p-2">
                    미리보기{" "}
                  </div>
                </div>
                <div
                  className="
              text-grey-6 text-xs font-medium"
                >
                  강의에 대한 요약 설명이 들어갑니다. 더미 텍스트 입니다. 글로벌
                  IT 기업 UX디자이너의 업무 프로세스와 더미 텍스트 입니다.
                  확실한 도움을 드리겠습니다. 저와 같은 상황인 분들을 진심으로
                  돕겠습니다. 저와 같은 상황인 분들을 진심으로 돕겠습니다.글로벌
                  IT 기업 UX디자이너의 업무 프로세스와 커리어 상담과 취업준비를
                  하시는데 확실한 도움을 드리겠습니다.
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Curriculum;
