import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export default function LectureRegisrationPractice() {
  const [selectedOption, setSelectedOption] = useState("공개");
  const [selectedOption2, setSelectedOption2] = useState("비공개");
  const [testCases, setTestCases] = useState<
    { input: string; output: string }[]
  >([]);

  const handleRadioChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOption(event.target.value);
  };

  const handleAddTestCase = () => {
    setTestCases([...testCases, { input: "", output: "" }]);
  };

  const handleDeleteTestCase = (index: number) => {
    const updatedTestCases = [...testCases];
    updatedTestCases.splice(index, 1);
    setTestCases(updatedTestCases);
  };

  const handleInputChange = (
    index: number,
    value: string,
    type: "input" | "output"
  ) => {
    const updatedTestCases = [...testCases];
    updatedTestCases[index][type as keyof (typeof updatedTestCases)[number]] =
      value;
    setTestCases(updatedTestCases);
  };
  const [content, setContent] = useState("");

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <div className="min-h-screen bg-white p-6 rounded-lg">
      <div className="max-w-full px-10 py-8 bg-white rounded-2xl flex-col justify-start items-start gap-7 flex">
        <div className="text-center text-[#242424] text-xl font-bold font-['Pretendard'] leading-7">
          실습 IDE
        </div>
        <div className="w-full flex flex-col gap-3">
          <div className="text-[#242424] text-base font-bold">기본정보</div>
          <div className="w-full p-7 bg-white border-t border-[#e0e0e0] flex flex-col gap-4">
            <div className="w-full flex flex-col gap-2">
              <label className="text-[#242424] text-base font-bold">
                구성 명
              </label>
              <input
                type="text"
                placeholder="강의명을 입력해 주세요"
                className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] placeholder-gray-400 text-base"
              />
            </div>

            <div className="w-full flex gap-5">
              <div className="w-1/2 flex flex-col gap-2">
                <label className="text-[#242424] text-base font-bold">
                  차시 선택
                </label>
                <select className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-base">
                  <option value="" disabled selected>
                    차시를 선택해 주세요
                  </option>
                  <option value="1">차시 1</option>
                  <option value="2">차시 2</option>
                </select>
              </div>
              <div className="w-1/2 flex flex-col gap-2">
                <label className="text-[#242424] text-base font-bold">
                  출석 인정
                </label>
                <select className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-base">
                  <option value="" disabled selected>
                    인정
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="w-1/2 flex flex-col gap-2">
                <label className="text-[#242424] text-base font-bold">
                  출석 인정 기한
                </label>
                <input
                  type="date"
                  className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-base"
                />
              </div>
            </div>

            <div className="w-full flex  gap-2">
              <div className="flex flex-col w-full">
                <label className="text-[#242424] text-base font-bold">
                  강의 영상 첨부
                </label>
                <input
                  type="text"
                  placeholder="동영상 파일 혹은 URL을 입력해 주세요"
                  className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] placeholder-gray-400 text-base"
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-[#242424] text-base font-bold">
                  강의 영상 첨부
                </label>
                <input
                  type="text"
                  placeholder="동영상 파일 혹은 URL을 입력해 주세요"
                  className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] placeholder-gray-400 text-base"
                />
              </div>
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="text-[#242424] text-base font-bold">
                공개여부
              </label>
              <div className="flex items-center gap-4">
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="visibility"
                    value="공개"
                    checked={selectedOption === "공개"}
                    onChange={handleRadioChange}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    공개
                  </span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="visibility"
                    value="비공개"
                    checked={selectedOption2 === "비공개"}
                    onChange={handleRadioChange}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    비공개
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-3">
          <div className="text-[#242424] text-base font-bold pb-2">
            강의 노트
          </div>
          <div className="mb-6">
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={modules}
              placeholder="내용을 입력해주세요"
            />
          </div>
        </div>

        <div className="self-stretch flex flex-col justify-end gap-7">
          <div className="w-full flex flex-col gap-3">
            <div className="justify-start items-center gap-2 flex">
              <div className="text-center text-[#242424] text-base font-bold font-['Pretendard'] leading-snug">
                문제입력
              </div>
            </div>
            <div className="w-full h-[272px] px-10 py-7 bg-white border-t border-[#e0e0e0] flex flex-col gap-5">
              <textarea
                placeholder="TextArea"
                className="w-full h-[169px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-[#242424]"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col justify-end gap-7">
          <div className="w-full flex flex-col gap-3">
            <div className="justify-start items-center gap-2 flex">
              <div className="text-center text-[#242424] text-base font-bold font-['Pretendard'] leading-snug">
                문제입력
              </div>
            </div>
            <div className="w-full h-[272px] px-10 py-7 bg-white border-t border-[#e0e0e0] flex flex-col gap-5">
              <textarea
                placeholder="TextArea"
                className="w-full h-[169px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-[#242424]"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col justify-end gap-7">
          <div className="w-full flex flex-col gap-3">
            <div className="justify-start items-center gap-2 flex">
              <div className="text-center text-[#242424] text-base font-bold font-['Pretendard'] leading-snug">
                문제입력
              </div>
            </div>
            <div className="w-full h-[272px] px-10 py-7 bg-white border-t border-[#e0e0e0] flex flex-col gap-5">
              <textarea
                placeholder="TextArea"
                className="w-full h-[169px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-[#242424]"
              />
            </div>
          </div>
        </div>

        {/* <div className="self-stretch flex flex-col justify-end gap-7">
          <div className="w-full flex flex-col gap-3">
            <div className="justify-start items-center gap-2 flex">
              <div className="text-center text-[#242424] text-base font-bold font-['Pretendard'] leading-snug">
                테스트케이스
              </div>
            </div>
            <div className="w-full h-[272px] px-10 py-7 bg-white border-t border-[#e0e0e0] flex flex-col gap-5">
              <div className="flex items-center gap-4">
                <label className="text-[#242424] text-base font-bold mr-4">
                  공개여부
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="visibility"
                    value="공개"
                    checked={selectedOption === "공개"}
                    onChange={handleRadioChange}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    공개
                  </span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="visibility"
                    value="비공개"
                    checked={selectedOption2 === "비공개"}
                    onChange={handleRadioChange2}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    비공개
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div> */}

        <div className="w-full">
          <div className="w-full justify-between items-center flex gap-2">
            <div className="flex">
              <label className="text-[#242424] text-base font-bold mr-4">
                공개여부
              </label>
              <div className="flex items-center gap-4">
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="visibility"
                    value="공개"
                    checked={selectedOption === "공개"}
                    onChange={handleRadioChange}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    공개
                  </span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="visibility"
                    value="비공개"
                    checked={selectedOption === "비공개"}
                    onChange={handleRadioChange}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    비공개
                  </span>
                </label>
              </div>
            </div>

            {selectedOption === "공개" && (
              <div className="flex justify-end">
                <button
                  onClick={handleAddTestCase}
                  className={` py-2 px-4 border border-blue-500 text-blue-500 rounded`}
                >
                  테스트 케이스 추가 +
                </button>
              </div>
            )}
          </div>

          {selectedOption === "공개" && (
            <div className="mt-4">
              {testCases.map((testCase, index) => (
                <div key={index} className="flex gap-2 mb-2">
                  <input
                    type="text"
                    placeholder="입력"
                    value={testCase.input}
                    onChange={(e) =>
                      handleInputChange(index, e.target.value, "input")
                    }
                    className="border p-2 rounded flex-1"
                  />
                  <input
                    type="radio"
                    name="visibility"
                    value={testCase.input}
                    onChange={handleRadioChange}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <button
                    onClick={() => handleDeleteTestCase(index)}
                    className="text-red-500"
                  >
                    삭제
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="w-full flex justify-end">
          <button className="px-6 py-3 bg-[#2861EF] text-white rounded-xl text-base font-medium">
            등록
          </button>
        </div>
      </div>
    </div>
  );
}
