const IconNaver = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="117"
      height="32"
      viewBox="0 0 117 32"
      fill="none"
    >
      <path
        d="M0 5.0957H8.24232C8.24232 5.0957 15.7179 16.4049 15.9415 16.6925C16.1652 16.98 16.1971 16.6925 16.1971 16.6925C15.8457 14.9354 15.4623 14.1686 15.4623 11.3573V5.0957H23.6727V26.021H15.4623C15.4623 26.021 8.14647 15.3187 7.92285 15.0312C7.69922 14.7437 7.66727 15.0312 7.66727 15.0312C7.95479 16.4688 8.21037 16.7244 8.21037 19.2163V26.021H0V5.0957Z"
        fill="#C3C3C3"
      />
      <path
        d="M36.6107 18.4176L37.7288 14.1686C37.9844 13.3061 38.1442 12.0601 38.1442 12.0601C38.1761 11.8685 38.4317 11.8685 38.4956 12.0601C38.5595 12.3477 38.6872 13.2422 38.9428 14.1367L40.0929 18.4176H36.6107ZM35.0453 23.4013H41.5305L42.2973 26.021H51.3702L42.9681 5.0957H33.4799L25.2695 26.021H34.3425L35.0453 23.4013Z"
        fill="#C3C3C3"
      />
      <path
        d="M72.9983 5.0957L63.8614 26.021H56.6095L47.4727 5.0957H56.4178L59.5486 14.6159C59.9 15.6382 60.1556 17.9064 60.1556 17.9064C60.2195 18.0981 60.4112 18.0662 60.4431 17.9064C60.4431 17.9064 60.6987 15.6382 61.0501 14.6159L64.0531 5.0957H72.9983Z"
        fill="#C3C3C3"
      />
      <path
        d="M74.7871 5.0957H90.6647V10.8142H83.4447V12.7949H90.0258V18.2259H83.4447V20.3025H90.9203V26.021H74.7871V5.0957Z"
        fill="#C3C3C3"
      />
      <path
        d="M102.806 10.7185H103.477C104.947 10.7185 106.225 10.9102 106.225 12.5075C106.225 14.0729 104.819 14.2646 103.413 14.2646H102.806V10.7185ZM94.4043 25.9253H102.806V19.2164C102.806 19.2164 102.87 18.9928 103.03 19.2164L106.736 25.9253H116.639L111.496 19.1206C111.017 18.5136 110.218 18.0024 110.058 17.8746C109.867 17.7468 110.058 17.7149 110.058 17.7149C113.253 16.7565 114.627 15.4467 114.627 12.2839C114.627 7.01265 110.122 5 104.787 5H94.4043V25.9253Z"
        fill="#C3C3C3"
      />
    </svg>
  );
};

export default IconNaver
