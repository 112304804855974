import Button from "../../../components/materials/Button";
import IconReunlock from "../iconReunlock";
import { DiffEditor } from "@monaco-editor/react";
import { useEditorContext } from "../../../context/editorContext";
import { useRef } from "react";
import { editor } from "monaco-editor";
const DiffEditorComponent = () => {
  const { 
    isDark, 
    languange, 
    copyCode,
    value,
    setValueEditor, 
    runCode, 
    uploadFile, 
    acceptedFile ,
    downloadCode,
    modified

  } = useEditorContext();
  const editorRef = useRef<editor.IStandaloneDiffEditor | null>(null);
  function handleEditorDidMount(editor: editor.IStandaloneDiffEditor) {
    editorRef.current = editor;
  }
  return (
    <div className="relative overflow-hidden">
      <div
        className="
        flex items-center justify-between h-14
        px-5 text-grey-6 text-xl font-bold bg-grey-1
        dark:bg-grey-8 dark:text-grey-4
      "
      >
        <div className="flex justify-between items-center w-full">
          <div className="">정답비교</div>
          <div className="w-[94px]">
            <Button size="sm" variant="primary">
              <div className="flex justify-center items-center gap-2">
                <IconReunlock />
                제출
              </div>
            </Button>
          </div>
        </div>
      </div>
      <DiffEditor
        theme={`${isDark ? "vs-dark" : "vs-light"}`}
        language={`${languange ? languange : 'javascript'}`}
        original={value}
        modified={modified}
        onMount={(e) => handleEditorDidMount(e)}
        options={{ renderSideBySide: false }}
      />
    </div>
  );
};

export default DiffEditorComponent;
