/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import PracticeImg from "../../../../assets/practice-img.png";

export default function Practice() {
  return (
    <div className="w-full h-auto flex justify-center items-center mt-20 py-10">
      <div className="flex flex-col justify-center items-center gap-4 text-center">
        <div className="flex justify-center items-center">
          <img className="w-44 h-44 object-cover" src={PracticeImg} />
        </div>
        <div className="w-full max-w-xs h-10 px-6 py-2.5 bg-[#2860ef] rounded-lg flex justify-center items-center">
          <button className="text-white text-sm font-bold">
            IDE 실습하러가기
          </button>
        </div>
      </div>
    </div>
  );
}
