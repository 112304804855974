import IconTick from "../../components/Icons/iconTick";
import GraphImage from "../../assets/graph.png";
import ChartImage from "../../assets/chart.png";
const TrainingPlatform = () => {
  return (
    <div className="2xl:px-72 px-40  py-36 bg-bg_2 flex justify-between ">
      <div>
        <div className="grid gap-8">
          <div className="text-blue-5 text-xl font-bold">
            올인원 교육 플랫폼
          </div>
          <div className="w-[285px] text-grey-9 text-4xl font-bold">
            학습관리와  코딩교육을 한번에
          </div>
          <div className="grid gap-2">
            <div className="flex items-center gap-4">
              <IconTick />
              <div className="text-grey-6 font-medium text-lg">
                학습관리와 코딩교육을 한번에
              </div>
            </div>
            <div className="flex items-center gap-4">
              <IconTick />
              <div className="text-grey-6 font-medium text-lg">
                채피 IDE와 학습 컨텐츠 연동
              </div>
            </div>
            <div className="flex items-center gap-4">
              <IconTick />
              <div className="text-grey-6 font-medium text-lg">
                강의 컨텐츠 관리
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="relative">
          <img src={GraphImage} alt="" />
          <img className="absolute top-[40%] -left-[18%]" src={ChartImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default TrainingPlatform;
