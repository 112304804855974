import React, { useState } from "react";
import { ArrowDown2 } from "iconsax-react";

type Option = {
  label: string;
  value: string;
  size?: number;
};

type SelectDropdownProps = {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  size?: string;
};

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  options,
  value,
  onChange,
  placeholder = "Select an option",
  size,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(value);

  const handleSelect = (optionValue: string) => {
    setSelectedOption(optionValue);
    onChange(optionValue);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        type="button"
        className={`w-full
          ${
            size ? size : "py-2 px-2 rounded-md"
          } text-grey-6 font-medium has-[:disabled]:bg-[#F4F4F4] 
        border bg-white border focus:outline-none focus:border-primary flex items-center justify-between text-xs text-grey-7 gap-2`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-grey-7">
          {options.find((option) => option.value === selectedOption)?.label ||
            placeholder}
        </span>
        <ArrowDown2 size={16} color="gray" />
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
          <ul className="max-h-60 overflow-auto">
            {options.map((option) => (
              <li
                key={option.value}
                className={`cursor-pointer px-2 py-1 text-xs hover:bg-gray-100 ${
                  option.value === selectedOption ? "bg-gray-200" : ""
                }`}
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
