import { Setting2 } from "iconsax-react";
import { useState, useContext } from "react";
import useClickOutside from "../../../hook/useClickOutside";
import { useEditorContext } from "../../../context/editorContext";
import Select from "./select";
import { dummyFont, dummySize } from "./data";
const Setting = () => {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState<(typeof dummyFont)[0] | undefined>(
    dummyFont[0]
  );
  const [sizeValue, setSizeValue] = useState<(typeof dummySize)[0] | undefined>(
    dummySize[0]
  );
  const { setThemeEditor } = useEditorContext();
  const clickHandler = function () {
    setOpen(!isOpen);
  };
  const closeMenu = function () {
    setOpen(false);
  };

  const themneHandler = function (status: boolean) {
    closeMenu();
    setThemeEditor(status);
  };

  const ref = useClickOutside(closeMenu);
  return (
    <div ref={ref} className="text-grey-3 cursor-pointer relative">
      <Setting2 onClick={clickHandler} />
      {isOpen && (
        <div
          className="
          right-0 dark:bg-grey-8 dark:border-grey-7
          absolute z-30 p-5 w-60 top-full rounded-xl bg-bg_4 border border-grey-1
      "
        >
          <div className="text-grey-7 dark:text-grey-1 mb-5 text-base font-bold">
            환경설정
          </div>
          {/* screen mode */}
          <div className="grid  gap-4">
            <div>
              <div className="mb-2  text-grey-3 font-bold text-sm">
                화면모드
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div
                  onClick={() => themneHandler(true)}
                  className="
                text-grey-7 text-xs font-bold
                border-grey-2 border bg-grey-1 rounded-lg
                  flex justify-center items-center py-2
                  dark:bg-white dark:text-grey-7
              "
                >
                  Dark
                </div>
                <div
                  onClick={() => themneHandler(false)}
                  className="
                text-grey-7 text-xs font-bold
                  border bg-grey-3 rounded-lg border-grey-4
                  flex justify-center items-center py-2
                  dark:bg-grey-6 dark:text-grey-4
                "
                >
                  Light
                </div>
              </div>
            </div>

            <div>
              {/* font setting */}
              <div className="mb-2 text-grey-3 font-bold text-sm">폰트설정</div>
              <div className="grid gap-2 grid-cols-[65%_35%]">
                <Select
                  value={value}
                  options={dummyFont}
                  onChange={(o) => setValue(o)}
                />
                <Select
                  value={sizeValue}
                  options={dummySize}
                  onChange={(o) => setSizeValue(o)}
                />
              </div>
            </div>
            <div>
              <div className="mb-2 text-grey-3 font-bold text-sm">언어설정</div>
              <Select
                value={value}
                options={dummyFont}
                onChange={(o) => setValue(o)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Setting;
