import Input from "../../components/materials/Input"
import Button from "../../components/materials/Button"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'

const findMyIdFormSchema = z.object({
  name : z.string().min(1),
  phone: z.string().refine((value) => /^01([0])([0-9]{4})([0-9]{4})$/.test(value ?? ""), 'Name should contain only alphabets'),
  authenticationNumer : z.string().min(1)
})

type FindMyIdType = z.infer<typeof findMyIdFormSchema>
const FindMyId = () => {
  const { register, handleSubmit, formState , getFieldState } = useForm<FindMyIdType>({
    resolver: zodResolver(findMyIdFormSchema),
    mode : 'all'
  })
  const navigate = useNavigate()
  const [isCertified , setIsCertified] = useState<Boolean>(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [timer, setTimer] = useState<string>('')
  const [variant, setVariant] = useState('default')

  const { invalid } = getFieldState('phone',formState)

  const onSubmit = handleSubmit((values) => {
    console.log(values)
    navigate('/information-exist')
  })

  const handleCertified = function () {
    setIsCertified(true)
    setTimer("02:03")
    setVariant('timer')
  } 

  useEffect(() => {
    if(!formState.dirtyFields.phone){
      setIsDisabled(true)
    }else{
      if(invalid) {
        setIsDisabled(true)
      }else{
        setIsDisabled(false)
      }
    }
  }, [invalid, formState])

  return (
    <div className="bg-blue-1 h-[calc(100vh-4rem)] flex items-center justify-center">
      <div className="p-12 w-[440px] rounded-[20px] bg-white border border-grey-1">
        <form onSubmit={onSubmit}>
          <div className="text-center text-grey-9 text-2xl font-bold mb-12">아이디(이메일) 찾기</div>
          <Input 
            {...register('name')}
            isRequired id="name" label="이름" placeholder="이름" />
          <div className="grid grid-cols-[1fr_90px] items-end gap-2.5 mt-7 mb-8">
            <Input 
              isRequired 
              id="phone" 
              label="휴대폰 번호" 
              placeholder="휴대폰 번호" 
              variant={variant}
              timer={timer}
              {...register('phone')}
            />
            <div>
              <Button 
                onClick={handleCertified} 
                type="button" 
                disabled={isDisabled} 
                variant="outline_primary"
              >
                인증 받기 
              </Button>

            </div>
            
          </div>
          {
            isCertified &&
            <Input 
              isRequired 
              id="authenticationNumber" 
              label="인증번호" 
              placeholder="인증번호" 
              type="text"
              {...register('authenticationNumer')}
            />
          }
          <div className="mb-16"></div>
          <Button disabled={!formState.isValid} type="submit"  variant="outline_primary">다음</Button>
        </form>
      </div>
    </div>
  )
}

export default FindMyId