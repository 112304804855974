import React from "react";
import EmptyState from "../../../components/emptyState";
import EmptyGrades from "../../../assets/empty-grades.png";

interface PerformanceItem {
  status: string;
  course: string;
  weeks: number;
  type: string;
  icon?: React.ReactNode;
}

const performanceData: PerformanceItem[] = [];

function PerformanceCheck() {
  return (
    <div className="bg-white rounded-lg flex flex-col p-2 ">
      <h3 className="text-lg font-bold mb-2 border-b pb-2">성적확인</h3>
      <ul>
        {performanceData.length === 0 ? (
          <EmptyState
            imgSrc={EmptyGrades}
            title="제출 기록이 없어요"
            description="강의를 복습하며 과제를 제출해 보아요"
            py="py-10"
          />
        ) : (
          <ul>
            {performanceData.map((question, index) => (
              <li
                key={index}
                className="flex items-center justify-between py-2"
              >
                <div className="flex items-center">
                  <span className="flex items-center justify-center h-8 text-xs rounded text-primary mr-4">
                    {question.status}
                  </span>
                  <div>
                    <p className="text-gray-900 text-sm gap-2">
                      <span className="font-bold">{question.course}</span>{" "}
                      <span className="text-gray-200">|</span> {question.weeks}
                      <span
                        className={`w-8 h-6 text-xs rounded ml-1 ${
                          question.status === "답변 완료"
                            ? "bg-blue-100  border-blue-500 text-blue-500"
                            : "bg-gray-100 border text-gray-500"
                        }`}
                      >
                        {question.type}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="text-grey-3 text-xl">{question.icon}</div>
              </li>
            ))}
          </ul>
        )}
      </ul>
    </div>
  );
}

export default PerformanceCheck;
