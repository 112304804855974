/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import Button from "../../../components/materials/Button";
import { ArrowRight2, MedalStar } from "iconsax-react";
import Modal from "../../../components/modal";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../assets/avatar.png";
import Start from "../../../assets/start.png";
import Comment from "../../../assets/comment.png";
import CourseImg from "../../../assets/course-image.png";

type CourseCardProps = {
  id?: number;
  title: string;
  instructor: string;
  instructorImage: string;
  progress?: number;
  status: string;
  slug?: string;
  category: string;
  startDate: string;
  endDate: string;
  badge: string;
  src: string;
};

const CourseCardProfessor: React.FC<CourseCardProps> = ({
  id,
  title,
  instructor,
  instructorImage,
  progress,
  status,
  slug,
  category,
  startDate,
  endDate,
  badge,
  src,
}) => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [agree, setAgree] = useState(false);
  const [showModalTerminate, setShowModalTerminate] = useState(false);
  const [showModalConfimTerminate, setShowModalConfirmTerminate] =
    useState(false);
  const [showModalCompletion, setShowModalCompletion] = useState(false);

  const openModalTerminate = () => setShowModalTerminate(true);
  const closeModalTerminate = () => setShowModalTerminate(false);
  const openModalConfirmTerminate = () => setShowModalConfirmTerminate(true);
  const closeModalConfirmTerminate = () => setShowModalConfirmTerminate(false);
  const openModalCompletion = () => setShowModalCompletion(true);
  const closeModalCompletion = () => setShowModalCompletion(false);

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const handleReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReview(e.target.value);
  };

  const handleAgreeChange = () => {
    setAgree(!agree);
  };

  const handleSubmit = () => {
    console.log("Review Submitted");
    closeModalTerminate();
  };

  return (
    <div className="bg-gray-50 border border-gray-100 rounded-lg overflow-hidden p-4">
      <img src={src} alt="Course" className="w-full " />
      <div className="">
        <h3 className="text-lg font-bold mt-2">{title}</h3>
        <p className="text-xs text-gray-500">{`${startDate} ~ ${endDate}`}</p>
        <div className="flex items-center my-4">
          <img
            src={instructorImage}
            alt={instructor}
            className="w-6 h-6 rounded-full mr-2"
          />
          <p className="text-sm text-gray-500">{instructor}</p>
          <span className="ml-auto bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded">
            {badge}
          </span>
        </div>
        {progress && (
          <div className=" bg-white rounded-lg mb-4 p-2 px-4">
            <p className="text-sm text-center border-b font-semibold mb-1 p-2 ">
              진도율
            </p>

            <div className="flex justify-center gap-2 items-center mb-1">
              <p className="text-sm font-bold text-blue-600">{`${progress}%`}</p>
              <p className="text-sm text-gray-500 mt-1">{status} 😆</p>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-2">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        )}
        {category === "in-progress" ? (
          <Button size="sm" variant="primary">
            <div
              className="flex justify-center items-center gap-1"
              onClick={() => {
                navigate(`/classroom/${id}`);
              }}
            >
              수업 바로가기 <ArrowRight2 size="16" />
            </div>
          </Button>
        ) : category === "termination" ? (
          <>
            <Button size="sm" variant="primary">
              <div className="flex justify-center items-center gap-1">
                수업 바로가기
              </div>
            </Button>
            {showModalTerminate && (
              <Modal
                title="수업 평가"
                onClose={() => {
                  closeModalTerminate();
                }}
                size="lg"
              >
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-3">강의정보</h3>
                  <div className="grid grid-cols-[40%_60%] gap-4 items-center">
                    <img
                      src={src}
                      alt="Course"
                      className="w-full h-36 rounded-lg"
                    />
                    <div className=" space-y-2">
                      <p className="text-xl font-bold border-b pb-2">{title}</p>
                      <p className="text-md text-gray-500">
                        <span className="font-semibold">수강일자: </span>
                        {`${startDate} ~ ${endDate}`}
                      </p>
                      <p className="text-md text-gray-500">
                        <span className="font-semibold">강사: </span>
                        {instructor}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-3">별점 선택</h3>
                  <div className="flex flex-col items-center">
                    <p className="text-gray-500 font-semibold mb-3">
                      강의는 어땠나요?
                    </p>
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <button key={star} onClick={() => handleRating(star)}>
                          {star <= rating ? (
                            <MedalStar
                              size="32"
                              variant="Bold"
                              color="#2861EF"
                              className="mb-3 mx-2"
                            />
                          ) : (
                            <MedalStar size="32" className="mb-3 mx-2" />
                          )}
                        </button>
                      ))}
                    </div>
                    <p className="text-grey-3 mb-3">별점을 선택해주세요</p>
                  </div>
                </div>
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-3">내용 작성</h3>
                  <textarea
                    value={review}
                    onChange={handleReviewChange}
                    rows={4}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="강사님이 너무 잘 가르쳐 주셔서 코딩에 대해 많이 알게 되었습니다 ㅎㅎ 감사합니다~!"
                  />
                  <div className="flex items-center mt-2">
                    <input
                      type="checkbox"
                      checked={agree}
                      onChange={handleAgreeChange}
                    />
                    <label className="ml-2 text-sm text-gray-500">
                      마케팅 활용정보 제공에 모든 내용을 확인했으며, 이에
                      동의합니다
                    </label>
                  </div>
                </div>
                <div className="flex justify-center items-center mb-3">
                  <Button
                    size="sm"
                    variant={agree ? "primary" : "grey"}
                    onClick={() => {
                      if (agree) {
                        openModalConfirmTerminate();
                      }
                    }}
                  >
                    수업 평가 등록
                  </Button>
                </div>
              </Modal>
            )}
            {showModalConfimTerminate && (
              <Modal
                title="수업 평가가"
                onClose={() => {
                  closeModalConfirmTerminate();
                }}
                size="sm"
              >
                <div className="space-y-4">
                  <div className="text-center">
                    <p className="text-sm text-gray-500">
                      정말로 수업을 평가하시겠습니까?
                    </p>
                  </div>
                  <div className="flex justify-center space-x-4">
                    <button
                      className="flex gap-1 justify-center items-center px-6 py-1 bg-primary text-white rounded-md"
                      onClick={() => {
                        closeModalConfirmTerminate();
                        handleSubmit();
                      }}
                    >
                      응, 평가할래요
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          </>
        ) : category === "completion" ? (
          <>
            <Button size="sm" variant="primary" onClick={openModalCompletion}>
              <div className="flex justify-center items-center gap-1">
                평가서 확인 <ArrowRight2 size="16" />
              </div>
            </Button>
            {showModalCompletion && (
              <Modal
                title="수업 평가"
                onClose={() => {
                  closeModalCompletion();
                }}
                size="lg"
              >
                <div className="h-[180px] mb-6 flex-col justify-start items-start gap-4 inline-flex">
                  <div className="p-5 bg-[#f9f9f9] rounded-xl justify-start items-center gap-6 inline-flex">
                    <img
                      className="w-[250px] h-[140px] relative rounded-xl backdrop-blur-sm"
                      src={CourseImg}
                    />
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
                      <div className="self-stretch text-[#242424] text-lg font-bold font-['Pretendard'] leading-[25.20px]">
                        죽을때 까지 기억나는 JavaScript 기초부터 응용까지
                      </div>
                      <div className="self-stretch h-[70px] py-3 border-t border-[#e0e0e0] flex-col justify-start items-start gap-3 flex">
                        <div className="justify-start items-start gap-4 inline-flex">
                          <div className="text-[#333333] text-xs font-bold font-['Pretendard'] leading-none">
                            수업일자
                          </div>
                          <div className="h-[17px] justify-start items-start gap-2 flex">
                            <div className="text-right text-[#555555] text-xs font-medium font-['Pretendard'] leading-none">
                              23.02.04{" "}
                            </div>
                            <div className="text-right text-[#a5a5a5] text-xs font-medium font-['Pretendard'] leading-none">
                              ~
                            </div>
                            <div className="text-right text-[#555555] text-xs font-medium font-['Pretendard'] leading-none">
                              23.02.04{" "}
                            </div>
                          </div>
                        </div>
                        <div className="justify-start items-start gap-4 inline-flex">
                          <div className="w-[42px] text-[#333333] text-xs font-bold font-['Pretendard'] leading-none">
                            강사
                          </div>
                          <div className="h-[17px] justify-end items-start gap-2 flex">
                            <div className="grow shrink basis-0 text-[#555555] text-xs font-medium font-['Pretendard'] leading-none">
                              김강사
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[88px] mb-4 justify-start items-start gap-6 inline-flex">
                  <div className="pl-7 pr-3 py-3 bg-[#fbfcff] rounded-xl border border-[#dbe5ff] justify-end items-center gap-[92px] flex">
                    <div className="self-stretch justify-start items-center gap-6 inline-flex">
                      <div className="text-center text-[#333333] text-base font-bold font-['Pretendard'] leading-snug">
                        리뷰 수
                      </div>
                      <div className="justify-start items-center gap-1 flex">
                        <div className="text-[#0b49e6] text-xl font-bold font-['Pretendard'] leading-7">
                          25
                        </div>
                        <div className="text-[#759cff] text-xs font-medium font-['Pretendard'] leading-none">
                          개
                        </div>
                      </div>
                    </div>
                    <div className="w-16 h-16 px-[7px] pt-2.5 pb-[9px] justify-center items-center inline-flex">
                      <img className="w-[50px] h-[45px]" src={Comment} />
                    </div>
                  </div>
                  <div className="pl-7 pr-3 py-3 bg-[#fbfcff] rounded-xl border border-[#dbe5ff] justify-end items-center gap-[88px] flex">
                    <div className="self-stretch justify-start items-center gap-6 inline-flex">
                      <div className="text-center text-[#242424] text-base font-bold font-['Pretendard'] leading-snug">
                        평균 총점
                      </div>
                      <div className="justify-start items-center gap-1 flex">
                        <div className="text-[#0b49e6] text-xl font-bold font-['Pretendard'] leading-7">
                          4.5
                        </div>
                        <div className="text-[#759cff] text-xs font-medium font-['Pretendard'] leading-none">
                          /
                        </div>
                        <div className="text-[#759cff] text-xs font-medium font-['Pretendard'] leading-none">
                          5점
                        </div>
                      </div>
                    </div>
                    <div className="w-16 h-16 py-2.5 justify-center items-center inline-flex">
                      <img className="w-16 h-11" src={Start} />
                    </div>
                  </div>
                </div>
                <div className="h-full flex-col justify-center items-center gap-6 inline-flex">
                  <div className="py-4 border-b border-[#f2f4f6] flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-3 inline-flex">
                      <div className="h-11 px-0.5 pt-2 bg-[#f2f4f6] rounded-[55px] justify-center items-center flex">
                        <img className="w-10 h-10" src={Avatar} />
                      </div>
                      <div className="flex-col justify-center items-start gap-0.5 inline-flex">
                        <div className="text-[#333333] text-xs font-bold font-['Pretendard'] leading-none">
                          김학생
                        </div>
                        <div className="justify-start items-center gap-1 inline-flex">
                          <div className="justify-start items-start flex">
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-col justify-start items-start gap-3 flex">
                      <div className="text-[#242424] text-base font-bold font-['Pretendard'] leading-snug">
                        현실적인 도움이 되었습니다
                      </div>
                      <div className="w-[674px] text-[#555555] text-sm font-medium font-['Pretendard'] leading-tight">
                        신경써서 봐주신 덕분에 손쉽게 원하던 곳에 합격할
                        수있었습니다 감사합니다 멘토님 저와 비슷한 경험이 있셔서
                        더욱 도움이 되었습니다 감사합니다! 멘토님 저와 비슷한
                        경험이 있셔서 더욱 도움이 되었습니다 감사합니다! 글로벌
                        IT 기업 UX디자이너의 업무 프로세스와 더미텍스트.
                      </div>
                    </div>
                    <div className="justify-start items-start gap-1 inline-flex">
                      <div className="text-[#a5a5a5] text-xs font-medium font-['Pretendard'] leading-none">
                        22.08.14
                      </div>
                    </div>
                  </div>
                  <div className="py-4 border-b border-[#f2f4f6] flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-3 inline-flex">
                      <div className="h-11 px-0.5 pt-2 bg-[#f2f4f6] rounded-[55px] justify-center items-center flex">
                        <img className="w-10 h-10" src={Avatar} />
                      </div>
                      <div className="flex-col justify-center items-start gap-0.5 inline-flex">
                        <div className="text-[#333333] text-xs font-bold font-['Pretendard'] leading-none">
                          김학생
                        </div>
                        <div className="justify-start items-center gap-1 inline-flex">
                          <div className="justify-start items-start flex">
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-col justify-start items-start gap-3 flex">
                      <div className="text-[#242424] text-base font-bold font-['Pretendard'] leading-snug">
                        현실적인 도움이 되었습니다
                      </div>
                      <div className="w-[674px] text-[#555555] text-sm font-medium font-['Pretendard'] leading-tight">
                        신경써서 봐주신 덕분에 손쉽게 원하던 곳에 합격할
                        수있었습니다 감사합니다 멘토님 저와 비슷한 경험이 있셔서
                        더욱 도움이 되었습니다 감사합니다! 멘토님 저와 비슷한
                        경험이 있셔서 더욱 도움이 되었습니다 감사합니다! 글로벌
                        IT 기업 UX디자이너의 업무 프로세스와 더미텍스트.
                      </div>
                    </div>
                    <div className="justify-start items-start gap-1 inline-flex">
                      <div className="text-[#a5a5a5] text-xs font-medium font-['Pretendard'] leading-none">
                        22.08.14
                      </div>
                    </div>
                  </div>
                  <div className="py-4 border-b border-[#f2f4f6] flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-3 inline-flex">
                      <div className="h-11 px-0.5 pt-2 bg-[#f2f4f6] rounded-[55px] justify-center items-center flex">
                        <img className="w-10 h-10" src={Avatar} />
                      </div>
                      <div className="flex-col justify-center items-start gap-0.5 inline-flex">
                        <div className="text-[#333333] text-xs font-bold font-['Pretendard'] leading-none">
                          김학생
                        </div>
                        <div className="justify-start items-center gap-1 inline-flex">
                          <div className="justify-start items-start flex">
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                            <div className="w-4 h-4 relative"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-col justify-start items-start gap-3 flex">
                      <div className="text-[#242424] text-base font-bold font-['Pretendard'] leading-snug">
                        현실적인 도움이 되었습니다
                      </div>
                      <div className="w-[674px] text-[#555555] text-sm font-medium font-['Pretendard'] leading-tight">
                        신경써서 봐주신 덕분에 손쉽게 원하던 곳에 합격할
                        수있었습니다 감사합니다 멘토님 저와 비슷한 경험이 있셔서
                        더욱 도움이 되었습니다 감사합니다! 멘토님 저와 비슷한
                        경험이 있셔서 더욱 도움이 되었습니다 감사합니다! 글로벌
                        IT 기업 UX디자이너의 업무 프로세스와 더미텍스트.
                      </div>
                    </div>
                    <div className="justify-start items-start gap-1 inline-flex">
                      <div className="text-[#a5a5a5] text-xs font-medium font-['Pretendard'] leading-none">
                        22.08.14
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CourseCardProfessor;
