import Footer from "../../components/containers/footer";
import { ArrowRight2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
const Detail = () => {
  const navigation = useNavigate()
  const handlerClick = function () {
    navigation('/announcement')
  }
  return (
    <div>
      <div className="2xl:px-72 px-40  py-14 bg-white  ">
        <div className="border border-grey-1 p-10 grid gap-7 rounded-2xl">
          <div className="flex gap-2 items-center">
            <div className="text-grey-3 text-sm font-medium">공지</div>
            <div className="text-grey-3">
              <ArrowRight2 size={16} />
            </div>
            <div className="text-grey-6 text-sm font-bold">공지 상세</div>
          </div>
          <div className="text-grey-8 font-bold text-xl">
            학습시간 변경 안내 공지
          </div>
          <div className="flex items-center gap-7">
            <div className="flex items-center gap-2">
              <div className="text-grey-7 text-sm font-bold">작성자</div>
              <div className="text-grey-1 text-xs font-medium">|</div>
              <div className="text-grey-6 text-sm font-medium">작성자</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-grey-7 text-sm font-bold">작성일</div>
              <div className="text-grey-1 text-xs font-medium">|</div>
              <div className="text-grey-4 text-sm font-medium">
                23.02.04 23:59
              </div>
            </div>
          </div>
          <div className="py-7 h-[558px] border-t border-grey-1 border-b ">
            <div className="text-grey-6 text-sm font-medium">안녕하세요.</div>
            <div className="text-grey-6 text-sm font-medium">
              2023년 한 해 동안 채피를 이용해주신 모든 고객님께 감사드립니다.
            </div>
            <div className="text-grey-8 text-sm font-bold my-4">
              신정 연휴 : 1월 1일 (월) - 고객센터 휴무
            </div>

            <div className="text-grey-6 text-sm font-medium">
              <div>
                해당 일자에는 신정으로 인해 고객센터 휴무가 진행되어 상담이
                어려우니 양해 부탁드립니다.
              </div>
              <div>2024년에도 많은 관심 부탁드리며 새해 복 많이 받으세요.</div>
              <div>
                휴무 동안에도 수강생분들의 편의를 위해 자주 묻는 질문을
                참고해주시길 바라며, 문의사항이 있으신 경우
              </div>
              <div>
                문의하기에 남겨주시면 1월 2일(화) 부터 순차적으로
                안내드리겠습니다.
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-end w-full">
          <div
            onClick={handlerClick}
            className="
              inline-block
              cursor-pointer text-white text-xs font-bold
              rounded-lg px-3 py-2 bg-grey-3
            "
          >목록으로 이동 </div>
        </div>
      </div>
      <Footer variant="blue" />
    </div>
  );
};

export default Detail;
