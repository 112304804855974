import React from "react";
import CourseImage from "../../../assets/course-image.png";
import AuthorProfile from "../../../assets/profile-img.png";
import { ArrowRight2 } from "iconsax-react";
import { useCourseStore } from "../../../store/course";
import { useParams } from "react-router-dom";

export default function InfoLearning() {
  const { id } = useParams<{ id: string }>();
  const { courseData } = useCourseStore.getState();
  const getDataById = courseData.find(
    (item) => item.id === parseInt(id || "", 10)
  );

  return (
    <div className="bg-white p-6 rounded-lg border border-gray-100 shadow-sm flex">
      <img src={CourseImage} alt="Learning" className="w-1/2 rounded-md" />
      <div className="ml-6 flex flex-col justify-between w-full">
        <div>
          <p className="text-sm mb-2">
            프로그래밍 기초 &gt; {getDataById?.title}
          </p>
          <h2 className="text-2xl font-bold mb-2">{getDataById?.title}</h2>
          <div className="flex justify-between items-center mb-4">
            <div className="flex justify-center items-center">
              <img
                src={AuthorProfile}
                alt="Author"
                className="w-12 h-12 rounded-full mr-2"
              />
              <p>김강사</p>
            </div>
            <div className="flex gap-2">
              <span className="border border-red-100 text-red-500 px-3 py-1 rounded text-xs">
                NEW
              </span>
              <span className="border border-red-100 text-red-500 px-3 py-1 rounded text-xs">
                JavaScript
              </span>
              <span className="border border-red-100 text-red-500 px-3 py-1 rounded text-xs">
                초급
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center w-full">
          <button className="flex justify-center w-full items-center gap-2 bg-blue-500 text-white px-6 py-2 rounded">
            이어보기 <ArrowRight2 size="18" />
          </button>
        </div>
      </div>
    </div>
  );
}
