import React from "react";
import {
  IconPeopleProgress,
  IconPeopleCheckedProgress,
  IconPeopleDashProgress,
} from "../../../components/Icons";

const PeopleProgress = () => {
  return (
    <div className="flex justify-center w-full gap-10 p-10 mb-10 bg-white divide-x-2 rounded-lg 2xl:gap-20 ">
      <div className="flex items-center gap-4">
        <span className="p-3 rounded-full bg-slate-100 border-slate-400">
          <IconPeopleProgress />
        </span>
        <div className="flex flex-col gap-2">
          <h2 className="text-xl font-bold ">전체</h2>
          <p className="text-sm font-medium text-[#A5A5A5]">2100명</p>
        </div>
      </div>
      <div className="flex items-center gap-4 pl-10 2xl:pl-20">
        <span className="p-3 rounded-full bg-slate-100 border-slate-400">
          <IconPeopleCheckedProgress />
        </span>
        <div className="flex flex-col gap-2">
          <h2 className="text-xl font-bold ">학생</h2>
          <p className="text-sm font-medium text-[#A5A5A5]">1500명</p>
        </div>
      </div>
      <div className="flex items-center gap-4 pl-10 2xl:pl-20">
        <span className="p-3 rounded-full bg-slate-100 border-slate-400">
          <IconPeopleDashProgress />
        </span>
        <div className="flex flex-col gap-2">
          <h2 className="text-xl font-bold ">강사</h2>
          <p className="text-sm font-medium text-[#A5A5A5]">500명</p>
        </div>
      </div>
      <div className="flex items-center gap-4 pl-10 2xl:pl-20">
        <span className="p-3 rounded-full bg-slate-100 border-slate-400">
          <IconPeopleDashProgress />
        </span>
        <div className="flex flex-col gap-2">
          <h2 className="text-xl font-bold ">담당자</h2>
          <p className="text-sm font-medium text-[#A5A5A5]">100명</p>
        </div>
      </div>
    </div>
  );
};

export default PeopleProgress;
