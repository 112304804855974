import React, { useState } from "react";
import { useCourseStore } from "../../../store/course";
import CourseCardProfessor from "../components/courseCardProfessor";
import EmptyState from "../../../components/emptyState";
import Pagination from "../../../components/pagination";
import EmptyCourse from "../../../assets/course-empty.png";
import { AddCircle } from "iconsax-react";
import { useNavigate } from "react-router-dom";

const SubjectsProfessor: React.FC = () => {
  const [activeTab, setActiveTab] = useState("in-progress");
  const navigate = useNavigate();
  const { courseData } = useCourseStore();
  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 4;

  const tabs = [
    { name: "진행중", id: "in-progress" },
    { name: "수강 신청 중", id: "termination" },
    { name: "종료", id: "completion" },
  ];

  const filteredCourses = courseData.filter(
    (course) => activeTab === course.category
  );
  const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);
  const displayedCourses = filteredCourses.slice(
    (currentPage - 1) * coursesPerPage,
    currentPage * coursesPerPage
  );

  return (
    <div className="min-h-screen bg-white p-6 rounded-lg shadow">
      <div className="min-h-screen">
        <h2 className="text-xl font-bold flex mb-4 border-b pb-2">과목</h2>
        <div className="flex justify-between items-center mb-6">
          <div className="flex">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`py-2 px-6 ${
                  activeTab === tab.id
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-400"
                } focus:outline-none`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.name}
              </button>
            ))}
          </div>
          <div className="flex justify-end ">
            <button
              className="flex justify-center gap-1 items-center border text-primary border-primary px-4 py-2 rounded-lg"
              onClick={() =>
                navigate("/dashboard-professor/create-lecture-form")
              }
            >
              글쓰기
              <AddCircle size={18} />
            </button>
          </div>
        </div>
        {displayedCourses.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {displayedCourses.map((course, index) => (
                <CourseCardProfessor key={index} {...course} />
              ))}
            </div>
          </>
        ) : (
          <EmptyState
            imgSrc={EmptyCourse}
            title="강의 목록이 없어요"
            description="채피의 인기 강의를 둘러보세요"
            buttonText="강의 둘러보기"
            onButtonClick={() => console.log("Button clicked")}
          />
        )}
      </div>
      {displayedCourses && displayedCourses.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

export default SubjectsProfessor;
