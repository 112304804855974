import React, { useState } from "react";
import { Table } from "../../../components/tables";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination";
import Modal from "../../../components/modal";
import ProfilImg from "../../../assets/profile-img.png";
import SelectDropdown from "../../../components/materials/SelectDropdown";

interface CheckHistoryProps {
  id: number;
  date: string;
  class: string;
  payment: string;
  total: string;
  purchase: string;
}

interface CheckHistory2Props {
  id: number;
  date: string;
  class: string;
  payment: string;
  total: string;
  refund: string;
}

interface ExtendedCheckHistoryProps extends CheckHistoryProps {
  isChecked: boolean;
  toggleCheckbox: () => void;
}

const dataCheckHistory: CheckHistoryProps[] = [
  {
    id: 1,
    date: "23.02.04 23:59",
    class: "기초 css 배우기",
    payment: "카드",
    total: "25,200원",
    purchase: "결제확인",
  },
  {
    id: 2,
    date: "23.02.04 23:59",
    class: "기초 Javascript 배우기",
    payment: "카드",
    total: "25,200원",
    purchase: "결제확인",
  },
  {
    id: 3,
    date: "23.02.04 23:59",
    class: "기초 css 배우기",
    payment: "카드",
    total: "25,200원",
    purchase: "결제확인",
  },
  {
    id: 4,
    date: "23.02.04 23:59",
    class: "기초 Javascript 배우기",
    payment: "카드",
    total: "25,200원",
    purchase: "결제확인",
  },
  {
    id: 5,
    date: "23.02.04 23:59",
    class: "기초 css 배우기",
    payment: "카드",
    total: "25,200원",
    purchase: "결제확인",
  },
];

const dataCheckHistory2: CheckHistory2Props[] = [
  {
    id: 1,
    date: "23.02.04 23:59",
    class: "기초 css 배우기",
    payment: "카드",
    total: "25,200원",
    refund: "환불신청",
  },
  {
    id: 2,
    date: "23.02.04 23:59",
    class: "기초 css 배우기",
    payment: "카드",
    total: "25,200원",
    refund: "환불신청",
  },
  {
    id: 3,
    date: "23.02.04 23:59",
    class: "기초 css 배우기",
    payment: "카드",
    total: "25,200원",
    refund: "환불신청",
  },
  {
    id: 4,
    date: "23.02.04 23:59",
    class: "기초 css 배우기",
    payment: "카드",
    total: "25,200원",
    refund: "환불신청",
  },
  {
    id: 5,
    date: "23.02.04 23:59",
    class: "기초 css 배우기",
    payment: "카드",
    total: "25,200원",
    refund: "환불신청",
  },
];

const columns: ColumnDef<ExtendedCheckHistoryProps>[] = [
  {
    accessorKey: "date",
    header: "신청일",
  },
  {
    accessorKey: "class",
    header: "강의명",
  },
  {
    accessorKey: "payment",
    header: "결제수단",
  },
  {
    accessorKey: "total",
    header: "총 금액",
  },
  {
    accessorKey: "purchase",
    header: "구매명단",
    cell: () => null,
  },
];

const columns2: ColumnDef<CheckHistory2Props>[] = [
  {
    accessorKey: "date",
    header: "신청일",
  },
  {
    accessorKey: "class",
    header: "강의명",
  },
  {
    accessorKey: "payment",
    header: "결제수단",
  },
  {
    accessorKey: "total",
    header: "총 금액",
  },
  {
    accessorKey: "refundList",
    header: "환불명단",
    cell: () => null,
  },
];

export default function CheckHistoryProfessor() {
  const [activeTab, setActiveTab] = useState("purchase");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const dataPerPage = 10;
  const navigate = useNavigate();
  const [openModalPurchase, setOpenModalPurchase] = useState(false);
  const [openModalRefund, setOpenModalRefund] = useState(false);
  const [openModalBuyer, setOpenModalBuyer] = useState(false);

  const onOpenModalPurchase = () => setOpenModalPurchase(true);
  const onCloseModalPurchase = () => setOpenModalPurchase(false);

  const onOpenModalRefund = () => setOpenModalRefund(true);
  const onCloseModalRefund = () => setOpenModalRefund(false);

  const onOpenModalBuyer = () => setOpenModalBuyer(true);
  const onCloseModalBuyer = () => setOpenModalBuyer(false);

  const tabs = [
    { name: "결제내역", id: "purchase" },
    { name: "환불시청", id: "refund" },
  ];

  const filteredDataCheckHistory = dataCheckHistory.filter(
    (notice) => activeTab === "purchase"
  );
  const filteredDataCheckHistory2 = dataCheckHistory2.filter(
    (notice) => activeTab === "refund"
  );

  const totalPages = Math.ceil(filteredDataCheckHistory.length / dataPerPage);
  const totalPages2 = Math.ceil(filteredDataCheckHistory2.length / dataPerPage);

  const handleCheckboxChange = (id: number) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(id)) {
        newSelectedRows.delete(id);
      } else {
        newSelectedRows.add(id);
      }
      return newSelectedRows;
    });
  };

  const isButtonEnabled = selectedRows.size > 0;

  const extendedData: ExtendedCheckHistoryProps[] = filteredDataCheckHistory
    .slice((currentPage - 1) * dataPerPage, currentPage * dataPerPage)
    .map((row) => ({
      ...row,
      isChecked: selectedRows.has(row.id),
      toggleCheckbox: () => handleCheckboxChange(row.id),
    }));

  const handlePurchaseButton = (row: CheckHistoryProps) => {
    onOpenModalPurchase();
  };

  const handleRefundButton = (row: CheckHistory2Props) => {
    onOpenModalRefund();
  };

  return (
    <div className="bg-white min-h-screen p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold border-b pb-2">내역확인</h2>
      <div className="flex justify-between items-center">
        <div className="flex">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`py-2 px-6 ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-600 text-blue-600"
                  : "text-gray-400"
              } focus:outline-none`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>
      <div className="flex gap-2 my-4">
        <SelectDropdown
          options={[
            { label: "월별", value: "month" },
            { label: "주별", value: "week" },
            { label: "일별", value: "day" },
          ]}
          value="all"
          placeholder="진행사항"
          onChange={(value) => console.log(value)}
        />
        <SelectDropdown
          options={[
            { label: "강의 명 1", value: "lecture1" },
            { label: "강의 명 2", value: "lecture2" },
            { label: "강의 명 3", value: "lecture3" },
          ]}
          value="all"
          placeholder="결제수단"
          onChange={(value) => console.log(value)}
        />
      </div>
      {activeTab === "purchase" ? (
        <>
          <Table
            columns={columns}
            data={extendedData}
            onRefundRequestClick={handlePurchaseButton}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </>
      ) : (
        <>
          <Table
            columns={columns2}
            data={filteredDataCheckHistory2}
            onRefundRequestClick={handleRefundButton}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages2}
            onPageChange={setCurrentPage}
          />
        </>
      )}

      {openModalPurchase && (
        <Modal
          title="구매 목록"
          onClose={() => {
            onCloseModalPurchase();
          }}
          size="md"
        >
          <div className="w-full h-auto rounded-xl overflow-hidden">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-[#e0e0e0]">
                  <th className="text-center text-[#242424] text-sm font-bold p-3">
                    번호
                  </th>
                  <th className="text-center text-[#242424] text-sm font-bold p-3">
                    수강생
                  </th>
                  <th className="text-center text-[#242424] text-sm font-bold p-3">
                    구매시간
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-[#e0e0e0]">
                  <td className="text-center text-[#8a8a8a] text-sm font-medium p-3">
                    01
                  </td>
                  <td
                    className="text-center cursor-pointer text-primary text-sm font-medium p-3"
                    onClick={() => onOpenModalBuyer()}
                  >
                    김학생
                  </td>
                  <td className="text-center text-[#555555] text-sm font-medium p-3">
                    23.12.23 00:00:00
                  </td>
                </tr>
                <tr className="border-b border-[#e0e0e0]">
                  <td className="text-center text-[#8a8a8a] text-sm font-medium p-3">
                    02
                  </td>
                  <td
                    className="text-center cursor-pointer text-primary text-sm font-medium p-3"
                    onClick={() => onOpenModalBuyer()}
                  >
                    김학생
                  </td>
                  <td className="text-center text-[#555555] text-sm font-medium p-3">
                    23.12.23 00:00:00
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      )}

      {openModalRefund && (
        <Modal
          title="환불 목록"
          onClose={() => {
            onCloseModalRefund();
          }}
          size="md"
        >
          <div className="w-full h-auto rounded-xl overflow-hidden">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-[#e0e0e0]">
                  <th className="text-center text-[#242424] text-sm font-bold p-3">
                    번호
                  </th>
                  <th className="text-center text-[#242424] text-sm font-bold p-3">
                    수강생
                  </th>
                  <th className="text-center text-[#242424] text-sm font-bold p-3">
                    구매시간
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-[#e0e0e0]">
                  <td className="text-center text-[#8a8a8a] text-sm font-medium p-3">
                    01
                  </td>
                  <td
                    className="text-center cursor-pointer text-primary text-sm font-medium p-3"
                    onClick={() => onOpenModalBuyer()}
                  >
                    김학생
                  </td>
                  <td className="text-center text-[#555555] text-sm font-medium p-3">
                    23.12.23 00:00:00
                  </td>
                </tr>
                <tr className="border-b border-[#e0e0e0]">
                  <td className="text-center text-[#8a8a8a] text-sm font-medium p-3">
                    02
                  </td>
                  <td
                    className="text-center cursor-pointer text-primary text-sm font-medium p-3"
                    onClick={() => onOpenModalBuyer()}
                  >
                    김학생
                  </td>
                  <td className="text-center text-[#555555] text-sm font-medium p-3">
                    23.12.23 00:00:00
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      )}

      {openModalBuyer && (
        <Modal
          title="수강생 목록"
          onClose={() => {
            onCloseModalBuyer();
          }}
          size="lg"
        >
          <div className="max-w-4xl mx-auto bg-white rounded-2xl flex flex-col justify-center items-center ">
            <div className="w-full flex justify-between items-center mb-4">
              <div></div>
              <div className="w-6 h-6 relative"></div>
            </div>
            <div className="flex justify-center items-center gap-7 mb-6">
              <div className="flex flex-col justify-start items-center gap-2">
                <div className="relative w-20 h-20">
                  <div className="absolute inset-0 bg-[#f2f4f6] rounded-full"></div>
                  <div className="absolute inset-0">
                    <div className="absolute inset-0 bg-white rounded-full"></div>
                    <img
                      className="absolute w-full h-full object-cover"
                      src={ProfilImg}
                      alt="Profile"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-start items-center">
                  <div className="text-[#242424] text-base font-bold">
                    김채피
                  </div>
                  <div className="text-[#8a8a8a] text-xs font-medium">
                    chappy@naasf.com
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-white p-6 flex flex-col gap-7">
              <div className="flex justify-start items-center gap-7">
                <div className="w-24 text-[#a5a5a5] text-base font-medium">
                  이름
                </div>
                <div className="flex-grow flex items-center h-14">
                  <div className="flex-grow h-full pl-4 pr-3.5 py-[15px] bg-white rounded-xl border border-[#e0e0e0] flex items-center">
                    <div className="text-[#555555] text-base font-medium">
                      홍길동
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-center gap-7">
                <div className="w-24 text-[#a5a5a5] text-base font-medium">
                  연락처
                </div>
                <div className="flex-grow flex items-center h-14">
                  <div className="flex-grow h-full pl-4 pr-3.5 py-[15px] bg-white rounded-xl border border-[#e0e0e0] flex items-center">
                    <div className="text-[#555555] text-base font-medium">
                      01012345678
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-center gap-7">
                <div className="w-24 text-[#a5a5a5] text-base font-medium">
                  이메일
                </div>
                <div className="flex-grow flex items-center h-14">
                  <div className="flex-grow h-full pl-4 pr-3.5 py-[15px] bg-white rounded-xl border border-[#e0e0e0] flex items-center">
                    <div className="text-[#555555] text-base font-medium">
                      abcd@naver.com
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-center gap-7">
                <div className="w-24 text-[#a5a5a5] text-base font-medium">
                  소속
                </div>
                <div className="flex-grow flex items-center h-14">
                  <div className="flex-grow h-full pl-4 pr-3.5 py-[15px] bg-white rounded-xl border border-[#e0e0e0] flex items-center">
                    <div className="flex items-center gap-2">
                      <div className="text-[#555555] text-base font-medium">
                        abcdqwer
                      </div>
                      <div className="px-2 py-1 bg-[#fbfcff] rounded border border-[#2860ef] flex items-center">
                        <div className="text-[#2860ef] text-xs font-medium">
                          무소속
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start gap-7">
                <div className="w-24 text-[#a5a5a5] text-base font-medium">
                  자기소개
                </div>
                <div className="flex-grow h-32 p-4 bg-white rounded-xl border border-[#e0e0e0] flex items-start">
                  <div className="text-[#555555] text-base font-medium">
                    안녕하세요. 코딩을 좋아하고 잘하고싶은 코딩 입문한지 얼마
                    안된 홍길동입니다!
                    <br />잘 부탁드립니다~~
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
