import React from "react";

type EmptyStateProps = {
  imgSrc: string;
  title: string;
  description?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  py?: string;
};

const EmptyState: React.FC<EmptyStateProps> = ({
  imgSrc,
  title,
  description,
  buttonText,
  onButtonClick,
  py,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center ${
        py ? py : "py-24"
      }`}
    >
      <img src={imgSrc} alt="Empty State" className="w-20 h-20 mb-4" />
      <h2 className="text-grey-7 font-bold mb-2">{title}</h2>
      {description && <p className="text-grey-3 text-sm mb-4">{description}</p>}
      {buttonText && (
        <button
          className="px-12 py-2 bg-primary text-white rounded-lg"
          onClick={onButtonClick}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default EmptyState;
