

import { useState } from 'react'
import { ArrowDown2 } from 'iconsax-react'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'



type SelectOption = {
  label : string ,
  value : string 
}

type SelectProps = {
  options : SelectOption[],
  value? : SelectOption,
  onChange : (value : SelectOption | undefined) => void,
  size? : string
}

const sizeVariants = {
  sm : '',
  md : 'h-[40px]'
}

export default function Select({
  value,
  onChange,
  options,
  size = 'sm' 
} : SelectProps) {
  const selectOption = function (option : any) {
    onChange(option)
  }
  return (
    <Listbox value={value} onChange={(e) => selectOption(e)}>
      <div className="relative">
        <ListboxButton 
          className={`
            relative w-full 
            rounded-md bg-grey-2 py-2 px-3 
            text-left text-grey-7 text-xs font-bold
            focus:outline-none cursor-pointer
            dark:bg-grey-8 dark:text-grey-1
            ${sizeVariants[size as keyof typeof sizeVariants]}
          `}
          >
          <span className="flex items-center">
            <span className="block truncate">{value?.label}</span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ArrowDown2 size="15"/>
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md dark:bg-grey-8 bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {options.map((item, idx) => (
            <ListboxOption
              key={idx}
              value={item}
              className="
                group dark:bg-grey-8 dark:text-grey-1  relative cursor-default select-none py-2 pl-3 pr-9 text-grey-7 data-[focus]:bg-grey-2 data-[focus]:text-white
                [&:not(:last-child)]:border-b border-b-grey-2 dark:border-b-grey-4
                "
            >
              <div className="flex items-center">
                <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                  {item.label}
                </span>
              </div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  )
}
