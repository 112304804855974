function IconPeopleProgress() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 35C37.5 35 40 35 40 32.5C40 30 37.5 22.5 27.5 22.5C17.5 22.5 15 30 15 32.5C15 35 17.5 35 17.5 35H37.5ZM17.5559 32.5C17.5472 32.499 17.5351 32.4974 17.5204 32.495C17.5134 32.4938 17.5067 32.4926 17.5 32.4913C17.5036 31.8312 17.9172 29.9173 19.3981 28.1895C20.7834 26.5734 23.2067 25 27.5 25C31.7933 25 34.2166 26.5734 35.6019 28.1895C37.0828 29.9173 37.4964 31.8312 37.5 32.4913C37.4933 32.4926 37.4866 32.4938 37.4796 32.495C37.4649 32.4974 37.4528 32.499 37.4441 32.5H17.5559Z"
        fill="black"
      />
      <path
        d="M27.5 17.5C30.2614 17.5 32.5 15.2614 32.5 12.5C32.5 9.73858 30.2614 7.5 27.5 7.5C24.7386 7.5 22.5 9.73858 22.5 12.5C22.5 15.2614 24.7386 17.5 27.5 17.5ZM35 12.5C35 16.6421 31.6421 20 27.5 20C23.3579 20 20 16.6421 20 12.5C20 8.35786 23.3579 5 27.5 5C31.6421 5 35 8.35786 35 12.5Z"
        fill="black"
      />
      <path
        d="M17.3398 23.1999C16.4203 22.9058 15.3988 22.692 14.2657 22.5826C13.7049 22.5285 13.1167 22.5 12.5 22.5C2.5 22.5 0 30 0 32.5C0 34.1667 0.833333 35 2.5 35H13.0409C12.6889 34.2898 12.5 33.4478 12.5 32.5C12.5 29.9743 13.4431 27.395 15.2245 25.2405C15.8332 24.5044 16.5397 23.8178 17.3398 23.1999ZM12.3001 25.0011C10.8064 27.284 10 29.8867 10 32.5H2.5C2.5 31.8482 2.91061 29.9249 4.39814 28.1895C5.76171 26.5986 8.13123 25.0492 12.3001 25.0011Z"
        fill="black"
      />
      <path
        d="M3.75 13.75C3.75 9.60786 7.10786 6.25 11.25 6.25C15.3921 6.25 18.75 9.60786 18.75 13.75C18.75 17.8921 15.3921 21.25 11.25 21.25C7.10786 21.25 3.75 17.8921 3.75 13.75ZM11.25 8.75C8.48858 8.75 6.25 10.9886 6.25 13.75C6.25 16.5114 8.48858 18.75 11.25 18.75C14.0114 18.75 16.25 16.5114 16.25 13.75C16.25 10.9886 14.0114 8.75 11.25 8.75Z"
        fill="black"
      />
    </svg>
  );
}
function IconPeopleCheckedProgress() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.25 40C36.0825 40 40 36.0825 40 31.25C40 26.4175 36.0825 22.5 31.25 22.5C26.4175 22.5 22.5 26.4175 22.5 31.25C22.5 36.0825 26.4175 40 31.25 40ZM35.4469 28.7681L32.1087 34.3317C31.4811 35.3777 30.0376 35.5554 29.1751 34.6929L27.2411 32.7589C26.753 32.2707 26.753 31.4793 27.2411 30.9911C27.7293 30.503 28.5207 30.503 29.0089 30.9911L30.3768 32.3591L33.3031 27.4819C33.6583 26.8899 34.4261 26.6979 35.0181 27.0531C35.6101 27.4083 35.8021 28.1761 35.4469 28.7681Z"
        fill="black"
      />
      <path
        d="M27.5 12.5C27.5 16.6421 24.1421 20 20 20C15.8579 20 12.5 16.6421 12.5 12.5C12.5 8.35786 15.8579 5 20 5C24.1421 5 27.5 8.35786 27.5 12.5ZM20 17.5C22.7614 17.5 25 15.2614 25 12.5C25 9.73858 22.7614 7.5 20 7.5C17.2386 7.5 15 9.73858 15 12.5C15 15.2614 17.2386 17.5 20 17.5Z"
        fill="black"
      />
      <path
        d="M20.6401 35C20.3578 34.2013 20.1632 33.3612 20.0677 32.4913H7.5C7.50356 31.8743 7.88439 30.0261 9.58024 28.3303C11.2109 26.6996 14.2772 25 20 25C20.6512 25 21.2681 25.022 21.8523 25.0634C22.4152 24.2101 23.0912 23.4379 23.859 22.7683C22.6927 22.5954 21.41 22.5 20 22.5C7.5 22.5 5 30 5 32.5C5 35 7.5 35 7.5 35H20.6401Z"
        fill="black"
      />
    </svg>
  );
}
function IconPeopleDashProgress() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.25 40C36.0825 40 40 36.0825 40 31.25C40 26.4175 36.0825 22.5 31.25 22.5C26.4175 22.5 22.5 26.4175 22.5 31.25C22.5 36.0825 26.4175 40 31.25 40ZM27.5 30H35C35.6904 30 36.25 30.5596 36.25 31.25C36.25 31.9404 35.6904 32.5 35 32.5H27.5C26.8096 32.5 26.25 31.9404 26.25 31.25C26.25 30.5596 26.8096 30 27.5 30Z"
        fill="black"
      />
      <path
        d="M27.5 12.5C27.5 16.6421 24.1421 20 20 20C15.8579 20 12.5 16.6421 12.5 12.5C12.5 8.35786 15.8579 5 20 5C24.1421 5 27.5 8.35786 27.5 12.5ZM20 17.5C22.7614 17.5 25 15.2614 25 12.5C25 9.73858 22.7614 7.5 20 7.5C17.2386 7.5 15 9.73858 15 12.5C15 15.2614 17.2386 17.5 20 17.5Z"
        fill="black"
      />
      <path
        d="M20.6401 35C20.3578 34.2013 20.1632 33.3612 20.0677 32.4913H7.5C7.50356 31.8743 7.88439 30.0261 9.58024 28.3303C11.2109 26.6996 14.2772 25 20 25C20.6512 25 21.2681 25.022 21.8523 25.0634C22.4152 24.2101 23.0912 23.4379 23.859 22.7683C22.6927 22.5954 21.41 22.5 20 22.5C7.5 22.5 5 30 5 32.5C5 35 7.5 35 7.5 35H20.6401Z"
        fill="black"
      />
    </svg>
  );
}

export {
  IconPeopleDashProgress,
  IconPeopleProgress,
  IconPeopleCheckedProgress,
};
