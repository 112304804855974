import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Alignment,
  Underline,
  FontSize,
  List,
  Indent,
  Link,
  Heading
} from "ckeditor5";
import Button from "../../../../components/materials/Button";
import "ckeditor5/ckeditor5.css";

function CreateEditor() {
  return (
    <div className="overflow-hidden bg-white border border-grey-1 rounded-2xl h-full">
      <CKEditor
        editor={ClassicEditor}
        
        config={{
          alignment: {
            options: [ 'left', 'right', 'center' ]
        },
          toolbar: {
            items: [
              "heading",
              "undo", "redo", 
              "|", "bold", "italic", "paragraph" , 
              "|" , 'alignment:left', 'alignment:right', 'alignment:center', "underline" ,
              "fontSize",  "indent" ,"bulletedList" , "link"
            ],
          },
          plugins: [
            Indent,
            Heading,
            Link,
            List,
            FontSize,
            Underline,Alignment,Bold, Essentials, Italic, Mention, Paragraph, Undo],
          initialData: "<p>Hello from CKEditor 5 in React!</p>",
        }}
      />
      <div className="flex justify-end">
      <div className="w-[120px] text-right my-5 pr-5">
        <Button size="sm" variant="primary" >완료</Button>
      </div>
      </div>
     
    </div>
  );
}

export default CreateEditor;
