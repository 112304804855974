import Notices from "../pages/DashboardAdmin/Notices/Notices";
import Bulletin from "../pages/DashboardAdmin/Bulletin/Bulletin";
import CreateBulletin from "../pages/DashboardAdmin/Bulletin/CreateBulletin";
import DetailBulletin from "../pages/DashboardAdmin/Bulletin/DetailBulletin";
import DashboardLayoutAdmin from "../layouts/DashboardLayoutAdmin";
import Home from "../pages/DashboardAdmin/Home/Home";
import MembershipManagement from "../pages/DashboardAdmin/MembershipManagement";
import Grades from "../pages/DashboardAdmin/Grades/Grades";
import Qna from "../pages/DashboardAdmin/QNA/Qna";
import StatusCode from "../pages/DashboardAdmin/StatusCode/StatusCode";
import Wallet from "../pages/DashboardAdmin/Wallet/Wallet";
import DetailNotices from "../pages/DashboardAdmin/Notices/DetailNotices";
import Course from "../pages/DashboardAdmin/Course/Course";
import LecturesManagement from "../pages/DashboardAdmin/LecturesManagement";
import StudentsManagement from "../pages/DashboardAdmin/LecturesManagement/students";
import CreateQna from "../pages/DashboardAdmin/QNA/CreateQna";
import DetailQna from "../pages/DashboardAdmin/QNA/DetailQna";
import NoticeManagement from "../pages/DashboardAdmin/NoticeManagement";
import DetailNoticeManagement from "../pages/DashboardAdmin/NoticeManagement/detail";
import CreateNotice from "../pages/DashboardAdmin/NoticeManagement/create";
import InquiryManagement from "../pages/DashboardAdmin/InquiryManagement";
import DetailInquiry from "../pages/DashboardAdmin/InquiryManagement/detail";
const router = [
  {
    path: "/dashboard-admin",
    element: <DashboardLayoutAdmin />,
    children: [
      { index: true, element: <Home /> },
      { path: "home", element: <Home /> },
      { path: "membership-management", element: <MembershipManagement /> },
      { path: "lectures-management", element: <LecturesManagement /> },
      { path: "students-management", element: <StudentsManagement /> },
      { path: "grades", element: <Grades /> },
      { path: "notices", element: <Notices /> },
      { path: "notices/:id", element: <DetailNotices /> },
      { path: "bulletin", element: <Bulletin /> },
      { path: "bulletin/create", element: <CreateBulletin /> },
      { path: "bulletin/:id", element: <DetailBulletin /> },
      { path: "qna", element: <Qna /> },
      { path: "qna/create", element: <CreateQna /> },
      { path: "qna/:id", element: <DetailQna /> },
      { path: "coding", element: <StatusCode /> },
      { path: "wallet", element: <Wallet /> },
      { path : "notice-management", element : <NoticeManagement/>},
      { path : 'notice-management/:id' ,element :  <DetailNoticeManagement/> },
      { path : 'notice-management/create', element : <CreateNotice/>},
      { path : 'inquiry-management', element : <InquiryManagement/>},
      { path : 'inquiry-management/:id', element : <DetailInquiry/>}
    ],
  },
];

export default router;
