import React from "react";
import IconCoin from "../../../assets/coin.png";

function StatsCardsProfessor() {
  return (
    <div className="flex space-x-6">
      <div className="flex-1 grid grid-cols-2 gap-4 p-4 bg-white rounded-lg shadow h-34 overflow-hidden relative">
        <div className="items-start flex flex-col justify-center w-full">
          <div className="border-b border-gray-100 pb-1 w-full">
            <div className="flex justify-start items-start space-x-1">
              <span className="text-lg text-gray-500">이번달 총 결제금액</span>
            </div>
          </div>
          <div className="pt-1">
            <div className="flex justify-start items-start space-x-1">
              <span className="text-md text-3xl">823,323,000</span>
              <span className="text-md text-gray-400">원</span>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-end">
          <img
            src={IconCoin}
            alt="coin"
            className="w-32 h-32 absolute right-14 bottom-12 transform translate-x-1/2 translate-y-1/2"
          />
        </div>
      </div>

      <div className="w-1/4">
        <div className="flex flex-col justify-between bg-white border border-gray-100 p-5 rounded-lg">
          <div className="border-b border-gray-100 pb-2">
            <h3 className="text-xs font-semibold text-gray-400">이번달</h3>
            <p className="text-md text-grey-8">결제 건수</p>
          </div>
          <div className="flex justify-end items-end pt-2 space-x-1">
            <p className="text-2xl font-bold text-blue-500">+24</p>
            <span className="text-md text-gray-400">개</span>
          </div>
        </div>
      </div>
      <div className="w-1/4">
        <div className="flex flex-col justify-between bg-white border border-gray-100 p-5 rounded-lg">
          <div className="border-b border-gray-100 pb-2">
            <h3 className="text-xs font-semibold text-gray-400">이번달</h3>
            <p className="text-md text-grey-8">수업 강의</p>
          </div>
          <div className="flex justify-end items-end pt-2 space-x-1">
            <p className="text-2xl font-bold text-blue-500">+4</p>
            <span className="text-md text-gray-400">개</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsCardsProfessor;
