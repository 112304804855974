import Logo from "../../../assets/logo.png";
import IconProgramming from "../../Icons/programming";
import IconWeb from "../../Icons/iconWeb";
import IconDataAnalys from "../../Icons/iconDataAnalys";
import IconAi from "../../Icons/iconAi";
import IconAlgoritm from "../../Icons/iconAlgoritm";
import { useState } from "react";
import { ArrowDown2 } from "iconsax-react";
import Avatar from "../../../assets/avatar.png"
import useClickOutside from "../../../hook/useClickOutside";
import { Link } from "react-router-dom";
import Button from "../../materials/Button";
import { useAuthContext } from "../../../context/authContext";
const Navigation = () => {
  const [isMenu, setIsMenu] = useState(false);
  // const [isLogin, setIsLogin] = useState(false);
  const { isLogin } = useAuthContext()
  const openMenu = function () {
    setIsMenu(true);
  };

  const handleClickOutside = function () {
    setIsMenu(false);
  };

  const ref = useClickOutside(handleClickOutside);

  return (
    <nav className="h-16 w-full  z-10 fixed top-0 bg-white">
      <div className="flex items-center justify-between h-full 2xl:px-72 px-40">
        <div className="flex gap-12">
          <Link to={"/"}>
            <img className="w-28" src={Logo} alt="" />
          </Link>

          <div className="flex gap-5 items-center relative">
            {/* lecture */}
            <div
              ref={ref}
              onClick={openMenu}
              className=" flex gap-2 cursor-pointer "
            >
              <div className="text-sm font-bold text-primary">강의 </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
                    stroke="#2861EF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {isMenu && (
                <div className="shadow-menu rounded-b-xl top-11 absolute bg-white  w-[173px]">
                  {/* programming foundation */}
                  <Link to="/lectures">
                    <div className="flex items-center gap-2 px-4 py-2">
                      <IconProgramming />
                      <div className="text-grey-6 text-sm font-medium">
                        프로그래밍 기초
                      </div>
                    </div>
                  </Link>

                  {/* Web */}
                  <div className="flex items-center gap-2 px-4 py-2">
                    <IconWeb />
                    <div className="text-grey-6 text-sm font-medium">웹</div>
                  </div>
                  {/* Data Analys */}
                  <div className="flex items-center gap-2 px-4 py-2">
                    <IconDataAnalys />
                    <div className="text-grey-6 text-sm font-medium">
                      데이터 분석
                    </div>
                  </div>
                  {/* Artificial intelligence */}
                  <div className="flex items-center gap-2 px-4 py-2">
                    <IconAi />
                    <div className="text-grey-6 text-sm font-medium">
                      인공지능
                    </div>
                  </div>
                  {/* Algorithms */}
                  <div className="flex items-center gap-2 px-4 py-2">
                    <IconAlgoritm />
                    <div className="text-grey-6 text-sm font-medium">
                      알고리즘
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* introduction */}
            <Link to={"/introduction"}>
              <div className="text-grey-6 text-sm font-medium">소개</div>
            </Link>
            {/* plan */}
            <div className="text-grey-6 text-sm font-medium">플랜</div>

            {/* Announcement */}
            <Link to="/announcement">
              <div className="text-grey-6 text-sm font-medium">공지</div>
            </Link>

            {/* inquiry */}
            <Link to={"/inquiry"}>
              <div className="text-grey-6 text-sm font-medium">문의</div>
            </Link>
          </div>
        </div>
          {isLogin ? (
            <div className="flex items-center gap-2 ">
              <div className="bg-bg_2 overflow-hidden w-[30px] h-[30px] rounded-full">
                <img src={Avatar} alt="" />
              </div>
              <div className="flex items-center gap-1">
                <div className="text-xs font-bold text-grey-6">김채피님</div>
                <div className="text-grey-3"><ArrowDown2 size={15}/></div>
              </div>
              <div className="w-[92px]">
              {/* learning board */}
                <Button size="sm" variant="outline_primary" >학습보드</Button>
              </div>
            </div>
          ) : (
            // join Membership
            <div className="flex gap-7">
              <Link to={"/join-membership"}>
                <div className="text-grey-6 text-sm font-medium">회원가입</div>
              </Link>
              {/* login */}
              <Link to={"/login"}>
                <div className="text-grey-6 text-sm font-medium">로그인</div>
              </Link>
            </div>
          )}
      </div>
    </nav>
  );
};

export default Navigation;
