import Button from "../../../components/materials/Button";
const RightSide = () => {
  return (
    <div className="px-10 py-8 rounded-2xl border border-grey-1 ">
      <div
        className="
        grid gap-4 px-5 py-4 bg-bg_1 rounded-lg
        h-max  mb-7"
      >
        <div className="pb-4 flex gap-9 items-start border-b border-b-grey-1 text-grey-6">
          <div className="basis-14 text-sm font-bold">강의구성</div>
          <div className=" text-sm font-medium">15차시</div>
        </div>
        <div className="pb-4 flex gap-9 items-start border-b border-b-grey-1 text-grey-6">
          <div className="basis-14 text-sm font-bold">강의기간</div>
          <div className=" text-sm font-medium">2023-03-01 ~ 2023-05-31</div>
        </div>
        <div className="pb-4 flex gap-9 items-start border-b border-b-grey-1 text-grey-6">
          <div className="basis-14 text-sm font-bold">모집인원</div>
          <div className=" text-sm font-medium">25명</div>
        </div>
        <div className="pb-4 flex gap-9 items-start border-b border-b-grey-1 text-grey-6">
          <div className="basis-14 text-sm font-bold">수강신청 가능기간</div>
          <div className=" text-sm font-medium">2023-02-15 ~ 2023-03-01</div>
        </div>
        <div className="pb-4 flex gap-9 items-start border-b border-b-grey-1 text-grey-6">
          <div className="basis-14 text-sm font-bold">수강조건</div>
          <div className=" text-sm font-medium">웹기획 기초 I</div>
        </div>
      </div>
      <Button variant="primary">수강신청하기</Button>
    </div>
  );
};

export default RightSide;
