import ExclamationImage from "../../assets/exclamation.png";
import PencilImage from "../../assets/pencil.png";
import BrowserImage from "../../assets/browser.png";
const Story = () => {
  return (
    <div className="relative px-[200px] 2xl:px-[360px]">
      <div
        className="
          py-10  rounded-[20px] -translate-y-[80px] bg-white 
          left-0 right-0 m-auto -top-[60px] 
        "
      >
        <div className=" text-blue-4 text-center font-bold text-xl">
          Our Story
        </div>
        <div className="mt-7 text-center text-black text-[40px] font-bold ">
          왜 코딩이 재미없을까요?
        </div>
        <div className="text-grey-6 mt-12 text-center text-base font-medium">
          매년 약 15% 씩 증가하는 프로그래밍 교육 시장의 성장 대비, 교육
          현장에서는 솔루션이 이를 따라가지 못하고 있습니다.
        </div>
        <div className="text-grey-6  text-center text-base font-medium">
          비효율적이고 성취도를 확인할 수 없는 채점 방식, 오답 코드 수정에 대한
          솔루션 부재, 프로그램 언어(코드)의 어려움은 코딩을 사랑할 수 없게
          만듭니다.
        </div>
        <div className="mt-[150px] grid grid-cols-3 gap-7 px-10">
          <div className="p-10 rounded-[20px] bg-[#86A9FF] ">
            <div>
              <div className="text-white mb-5 text-2xl font-bold">
                오답에 대한 솔루션
              </div>
              <div className="text-white text-sm font-bold">
                체점 알고리즘을 통한{" "}
              </div>
              <div className="text-white text-sm font-bold">
                사용자 성취도 및 스킬 분석 가능
              </div>
            </div>
            <div className="mt-[70px] flex justify-end">
              <img src={ExclamationImage} alt="" />
            </div>
          </div>
          <div className="p-10 rounded-[20px] bg-[#9391FF] -translate-y-20">
            <div>
              <div className="text-white mb-5 text-2xl font-bold">
                비효율적인 체점 방식
              </div>
              <div className="text-white text-sm font-bold">
                체점 알고리즘을 통한{" "}
              </div>
              <div className="text-white text-sm font-bold">
                사용자 성취도 및 스킬 분석 가능
              </div>
            </div>
            <div className="mt-[70px] flex justify-end">
              <img src={PencilImage} alt="" />
            </div>
          </div>
          <div className="p-10 rounded-[20px] bg-[#7BB8FF] ">
            <div>
              <div className="text-white mb-5 text-2xl font-bold">
                오답에 대한 솔루션
              </div>
              <div className="text-white text-sm font-bold">
                체점 알고리즘을 통한{" "}
              </div>
              <div className="text-white text-sm font-bold">
                사용자 성취도 및 스킬 분석 가능
              </div>
            </div>
            <div className="mt-[70px] flex justify-end">
              <img src={BrowserImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
