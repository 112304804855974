export default function IconLetter() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.625 4.8875C7.28673 4.8875 7.0125 5.16173 7.0125 5.5C7.0125 5.83827 7.28673 6.1125 7.625 6.1125H14.375C14.7132 6.1125 14.9875 5.83827 14.9875 5.5C14.9875 5.16173 14.7132 4.8875 14.375 4.8875H7.625Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
      <path
        d="M7.625 7.1375C7.28673 7.1375 7.0125 7.41173 7.0125 7.75C7.0125 8.08822 7.28673 8.3625 7.625 8.3625H14.375C14.7132 8.3625 14.9875 8.08823 14.9875 7.75C14.9875 7.41173 14.7132 7.1375 14.375 7.1375H7.625Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
      <path
        d="M7.625 9.3875C7.28673 9.3875 7.0125 9.66178 7.0125 10C7.0125 10.3382 7.28673 10.6125 7.625 10.6125H14.375C14.7132 10.6125 14.9875 10.3382 14.9875 10C14.9875 9.66177 14.7132 9.3875 14.375 9.3875H7.625Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
      <path
        d="M7.625 11.6375C7.28673 11.6375 7.0125 11.9118 7.0125 12.25C7.0125 12.5882 7.28673 12.8625 7.625 12.8625H14.375C14.7132 12.8625 14.9875 12.5882 14.9875 12.25C14.9875 11.9118 14.7132 11.6375 14.375 11.6375H7.625Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
      <path
        d="M7.625 13.8875C7.28673 13.8875 7.0125 14.1618 7.0125 14.5C7.0125 14.8382 7.28673 15.1125 7.625 15.1125H14.375C14.7132 15.1125 14.9875 14.8382 14.9875 14.5C14.9875 14.1618 14.7132 13.8875 14.375 13.8875H7.625Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
      <path
        d="M3.075 3.25V15.0125H2.28125C1.16632 15.0125 0.2625 15.9163 0.2625 17.0312C0.2625 18.1462 1.16632 19.05 2.28125 19.05H16.625C17.8953 19.05 18.925 18.0203 18.925 16.75V4.9875H19.7188C20.8337 4.9875 21.7375 4.08368 21.7375 2.96875C21.7375 1.85382 20.8337 0.95 19.7188 0.95H5.375C4.10474 0.95 3.075 1.97974 3.075 3.25ZM5.375 2.175H16.625C17.2187 2.175 17.7 2.6563 17.7 3.25V16.75C17.7 17.3437 17.2187 17.825 16.625 17.825H5.375C4.7813 17.825 4.3 17.3437 4.3 16.75V3.25C4.3 2.6563 4.7813 2.175 5.375 2.175ZM3.075 16.75C3.075 17.1383 3.17123 17.5041 3.34117 17.825H2.28125C1.84288 17.825 1.4875 17.4696 1.4875 17.0312C1.4875 16.5929 1.84288 16.2375 2.28125 16.2375H3.075V16.75ZM18.925 3.25C18.925 2.86176 18.8288 2.49586 18.6588 2.175H19.7188C20.1571 2.175 20.5125 2.53038 20.5125 2.96875C20.5125 3.40712 20.1571 3.7625 19.7188 3.7625H18.925V3.25Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
    </svg>
  );
}
