const IconPlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 4V20"
        stroke="#C3C3C3"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 12L20 12"
        stroke="#C3C3C3"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconPlus;
