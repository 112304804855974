type IconProps = {
  color? :string
}
const IconCode : React.FC<IconProps> = ({
  color = "#333333"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M8.9342 5.0436C8.61286 4.71899 8.09022 4.71705 7.76618 5.03895C7.76463 5.0405 7.76308 5.04205 7.76153 5.0436L6.10958 6.69848C5.78554 7.02038 5.7836 7.54396 6.10493 7.86857C6.10648 7.87012 6.10803 7.87167 6.10958 7.87323L7.76153 9.5281C8.08286 9.85272 8.60551 9.85466 8.92955 9.53276C8.9311 9.53121 8.93265 9.52965 8.9342 9.5281C9.25824 9.2062 9.26017 8.68263 8.93884 8.35802C8.93729 8.35646 8.93574 8.35491 8.9342 8.35336L7.86064 7.28605L8.9342 6.21873C9.25824 5.89683 9.26017 5.37326 8.93884 5.04865C8.93729 5.0471 8.93574 5.04554 8.9342 5.04399V5.0436Z"
        fill={color}
      />
      <path
        d="M12.2373 5.04302C11.9133 4.71841 11.3883 4.71841 11.0647 5.04302C10.741 5.36764 10.7406 5.89354 11.0647 6.21776L12.1382 7.28508L11.0647 8.35239C10.7406 8.67429 10.7387 9.19786 11.06 9.52248C11.0616 9.52403 11.0631 9.52558 11.0647 9.52713C11.386 9.85175 11.9086 9.85369 12.2327 9.53179C12.2342 9.53024 12.2358 9.52868 12.2373 9.52713L13.8893 7.87226C14.2133 7.55035 14.2153 7.02678 13.8939 6.70217C13.8924 6.70062 13.8908 6.69906 13.8893 6.69751L12.2373 5.04263V5.04302Z"
        fill={color}
      />
      <path
        d="M18.2592 12.2501V3.14887C18.2592 1.77789 17.15 0.666748 15.7815 0.666748H4.21856C2.85038 0.666748 1.74082 1.77789 1.74082 3.14887V12.2501C1.28476 12.2501 0.915039 12.6205 0.915039 13.0774V14.7322C0.915039 16.1032 2.02421 17.2144 3.39277 17.2144H16.6072C17.9758 17.2144 19.085 16.1032 19.085 14.7322V13.0774C19.085 12.6205 18.7152 12.2501 18.2592 12.2501ZM3.39277 3.14887C3.39277 2.69201 3.7625 2.32163 4.21856 2.32163H15.7815C16.2375 2.32163 16.6072 2.69201 16.6072 3.14887V12.2501H3.39277V3.14887ZM17.4334 14.7322C17.4334 15.1891 17.0637 15.5595 16.6076 15.5595H3.39277C2.93672 15.5595 2.56699 15.1891 2.56699 14.7322V13.905H17.4334V14.7322Z"
        fill={color}
      />
    </svg>
  );
};

export default IconCode;
