import React from "react";
import DetailProgressLearning from "../../DashboardLearning/components/DetailProgressLearning";
import InfoClassroom from "../components/infoClassroom";
import ProgressBarClassroom from "../components/progressBarClassroom";

function Classroom() {
  return (
    <div className="bg-white p-6 rounded-lg shadow min-h-screen flex flex-col">
      <div className="space-y-6 flex flex-col mb-6">
        <InfoClassroom />
        <ProgressBarClassroom />
        <DetailProgressLearning />
      </div>
    </div>
  );
}

export default Classroom;
