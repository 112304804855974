import Input from "../../components/materials/Input"
import Button from "../../components/materials/Button"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from '@hookform/resolvers/zod'
import Logo from "../../assets/logo.png"
import { useAuthContext } from "../../context/authContext"

const loginFormSchema = z.object({
  email: z.string().email({ message: "이메일을 다시 확인해주세요" }),
  password: z.string().min(1, { message: "비밀번호를 다시 확인해주세요." })
})

type LoginSchema = z.infer<typeof loginFormSchema>

const Login = () => {
  const { register, handleSubmit, formState } = useForm<LoginSchema>({
    resolver: zodResolver(loginFormSchema),
    mode: 'onChange'
  })

  const { loginHandler } = useAuthContext()

  const onSubmit = handleSubmit((values) => {
    return loginHandler(values)
  })

  return (
    <div className="bg-home  h-[calc(100vh-4rem)]  bg-cover bg-no-repeat">
      <div className='h-full  bg-[linear-gradient(271deg, _#FFF 30.88%, _rgba(255, 255, 255, 0.00) 65.26%)]'
        style={{ background: 'linear-gradient(271deg, #FFF 30.88%, rgba(255, 255, 255, 0.00) 65.26%)' }}
      >
        <div className='w-[336px] absolute top-0 right-8 bottom-0 flex items-center'>
          <div className='w-full'>
            <div className="flex flex-col items-center mb-8 gap-4">
              <div className="text-lg font-medium text-grey-6">코딩을 행복하게-</div>
              <img className="w-[187px]" src={Logo} alt="" />
            </div>
            <form noValidate onSubmit={onSubmit} className="grid gap-8">
              <div className="grid gap-3">
                <Input
                  id="email"
                  placeholder='이메일'
                  type="email"
                  variant={formState.errors.email && 'error' }  
                  errorMessage={formState.errors.email && formState.errors.email.message}
                  {...register('email')}
                />
                <Input
                  id="password"
                  placeholder='비밀번호'
                  type="password"
                  autoComplete="a"
                  variant={formState.errors.password && 'error' } 
                  errorMessage={formState.errors.password && formState.errors.password.message}
                  {...register('password')}
                />
              </div>

              <Button disabled={!formState.isValid} type="submit" variant="primary">로그인</Button>
            </form>
            <div className="grid grid-cols-3 mt-8">
              <div className="text-sm text-grey-3 font-medium text-center border-r border-grey-1">아이디 찾기</div>
              <div className="text-sm text-grey-3 font-medium text-center border-r border-grey-1">비밀번호 찾기</div>
              <div className="text-sm text-grey-3 font-medium text-center">회원가입</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Login