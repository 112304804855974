import React, { useState } from "react";
import CourseImage from "../../../assets/course-image.png";
import Input from "../../../components/materials/Input";
import SelectDropdown from "../../../components/materials/SelectDropdown";
import Breadcrumb from "../../../components/materials/Breadcrump";
import { useParams } from "react-router-dom";
import Modal from "../../../components/modal";
import Button from "../../../components/materials/Button";
import { useNavigate } from "react-router-dom";

interface CheckHistoryProps {
  id: number;
  no: number;
  date: string;
  course: string;
  payment: string;
  progress: string;
}

const dataCheckHistory: CheckHistoryProps[] = [
  {
    id: 1,
    no: 1,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 2,
    no: 2,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 3,
    no: 3,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 4,
    no: 4,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 5,
    no: 5,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 6,
    no: 6,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 7,
    no: 7,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 8,
    no: 8,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 9,
    no: 9,
    date: "23.02.04 23:59",
    course: "기초 css 배우기",
    payment: "카드",
    progress: "결제확인",
  },
  {
    id: 10,
    no: 10,
    date: "23.02.04 23:59",
    course: "기초 Javascript 배우기",
    payment: "카드",
    progress: "결제확인",
  },
];

export default function DetailCheckHistory() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [selectedBank, setSelectedBank] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showModalConfirmRefund, setShowModalConfirmRefund] = useState(false);

  const openModalConfirmRefund = () => setShowModalConfirmRefund(true);
  const closeodalConfirmRefund = () => setShowModalConfirmRefund(false);

  const checkHistoryDetail = dataCheckHistory.find(
    (item) => item.id === parseInt(id || "", 10)
  );

  if (!checkHistoryDetail) {
    return (
      <div className="bg-white p-6 rounded-lg text-center">
        Notice not found
      </div>
    );
  }

  const handleChangeBank = (selectedOption: any) => {
    setSelectedBank(selectedOption);
  };

  return (
    <div className="bg-white min-h-screen p-6 rounded-lg shadow">
      <Breadcrumb
        items={[
          { title: "내역확인", path: "/dashboard/check-history" },
          {
            title: "환불 신청",
            path: `/dashboard/check-history/${checkHistoryDetail.id}`,
          },
        ]}
      />
      <h2 className="text-xl font-bold mb-4">환불 신청</h2>
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">신청 정보</h3>
        <div className="border bg-gray-50 p-4 rounded-lg justify-between items-center">
          <div className="grid grid-cols-[30%_70%] gap-4 items-center">
            <img
              src={CourseImage}
              alt="Course"
              className="w-full h-36 rounded-lg"
            />
            <div className=" space-y-2">
              <p className="text-xl font-bold border-b pb-2">
                {checkHistoryDetail.course}
              </p>
              <p className="text-gray-500">최종 결제 금액</p>

              <p className="text-2xl text-red-500 font-bold">25,200원</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2 border-b pb-2">입금 정보</h3>
        <div className="grid grid-cols-2 gap-4 p-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              예금주 명
            </label>
            <Input id="name" placeholder="예금주 명" type="text" />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              은행 명
            </label>
            <SelectDropdown
              options={[
                { value: "", label: "옵션을 선택하세요" },
                { value: "1", label: "국민은행" },
                { value: "2", label: "신한은행" },
                { value: "3", label: "우리은행" },
              ]}
              value={selectedBank}
              onChange={handleChangeBank}
              size="py-3.5 px-4 h-14 rounded-xl"
            />
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              계좌 번호
            </label>
            <Input id="account-member" placeholder="예금주 명" type="number" />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h3 className="text-lg font-semibold border-b pb-2 mb-2">회원 확인</h3>
        <div className="grid grid-cols-1 p-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              비밀번호 입력
            </label>
            <Input id="password" placeholder="비밀번호 입력" type="password" />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2 border-b pb-2">
          환불 안내 및 개인정보 수집 동의
        </h3>
        <p className="text-sm text-gray-600 p-4">
          입금정보를 신청하신 계좌로 입금되며, 신청하신 회원 및 계정 명의자가
          일치해야 가능합니다. 입금금은 신청일로부터 3일 (영업일 기준) 이내에
          입금됩니다. 환불정보 수집 후 입력하신 계좌정보는 수정이 불가합니다.
        </p>
      </div>
      <div className="mb-6 flex items-center">
        <input
          type="checkbox"
          className="h-4 w-4 text-blue-600 border-gray-300 rounded"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <label className="ml-2 text-sm text-gray-700">
          모든 내용을 확인했으며, 이에 동의합니다
        </label>
      </div>
      <button
        className={`w-full py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
          isChecked ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-500"
        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
          isChecked ? "" : "cursor-not-allowed"
        }`}
        disabled={!isChecked}
        onClick={openModalConfirmRefund}
      >
        환불 신청
      </button>
      {showModalConfirmRefund && (
        <Modal
          title="환불신청완료"
          onClose={() => {
            closeodalConfirmRefund();
          }}
          size="sm"
        >
          <div className="space-y-4">
            <div className="text-center">
              <p className="text-sm text-gray-500">
                환불신청이 성공적으로 완료되었습니다. 신청하신 정보는 결제내역
                에서 확인 가능합니다
              </p>
            </div>
            <div className="flex justify-center space-x-4">
              <Button
                size="sm"
                variant="primary"
                onClick={() => {
                  closeodalConfirmRefund();
                  navigate("/dashboard/check-history");
                }}
              >
                닫기
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
