import { ArrowRight2 } from "iconsax-react";
import React from "react";
import { Link } from "react-router-dom";

interface BreadcrumbItem {
  title: string;
  path?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-2 mb-4">
        {items.map((item, index) => (
          <li key={index} className="inline-flex items-center">
            {item.path ? (
              <Link
                to={item.path}
                className="text-xs font-medium text-gray-700 hover:text-blue-600"
              >
                {item.title}
              </Link>
            ) : (
              <span className="text-xs font-medium text-gray-500">
                {item.title}
              </span>
            )}
            {index < items.length - 1 && (
              <span className="mx-2 text-gray-400">
                <ArrowRight2 size={12} />
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
