import React from "react";
import Button from "../../../components/materials/Button";

type CourseCardProps = {
  title: string;
  instructor: string;
  instructorImage: string;
  progress?: number;
  status: string;
  startDate: string;
  endDate: string;
  badge: string;
  src: string;
};

const CourseCard: React.FC<CourseCardProps> = ({
  title,
  instructor,
  instructorImage,
  progress,
  status,
  startDate,
  endDate,
  badge,
  src,
}) => {
  return (
    <div className="bg-gray-50 border border-gray-100 rounded-lg overflow-hidden p-4">
      <img src={src} alt="Course" className="w-full " />
      <div className="">
        <h3 className="text-lg font-bold mt-2">{title}</h3>
        <p className="text-xs text-gray-500">{`${startDate} ~ ${endDate}`}</p>
        <div className="flex items-center my-4">
          <img
            src={instructorImage}
            alt={instructor}
            className="w-6 h-6 rounded-full mr-2"
          />
          <p className="text-sm text-gray-500">{instructor}</p>
          <span className="ml-auto bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded">
            {badge}
          </span>
        </div>
        {progress && (
          <div className=" bg-white rounded-lg mb-4 p-2 px-4">
            <p className="text-sm text-center border-b font-semibold mb-1 p-2 ">
              진도율
            </p>

            <div className="flex justify-center gap-2 items-center mb-1">
              <p className="text-sm font-bold text-blue-600">{`${progress}%`}</p>
              <p className="text-sm text-gray-500 mt-1">{status} 😆</p>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-2">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        )}
        <Button size="sm" variant="primary">
          수강하기
        </Button>
      </div>
    </div>
  );
};

export default CourseCard;
