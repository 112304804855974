const IconRefresh = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M30.666 26.6667V18.6667H22.666"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33398 5.33325V13.3333H9.33398"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3207 12C26.6444 10.0891 25.4951 8.38056 23.98 7.03391C22.4649 5.68726 20.6334 4.74637 18.6563 4.29903C16.6792 3.85169 14.621 3.91247 12.6737 4.47572C10.7265 5.03896 8.95365 6.0863 7.52065 7.52002L1.33398 13.3334M30.6673 18.6667L24.4807 24.48C23.0477 25.9137 21.2748 26.9611 19.3276 27.5243C17.3803 28.0876 15.3221 28.1484 13.345 27.701C11.3679 27.2537 9.53638 26.3128 8.02127 24.9661C6.50616 23.6195 5.35687 21.911 4.68065 20"
        stroke="#A5A5A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconRefresh;
