import Avatar from "../../assets/avatar.png";
import IconGear from "../../components/Icons/iconGear";
import { ArrowRight2 } from "iconsax-react";
import Dialog from "./dialog";
import { useState } from "react";
import Button from "../../components/materials/Button";
import { useNavigate } from "react-router-dom";
const Left = () => {
  const [isOpen, setOpen] = useState(false);
  const navigation = useNavigate()

  const setUpDialog = function () {
    setOpen(!isOpen);
  };

  const openChangePasswordPage = function () {
    navigation('/info-profile/change-password')
  }

  const openEditProfile = function () {
    navigation('/info-profile/edit')
  }
  return (
    <div>
      <div
        className="
          border border-grey-1 rounded-2xl
           bg overflow-hidden
        "
      >
        <div className="w-full p-6 bg-bg_1">
          <Dialog width="390" isDialog={isOpen} setUpDialog={setUpDialog}>
            <div className="grid gap-7">
              <div className="text-center text-grey-8 font-bold text-lg">
                회원탈퇴
              </div>
              <div className="text-center m-auto w-[160px] text-grey-6 text-sm font-medium">
                탈퇴 시 모든 정보는 삭제되며 계정은 복구되지 않습니다.
              </div>
              <div className="grid gap-2">
                <Button onClick={setUpDialog} size="sm" variant="primary">
                  닫기
                </Button>
                <Button onClick={setUpDialog} size="sm" variant="grey">
                  탈퇴하기
                </Button>
              </div>
            </div>
          </Dialog>
          <div className="w-24 h-24 overflow-hidden rounded-full bg-bg_2 m-auto">
            <img src={Avatar} alt="" />
          </div>
          <div className="mt-5 flex justify-center gap-2 items-center">
            <div className="text-grey-8 text-xl font-bold">김채피</div>
            <div
              className="
              text-blue-4 text-xs font-medium
              border border-blue-4 bg-bg_2 rounded-[4px]
              px-2 py-1
            "
            >
              소속없음
            </div>
          </div>
          <div className="text-grey-12 mt-1.5 text-center text-sm font-medium mb-6">
            chappy@naasf.com
          </div>
          <div
            onClick={openEditProfile}
            className="
          flex justify-center
          cursor-pointer
          w-full text-blue-4 border border-blue-4
          rounded-[10px] bg-white
          py-[10px] items-center gap-1
        "
          >
            <div>프로필 수정</div>
            <IconGear />
          </div>
        </div>

        <div className="w-full p-6 bg-white">
          <div className="flex pb-5 items-center justify-between">
            <div className="text-grey-9 text-base font-bold">내 프로필</div>
            <div className="text-grey-6">
              <ArrowRight2 />
            </div>
          </div>
          <div onClick={openChangePasswordPage}  className="cursor-pointer flex pt-5 items-center justify-between border-t border-t-grey-1">
            <div className="text-grey-6 text-base font-medium">
              비밀번호 변경
            </div>
            <div className="text-grey-6">
              <ArrowRight2 />
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={setUpDialog}
        className="
          mt-5
          cursor-pointer text-grey-3 text-xs font-medium text-center
        "
      >
        회원탈퇴
      </div>
    </div>
  );
};

export default Left;
