import { type ReactNode, createContext, useState, useContext, useEffect } from "react";
import { CODE_SNIPPETS, CODE_FILE, CODE_SNIPPETS_MODIFIED } from "../pages/Editor/constants";
import { executeCode } from "../pages/Editor/api";
type EditorType = {
  isDark : boolean,
  setThemeEditor : (status : boolean) => void,
  languange : string,
  setLanguangeEditor : (lang : string) => void,
  value : string,
  setValueEditor : (v : any) => void,
  runCode : () => void,
  output : any,
  uploadFile : (file : File) => void,
  acceptedFile : string,
  copyCode : () => void,
  downloadCode : () => void,
  modified : string
}

type EditorContextProviderProps = {
  children : ReactNode
}

const EditorContext = createContext<EditorType | null >(null)

function useEditorContext () {
  const editorCtx = useContext(EditorContext)
  if(editorCtx === null) {
    throw new Error('Editor Context Error')
  }else{
    return editorCtx
  }
}

function EditorContextProvider ({
  children
}: EditorContextProviderProps) {
  const [isDark, setIsDark] = useState<boolean>(false)
  const [languange, setLanguange] = useState('')
  const [value, setValue] = useState('')
  const [modified, setModified] = useState('')
  const [output, setOutput] = useState(null);
  const [acceptedFile, setAcceptedFile] = useState('')

  useEffect(() => {
    setAcceptedFile(CODE_FILE[languange as keyof typeof CODE_FILE])
  },[languange])
  const setThemeEditor = function (status : boolean) {
    setIsDark(status)
  }

  const setValueEditor = function (v : any) {
    setValue(v)
  }

  const setLanguangeEditor = function (lang : string) {
    setLanguange(lang)
    setValue(CODE_SNIPPETS[lang as keyof typeof CODE_SNIPPETS])
    setModified(CODE_SNIPPETS_MODIFIED[lang as keyof typeof CODE_SNIPPETS_MODIFIED])
   
  }

  const runCode = async function () {
    return executeCode(languange, value)
      .then(({run}) => {
        setOutput(run.output.split("\n"));
      })
      .catch(err => {
        console.log(err)
      })
  }

  const uploadFile = function (file : File) {
    let reader = new FileReader()
    reader.onload = async (e : any) => {
      setValue(e.target?.result)
    }
    reader.readAsText(file)
    const extension = file.name.split('.').pop();
    const languange = getLanguangeByTypeFile(`.${extension}`)
    setLanguange(languange!)
    reader.onerror = function () {     

      console.log('Error occured while reading file!');      
    
    };
  }

  const copyCode = function () {
    navigator.clipboard.writeText(value)
  }

  const downloadCode = function () {
    const blob = new Blob([value])
    const downloadUrl = window.URL.createObjectURL(blob)
    const fileName = `file${CODE_FILE[languange as keyof typeof CODE_FILE]}`
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', fileName)
    link.click()
    link.remove()
  }

  const getLanguangeByTypeFile = function (type : string | undefined ) {
    return Object.keys(CODE_FILE).find(key => CODE_FILE[key as keyof typeof CODE_FILE] === type )
  }

  const ctx : EditorType = {
    isDark,
    setThemeEditor,
    languange,
    setLanguangeEditor,
    value,
    setValueEditor,
    runCode,
    output,
    uploadFile,
    acceptedFile,
    copyCode,
    downloadCode,
    modified
  }

  return <EditorContext.Provider value={ctx}> {children} </EditorContext.Provider>
}

export {
  EditorContext,
  EditorContextProvider,
  useEditorContext
}

