import DummyImage from "../../assets/dummyimage.png";
import { useNavigate } from "react-router-dom";


const List = () => {
  const arrayDummy = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const navigate = useNavigate()
  const handlerClick = function () {
    navigate('/announcement/detail')
  }
  return (
    <div>
      <div className="grid grid-cols-4 gap-7">
        {arrayDummy.map((i) => {
          return (
            <div onClick={handlerClick} key={i} className="relative cursor-pointer ">
              <div className="
              text-blue-4 text-sm font-medium
                rounded-[4px] border border-blue-3 bg-blue-1
                px-3 py-1.5 absolute top-4 left-4
              ">
                뉴스
              </div>
              <img src={DummyImage} alt="" />
              <div className="text-grey-9 text-base font-bold truncate">CHAT GPT 4.0과 숨막히는 코딩대결! 인공지능끼리 진검승부</div>
              <div className="text-grey-3 text-sm font-medium">2023. 12. 27</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default List;
