import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { courseData } from "../constans";

type CourseData = {
  id: number;
  title: string;
  instructor: string;
  instructorImage: string;
  progress: number;
  status: string;
  category: string;
  startDate: string;
  endDate: string;
  badge: string;
  src: string;
  chapter?: {
    id: number;
    title: string;
    slug: string;
    progress: number;
    status: string;
    category: string;
    startDate: string;
    endDate: string;
    badge: string;
    src: string;
  }[];
};

type CourseStore = {
  courseData: CourseData[];
};

export const useCourseStore = create<CourseStore>()(
  persist(
    (set) => ({
      courseData: courseData,
    }),
    {
      name: "course-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
