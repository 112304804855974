import Split from "react-split"
import "../../Editor/content/content.css"
import LeftSide from "../../Editor/content/leftSide";
import ContentEditor from "./diffeditor"
import RightSide from "./rightSide";
const Content = () => {
  return ( 
    <div className="h-[calc(100vh-88px)]" >
      <Split
        className="flex flex-row"
        gutterSize={14}
        sizes={[25, 50, 25]}
        style={{
          height : "100%"
        }}
      >
        <LeftSide/>
        <ContentEditor/>
        <RightSide/>
      </Split>
    </div>
   );
}
 
export default Content;