import React, { useState } from "react";
import Search from "../../../components/materials/Search";
import { Table } from "../../../components/tables";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination";
import { useParams } from "react-router-dom";
import { AddCircle } from "iconsax-react";

interface Notice {
  id: number;
  no: number;
  course: string;
  title: string;
  date: string;
}

const noticesData: Notice[] = [
  {
    id: 1,
    no: 1,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    date: "23.02.04 23:59",
  },
  {
    id: 2,
    no: 2,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    date: "23.02.04 23:59",
  },
  {
    id: 3,
    no: 3,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    date: "23.02.04 23:59",
  },
  {
    id: 4,
    no: 4,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    date: "23.02.04 23:59",
  },
  {
    id: 5,
    no: 5,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    date: "23.02.04 23:59",
  },
];

const columns: ColumnDef<Notice>[] = [
  {
    accessorKey: "no",
    header: "NO.",
  },
  {
    accessorKey: "course",
    header: "강의명",
  },
  {
    accessorKey: "title",
    header: "제목",
  },
  {
    accessorKey: "date",
    header: "작성일",
  },
];

export default function NoticesClassroom() {
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const navigate = useNavigate();
  const { id } = useParams();

  const totalPages = Math.ceil(noticesData.length / dataPerPage);

  return (
    <div className="bg-white min-h-screen p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold border-b pb-2">게시판</h2>
      <div className="flex justify-between items-center">
        <div className="flex justify-end">
          <div className="relative mt-4">
            <Search placeholder="검색..." />
          </div>
        </div>
        <div className="flex justify-end">
          <div className="flex justify-end m-4">
            <button
              className="flex justify-center gap-1 items-center bg-primary px-4 py-2 text-white rounded-lg"
              onClick={() => navigate(`/classroom/${id}/notices/create`)}
            >
              글쓰기
              <AddCircle size={18} />
            </button>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={noticesData}
        onRowClick={(row) => {
          navigate(`/classroom/${id}/notices/${row.id}`);
        }}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}
