import { ArrowLeft, Timer1 } from "iconsax-react";
import Selector from "./languangeSelector";
import Setting from "./setting";
import Cheppy from "../../../assets/chappyBlack.png";
import { useEditorContext } from "../../../context/editorContext";
import { useNavigate } from "react-router-dom";
const NavigationBar = () => {
  const navigation = useNavigate()
  const { isDark } = useEditorContext();
  const homeHandler = function () {
    navigation('/')
  }
  return (
    <nav className="grid grid-cols-[25%_1fr_25%] p-5 bg-bg_4 dark:bg-grey-7">
      <div onClick={homeHandler} className="cursor-pointer">
        {isDark ? (
          <div className="flex w-max items-center gap-2 border border-grey-8 rounded-lg bg-grey-9 px-3 py-2">
            <ArrowLeft color="white" />
            <div className="text-white text-base font-bold">코딩 종료</div>
          </div>
        ) : (
          <img src={Cheppy} alt="" />
        )}
      </div>
      <div>
        <Selector />
      </div>
      <div className="flex items-center gap-8 justify-end">
        <div
          className="
          w-max flex items-center gap-2 text-grey-5 text-base font-bold bg-grey-1 rounded-lg px-4 py-3
          dark:bg-grey-6 dark:text-grey-1
        "
        >
          <Timer1 size="20" />
          <div>남은 시간 : 2일 13시간 30분</div>
        </div>
        <Setting />
      </div>
    </nav>
  );
};

export default NavigationBar;
