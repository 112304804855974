import React from "react";
import MdiEdit from "../../assets/mdi_edit.png";

type Headers = {
  accessorKey: string;
  header: string;
  width: string;
};

type DataTable = {
  id: number;
  member: string;
  lectureID: number;
  courseName: string;
  RepLecture: string;
  OpenDate: string;
  state: string;
  DiscStatus: string;
  students: string;
};
type ManagemenetLectureTableProps = {
  columns: Headers[];
  rows: DataTable[];
  onChange?: (value: string) => void;
};

const ManagemenetLectureTable: React.FC<ManagemenetLectureTableProps> = ({
  columns,
  rows,
  onChange,
}) => {
  return (
    <div className="flex overflow-hidden overflow-x-scroll rounded-2xl border border-[#E0E0E0]">
      <table className="w-full table-fixed ">
        <thead>
          <tr className="h-[48px] bg-[#E0E0E0] border-b border-[#E0E0E0]">
            {columns?.map((col: any, ky1: number) => (
              <th key={ky1} className={`w-[100%] text-center`}>
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((cols, ky2) => (
            <tr
              key={ky2}
              className={`${
                ky2 + 1 === rows.length ? "" : "border-b"
              }  h-[48px]`}
            >
              <td className="text-sm font-medium text-center break-words">
                {cols.member}
              </td>
              <td className="text-sm font-medium text-center break-words">
                {cols.lectureID}
              </td>
              <td className="text-sm font-medium text-center break-words text-primary ">
                {cols.courseName}
              </td>
              <td className="text-sm font-medium text-center break-words">
                {cols.RepLecture}
              </td>
              <td className="text-sm font-medium text-center break-words">
                {cols.OpenDate}
              </td>

              <td className="text-sm font-medium text-center break-words">
                {cols.state}
              </td>
              <td className="text-sm font-medium text-center break-words">
                {cols.DiscStatus}
              </td>
              <td className="text-sm font-medium text-center break-words">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange && onChange(cols.students);
                  }}
                  className="flex items-center gap-1 mx-auto "
                >
                  <span className=" text-primary">{cols.students}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="-rotate-90 stroke-primary"
                  >
                    <path
                      d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManagemenetLectureTable;
