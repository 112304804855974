import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

interface NavbarProps {
  title: string;
  onBack?: () => void;
}

const NavigationLearning = ({ title, onBack }: NavbarProps) => {
  return (
    <nav className="h-16 w-full  z-10 fixed top-0 bg-white shadow">
      <div className="flex items-center justify-between h-full 2xl:px-72 px-40">
        <div className="flex items-center">
          <button onClick={onBack} className="mr-2 px-2 text-blue-500">
            <ArrowLeft2 size={18} />
          </button>
          <h1 className="text-lg">{title}</h1>
        </div>{" "}
        <div className="flex items-center space-x-3">
          <button className="flex border border-gray-500 text-gray-500 rounded justify-center items-center px-4 py-1 text-blue-500 border-">
            <ArrowLeft2 size={18} />
            돌아가기
          </button>
          <button className="flex border border-primary px-4 py-1 rounded justify-center items-center text-blue-500">
            다음강의
            <ArrowRight2 size={18} />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavigationLearning;
