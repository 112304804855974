import IconNaver from "../../components/Icons/iconNaver";
import {
  IconQuateLeft,
  IconQuateRight,
} from "../../components/Icons/iconQuote";
const Review = () => {
  return (
    <div className="2xl:px-72 pl-40  py-36 bg-bg_2">
      <div className="mb-7 text-blue-5 text-xl font-bold">
        후기가 증명하는 채피
      </div>
      <div className="mb-20 w-[408px] text-grey-9 text-4xl font-bold">
        앞서나가는 기업들이 채피와 함께하고 있습니다.
      </div>

      <div className="grid gap-8 grid-flow-col auto-cols-[420px] overflow-x-auto overscroll-contain no-scrollbar">
        <div className="p-7 bg-white rounded-xl">
          <div className="h-[129px] flex justify-center items-center">
            <IconNaver />
          </div>
          <div className="mt-5 flex gap-2">
            <IconQuateLeft />
            <div className=" text-blue-4 text-xl font-bold">
              언어를 처음 배울때 <span className="text-grey-8">유용했어요</span>
            </div>
            <IconQuateRight />
          </div>
          <div className="mt-4 pt-4 border-t border-t-grey-1">
            <div className="flex items-center gap-2">
              <div className="text-grey-7 text-base font-bold">네이버</div>
              <div className="text-grey-1 text-xs font-medium">|</div>
              <div className="text-grey-6 text-base font-bold">개발자 김구독</div>
            </div>
            <div className="text-grey-5 font-medium mt-3">
            프로그래밍 언어를 처음 배우는 학습자들에게는 외국어로 된 IDE보다 이해하기 쉽고 학습하기 편한 장점이 있습니다.
            </div>
          </div>
        </div>
        <div className="p-7 bg-white rounded-xl">
          <div className="h-[129px] flex justify-center items-center">
            <IconNaver />
          </div>
          <div className="mt-5 flex gap-2">
            <IconQuateLeft />
            <div className=" text-blue-4 text-xl font-bold">
              언어를 처음 배울때 <span className="text-grey-8">유용했어요</span>
            </div>
            <IconQuateRight />
          </div>
          <div className="mt-4 pt-4 border-t border-t-grey-1">
            <div className="flex items-center gap-2">
              <div className="text-grey-7 text-base font-bold">네이버</div>
              <div className="text-grey-1 text-xs font-medium">|</div>
              <div className="text-grey-6 text-base font-bold">개발자 김구독</div>
            </div>
            <div className="text-grey-5 font-medium mt-3">
            프로그래밍 언어를 처음 배우는 학습자들에게는 외국어로 된 IDE보다 이해하기 쉽고 학습하기 편한 장점이 있습니다.
            </div>
          </div>
        </div>
        <div className="p-7 bg-white rounded-xl">
          <div className="h-[129px] flex justify-center items-center">
            <IconNaver />
          </div>
          <div className="mt-5 flex gap-2">
            <IconQuateLeft />
            <div className=" text-blue-4 text-xl font-bold">
              언어를 처음 배울때 <span className="text-grey-8">유용했어요</span>
            </div>
            <IconQuateRight />
          </div>
          <div className="mt-4 pt-4 border-t border-t-grey-1">
            <div className="flex items-center gap-2">
              <div className="text-grey-7 text-base font-bold">네이버</div>
              <div className="text-grey-1 text-xs font-medium">|</div>
              <div className="text-grey-6 text-base font-bold">개발자 김구독</div>
            </div>
            <div className="text-grey-5 font-medium mt-3">
            프로그래밍 언어를 처음 배우는 학습자들에게는 외국어로 된 IDE보다 이해하기 쉽고 학습하기 편한 장점이 있습니다.
            </div>
          </div>
        </div>
        <div className="p-7 bg-white rounded-xl">
          <div className="h-[129px] flex justify-center items-center">
            <IconNaver />
          </div>
          <div className="mt-5 flex gap-2">
            <IconQuateLeft />
            <div className=" text-blue-4 text-xl font-bold">
              언어를 처음 배울때 <span className="text-grey-8">유용했어요</span>
            </div>
            <IconQuateRight />
          </div>
          <div className="mt-4 pt-4 border-t border-t-grey-1">
            <div className="flex items-center gap-2">
              <div className="text-grey-7 text-base font-bold">네이버</div>
              <div className="text-grey-1 text-xs font-medium">|</div>
              <div className="text-grey-6 text-base font-bold">개발자 김구독</div>
            </div>
            <div className="text-grey-5 font-medium mt-3">
            프로그래밍 언어를 처음 배우는 학습자들에게는 외국어로 된 IDE보다 이해하기 쉽고 학습하기 편한 장점이 있습니다.
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Review;
