import Input from "../../components/materials/Input"
import Button from "../../components/materials/Button"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

const findPasswordSchema = z.object({
  email : z.string().email(),
  name : z.string().min(1)
})

type FindPasswordType = z.infer<typeof findPasswordSchema>

const FindPassword = () => {
  const { register, handleSubmit, formState} = useForm<FindPasswordType>({
    resolver : zodResolver(findPasswordSchema)
  })

  const submitHandler = handleSubmit(() => {

  })
  return (
    <div className="bg-blue-1 h-[calc(100vh-4rem)] flex items-center justify-center">
      <div className="p-12 w-[440px] rounded-[20px] bg-white border border-grey-1">
      <div className="text-center text-grey-9 text-2xl font-bold mb-12">비밀번호  찾기</div>
      <form onSubmit={submitHandler} >
        <div className="grid gap-2.5 mb-16">
          <Input 
            id="email" 
            isRequired 
            label="이메일" 
            placeholder="이메일"
            {...register('email')}
          />
          <Input 
            id="name" 
            isRequired 
            label="이름" 
            placeholder="이름"
            {...register('name')}
          />
        </div>
        
        <Button disabled={!formState.isValid} type="submit" variant="primary">비밀번호 재설정 링크 전송</Button>
      </form>

      </div>
    </div>
  )
}

export default FindPassword