import Input from "../../components/materials/Input";
import TextArea from "../Inquiry/textarea";
const InfoProfile = () => {
  return (
    <>
      <div className="text-grey-9 text-xl font-bold mb-8">내 프로필</div>
      <div className="grid grid-cols-[100px_1fr] gap-5">
        <div
          className="
            flex items-center
            text-base font-medium text-grey-3
          "
        >
          회원ID
        </div>
        <Input value={123456} disabled id="membersId" />
        <div
          className="
            flex items-center
            text-base font-medium text-grey-3
          "
        >
          이름
        </div>
        <Input value={"홍길동"} id="name" />
        <div
          className="
            flex items-center
            text-base font-medium text-grey-3
          "
        >
          연락처
        </div>
        <Input value={"01012345678"} id="conctact" />
        <div
          className="
            flex items-center
            text-base font-medium text-grey-3
          "
        >
          이메일
        </div>
        <Input disabled value={"abcd@naver.com"} id="email" />
        <div
          className="
            flex items-center
            text-base font-medium text-grey-3
          "
        >
          소속
        </div>
        <div className="py-3.5 px-4 h-14 rounded-xl relative bg-white border border-grey-1">
          <div className="
            text-xs text-blue-4 rounded-[4px] 
            border border-blue-4
            inline-block px-2 py-1
          "
          >소속없음</div>
        </div>
        <div
          className="
            flex items-center
            text-base font-medium text-grey-3
          "
        >
          자기소개
        </div>
        <TextArea
        rows={2}
          value={` 안녕하세요. 코딩을 좋아하고 잘하고싶은 코딩 입문한지 얼마 안된 홍길동입니다!
          잘 부탁드립니다~~
        `}
          id="affiliation"
        />
      </div>
    </>
  );
};

export default InfoProfile;
