import React from "react";

export default function Quiz() {
  return (
    <div className="w-full h-full px-6 md:px-12 lg:px-24 pb-20 bg-[#f4f4f4] backdrop-blur-sm flex justify-center items-center">
      <div className="w-full max-w-5xl flex flex-col justify-start items-center gap-6">
        <div className="w-full p-8 bg-white rounded-2xl flex flex-col gap-5">
          <div className="w-full flex justify-between items-center gap-4">
            <div className="flex-grow bg-[#e0e0e0] h-1.5 rounded-full">
              <div
                className="bg-gradient-to-l from-[#2860ef] to-[#618eff] h-full rounded-full"
                style={{ width: "28%" }}
              ></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-[#2860ef] text-sm font-bold">1</div>
              <div className="text-[#a5a5a5] text-sm font-medium">/</div>
              <div className="text-[#a5a5a5] text-sm font-medium">10</div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-7">
            <div className="flex flex-col gap-3">
              <div className="text-[#242424] text-2xl md:text-3xl font-bold">
                Q.2
              </div>
              <div className="text-[#242424] text-base font-medium">
                다음 중 hello world를 출력할 수 있는 문이 아닌 것은?
              </div>
            </div>
            <div className="flex flex-col gap-2.5">
              {[
                "Hi world",
                "Welcome world",
                "Everyone Hi",
                "Hello to you, too",
                "Everywhere",
              ].map((option, index) => (
                <label key={index} className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="quiz"
                    className="w-5 h-5 rounded-full border border-[#c3c3c3]"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    {option}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-4 w-full">
          <button className="w-28 h-10 bg-white rounded-[10px] border border-[#c3c3c3] flex justify-center items-center">
            <span className="text-center text-[#a5a5a5] text-sm font-bold">
              이전
            </span>
          </button>
          <button className="w-28 h-10 bg-white rounded-[10px] border border-[#2860ef] flex justify-center items-center">
            <span className="text-center text-[#2860ef] text-sm font-bold">
              다음문제
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
