import IconChappieGrey from "../../Icons/iconChappieGrey";

type Variants = {
  default : string,
  blue : string
}

const Footer = ({
  variant = 'default'
}) => {

  const variants : Variants = {
    default : 'bg-white',
    blue : 'bg-body'
  }

  return (
    <div className={`2xl:px-72 px-40  py-14 ${variants[variant as keyof typeof variants ]}`}>
      <IconChappieGrey />
      <div className=" my-5 text-grey-7 text-sm font-bold flex gap-5">
        <div>강의</div>
        <div>소개</div>
        <div>플랜</div>
        <div>공지</div>
        <div>문의</div>
      </div>
      <div className="border-t border-t-grey-1 pt-5">
        <div className="flex gap-2 text-sm font-bold mb-3">
          <div className="text-grey-6 ">사업자명</div>
          <div className="text-grey-10" >주식</div>
          <div className="text-grey-1">|</div>
          <div className="text-grey-6 ">대표</div>
          <div className="text-grey-10">이은석, 최동욱</div>
          <div className="text-grey-1">|</div>
          <div className="text-grey-6 ">개인정보관리책임자</div>
          <div className="text-grey-10">이정훈</div>
          <div className="text-grey-1">|</div>
          <div className="text-grey-6 ">사업자등록번호</div>
          <div className="text-grey-10">000-00-0000</div>
          <div className="text-grey-1">|</div>
          <div className="text-grey-6 ">통신판매업</div>
          <div className="text-grey-10">0000-1214-01126호</div>
        </div>
        <div className="flex gap-2 text-sm font-bold mb-5">
          <div className="text-grey-6">주소</div>
          <div className="text-grey-10">서울특별시 강남구 테헤란로 72- 1234 1층 </div>
          <div className="text-grey-1">|</div>
          <div className="text-grey-6">이메일</div>
          <div className="text-grey-10">contact@chappy.io</div>
        </div>
        <div className="flex gap-2 text-sm font-bold mb-5">
          <div className="text-grey-10">서비스 이용약관</div>
          <div className="text-grey-10">|</div>
          <div className="text-grey-10">개인정보보호처리방침</div>
        </div>
        <div className="text-grey-11 text-sm font-medium" >©2023 by  chappy. Inc.</div>
      </div>
    </div>
  );
};

export default Footer;
