import Button from "../../components/materials/Button"
import Robot from "../../assets/robot.png"
import Logo from "../../assets/logo.png"
import { useNavigate } from "react-router-dom"
const DraftEmail = () => {
  const navigate = useNavigate()
  const navigateToResetPassword = function () {
    navigate('/reset-password')
  }
  return (
    <div className="bg-blue-1 h-screen flex justify-center items-center relative">
      <img className="w-24 absolute top-[40px] right-[40px]" src={Logo} alt="" />
      <div className="flex flex-col items-center">
        <img className="w-[152px] mb-11" src={Robot} alt="" />
        <div className="text-lg font-bold text-black mb-3">비밀번호를 변경해주세요</div>
        <div className="text-sm font-medium text-grey-6 mb-7">아래 링크로 접속해 비밀번호를 변경해주세요</div>
        <div className="w-[194px]">
        <Button onClick={navigateToResetPassword} variant="primary">비밀번호 변경하기</Button>
        </div>
       
      </div>
    </div>
  )
}

export default DraftEmail