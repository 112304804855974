
import { 
  type ReactNode, 
  createContext, 
  useState, 
  useContext ,
  useEffect
} from "react";
import useLocalStorage from "../hook/useLocalStorage";
type PayloadAuth = {
  email : string,
  password : string
}

type AuthType = {
  isLogin : boolean,
  loginHandler : (values : PayloadAuth) => void
}

type AuthContextProviderProps = {
  children : ReactNode
}

const AuthContext = createContext<AuthType | null >(null)

function useAuthContext() {
  const authCtx = useContext(AuthContext)
  if(authCtx === null) {
    throw new Error(' context auth error')
  }else{
    return authCtx
  }
}

function AuthContextProvider({
  children
}: AuthContextProviderProps) {
  const [isLogin, setIsLogin] = useState(false)
  const [value,setValue] = useLocalStorage("token", "")
  useEffect(() => {
    if(value) {
      setIsLogin(true)
    }else{
      setIsLogin(false)
    }
  },[value])
  const loginHandler = function (values: PayloadAuth) {
    setIsLogin(true)
    setValue("token available")
  }
  const context = {
    isLogin,
    loginHandler
  }
  return <AuthContext.Provider value={context} > {children} </AuthContext.Provider>
}

export {
  AuthContextProvider,
  useAuthContext
}