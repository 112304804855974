import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function Task() {
  const [content, setContent] = useState("");

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <div className="flex flex-col gap-6 p-8 w-full h-full">
      <div className="flex flex-col w-full h-full rounded-2xl border border-[#e0e0e0] bg-white">
        <div className="w-full px-6 py-4 border-b border-[#e0e0e0] flex items-center gap-4">
          <div className="text-[#242424] text-xl font-bold font-['Pretendard'] leading-7">
            과제
          </div>
        </div>
        <div className="flex flex-col p-6 gap-2">
          <div className="text-[#555555] space-y-4 text-base font-medium font-['Pretendard'] leading-snug">
            <p>hello world를 출력할 수 있는 코드 10가지를 작성해 오세요.</p>
            <p>
              2주차 과제 드리겠습니다. hello world를 출력할 수 있는 코드
              10가지를 작성해 오세요.
            </p>
          </div>
          <button
            onClick={() => alert("링크 클릭됨!")}
            className="text-[#2860ef] flex justify-start my-2 text-base font-medium font-['Pretendard'] underline leading-snug bg-transparent border-none cursor-pointer"
          >
            2주차 과제 참고자료.pdf
          </button>
          <div className="flex flex-col gap-4">
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={modules}
              placeholder="내용을 입력해주세요"
              className="bg-white border border-[#fff] rounded-lg"
            />
            <div className="flex justify-end py-10">
              <button className="w-32 h-10 bg-[#2860ef] text-white rounded-lg">
                제출하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
