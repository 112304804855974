import IdeImage from "../../assets/ide.png";
import Progress from "../../assets/progress.png";
import IconTick from "../../components/Icons/iconTick";
const Ide = () => {
  return (
    <div className="2xl:px-72 px-40  py-36  flex justify-between ">
      <div>
        <div className="relative">
          <div className="rounded-[10px] overflow-hidden">
            <img src={IdeImage} alt="" />
          </div>
          <img
            className="absolute top-[50%] -right[18%]"
            src={Progress}
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="grid gap-8">
          <div className="text-blue-5 text-xl font-bold">맞춤형 IDE 서비스</div>
          <div className="w-[285px] text-grey-9 text-4xl font-bold">
            자동 코드 평가로 유연한 개발환경 구축
          </div>
          <div className="grid gap-2">
            <div className="flex items-center gap-4">
              <IconTick />
              <div className="text-grey-6 font-medium text-lg">
              유연한 코딩플랫폼으로 개발환경 구축
              </div>
            </div>
            <div className="flex items-center gap-4">
              <IconTick />
              <div className="text-grey-6 font-medium text-lg">
              한글화된 IDE로 워크플로우 향상
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ide;
