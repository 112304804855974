const IconTime = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.0559 11.4088L10.5558 9.77249V6.43931C10.5558 6.10404 10.2456 5.83325 9.86142 5.83325C9.47726 5.83325 9.16699 6.10404 9.16699 6.43931V10.0757C9.16699 10.2667 9.2696 10.4467 9.44469 10.5604L12.2224 12.3786C12.3474 12.4605 12.4931 12.4999 12.6384 12.4999C12.8503 12.4999 13.0587 12.4168 13.1945 12.2569C13.425 11.9895 13.3626 11.6096 13.0559 11.4091V11.4088Z"
        fill="#A5A5A5"
      />
      <path
        d="M10.0003 1.66675C5.40495 1.66675 1.66699 5.4047 1.66699 10.0001C1.66699 14.5955 5.40495 18.3334 10.0003 18.3334C14.5957 18.3334 18.3337 14.5955 18.3337 10.0001C18.3337 5.4047 14.5957 1.66675 10.0003 1.66675ZM10.0003 17.0424C6.11784 17.0424 2.95801 13.8826 2.95801 10.0001C2.95801 6.11759 6.11784 2.95776 10.0003 2.95776C13.8828 2.95776 17.0426 6.11759 17.0426 10.0001C17.0426 13.8826 13.8828 17.0424 10.0003 17.0424Z"
        fill="#A5A5A5"
      />
    </svg>
  );
};

export default IconTime;
