import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function LectureRegisterAssigment() {
  const [selectedOption, setSelectedOption] = useState<string>("공개");
  const [content, setContent] = useState("");

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="min-h-screen bg-white p-6 rounded-lg">
      <div className="w-full max-w-[1200px] h-full p-10 bg-white rounded-2xl flex flex-col gap-7">
        <div className="text-left text-[#242424] text-xl font-bold leading-7">
          과제 등록
        </div>

        <div className="w-full flex flex-col gap-3">
          <div className="text-[#242424] text-base font-bold">기본정보</div>
          <div className="w-full p-7 bg-white border-t border-[#e0e0e0] flex flex-col gap-4">
            <div className="w-full flex flex-col gap-2">
              <label className="text-[#242424] text-base font-bold">
                구성 명
              </label>
              <input
                type="text"
                placeholder="강의명을 입력해 주세요"
                className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] placeholder-gray-400 text-base"
              />
            </div>

            <div className="w-full flex gap-5">
              <div className="w-1/2 flex flex-col gap-2">
                <label className="text-[#242424] text-base font-bold">
                  차시 선택
                </label>
                <select className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-base">
                  <option value="" disabled selected>
                    차시를 선택해 주세요
                  </option>
                  <option value="1">차시 1</option>
                  <option value="2">차시 2</option>
                </select>
              </div>
              <div className="w-1/2 flex flex-col gap-2">
                <label className="text-[#242424] text-base font-bold">
                  출석 인정
                </label>
                <select className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-base">
                  <option value="" disabled selected>
                    인정
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="w-1/2 flex flex-col gap-2">
                <label className="text-[#242424] text-base font-bold">
                  출석 인정 기한
                </label>
                <input
                  type="date"
                  className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] text-base"
                />
              </div>
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="text-[#242424] text-base font-bold">
                강의 영상 첨부
              </label>
              <input
                type="text"
                placeholder="동영상 파일 혹은 URL을 입력해 주세요"
                className="w-full h-[54px] p-4 bg-white rounded-xl border border-[#e0e0e0] placeholder-gray-400 text-base"
              />
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="text-[#242424] text-base font-bold">
                공개여부
              </label>
              <div className="flex items-center gap-4">
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="visibility"
                    value="공개"
                    checked={selectedOption === "공개"}
                    onChange={handleRadioChange}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    공개
                  </span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="visibility"
                    value="비공개"
                    checked={selectedOption === "비공개"}
                    onChange={handleRadioChange}
                    className="form-radio text-[#759cff] h-5 w-5"
                  />
                  <span className="text-[#555555] text-sm font-medium">
                    비공개
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-3">
          <div className="text-[#242424] text-base font-bold">강의 노트</div>
          <div className="mb-6">
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={modules}
              placeholder="내용을 입력해주세요"
            />
          </div>
        </div>

        <div className="w-full flex justify-end">
          <button className="px-6 py-3 bg-[#1679AB] text-white rounded-xl text-base font-medium">
            등록
          </button>
        </div>
      </div>
    </div>
  );
}
