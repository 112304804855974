import { ReactNode } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'

type DialogType =  {
  isDialog : boolean,
  setUpDialog : () => void,
  children : ReactNode,
  width? : string | number
}

export default function DialogComponent({
  isDialog,
  setUpDialog,
  width,
  children
} :DialogType ) {

  return (
    <Dialog open={isDialog} onClose={setUpDialog} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            style={{width : `${width}px`}}
            className="relative   transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-7 py-10  sm:p-6 sm:pb-4">
              {children}
            </div>
          
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
