const IconReunlock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clip-path="url(#clip0_784_14471)">
        <path
          d="M1.16699 2.66675V6.66675H5.16699"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.84033 10.0004C3.27259 11.2273 4.09188 12.2805 5.17476 13.0013C6.25764 13.7221 7.54545 14.0714 8.84414 13.9967C10.1428 13.9219 11.382 13.4272 12.3751 12.5869C13.3681 11.7466 14.0612 10.6064 14.3498 9.33795C14.6385 8.06954 14.5071 6.74168 13.9754 5.55444C13.4438 4.3672 12.5407 3.3849 11.4022 2.75555C10.2638 2.1262 8.95159 1.88388 7.66344 2.06512C6.37529 2.24635 5.18093 2.84132 4.26033 3.76037L1.16699 6.66704"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_784_14471">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconReunlock;
