const IconPencil = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M11.834 1.99955C12.0091 1.82445 12.2169 1.68556 12.4457 1.5908C12.6745 1.49604 12.9197 1.44727 13.1673 1.44727C13.4149 1.44727 13.6601 1.49604 13.8889 1.5908C14.1177 1.68556 14.3256 1.82445 14.5007 1.99955C14.6757 2.17465 14.8146 2.38252 14.9094 2.61129C15.0042 2.84006 15.0529 3.08526 15.0529 3.33288C15.0529 3.58051 15.0042 3.8257 14.9094 4.05448C14.8146 4.28325 14.6757 4.49112 14.5007 4.66622L5.50065 13.6662L1.83398 14.6662L2.83398 10.9996L11.834 1.99955Z"
        stroke="#2861EF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="10.3643"
        y1="2.80365"
        x2="13.031"
        y2="5.47032"
        stroke="#2861EF"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconPencil;
