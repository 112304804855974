import { type ComponentPropsWithoutRef, forwardRef } from "react";
import IconEye from "../../Icons/iconEye";
import IconDate from "../../Icons/iconDate";

type InputProps = {
  label?: string;
  isRequired?: boolean;
  id: string;
  errorMessage?: string;
  variant?: string;
  timer?: string | number;
  sizeVariant?: string;
  icons?: any;
} & ComponentPropsWithoutRef<"input">;

type InputVariant = {
  default: string;
  error: string;
  timer: string;
  disabled: string;
};

type SizeVariant = {
  md: string;
  sm: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    label,
    id,
    errorMessage,
    isRequired,
    variant = "default",
    sizeVariant = "md",
    timer,
    icons,
    ...props
  },
  ref
) {
  const variants: InputVariant = {
    default: "border-grey",
    error: "border-danger",
    timer: "border-grey flex items-center",
    disabled: "border-grey bg-grey-4",
  };

  const sizeVariantsInput: SizeVariant = {
    md: "py-3.5 px-4 h-14 rounded-xl",
    sm: "h-10 px-3 py-2 rounded-lg text-sm",
  };

  return (
    <div className="grid">
      {label && (
        <label htmlFor={id} className="relative block mb-2">
          <span
            className={`after:ml-0.5 after:absolute after:bottom-1 after:text-red-500 block text-grey-7 text-base font-bold ${
              isRequired && "after:content-['*']"
            } `}
          >
            {label}
          </span>
        </label>
      )}
      <div
        className={`
        ${variants[variant as keyof typeof variants]} 
        ${sizeVariantsInput[sizeVariant as keyof typeof sizeVariantsInput]}
        has-[:disabled]:text-grey-2 text-grey-6 font-medium has-[:disabled]:bg-[#F4F4F4] 
        border relative bg-white flex items-center justify-between`  }
      >
        <input
          ref={ref}
          className="w-full focus:outline-none disabled:bg-transparent"
          id={id}
          {...props}
        />
        {timer && <span className="text-primary">02:03</span>}

        {icons && (
          <div className=" right-2.5 top-0 bottom-0 self-center">
            {" "}
            {icons}{" "}
          </div>
        )}

        {props.type === "password" && (
          <div className="absolute right-2.5 top-0 bottom-0 self-center">
            <IconEye />
          </div>
        )}
        {props.type === "date" && (
          <div className="absolute left-2.5 top-0 bottom-0 self-center">
            <IconDate />
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="flex items-center gap-2 mt-2 text-sm font-medium text-danger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.00065 14.6673C4.31865 14.6673 1.33398 11.6827 1.33398 8.00065C1.33398 4.31865 4.31865 1.33398 8.00065 1.33398C11.6827 1.33398 14.6673 4.31865 14.6673 8.00065C14.6673 11.6827 11.6827 14.6673 8.00065 14.6673ZM7.33398 10.0007V11.334H8.66732V10.0007H7.33398ZM7.33398 4.66732V8.66732H8.66732V4.66732H7.33398Z"
              fill="#FF1D53"
            />
          </svg>
          {errorMessage}
        </div>
      )}
    </div>
  );
});

export default Input;
