import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import ProfileLogo from "../../assets/profile-img.png";
import Avatar from "../../assets/avatar-ds-admin.png";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { sidebarItems } from "./menuScame";

function DashboardLayoutAdmin() {
  const location = useLocation();
  const [openSubItems, setOpenSubItems] = useState<{ [key: string]: boolean }>(
    {}
  );

  const isActive = (path: string): string => {
    return location.pathname === path
      ? "border border-primary text-primary rounded-lg"
      : "text-gray-500";
  };

  const getIconColor = (path: string) => {
    return location.pathname.startsWith(path) ? "blue" : "gray";
  };

  const handleToggleSubItems = (path: string) => {
    setOpenSubItems((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  return (
    <div className="relative flex min-h-screen px-16 py-8 bg-body">
      <div className="absolute top-0 left-0 w-full h-64 bg-decoration"></div>

      {/* Sidebar */}
      <aside className="relative w-64 p-6 bg-white rounded-lg shadow">
        <div className="mb-8 text-center">
          <img
            src={Avatar}
            alt="Profile"
            className="w-24 h-24 mx-auto rounded-full bg-[#AAB6CF]"
          />
          <h1 className="mt-4 text-xl font-bold">관리자</h1>
          <p className="text-grey-5">admin@naasf.com</p>
        </div>
        <nav>
          {sidebarItems.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <div className="mb-4 text-lg font-bold">{section.label}</div>
              <ul className="space-y-2">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex}>
                    <Link
                      to={item.path}
                      className={`flex items-center py-2 px-4 mb-4 hover:border-primary hover:text-primary rounded ${
                        item.subItems ? "justify-between" : "gap-2"
                      } ${isActive(item.path)}`}
                      onClick={() => {
                        if (item.subItems) {
                          handleToggleSubItems(item.path);
                        } else {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }
                      }}
                    >
                      <span className="flex items-center gap-2">
                        {React.cloneElement(item.icon, {
                          className: `transition-colors ${getIconColor(
                            item.path
                          )}`,
                        })}
                        {item.label}
                      </span>
                      {item.subItems && (
                        <>
                          {openSubItems[item.path] ? (
                            <ArrowUp2
                              size="18"
                              className="group-hover:text-primary"
                            />
                          ) : (
                            <ArrowDown2
                              size="18"
                              className="group-hover:text-primary"
                            />
                          )}
                        </>
                      )}
                    </Link>
                    {item.subItems && (
                      <ul
                        className={`pl-4 space-y-2 ${
                          openSubItems[item.path] ? "block" : "hidden"
                        }`}
                      >
                        {item.subItems.map((subItem, subItemIndex) => (
                          <li key={subItemIndex}>
                            <Link
                              to={subItem.path}
                              className={`block py-2 px-4 hover:border-blue-500 hover:text-blue-500 rounded ${isActive(
                                subItem.path
                              )}`}
                              onClick={() =>
                                window.scrollTo({ top: 0, behavior: "smooth" })
                              }
                            >
                              {subItem.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </nav>
      </aside>

      {/* Main content area */}
      <div className="relative flex-1 pl-6">
        <div className="relative bg-transparent">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default DashboardLayoutAdmin;
