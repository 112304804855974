import Button from "../../components/materials/Button"
import TearDrop from "../../assets/teardrop.jpg"
import { useNavigate } from 'react-router-dom'
const InformationNotFound = () => {
  const navigate = useNavigate()
  const handleClick = function () {
    navigate('/login')
  }
  return (
    <div className="bg-blue-1 h-full flex items-center justify-center">
      <div className="grid gap-6 py-12 px-28 rounded-[20px] bg-white border border-grey-1">
        <div className="flex justify-center">
         <img src={TearDrop} alt="" className="w-[158px]" />
        </div>
        <div>
          <div className="mt-2 text-primary text-2xl font-bold">일치하는 회원정보가 없습니다.</div>
          <div className="text-grey-5 text-center text-[20px] font-medium">다시 확인해주세요.</div>
        </div>
        {/* go to membership */}
        <Button onClick={handleClick} variant="primary">회원가입하기</Button>
      </div>
    </div>
    
  )
}

export default InformationNotFound