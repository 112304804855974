import React from "react";
import StatsCardsLearning from "../components/StatsCardLearning";
import CourseStatusLearning from "../components/CourseStatusLearning";
import { Table } from "../../../components/tables";

const columns = [
  {
    header: "분류",
    accessorKey: "category",
  },
  {
    header: "과제명",
    accessorKey: "assignmentName",
  },
  {
    header: "날짜",
    accessorKey: "date",
  },
  {
    header: "상태",
    accessorKey: "status",
  },
  {
    header: "만점",
    accessorKey: "score",
  },
  {
    header: "평균",
    accessorKey: "average",
  },
  {
    header: "최고",
    accessorKey: "highest",
  },
  {
    header: "최저",
    accessorKey: "lowest",
  },
  {
    header: "상세정보",
    id: "detail",
    cell: () => "상세보기",
  },
];

const data = [
  {
    id: 1,
    category: "중간고사",
    assignmentName: "MIDTERM",
    date: "22.12.25",
    status: "채점중",
    score: 100,
    average: 55,
    highest: 99,
    lowest: 50,
  },
  {
    id: 2,
    category: "중간고사",
    assignmentName: "MIDTERM",
    date: "22.12.25",
    status: "채점중",
    score: 100,
    average: 55,
    highest: 99,
    lowest: 50,
  },
  {
    id: 3,
    category: "중간고사",
    assignmentName: "MIDTERM",
    date: "22.12.25",
    status: "채점중",
    score: 100,
    average: 55,
    highest: 99,
    lowest: 50,
  },
  {
    id: 4,
    category: "중간고사",
    assignmentName: "MIDTERM",
    date: "22.12.25",
    status: "채점중",
    score: 100,
    average: 55,
    highest: 99,
    lowest: 50,
  },
];

export default function GradesLearning() {
  const handleRowClick = (row: any) => {
    console.log("Row clicked:", row);
  };

  const handleRefundRequestClick = (row: any) => {
    console.log("Refund request clicked:", row);
  };
  return (
    <div className="bg-white p-6 rounded-lg shadow min-h-screen flex flex-col">
      <div className="space-y-6 flex flex-col mb-6">
        <StatsCardsLearning />
        <CourseStatusLearning />
      </div>
      <div className="grid grid-cols-1 gap-6 flex-1 min-h-0">
        <div className="p-6">
          <h1 className="text-lg font-bold mb-4">상세목록</h1>
          <Table
            columns={columns}
            data={data}
            onRowClick={handleRowClick}
            onRefundRequestClick={handleRefundRequestClick}
          />
        </div>
      </div>
    </div>
  );
}
