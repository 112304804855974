import Left from "./left";
import Footer from "../../components/containers/footer";
import { Outlet } from "react-router-dom";
const InformationProfile = () => {
  return (
    <div>
      <div
        className="
        2xl:px-72 px-40 
        py-14 bg-white
      "
      >
        <div className="grid grid-cols-3 gap-8">
          <Left />
          <div
            className="
              col-span-2 border border-grey-1 rounded-lg
              p-10 bg-white h-fit
            "
          >
            <Outlet />
          </div>
        </div>
      </div>
      <Footer variant="blue" />
    </div>
  );
};

export default InformationProfile;
