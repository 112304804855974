import React from "react";
import { ArrowRight2 } from "iconsax-react";

import IconRouterSignal from "../../../components/Icons/iconRouterSignal";

function NoticeSection() {
  const questions = [
    {
      status: "뉴스",
      course: "JavaScript 기초",
      date: "작성일 23.02.04 23:59",
      icon: <ArrowRight2 />,
    },
    {
      status: "공지",
      course: "JavaScript 기초",
      date: "작성일 23.02.04 23:59",
      icon: <ArrowRight2 />,
    },
    {
      status: "소식",
      course: "JavaScript 기초",
      date: "작성일 23.02.04 23:59",
      icon: <ArrowRight2 />,
    },
    {
      status: "소식",
      course: "JavaScript 기초",
      date: "작성일 23.02.04 23:59",
      icon: <ArrowRight2 />,
    },
  ];

  return (
    <div className="bg-white">
      <div className="flex items-center justify-between p-2 mb-4 border-b">
        <h3 className="text-lg font-bold">공지사항</h3>
      </div>
      <ul>
        {questions.map((question, index) => (
          <li key={index} className="flex items-center justify-between py-2">
            <div className="flex items-center gap-4">
              <span className="h-[44px] min-w-[44px] w-[44px] flex justify-center items-center rounded-full border-[#DBE5FF] border bg-[#FBFCFF]">
                <IconRouterSignal />
              </span>
              <div>
                <div className="flex items-center gap-x-2 ">
                  <p className="text-gray-900">{question.course}</p>
                  <div className="w-[1px] h-5 bg-slate-300"></div>
                  <span
                    className={`flex items-center justify-center w-10 h-8 text-xs rounded bg-[#FFF7F7] border border-[#FFCFDB] text-[#FF1D53]`}
                  >
                    {question.status}
                  </span>
                </div>
                <p className="text-xs text-gray-500">{question.date}</p>
              </div>
            </div>
            <div className="mr-4 text-2xl text-blue-500">{question.icon}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NoticeSection;
