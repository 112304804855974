import ReactPaginate from "react-paginate";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

const Pagination = () => {
  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <span className="text-grey-3 w-4 h-4 flex justify-center items-center">
            <ArrowRight2 />
          </span>
        }
        pageRangeDisplayed={10}
        containerClassName="
          flex justify-center items-center gap-2"
        previousLabel={
          <span className="text-grey-3 w-4 h-4 flex justify-center items-center">
            <ArrowLeft2 />
          </span>
        }
        pageCount={10}
        activeClassName="text-primary text-sm font-bold bg-blue-2 rounded-full"
        pageClassName="block text-grey-3 w-[28px] h-[28px] font-medium text-sm flex justify-center items-center"
      />
    </div>
  );
};

export default Pagination;
