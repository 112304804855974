export default function IconComunication() {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6.45C3.69625 6.45 3.45 6.69625 3.45 7C3.45 7.30371 3.69624 7.55 4 7.55H12C12.3037 7.55 12.55 7.30371 12.55 7C12.55 6.69624 12.3037 6.45 12 6.45H4Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
      <path
        d="M4 8.45C3.69624 8.45 3.45 8.69629 3.45 9C3.45 9.30371 3.69624 9.55 4 9.55H12C12.3037 9.55 12.55 9.30371 12.55 9C12.55 8.69629 12.3037 8.45 12 8.45H4Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
      <path
        d="M8 0.45C6.60838 0.45 5.47712 1.56475 5.45048 2.95H3C1.86782 2.95 0.95 3.86782 0.95 5V12.6492C0.95 13.4228 1.57715 14.05 2.35078 14.05C2.74594 14.05 3.11966 14.2296 3.36651 14.5382L3.36652 14.5382L3.75479 15.0235C4.31728 15.7267 5.42911 15.5618 5.76351 14.7259L5.71709 14.7073L5.76351 14.7259C5.92679 14.3177 6.32213 14.05 6.76176 14.05H13C13.9417 14.05 14.735 13.4151 14.9754 12.55H18.375C19.5762 12.55 20.55 11.5762 20.55 10.375V3C20.55 1.59168 19.4083 0.45 18 0.45H8ZM4.61374 14.3364L4.57469 14.3676L4.61373 14.3364L4.22547 13.8511C3.76988 13.2815 3.0801 12.95 2.35078 12.95C2.18467 12.95 2.05 12.8153 2.05 12.6492V5C2.05 4.47533 2.47533 4.05 3 4.05H13C13.5247 4.05 13.95 4.47533 13.95 5V12C13.95 12.5247 13.5247 12.95 13 12.95H6.76176C5.87233 12.95 5.07251 13.4916 4.74219 14.3173C4.7208 14.3708 4.64969 14.3813 4.61374 14.3364ZM18.375 11.45H15.05V5C15.05 3.86782 14.1322 2.95 13 2.95H6.55085C6.5772 2.17234 7.21592 1.55 8 1.55H18C18.8008 1.55 19.45 2.19919 19.45 3V10.375C19.45 10.9687 18.9687 11.45 18.375 11.45Z"
        fill="#A5A5A5"
        stroke="#A5A5A5"
        strokeWidth="0.1"
      />
    </svg>
  );
}
