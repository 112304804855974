const IconGear = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M6.59525 14.3903C5.47822 14.0577 4.48392 13.4403 3.69661 12.6222C3.99032 12.2741 4.16732 11.8243 4.16732 11.3332C4.16732 10.2286 3.27189 9.33321 2.16732 9.33321C2.1005 9.33321 2.03445 9.33648 1.96931 9.34288C1.88058 8.90908 1.83398 8.45991 1.83398 7.99988C1.83398 7.30301 1.94091 6.63111 2.13925 5.99968C2.14859 5.99981 2.15794 5.99988 2.16732 5.99988C3.27189 5.99988 4.16732 5.10445 4.16732 3.99988C4.16732 3.68278 4.09352 3.38291 3.96218 3.11654C4.73315 2.39967 5.67415 1.86318 6.71802 1.57422C7.04878 2.22257 7.72288 2.66655 8.50065 2.66655C9.27842 2.66655 9.95252 2.22257 10.2833 1.57422C11.3272 1.86318 12.2682 2.39967 13.0391 3.11654C12.9078 3.38291 12.834 3.68278 12.834 3.99988C12.834 5.10445 13.7294 5.99988 14.834 5.99988C14.8434 5.99988 14.8527 5.99981 14.8621 5.99968C15.0604 6.63111 15.1673 7.30301 15.1673 7.99988C15.1673 8.45991 15.1207 8.90908 15.032 9.34288C14.9669 9.33648 14.9008 9.33321 14.834 9.33321C13.7294 9.33321 12.834 10.2286 12.834 11.3332C12.834 11.8243 13.011 12.2741 13.3047 12.6222C12.5174 13.4403 11.5231 14.0577 10.4061 14.3903C10.1483 13.5838 9.39265 12.9999 8.50065 12.9999C7.60865 12.9999 6.85305 13.5838 6.59525 14.3903Z"
        stroke="#2861EF"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.49935 10.3327C9.78802 10.3327 10.8327 9.28802 10.8327 7.99935C10.8327 6.71068 9.78802 5.66602 8.49935 5.66602C7.21068 5.66602 6.16602 6.71068 6.16602 7.99935C6.16602 9.28802 7.21068 10.3327 8.49935 10.3327Z"
        stroke="#2861EF"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconGear;
