import React, { useState } from "react";
import Search from "../../../components/materials/Search";
import { Table } from "../../../components/tables";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { MessageEdit } from "iconsax-react";
import Pagination from "../../../components/pagination";
import { useParams } from "react-router-dom";

interface Bulletins {
  id: number;
  no: number;
  course: string;
  title: string;
  author: string;
  date: string;
}

const bulletinData: Bulletins[] = [
  {
    id: 1,
    no: 1,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
  },
  {
    id: 2,
    no: 2,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
  },
  {
    id: 3,
    no: 3,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "김채피",
    date: "23.02.04 23:59",
  },
  {
    id: 4,
    no: 4,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "홍학생",
    date: "23.02.04 23:59",
  },
  {
    id: 5,
    no: 5,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "홍학생",
    date: "23.02.04 23:59",
  },
  {
    id: 6,
    no: 6,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "홍학생",
    date: "23.02.04 23:59",
  },
  {
    id: 7,
    no: 7,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "강학생",
    date: "23.02.04 23:59",
  },
  {
    id: 8,
    no: 8,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "정학생",
    date: "23.02.04 23:59",
  },
  {
    id: 9,
    no: 9,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "최학생",
    date: "23.02.04 23:59",
  },
  {
    id: 10,
    no: 10,
    course: "기초 css 배우기",
    title: "학습시간 변경 안내 공지",
    author: "권학생",
    date: "23.02.04 23:59",
  },
];

const columns: ColumnDef<Bulletins>[] = [
  {
    accessorKey: "no",
    header: "NO.",
  },
  {
    accessorKey: "course",
    header: "강의명",
  },
  {
    accessorKey: "title",
    header: "제목",
  },
  {
    accessorKey: "author",
    header: "작성자",
  },
  {
    accessorKey: "date",
    header: "작성일",
  },
];

export default function BulletinLearnig() {
  const { id } = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const navigate = useNavigate();

  const totalPages = Math.ceil(bulletinData.length / dataPerPage);

  return (
    <div className="bg-white min-h-screen p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold border-b pb-2">게시판</h2>
      <div className="flex justify-between items-center">
        <div className="relative mt-4">
          <Search placeholder="검색..." />
        </div>
        <div className="flex justify-end m-4">
          <button
            className="flex justify-center gap-1 items-center bg-primary px-4 py-2 text-white rounded-lg"
            onClick={() => navigate(`/learning/${id}/bulletin/create`)}
          >
            글쓰기
            <MessageEdit size={18} />
          </button>
        </div>
      </div>
      <Table
        columns={columns}
        data={bulletinData}
        onRowClick={(row) => {
          navigate(`/learning/${id}/bulletin/${row.id}`);
        }}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}
