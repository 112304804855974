type variants = {
  color? : string,
  fill? : string
}

const IconPlay = ({
  fill = 'none',
  color = '#A5A5A5'
} : variants) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E0E0E0" />
      <path
        d="M9 8.58167C9 7.7905 9.87525 7.31266 10.5408 7.74049L15.8582 11.1588C16.4705 11.5525 16.4705 12.4475 15.8582 12.8412L10.5408 16.2595C9.87525 16.6873 9 16.2095 9 15.4183V8.58167Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
    </svg>


  );
};

export default IconPlay;
