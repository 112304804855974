import Footer from "../../components/containers/footer";
import Input from "../../components/materials/Input";
import List from "./list";
import Pagination from "./pagination";
const Announcement = () => {
  return (
    <div>
      <div className="2xl:px-72 px-40  py-14 bg-white">
        <div className="text-grey-9 font-bold text-2xl text-center mb-12">
          공지사항
        </div>
        <div className="flex justify-between items-center mb-12 ">
          <div className="h-[56px] bg-bg_2 p-2 grid gap-2 grid-cols-4 w-[420px] rounded-lg">
            <div className="text-primary text-base font-bold bg-white rounded-lg flex justify-center items-center">
              전체
            </div>
            <div className="text-grey-3 font-medium flex justify-center items-center">
              공지
            </div>
            <div className="text-grey-3 font-medium flex justify-center items-center">
              뉴스
            </div>
            <div className="text-grey-3 font-medium flex justify-center items-center">
              소식
            </div>
          </div>
          <div>
            <Input id="search" />
          </div>
        </div>
        <List />
        <div className="mb-10"></div>
        <Pagination/>
      </div>
      <Footer variant="blue"></Footer>
    </div>
  );
};

export default Announcement;
