import lecuresImage from "../../assets/lectures.png";
import { useNavigate } from "react-router-dom";
const ListLectures = () => {
  const navigation = useNavigate()
  const arrayDummy = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ,10 ,11];
  const handlerClick = function () {
    navigation('/lectures/detail')
  }
  return (
    <div className="grid grid-cols-4 gap-7 mt-6">
      {arrayDummy.map((id) => {
        return (
          <div onClick={handlerClick} className="cursor-pointer" key={id}>
            <div>
              <img src={lecuresImage} alt="" />
            </div>
            <div className="mt-2 grid gap-2">
              <div className="text-grey-5 font-medium text-sm">홍길동 강사</div>
              <div className="text-grey-9 font-bold text-base">
                JavaScript 기초부터 응용까지{" "}
              </div>
              <div className="text-grey-5 font-medium text-sm">
                약 4만명이 인증한 추천 강의로 쉽고 재미있게 JavaScript를
                배워보세요!
              </div>
              <div className="flex gap-2">
                <div
                  className="
              text-grey-3 text-xs font-medium rounded-[4px]
              bg-bg_1 border border-grey-1 p-2 inline-block
              "
                >
                  프로그래밍 기초
                </div>
                <div
                  className="
              text-grey-3 text-xs font-medium rounded-[4px]
              bg-bg_1 border border-grey-1 p-2 inline-block
              "
                >
                  초급
                </div>
                <div
                  className="
            text-grey-3 text-xs font-medium rounded-[4px]
            bg-bg_1 border border-grey-1 p-2 inline-block
            "
                >
                  JavaScript
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListLectures;
