import AvatarImage from "../../assets/avatar.png"
import Input from "../../components/materials/Input"
import Button from "../../components/materials/Button"
import { z } from "zod"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const objectZod = {
  name: z.string().min(1),
  email: z.string().email(),
  phone: z.string().regex(new RegExp('^01([0])([0-9]{4})([0-9]{4})$'), { message: 'error' }),
  password: z.string().refine(value => /^(?=.*[a-z]){2,}(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value ?? "")),
  confirmPassword: z.string(),
}

const joinMembershipSchema = z.discriminatedUnion("isOrganization", [
  z.object({
    isOrganization: z.literal(false),
    ...objectZod,
    organization: z.string().min(1),
  }),
  z.object({
    isOrganization: z.literal(true),
    ...objectZod,
    organization: z.string(),
  })
]).refine((data) => data.password === data.confirmPassword, {
  message: 'password dont match',
  path: ['confirmPassword']
})

type JoinMemberShipType = z.infer<typeof joinMembershipSchema>
const JoinMemberShip = () => {
  const {
    register,
    handleSubmit,
    formState,
    getFieldState,
    control,
    setValue

  } = useForm<JoinMemberShipType>({
    resolver: zodResolver(joinMembershipSchema),
    mode: 'onChange',
    defaultValues: {
      isOrganization: false
    }
  })
  const navigate = useNavigate()
  const [isDisabledEmail, setIsDisabledEmail] = useState<boolean>(true)
  const [isDisabledPhone, setIsDisabledPhone] = useState<boolean>(true)
  const [email, setEmail] = useState({
    variant: 'default',
    timer: ''
  })

  const [phone, setPhone] = useState({
    variant: 'default',
    timer: ''
  })

  const [isDisabledOrganization, setIsDisabledOrganization] = useState<boolean>(false)

  const EmailState = getFieldState('email', formState)
  const phoneState = getFieldState('phone', formState)
  const handlerSubmit = handleSubmit((values) => {
    console.log(values)
    navigate('/term-condition')
  })

  const handlerClickOrganization = function () {
    setIsDisabledOrganization(!isDisabledOrganization)
    setValue('organization', '')
  }

  const authorizeEmail = function () {
    setEmail(prevState => {
      return {
        ...prevState,
        variant: 'timer',
        timer: '02:03'
      }
    })
  }

  const authorizePhone = function () {
    setPhone(prevState => {
      return {
        ...prevState,
        variant: 'timer',
        timer: '02:03'
      }
    })
  }

  useEffect(() => {
    if (!formState.dirtyFields.email) {
      setIsDisabledEmail(true)
    } else {

      if (EmailState.invalid) {
        setIsDisabledEmail(true)
      } else {
        setIsDisabledEmail(false)
      }
    }
  }, [EmailState.invalid, formState])
  useEffect(() => {
    if (!formState.dirtyFields.phone) {
      setIsDisabledPhone(true)
    } else {
      console.log(phoneState)
      if (phoneState.invalid) {
        setIsDisabledPhone(true)
      } else {
        setIsDisabledPhone(false)
      }
    }
  }, [phoneState.invalid, formState])
  return (
    <div className="bg-blue-1 pt-10 flex items-center justify-center">
      <div className="p-12 w-[440px] rounded-[20px] bg-white border border-grey-1">
        <div className="text-center text-grey-9 text-2xl font-bold mb-12">회원가입</div>
        <div className="flex justify-center mb-2.5">
          <img className="w-[112px]" src={AvatarImage} alt="" />
        </div>
        <form onSubmit={handlerSubmit} className="grid gap-4">
          <Input
            id="name"
            placeholder="이름"
            label="이름"
            isRequired
            {...register('name')}
          />
          <div className="grid grid-cols-[1fr_90px] items-end gap-2.5  ">
            <Input
              id="email"
              placeholder="이메일"
              label="이메일"
              isRequired
              timer={email.timer}
              variant={email.variant}
              {...register('email')}
            />
            <div>
              <Button
                type="button"
                variant="outline_primary"
                disabled={isDisabledEmail}
                onClick={authorizeEmail}
              >
                인증 받기
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-[1fr_90px] items-end gap-2.5 ">
            <Input
              id="phone"
              placeholder="휴대폰 번호"
              label="휴대폰 번호"
              isRequired
              variant={phone.variant}
              timer={phone.timer}
              {...register('phone')}
            />
            <div>
              {/* get certified */}
              <Button
                type="button"
                variant="outline_primary"
                disabled={isDisabledPhone}
                onClick={authorizePhone}
              >
                인증 받기
              </Button>
            </div>
          </div>
          <Input
            id="password"
            isRequired
            label="비밀번호"
            placeholder="비밀번호"
            type="password"
            {...register('password')}
          />
          <Input
            type="password"
            id="confirmPassword"
            isRequired
            label="비밀번호 재입력"
            placeholder="비밀번호 재입력"
            {...register('confirmPassword')}
          />
          <Input
            id="organization"
            isRequired
            label="소속기관"
            placeholder="소속기관"
            disabled={isDisabledOrganization}
            variant={isDisabledOrganization ? 'disabled' : 'default'}
            {...register('organization')}
          />
          <Controller
            control={control}
            name="isOrganization"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="flex items-center gap-2">
                {/* i dont have agency */}
                <input
                  onClick={handlerClickOrganization}
                  onChange={onChange} // send value to hook form
                  onBlur={onBlur} // notify when input is touched/blur
                  checked={value}
                  type="checkbox"
                />
                <div className="text-grey-3 text-sm font-medium">소속기관이 없습니다.</div>
              </div>
            )}
          />
          <Button disabled={!formState.isValid} type="submit" variant="primary" >다음</Button>
        </form>
      </div>
    </div>
  )
}

export default JoinMemberShip