import React, { useState } from "react";
import Modal from "../../../components/modal";
import src from "../../../assets/course-image.png";

function CourseStatusLearning() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="bg-white px-4 py-4 rounded-lg border border-gray-100 shadow-sm">
      <div className="flex justify-between items-center text-center p-3 mb-2 border-b">
        <h3 className="text-lg font-bold">평가 구성표</h3>
        <div className="flex gap-2">
          <button
            className="flex gap-2 text-md text-gray-400 justify-center items-center cursor-pointer text-sm"
            aria-label="Edit course status"
            onClick={openModal}
          >
            지각정책
          </button>
          <div className="flex gap-2 text-gray-100"> | </div>
          <button
            className="flex gap-2 text-md text-gray-400 justify-center items-center cursor-pointer text-sm"
            aria-label="Edit course status"
            onClick={openModal}
          >
            성적 공개 정책
          </button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 p-2">
        <div className="flex flex-col justify-between bg-gray-50 border border-gray-100 p-6 rounded-lg">
          <div>
            <h3 className="text-md font-semibold">최다 실행</h3>
            <p className="text-grey-3 text-xs">가장 많이 실행된 횟수</p>
          </div>
          <div className="flex justify-end items-end">
            <p className="text-xl font-bold text-blue-500">83</p>
            <span className="text-md text-grey-3">%</span>
          </div>
        </div>

        <div className="flex flex-col justify-between bg-gray-50 border border-gray-100 p-6 rounded-lg">
          <div>
            <h3 className="text-md font-semibold">최다 실행</h3>
            <p className="text-grey-3 text-xs">가장 많이 실행된 횟수</p>
          </div>
          <div className="flex justify-end items-end">
            <p className="text-xl font-bold text-blue-500">83</p>
            <span className="text-md text-grey-3">%</span>
          </div>
        </div>

        <div className="flex flex-col justify-between bg-gray-50 border border-gray-100 p-6 rounded-lg">
          <div>
            <h3 className="text-md font-semibold">최다 실행</h3>
            <p className="text-grey-3 text-xs">가장 많이 실행된 횟수</p>
          </div>
          <div className="flex justify-end items-end">
            <p className="text-xl font-bold text-blue-500">83</p>
            <span className="text-md text-grey-3">%</span>
          </div>
        </div>

        <div className="flex flex-col justify-between bg-gray-50 border border-gray-100 p-6 rounded-lg">
          <div>
            <h3 className="text-md font-semibold">최다 실행</h3>
            <p className="text-grey-3 text-xs">가장 많이 실행된 횟수</p>
          </div>
          <div className="flex justify-end items-end">
            <p className="text-xl font-bold text-blue-500">83</p>
            <span className="text-md text-grey-3">%</span>
          </div>
        </div>
      </div>

      {isOpen && (
        <Modal
          title="지각 정책"
          onClose={() => {
            closeModal();
          }}
          size="lg"
        >
          <div className="flex justify-between items-center mb-4">
            <div className="w-full p-2">
              <div className="grid grid-cols-[35%_65%] gap-4 items-center rounded bg-gray-50 border border-gray-100 mb-6 p-4">
                <img
                  src={src}
                  alt="Course"
                  className="w-full h-36 rounded-lg"
                />
                <div className=" space-y-2">
                  <p className="text-xl font-bold border-b pb-2">
                    강의명: 채피의 인기 강의 1{" "}
                  </p>
                  <p className="text-md text-gray-500">
                    <span className="font-semibold">수강일자: </span>
                    23.02.04 ~ 23.02.04
                  </p>
                  <p className="text-md text-gray-500">
                    <span className="font-semibold">강사: </span>
                    김강사
                  </p>
                </div>
              </div>
              <div className="bg-gray-50 border border-gray-100 p-4 rounded">
                <h3 className="text-lg font-semibold mb-4">
                  지각 정책에 관련된 텍스트가 들어갑니다
                </h3>
                <ol className="list-decimal pl-5 space-y-2">
                  <li>
                    제1항의 해임건의는 국회재적의원 3분의 1 이상의 발의에 의하여
                    국회재적의원 과반수의 찬성이 있어야 한다. 외국인은 국제법과
                    조약이 정하는 바에 의하여 그 지위가 보장된다.
                  </li>
                  <li>
                    국채를 모집하거나 예산외에 국가의 부담이 될 계약을 체결하려
                    할 때에는 정부는 미리 국회의 의결을 얻어야 한다. 헌법재판소
                    재판관의 임기는 6년으로 하며, 법률이 정하는 바에 의하여
                    연임할 수 있다.
                  </li>
                  <li>
                    제1항의 해임건의는 국회재적의원 3분의 1 이상의 발의에 의하여
                    국회재적의원 과반수의 찬성이 있어야 한다. 외국인은 국제법과
                    조약이 정하는 바에 의하여 그 지위가 보장된다. 국채를
                    모집하거나 예산외에 국가의 부담이 될 계약을 체결하려 할
                    때에는 정부는 미리 국회의 의결을 얻어야 한다. 헌법재판소
                    재판관의 임기는 6년으로 하며, 법률이 정하는 바에 의하여
                    연임할 수 있다
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              className="bg-blue-500 text-white py-2 px-4 w-full rounded"
              onClick={() => {
                closeModal();
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CourseStatusLearning;
