import Button from "../../components/materials/Button";
import Input from "../../components/materials/Input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const changePasswordSchema = z
  .object({
    existingPassword: z.string().min(1),
    newPassword: z.string().min(1),
    checkPassword: z.string().min(1),
  })
  .refine((data) => data.newPassword === data.checkPassword, {
    message: "password not match",
  });

type ChangePasswordSchema = z.infer<typeof changePasswordSchema>;

const ChangePassword = () => {
  const { register, handleSubmit, formState } = useForm<ChangePasswordSchema>({
    resolver : zodResolver(changePasswordSchema)
  });

  const onSubmit = handleSubmit(() => {});

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="flex justify-between items-center">
          <div>비밀번호 변경</div>
          <div className="w-[120px]">
            <Button disabled={!formState.isValid} type="submit" variant="primary" size="sm">
              변경 완료
            </Button>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-[100px_1fr] gap-5">
          <div
            className="
            flex items-center
            text-base font-medium text-grey-3
          "
          >
            기존 비밀번호
          </div>
          <Input {...register('existingPassword')}  type="password" id="existingPassword" />
          <div
            className="
            flex items-center
            text-base font-medium text-grey-3
          "
          >
            새 비밀번호
          </div>
          <Input {...register('newPassword')} type="password" id="newPassword" />
          <div
            className="
            flex items-center
            text-base font-medium text-grey-3
          "
          >
            비밀번호 확인
          </div>
          <Input {...register('checkPassword')} type="password" id="checkPassword" />
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
