import { ArrowUp2, ArrowDown2 } from "iconsax-react";
import { useEditorContext } from "../../../context/editorContext";
import { useState } from "react";
import IconProhibit from "../../Editor/content/iconProhibit";
import IconCode from "../icons/iconCode";
import IconTime from "../icons/iconTime";
import IconBook from "../icons/iconBook";
import List from "../../Editor/content/Right/list";
import Recommendation from "./recommendation";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
const Right = () => {
  const { output, isDark } = useEditorContext();
  const [isExpand, setExpand] = useState(false);
  const expandTab = function () {
    setExpand(!isExpand);
  };
  return (
    <div className="bg-bg_4 dark:bg-grey-7 relative w-full">
      {/* execution results */}
      <div
        className={`
        bg-bg_4 dark:bg-grey-7
          absolute top-0 w-full cursor-pointer 
          z-10 overflow-hidden
          transition-all duration-500
          ${isExpand ? "h-full" : "h-14"}
        `}
      >
        <div
          onClick={expandTab}
          className="
            px-5 text-grey-6 text-xl font-bold bg-grey-1
            dark:bg-grey-8 dark:text-grey-4 h-14
            flex items-center justify-between
          "
        >
          <div>제출 결과</div>
          {isExpand ? <ArrowDown2 size="20" /> : <ArrowUp2 size="20" />}
        </div>
        <div className="p-4 relative h-full">
          <div className="grid grid-cols-2 gap-2 mb-2">
            <div>
              <div className="bg-white dark:border-none dark:bg-grey-8 border border-bg_2 rounded-lg px-6 py-2">
                <div className="text-grey-7 dark:text-white text-base font-bold">총점</div>
                <CircularProgressbarWithChildren
                  value={50}
                  circleRatio={0.75}
                  styles={buildStyles({
                    rotation: 1 / 2 + 1 / 8,
                    trailColor: "#eee",
                    pathColor: "#2861EF",
                    textSize: "16px",
                    textColor: "#2861EF",
                  })}
                >
                  <div>
                    <div className="text-blue-4 text-base font-bold">59점</div>
                    <div className="text-grey-4 text-base font-medium">
                      / 100점
                    </div>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div className="dark:bg-grey-8 dark:border-none text-base font-bold mt-3 flex justify-between px-4 py-2 bg-white border border-bg_2 rounded-lg">
                <div className="text-grey-7  dark:text-white">표절율</div>
                <div className="text-error">31%</div>
              </div>
            </div>

            <div className="grid gap-3">
              <div className="
                dark:bg-grey-8 dark:border dark:border-grey-4 dark:text-white
                h-max flex gap-2 rounded-lg p-4 bg-grey-2 border border-grey-3">
                <IconCode color={`${isDark ? "white" : "#333333" }`} />
                <div>기능</div>
                <div className="ml-auto">32</div>
              </div>
              <div className="h-max dark:bg-grey-6 dark:border dark:border-grey-4 text-grey-3 flex gap-2 rounded-lg p-4 bg-grey-1 border border-grey-2">
                <IconTime />
                <div>효율</div>
                <div className="ml-auto">58</div>
              </div>
              <div className="h-max dark:bg-grey-6 dark:border dark:border-grey-4 text-grey-3 flex gap-2 rounded-lg p-4 bg-grey-1 border border-grey-2">
                <IconBook />
                <div>가독성</div>
                <div className="ml-auto">60</div>
              </div>
            </div>
          </div>

          <div className="text-grey-3 font-bold  text-base">
            테스트 1 채점 결과
          </div>
          <div
            className="
              mt-4 flex gap-2 items-center border border-error
              p-2 rounded-lg bg-white dark:bg-grey-7
            "
          >
            <div>
              <IconProhibit />
            </div>
            <div className="text-error text-base font-medium">오답입니다.</div>
            <div className="bg-error text-white px-[10px] py-[6px] rounded-[4px]  ml-auto">
              60점
            </div>
          </div>
          <div className="text-grey-5 mt-2 mb-4 dark:text-grey-1 text-xs font-medium text-right">
            아쉬워요! 틀린 부분을 선택해 다시 시도해볼까요?
          </div>
          <List/>
        </div>
      </div>
      <Recommendation/>

      <div className="p-4 mt-14 text-base font-bold text-grey-7 dark:text-white">
        {output && output.map((line: any, i: any) => <p key={i}>{line}</p>)}
      </div>
    </div>
  );
};

export default Right;
