import React from "react";
import MdiEdit from "../../assets/mdi_edit.png";

type Headers = {
  accessorKey: string;
  header: string;
  width: string;
};

type DataTable = {
  id: number;
  no: number;
  member: string;
  name: string;
  email: string;
  mobilePhone: string;
  organization: string;
  student: string;
  intructor: string;
  personCharge: string;
  state: string;
  date: string;
  crystal: string;
};
type CustomProps = {
  columns: Headers[];
  rows: DataTable[];
  onChange?: (value: string) => void;
};

const Custom: React.FC<CustomProps> = ({ columns, rows, onChange }) => {
  return (
    <div className="flex overflow-hidden overflow-x-scroll rounded-2xl border border-[#E0E0E0]">
      <table className="w-full table-fixed ">
        <thead>
          <tr className="h-[48px] bg-[#E0E0E0] border-b border-[#E0E0E0]">
            {columns?.map((col: any, ky1: number) => (
              <th key={ky1} className={`w-[100%] pl-4 text-left`}>
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((cols, ky2) => (
            <tr
              key={ky2}
              className={`${
                ky2 + 1 === rows.length ? "" : "border-b"
              }  h-[48px]`}
            >
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.no}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.member}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.name}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.email}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.mobilePhone}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.organization}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.student}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.intructor}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.personCharge}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.state}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                {cols.date}
              </td>
              <td className="pl-4 text-sm font-medium text-left break-words">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange && onChange(cols.crystal);
                  }}
                >
                  <img src={MdiEdit} alt="" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Custom;
