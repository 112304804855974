import React, { useEffect, useState } from "react";
import SelectDropdown from "../../../components/materials/SelectDropdown";
import Pagination from "../../../components/pagination";
import Input from "../../../components/materials/Input";
import DatePickers from "../../../components/materials/DatePickers";
import IconUpload from "../../../assets/upload.png";
import ModalMembership from "../components/ModalMembership";
import Avatar from "../../../assets/avatar.png";
import ImgUpload from "../../../assets/img-icon.png";
import ManagemenetStudentsTable from "../../../components/tables/management-students";
import ModalStudentProgress from "../components/ModalStudentProgress";

interface Lecture {
  id: number;
  member: string;
  courseName: string;
  RepLecture: string;
  studentID: number;
  studentName: string;
  CoursePeriode: string;
  CourseStatus: string;
  complStatus: string;
  cancelOfClass: string;
}

interface LecturesManagementInterface {
  accessorKey: string;
  header: string;
  width: string;
}

const lecturesData: Lecture[] = [
  {
    id: 1,
    member: "01",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "02",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "",
  },
  {
    id: 1,
    member: "03",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "",
  },
  {
    id: 1,
    member: "04",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "05",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "06",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "07",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "08",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
];
const lecturesDataFill: Lecture[] = [
  {
    id: 1,
    member: "01",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "02",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "03",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "04",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "05",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
  {
    id: 1,
    member: "06",
    courseName: "파이썬프로그래밍",
    RepLecture: "김강사",
    studentID: 2311012,
    studentName: "김강사",
    CoursePeriode: "김강사",
    CourseStatus: "2023.01.02 00:00:00",
    complStatus: "진행중",
    cancelOfClass: "공개",
  },
];

const columns: LecturesManagementInterface[] = [
  {
    accessorKey: "member",
    header: "번호",
    width: "80px",
  },

  {
    accessorKey: "courseName",
    header: "강좌명",
    width: "80px",
  },
  {
    accessorKey: "RepLecture",
    header: "대표강사",
    width: "80px",
  },
  {
    accessorKey: "studentID",
    header: "학생ID",
    width: "80px",
  },
  {
    accessorKey: "studentName",
    header: "학생이름",
    width: "80px",
  },
  {
    accessorKey: "CoursePeriode",
    header: "수강기간",
    width: "80px",
  },
  {
    accessorKey: "CourseStatus",
    header: "수강상태",
    width: "80px",
  },
  {
    accessorKey: "complStatus",
    header: "수료여부",
    width: "80px",
  },
  {
    accessorKey: "cancelOfClass",
    header: "수강취소",
    width: "80px",
  },
];

export default function StudentsManagement() {
  const [openMenu, setOpenMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdditional, setIsAdditional] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [dataCurrent, setDataCurrent] = useState(false);
  const [selectYear, setSelectYear] = useState("");
  const [selectMonth, setSelectMonth] = useState("");
  const [detailStudent, setDetailStudent] = useState(false);

  const handleAdditional = (val: any) => {
    setTimeout(() => {
      if (dataCurrent) {
        setIsAdditional(!isAdditional);
        setIsConfirm(true);
      } else {
        setIsAdditional(!isAdditional);
        setIsCompleted(true);
      }
    }, 100);
  };

  const handleConfirm = (val: any) => {
    setTimeout(() => {
      if (dataCurrent) {
        setIsConfirm(false);
        setIsCompleted(true);
      }
    }, 100);
  };

  useEffect(() => {}, [selectYear, selectMonth]);

  return (
    <>
      {isAdditional && (
        <ModalMembershipBulk
          show={true}
          onClose={() => setIsAdditional(false)}
          confirm={() => handleAdditional(true)}
          isExits={dataCurrent}
        />
      )}
      {isCompleted && (
        <ModalComplete show={true} onClose={() => setIsCompleted(false)} />
      )}
      {isConfirm && (
        <ModalIsConfirm
          show={true}
          onClose={() => setIsConfirm(false)}
          confirm={() => handleConfirm(true)}
        />
      )}
      {detailStudent && (
        <ModalStudentProgress
          show={detailStudent}
          onClose={() => setDetailStudent(false)}
        ></ModalStudentProgress>
      )}
      <div className="min-h-screen p-6 bg-white rounded-lg">
        <h2 className="pb-2 text-xl font-bold border-b">수강생</h2>
        <div className="grid flex-wrap items-start justify-between grid-cols-3 gap-4 py-5">
          <label
            htmlFor="강의ID"
            className="flex flex-col gap-y-1 max-w-[250px] w-full mx-auto"
          >
            <span className="text-base font-bold ">강의ID</span>
            <Input id="강의ID" />
          </label>
          <label
            htmlFor="학생이름"
            className="flex flex-col gap-y-1 max-w-[250px] w-full mx-auto"
          >
            <span className="text-base font-bold ">학생이름</span>
            <Input id="학생이름" />
          </label>
          <label
            htmlFor="강좌명"
            className="flex flex-col gap-y-1 max-w-[250px] w-full mx-auto"
          >
            <span className="text-base font-bold ">강좌명</span>
            <Input id="강좌명" />
          </label>
          <label
            htmlFor="수강기간"
            className="flex flex-col gap-y-1 max-w-[250px] w-full mx-auto"
          >
            <span className="text-base font-bold ">수강 기간</span>
            <div className="flex items-center w-full gap-4">
              <SelectDropdown
                options={[
                  { value: "", label: "연도" },
                  { value: "2024", label: "2024년" },
                ]}
                value={selectYear}
                onChange={setSelectYear}
                size="justify-start items-center  w-[103px] px-2 h-[55px] rounded-xl border cursor-pointer flex "
              />
              <span>~</span>
              <SelectDropdown
                options={[
                  { value: "", label: "분기" },
                  { value: "2", label: "2분기" },
                ]}
                value={selectMonth}
                onChange={setSelectMonth}
                size="justify-start items-center  w-[103px] px-2 h-[55px] rounded-xl border cursor-pointer flex "
              />
            </div>
          </label>
          <label
            htmlFor="상태"
            className="flex flex-col gap-y-1 max-w-[250px] w-full mx-auto"
          >
            <span className="text-base font-bold ">상태</span>
            <div className="flex items-center gap-4">
              <label htmlFor="" className="flex gap-2">
                <input type="checkbox" />
                <span className="text-sm font-medium ">진행중</span>
              </label>
              <label htmlFor="" className="flex gap-2">
                <input type="checkbox" />
                <span className="text-sm font-medium ">수강신청중</span>
              </label>
              <label htmlFor="" className="flex gap-2">
                <input type="checkbox" />
                <span className="text-sm font-medium ">종료</span>
              </label>
            </div>
          </label>
          <label
            htmlFor="수료여부"
            className="flex flex-col gap-y-1 max-w-[250px] w-full mx-auto"
          >
            <span className="text-base font-bold ">수료여부</span>
            <div className="flex items-center gap-4">
              <label htmlFor="" className="flex gap-2">
                <input type="radio" name="상태" />
                <span className="text-sm font-medium ">모두</span>
              </label>
              <label htmlFor="" className="flex gap-2">
                <input type="radio" name="상태" />
                <span className="text-sm font-medium ">이수</span>
              </label>
              <label htmlFor="" className="flex gap-2">
                <input type="radio" name="상태" />
                <span className="text-sm font-medium ">미이수</span>
              </label>
            </div>
          </label>
        </div>

        <div className="flex items-center justify-end gap-5 py-3 mb-10 border-b border-slate-200">
          <button className="min-w-[88px] w-[88px] h-[32px] rounded bg-[#A5A5A5] text-white text-sm font-bold">
            초기화
          </button>
          <button className="min-w-[88px] w-[88px] h-[32px] rounded bg-[#2861EF] text-white text-sm font-bold">
            검색
          </button>
        </div>
        <div className="flex flex-col items-start justify-start">
          <ul>
            <li className="border-b-2 cursor-pointer border-slate-700">
              <span className="px-3 text-base font-medium ">총 10건</span>
            </li>
          </ul>
          <div className="flex items-center self-end gap-5 py-2 ">
            <div
              onClick={() => setOpenMenu(!openMenu)}
              className="relative flex gap-2 cursor-pointer"
            >
              <div className="text-sm font-medium text-[#A5A5A5]">
                10개씩 보기{" "}
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className=" stroke-[#A5A5A5]"
                >
                  <path
                    d="M3.33203 6.66797L7.9987 11.3346L12.6654 6.66797"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {openMenu && (
                <div className="shadow-menu rounded-xl top-[100%] absolute bg-white  w-[80px] drop-shadow-lg">
                  <div className="flex items-center gap-2 px-4 py-2">
                    <div className="text-sm font-medium text-grey-6">Test1</div>
                  </div>
                </div>
              )}
            </div>
            <button className=" text-sm font-medium text-[#A5A5A5] flex items-center gap-1 border p-2 border-slate-300 rounded-lg">
              표 내보내기
              <img src={IconUpload} alt="" />
            </button>
          </div>
        </div>
        <ManagemenetStudentsTable
          columns={columns}
          rows={
            selectYear !== "" && selectMonth !== ""
              ? lecturesDataFill
              : lecturesData
          }
          onChange={() => {
            setIsAdditional(true);
            setDataCurrent(true);
          }}
          checkStudentCard={() => {
            setDetailStudent(true);
          }}
        />
        <div className="flex justify-end w-full my-2">
          <button
            className="py-2 px-6 text-[#2861EF] border border-[#2861EF] rounded-lg text-xs"
            onClick={() => {
              setIsAdditional(true);
              setDataCurrent(false);
            }}
          >
            + 수강생추가
          </button>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={9}
          onPageChange={setCurrentPage}
        />
      </div>
    </>
  );
}

const ModalMembershipBulk = ({
  show = false,
  onClose = () => {},
  confirm = () => {},
  isExits = true,
}) => {
  return (
    <ModalMembership show={true} onClose={onClose}>
      <div className="w-[440px] min-h-fit flex flex-col items-center py-10">
        <h1 className="text-2xl font-bold ">회원가입</h1>
        <div className="relative flex mt-10 mb-8">
          <img
            src={Avatar}
            alt=""
            className="border rounded-full  border-slate-200 bg-[#F2F4F6]"
          />
          <button className="absolute bottom-0 right-0 p-2 rounded-full bg-[#A5A5A5] h-fit">
            <img src={ImgUpload} alt="" />
          </button>
        </div>
        <div className="flex flex-col w-full gap-4 px-5">
          <label htmlFor="이름" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              이름
            </span>
            <Input id="이름" placeholder="홍길동" />
          </label>
          <label htmlFor="이메일" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              이메일
            </span>
            <Input id="이메일" placeholder="abcd@naver.com" />
          </label>
          <label htmlFor="휴대폰번호" className="flex flex-col gap-y-1">
            <span className="text-base font-bold ">휴대폰 번호</span>
            <Input id="휴대폰번호" placeholder="010-0000-0000" />
          </label>
          <label htmlFor="비밀번호" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              비밀번호
            </span>
            <Input id="비밀번호" placeholder="***********" type="password" />
          </label>
          <label htmlFor="비밀번호재입력" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              비밀번호 재입력
            </span>
            <Input
              id="비밀번호재입력"
              placeholder="***********"
              type="password"
            />
          </label>
          <label htmlFor="소속기관" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              소속기관
            </span>
            <Input id="소속기관" placeholder="성균관대학교" />
          </label>
          <label htmlFor="" className="flex self-start gap-2">
            <input
              id="소속기관이없습니다."
              type="checkbox"
              className="w-6 h-6 custom-checkbox1-disabled"
            />
            <label
              htmlFor="소속기관이없습니다."
              className="text-base text-[#A5A5A5]"
            >
              소속기관이 없습니다.
            </label>
          </label>
          <label htmlFor="소속기관" className="flex flex-col gap-y-1">
            <span className="text-base font-bold after:content-['*'] after:text-red-500 ">
              권한
            </span>
            <div className="flex gap-4">
              <label htmlFor="" className="flex self-start gap-2">
                <input
                  id="학생"
                  type="checkbox"
                  className="w-6 h-6 min-w-6 custom-checkbox1"
                />
                <label htmlFor="학생" className="text-base text-sub-1">
                  학생
                </label>
              </label>
              <label htmlFor="" className="flex self-start gap-2">
                <input
                  id="강사"
                  type="checkbox"
                  className="w-6 h-6 min-w-6 custom-checkbox1"
                />
                <label htmlFor="강사" className="text-base text-sub-1">
                  강사
                </label>
              </label>
              <label htmlFor="" className="flex self-start gap-2">
                <input
                  id="담당자"
                  type="checkbox"
                  className="w-6 h-6 min-w-6 custom-checkbox1"
                />
                <label htmlFor="담당자" className="text-base text-sub-1">
                  담당자
                </label>
              </label>
            </div>
          </label>
        </div>
        <div className="w-full px-5 mt-10">
          {isExits ? (
            <>
              <button
                className="w-full rounded-lg mt-5 text-white bg-grey-3 h-[56px] flex justify-center items-center"
                onClick={confirm}
              >
                등록
              </button>
              <button
                className="w-full rounded-lg mt-2 text-white bg-red-3 h-[56px] flex justify-center items-center"
                onClick={onClose}
              >
                삭제
              </button>
            </>
          ) : (
            <button
              className="w-full rounded-lg mt-5 text-white bg-primary h-[56px] flex justify-center items-center"
              onClick={confirm}
            >
              등록
            </button>
          )}
        </div>
      </div>
    </ModalMembership>
  );
};

const ModalComplete = ({ show = false, onClose = () => {} }) => {
  return (
    <ModalMembership show={show}>
      <div className="flex flex-col items-center w-full min-h-[200px] justify-between">
        <h1 className="text-lg font-bold text-grey-8">추가완료</h1>
        <p className=" text-sm text-[#555555]">회원이 추가되었습니다.</p>
        <button
          className="w-full rounded-lg mt-5 text-white bg-primary h-[56px] flex justify-center items-center"
          onClick={onClose}
        >
          닫기
        </button>
      </div>
    </ModalMembership>
  );
};

const ModalIsConfirm = ({
  show = false,
  onClose = () => {},
  confirm = () => {},
}) => {
  return (
    <ModalMembership show={show}>
      <div className="flex flex-col items-center w-full min-h-[200px] justify-between">
        <h1 className="text-lg font-bold text-grey-8">
          정말 삭제하시겠습니까?
        </h1>
        <p className=" text-sm text-[#555555]">
          삭제하면 회원과 관련된 모든 정보가 삭제됩니다.
        </p>
        <div className="w-full">
          <button
            className="w-full rounded-lg mt-5 text-white bg-grey-3 h-[40px] flex justify-center items-center"
            onClick={confirm}
          >
            닫기
          </button>
          <button
            className="w-full rounded-lg mt-2 text-white bg-red-3 h-[40px] flex justify-center items-center"
            onClick={onClose}
          >
            삭제
          </button>
        </div>
      </div>
    </ModalMembership>
  );
};
