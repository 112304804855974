import React, { useState } from "react";
import Button from "../../../components/materials/Button";
import { ArrowRight2, DocumentDownload, MedalStar } from "iconsax-react";
import Modal from "../../../components/modal";
import { useNavigate } from "react-router-dom";

type CourseCardProps = {
  id?: number;
  title: string;
  instructor: string;
  instructorImage: string;
  progress?: number;
  status: string;
  slug?: string;
  category: string;
  startDate: string;
  endDate: string;
  badge: string;
  src: string;
};

const CourseCard: React.FC<CourseCardProps> = ({
  id,
  title,
  instructor,
  instructorImage,
  progress,
  status,
  slug,
  category,
  startDate,
  endDate,
  badge,
  src,
}) => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [agree, setAgree] = useState(false);
  const [showModalTerminate, setShowModalTerminate] = useState(false);
  const [showModalConfimTerminate, setShowModalConfirmTerminate] =
    useState(false);
  const [
    showModalConfimCancelRegisration,
    setShowModalConfirmCancelRegisration,
  ] = useState(false);

  const openModalTerminate = () => setShowModalTerminate(true);
  const closeModalTerminate = () => setShowModalTerminate(false);
  const openModalConfirmTerminate = () => setShowModalConfirmTerminate(true);
  const closeModalConfirmTerminate = () => setShowModalConfirmTerminate(false);
  const openModalConfirmCancelRegisration = () =>
    setShowModalConfirmCancelRegisration(true);
  const closeModalConfirmCancelRegisration = () =>
    setShowModalConfirmCancelRegisration(false);

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const handleReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReview(e.target.value);
  };

  const handleAgreeChange = () => {
    setAgree(!agree);
  };

  const handleSubmit = () => {
    console.log("Review Submitted");
    closeModalTerminate();
  };

  return (
    <div className="bg-gray-50 border border-gray-100 rounded-lg overflow-hidden p-4">
      <img src={src} alt="Course" className="w-full " />
      <div className="">
        <h3 className="text-lg font-bold mt-2">{title}</h3>
        <p className="text-xs text-gray-500">{`${startDate} ~ ${endDate}`}</p>
        <div className="flex items-center my-4">
          <img
            src={instructorImage}
            alt={instructor}
            className="w-6 h-6 rounded-full mr-2"
          />
          <p className="text-sm text-gray-500">{instructor}</p>
          <span className="ml-auto bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded">
            {badge}
          </span>
        </div>
        {progress && (
          <div className=" bg-white rounded-lg mb-4 p-2 px-4">
            <p className="text-sm text-center border-b font-semibold mb-1 p-2 ">
              진도율
            </p>

            <div className="flex justify-center gap-2 items-center mb-1">
              <p className="text-sm font-bold text-blue-600">{`${progress}%`}</p>
              <p className="text-sm text-gray-500 mt-1">{status} 😆</p>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-2">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        )}
        {category === "in-progress" ? (
          <Button size="sm" variant="primary">
            <div
              className="flex justify-center items-center gap-1"
              onClick={() => {
                navigate(`/learning/${id}`);
              }}
            >
              수업 바로가기 <ArrowRight2 size="16" />
            </div>
          </Button>
        ) : category === "termination" ? (
          <>
            <Button size="sm" variant="primary" onClick={openModalTerminate}>
              <div className="flex justify-center items-center gap-1">
                수업평가작성
              </div>
            </Button>
            {showModalTerminate && (
              <Modal
                title="수업 평가"
                onClose={() => {
                  closeModalTerminate();
                }}
                size="lg"
              >
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-3">강의정보</h3>
                  <div className="grid grid-cols-[40%_60%] gap-4 items-center">
                    <img
                      src={src}
                      alt="Course"
                      className="w-full h-36 rounded-lg"
                    />
                    <div className=" space-y-2">
                      <p className="text-xl font-bold border-b pb-2">{title}</p>
                      <p className="text-md text-gray-500">
                        <span className="font-semibold">수강일자: </span>
                        {`${startDate} ~ ${endDate}`}
                      </p>
                      <p className="text-md text-gray-500">
                        <span className="font-semibold">강사: </span>
                        {instructor}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-3">별점 선택</h3>
                  <div className="flex flex-col items-center">
                    <p className="text-gray-500 font-semibold mb-3">
                      강의는 어땠나요?
                    </p>
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <button key={star} onClick={() => handleRating(star)}>
                          {star <= rating ? (
                            <MedalStar
                              size="32"
                              variant="Bold"
                              color="#2861EF"
                              className="mb-3 mx-2"
                            />
                          ) : (
                            <MedalStar size="32" className="mb-3 mx-2" />
                          )}
                        </button>
                      ))}
                    </div>
                    <p className="text-grey-3 mb-3">별점을 선택해주세요</p>
                  </div>
                </div>
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-3">내용 작성</h3>
                  <textarea
                    value={review}
                    onChange={handleReviewChange}
                    rows={4}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="강사님이 너무 잘 가르쳐 주셔서 코딩에 대해 많이 알게 되었습니다 ㅎㅎ 감사합니다~!"
                  />
                  <div className="flex items-center mt-2">
                    <input
                      type="checkbox"
                      checked={agree}
                      onChange={handleAgreeChange}
                    />
                    <label className="ml-2 text-sm text-gray-500">
                      마케팅 활용정보 제공에 모든 내용을 확인했으며, 이에
                      동의합니다
                    </label>
                  </div>
                </div>
                <div className="flex justify-center items-center mb-3">
                  <Button
                    size="sm"
                    variant={agree ? "primary" : "grey"}
                    onClick={() => {
                      if (agree) {
                        openModalConfirmTerminate();
                      }
                    }}
                  >
                    수업 평가 등록
                  </Button>
                </div>
              </Modal>
            )}
            {showModalConfimTerminate && (
              <Modal
                title="수업 평가가"
                onClose={() => {
                  closeModalConfirmTerminate();
                }}
                size="sm"
              >
                <div className="space-y-4">
                  <div className="text-center">
                    <p className="text-sm text-gray-500">
                      정말로 수업을 평가하시겠습니까?
                    </p>
                  </div>
                  <div className="flex justify-center space-x-4">
                    <button
                      className="flex gap-1 justify-center items-center px-6 py-1 bg-primary text-white rounded-md"
                      onClick={() => {
                        closeModalConfirmTerminate();
                        handleSubmit();
                      }}
                    >
                      응, 평가할래요
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          </>
        ) : category === "completion" ? (
          <Button size="sm" variant="primary">
            <div className="flex justify-center items-center gap-1">
              수료증 내려받기 <DocumentDownload size="16" />
            </div>
          </Button>
        ) : (
          <>
            <div className="space-y-2">
              <Button size="sm" variant="primary">
                <div className="flex justify-center items-center gap-1">
                  강의 상세페이지 <ArrowRight2 size="16" />
                </div>
              </Button>
              <Button
                size="sm"
                variant="grey"
                onClick={openModalConfirmCancelRegisration}
              >
                <div className="flex justify-center items-center gap-1">
                  수강 취소 신청 <ArrowRight2 size="16" />
                </div>
              </Button>
            </div>
            {showModalConfimCancelRegisration && (
              <Modal
                title="수강 취소 신청"
                onClose={() => {
                  closeModalConfirmCancelRegisration();
                }}
                size="sm"
              >
                <div className="space-y-4">
                  <div className="text-center">
                    <p className="text-sm text-gray-500">
                      수강을 취소하시겠습니까?
                    </p>
                  </div>
                  <div className="flex justify-center space-x-4">
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => {
                        closeModalConfirmTerminate();
                      }}
                    >
                      수강 취소하기
                    </Button>
                    <Button
                      size="sm"
                      variant="grey"
                      onClick={() => {
                        closeModalConfirmCancelRegisration();
                      }}
                    >
                      취소
                    </Button>
                  </div>
                </div>
              </Modal>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CourseCard;
